import { $addClass, $find, $findAll, $qs, $setCss, $setText } from 'fxdom/es';
import { html, delay, each, go } from 'fxjs/es';

export const confirm = ({ title, body, cancel, ok }) => {
  const prom = $.confirm(`${title ? html`<div class="title">${title}</div>` : ``}
      ${body ? html`<div class="popup-body">${body}</div>` : ``}`);
  const el = $qs('.don_dialog_msg:last-child');
  go(el, $addClass('mshop-pop-up-style-1'));
  if (cancel) {
    go(el, $find('.cancel'), $setText(cancel));
  }
  if (ok) {
    go(el, $find('.ok'), $setText(ok));
  }

  go(
    el,
    $findAll('button'),
    delay(1),
    each($setCss({ transition: 'background-color 200ms ease-out, border 200ms ease-out' })),
  );

  return prom;
};
