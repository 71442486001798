import { html, includes, sel, strMap, reject } from 'fxjs/es';
import { MShopUtilConstantS } from '../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import {
  LNB_BOTTOM_MENUS,
  LNB_INVITE_MENU,
  LNB_IS_NOT_AGREE_MENUS,
  LNB_MID_MENUS,
  LNB_TOP_MENUS,
} from './lnb-data.js';
import { messages } from './messages_id.js';

const makeLi = ({
  link,
  user,
  domain_name,
  active_page_name,
  title,
  page_name,
  icon,
  dataShow,
  blank,
  is_app,
}) => {
  return html`<li data-show="${!dataShow || dataShow(user, is_app)}">
    <a
      class="menu${page_name == 'question-list' ? ' question-list-a' : ''}"
      href="${blank
        ? blank(domain_name) + '" target="_blank'
        : `/${T.lang}/${UtilS.escape(domain_name)}/settings/${link}`}"
      data-active="${active_page_name === page_name}"
    >
      ${icon ? `<i class="d-block lnr ${icon}"></i>` : ''} ${T(title, { br: '<br />' })}
    </a>
  </li>`;
};

const makeMenu =
  ({ domain_name, active_page_name, user, is_app, klass = '' }) =>
  ({ title, page_name, icon, dataShow, collapses, blank, link }) => {
    if (collapses?.length) {
      return html`<li data-show="${!dataShow || dataShow(user, is_app)}">
        <div
          is="collapse"
          class="collapse${includes(active_page_name, collapses.map(sel('page_name')))
            ? ' opened'
            : ''} ${klass}"
        >
          <a class="menu collapse-anchor">
            <span><i class="d-block lnr ${icon}"></i> ${T(title, { br: '<br />' })}</span>
          </a>
          <div class="collapse-content">
            <ul>
              ${strMap((data) => {
                return makeLi({
                  user,
                  domain_name,
                  active_page_name,
                  is_app,
                  ...data,
                });
              }, collapses)}
            </ul>
          </div>
        </div>
      </li>`;
    } else {
      return makeLi({
        user,
        domain_name,
        active_page_name,
        title,
        link,
        page_name,
        icon,
        dataShow,
        blank,
        is_app,
      });
    }
  };

const hide_menu_ids_for_afreeca_crew = [
  'seller_products',
  'digital_product',
  'question_list',
  'shop_management',
  'my_shop',
  'youtube',
  'seller_guide',
  'solution',
];
const makeMenusHtml = ({ domain_name, page_name: active_page_name, user, is_app }) => {
  const is_afreeca_crew = user._.crew.id === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID;

  if (sel('_.store.invited_store_id', user) && !sel('_.store.is_invite_info', user)) {
    return makeMenu({ domain_name, active_page_name, user, is_app })(LNB_INVITE_MENU);
  } else if (!sel('_.store.is_agree', user)) {
    return strMap(makeMenu({ domain_name, active_page_name, user, is_app }), LNB_IS_NOT_AGREE_MENUS);
  } else {
    return html`
      ${strMap(
        makeMenu({ domain_name, active_page_name, user, is_app }),
        is_afreeca_crew
          ? reject((menu) => {
              return hide_menu_ids_for_afreeca_crew.includes(menu.id);
            }, LNB_TOP_MENUS)
          : LNB_TOP_MENUS,
      )}
      <li class="divider"></li>
      ${strMap(
        makeMenu({ domain_name, active_page_name, user, is_app }),
        is_afreeca_crew
          ? reject((menu) => {
              return hide_menu_ids_for_afreeca_crew.includes(menu.id);
            }, LNB_MID_MENUS)
          : LNB_MID_MENUS,
      )}
      <li class="divider"></li>
      <li class="for-the-seller">FOR THE CREATOR</li>
      ${strMap(
        ({ dataShow, blank, link, title, klass = '' }) => html` <li
          data-show="${!dataShow || dataShow(user, is_app)}"
        >
          <a
            class="menu-bottom ${klass}"
            href="${blank
              ? blank(domain_name) + '" target="_blank'
              : `/${T.lang}/${UtilS.escape(domain_name)}/settings/${link}`}"
          >
            ${T(title, { br: '<br />' })}
          </a>
        </li>`,
        is_afreeca_crew
          ? reject((menu) => {
              return hide_menu_ids_for_afreeca_crew.includes(menu.id);
            }, LNB_BOTTOM_MENUS)
          : LNB_BOTTOM_MENUS,
      )}
      <li class="menu-live-chat live_chat">${T(messages.menus.contact_marppleshop)}</li>
    `;
  }
};

export const makeStoreSettingsMenuHtml = (domain_name, page_name = '', user, solution_count, data = {}) => {
  const { is_app = false } = data;

  return html`
    <nav id="creator_settings_menu" class="setting_aside">
      <div class="only-mobile close-btn">
        <button type="button" class="btn btn-icon lnr lnr-cross"></button>
      </div>
      <div class="inner_menu_wrapper">
        <ul class="menu_items">
          ${makeMenusHtml({ domain_name, page_name, user, is_app })}
        </ul>
      </div>
    </nav>
  `;
};
