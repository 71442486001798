import { SVG_ICONS } from './svg_icons.js';

export const DISCLAIMER = {
  BULLET: SVG_ICONS.BULLET,
  TEXTS: [
    () => TT('pdf::uploader::3'),
    () => TT('pdf::uploader::4'),
    () => TT('pdf::uploader::5'),
    () => TT('pdf::uploader::6'),
    () => TT('pdf::uploader::7'),
  ],
};

export const FRAME_CUSTOM_DATA = '__custom_data';
