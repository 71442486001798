import { html, go } from 'fxjs/es';
import { PriceS } from '../../Price/S/Function/module/PriceS.js';
import { soldOutSizeIds } from '../../ProductColor/S/fs.js';
import { calcMakerPrice, resetProductColorPrice } from '../../ProductColorPrice/S/fs.js';
import { legacyHtml } from '../../Util/S/Function/util.js';
import { BpOptionS } from '../../BpOption/S/Function/module/BpOptionS.js';
import { UtilArrayS } from '../../Util/Array/S/Function/module/UtilArrayS.js';
import { BpOptionTmplS } from '../../BpOption/S/Tmpl/module/BpOptionTmplS.js';
import { BpOptionConstantS } from '../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { UtilObjS } from '../../Util/Object/S/Function/module/UtilObjS.js';
import { UtilS } from '../../Util/S/Function/module/UtilS.js';
import { reviewFloatStarRatingTmpl } from '../../OMP/Core/Cell/S/Tmpl/review.js';
import { OMPProductListConstantS } from '../../OMP/ProductList/S/Constant/module/OMPProductListConstantS.js';
import { requiringBorder } from '../F/Product/bpc_tmpl.js';
import { NewMakerPropertyBaseProductConstantS } from '../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { OMPCoreUtilS } from '../../OMP/Core/Util/S/Function/module/OMPCoreUtilS.js';
import { VectorEditorConstantS } from '../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { BpOptionKeyRingS } from '../../BpOption/KeyRing/S/Function/module/BpOptionKeyRingS.js';
import { BpOptionKeyRingConstantS } from '../../BpOption/KeyRing/S/Constant/module/BpOptionKeyRingConstantS.js';
import { BpOptionAcrylicFigureS } from '../../BpOption/AcrylicFigure/S/Function/module/BpOptionAcrylicFigureS.js';
import { BpOptionUploadTypeS } from '../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { NewMakerPropertyBaseProductS } from '../../NewMaker/Property/BaseProduct/S/Function/module/NewMakerPropertyBaseProductS.js';

const plusBpName = (product) =>
  [VectorEditorConstantS.STICKER_EDITOR].includes(product._.base_product.maker_type)
    ? ` (${product._.base_product._.base_product_sizes[0]['name' + G._en]})`
    : ``;

export const makePcNameHtml = (pc) => {
  const pc_name = pc['name' + _en];
  const bp_name = pc._.base_product['name' + _collabo + _en];
  const plus_bp_name = plusBpName(pc);
  if (G.collabo_type === '') {
    const bp_brand = go(
      pc._.base_product._.brand,
      (brand) => brand && brand.id != OMPProductListConstantS.OTHER_BRAND_ID && brand,
    );
    return html`
      ${bp_brand
        ? html`<div class="brand_name name">
            <a
              href="/product/list/${pc._.base_product
                .cate_list_id}?cate_item_id=&sort=pick&f_brand[]=${bp_brand.id}"
              >${bp_brand.name}</a
            >
          </div>`
        : ``}
      <div class="bp_name name">${bp_name.replace(bp_brand?.name, '').trim() + plus_bp_name}</div>
    `;
  }
  return pc_name && pc_name !== bp_name
    ? legacyHtml`
        <div class="pc_name name">${pc_name}</div>
        <div class="bp_name name">${bp_name + plus_bp_name}</div>
      `
    : legacyHtml` <div class="bp_name name">${bp_name + plus_bp_name}</div> `;
};

export const makeReviewSummary = ({ review_total }) => {
  if (UtilObjS.isEmNil(review_total)) return '';

  const { count, avg } = review_total;

  const avg_score = count === 0 ? 0 : parseFloat(avg);

  const DEC = 10;
  const score_float = parseInt(avg_score * DEC) / DEC;

  return html`<div class="review_summary">
    ${reviewFloatStarRatingTmpl({ score: score_float })}
    <div class="score_avg">${score_float}</div>
    <div class="counts ${count === 0 ? 'no_review' : ''}">
      ${TT('cell::product::prod_1_2')} ${UtilS.commify(count)}
    </div>
  </div>`;
};

export function setPriceKeyringAndAcryl(product_color) {
  const is_designed = BpOptionS.isDesigned(product_color);

  const selected_option_group = product_color?._?.selected_option_group;
  const base_products_bp_option_groups = product_color._.base_product._.base_products_bp_option_groups;

  if (VectorEditorConstantS.KEYRING_EDITOR === product_color._.base_product.maker_type) {
    if (is_designed) {
      BpOptionKeyRingS.applyC2323WhenStart({
        base_products_bp_option_groups,
        selected_option_group,
        product: product_color,
      });
      if (selected_option_group?.bp_option_ids?.includes(BpOptionKeyRingConstantS.FREE_BP_OPTION_ID)) {
        BpOptionKeyRingS.setPriceByFittedSizeWhenStart({
          base_products_bp_option_groups,
          selected_option_group,
          product: product_color,
        });
      }
    } else {
      BpOptionS.legacyCalcPriceKeyring({
        base_products_bp_option_groups,
        selected_option_group,
        cv_objs: product_color.product_faces2?.value?.[0]?.designs,
      });
    }
  }
  if (
    VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR === product_color._.base_product.maker_type &&
    is_designed
  ) {
    BpOptionAcrylicFigureS.setPriceByFittedSizeWhenStart({
      base_products_bp_option_groups,
      selected_option_group,
      product: product_color,
    });
  }
  resetProductColorPrice(product_color);
}

export const makeProductColorInProductDetailHtmlMobile = (product_color, review_total, shared_product) => {
  const { is_pure_biz_product } = getIsBizProduct(product_color);
  const biz_option_values = bizOptionValuesFromProductColor(product_color);
  const bp_discount_set = product_color._.base_product._.bp_discount_set;
  const is_bp_option_groups_bp = BpOptionS.getBaseProductsListBpOptionGroups(
    product_color._.base_product,
  )?.length;
  setPriceKeyringAndAcryl(product_color);

  const basicInfoHtml =
    G.collabo_type === ''
      ? html`
          ${shared_product
            ? html`<div class="shared_product_expires_at">
                이 링크는
                <span class="expires_at"
                  >${moment(shared_product.expires_at).format('YYYY/MM/DD HH:mm')}</span
                >
                까지 유효합니다.
              </div>`
            : ''}
          ${makePcNameHtml(product_color)} ${makeReviewSummary({ review_total })}
          <div class="price_info">
            <div class="original_price mp-maker-product-price">
              ${calcMakerPrice(product_color, is_pure_biz_product)}
            </div>
            ${T.lang == 'kr'
              ? html`
                  <div class="delivery_price">
                    ${product_color._.base_product?.maker_features?.[
                      NewMakerPropertyBaseProductConstantS.DIFFERENT_DELIVERY_FEE
                    ]?.message || '배송비 3,000원'}
                  </div>
                `
              : ''}
          </div>
        `
      : html` ${makePcNameHtml(product_color)} `;
  const is_mobile_svg_upload_type_html_show =
    BpOptionUploadTypeS.UPLOAD_TYPE.hasUploadType(
      product_color._.base_product._.base_products_bp_option_groups,
    ) && !NewMakerPropertyBaseProductS.pdfFilePossible.isIt(product_color._.base_product);
  const is_bpc_show = !product_color._.base_product.is_bpc_hidden;
  const is_bps_show = !is_bp_option_groups_bp && !product_color._.base_product.is_bps_hidden;
  return html`
    <div class="product_color" id="ProductColorInProductDetail">
      <div class="inner maker-mobile-product-info">
        <div class="maker-mobile-product-info__basic-info">${basicInfoHtml}</div>
        ${is_bpc_show || is_bps_show
          ? html`<div class="row maker-mobile-product-info__basic-options">
              ${is_bpc_show
                ? html`
                    <div class="color_list select_list">
                      <div class="selected">
                        <div class="item color">
                          <div
                            class="code"
                            style="background: ${product_color._.base_product_color.color_code2
                              ? `linear-gradient(135deg, ${product_color._.base_product_color.color_code} 50%, ${product_color._.base_product_color.color_code2} 50%)`
                              : product_color._.base_product_color.color_code};"
                            color_code="${product_color._.base_product_color.color_code}"
                            need_ccc="${requiringBorder([
                              product_color._.base_product_color.color_code,
                              product_color._.base_product_color.color_code2,
                            ])}"
                          ></div>
                          <div class="name">${product_color._.base_product_color['name' + _en]}</div>
                        </div>
                      </div>
                    </div>
                  `
                : ``}
              ${is_bps_show
                ? html`
                    <div
                      class="size_list select_list"
                      data-base_product_colors_length="${product_color._.base_product._.base_product_sizes
                        .length}"
                    >
                      <div class="selected">
                        <div
                          class="item size sold-out-select-box-view-v2 ${_p.go(
                            soldOutSizeIds(product_color, product_color._.base_product._.base_product_colors),
                            _p(_p.contains, _p, product_color.base_product_size_id),
                          )
                            ? 'sold_out'
                            : ''}"
                        >
                          <div class="name sold-out-select-box-view-v2__name">
                            ${_p.find(product_color._.base_product._.base_product_sizes, function (bps) {
                              return bps.id == product_color.base_product_size_id;
                            })['short_name' + _en]}
                          </div>
                          <div class="sold-out-select-box-view-v2__no_stock">
                            ${T('cart_modal::일시품절')}
                          </div>
                        </div>
                      </div>
                    </div>
                  `
                : ``}
            </div>`
          : ``}
        ${is_bp_option_groups_bp
          ? html`
              <div class="maker-mobile-product-info__bp-option-groups">
                ${BpOptionTmplS.bpAllOptions({
                  product: product_color,
                  is_mobile: true,
                })}
              </div>
            `
          : ``}
        ${is_mobile_svg_upload_type_html_show
          ? html`
              <div class="maker-mobile-product-info__upload-type-mobile">
                ${BpOptionUploadTypeS.UPLOAD_TYPE.mobileHtml()}
              </div>
            `
          : ``}
        <!-- 도수 옵션 카드 -->
        ${makeBizDosuCardHtml({ product_color })}

        <!-- 비즈 전용 옵션 메뉴 -->
        ${BpOptionTmplS.biz.get.html.adapter({
          is_render: is_pure_biz_product,
          biz_option_values,
          bp_discount_set,
        })}
      </div>
    </div>
  `;
};

export const makeBizDosuCardHtml = ({ product_color }) => {
  const pf2 = product_color?.product_faces2?.value;

  if (UtilArrayS.isEmNil(pf2)) return '';

  const biz_bp_option_groups = BpOptionS.convertBpBpogToBizBpog({
    base_products_bp_option_groups: product_color._.base_product._.base_products_bp_option_groups,
  });

  const has_bp_dosu_related_option = BpOptionS.hasBpDosuRelatedOptions({
    bp_option_groups: biz_bp_option_groups,
  });
  if (has_bp_dosu_related_option === false) return '';
  const base_product = product_color._.base_product;
  return BpOptionS.getBizDosuOptionCardHtml({
    pf2,
    base_products_bp_option_groups: base_product._.base_products_bp_option_groups,
    base_product,
  });
};

export function getIsBizProduct(product_color) {
  const res = {
    is_biz_product: false,
    is_pure_biz_product: false,
  };
  const base_product = product_color._?.base_product;
  const biz_product = base_product?._?.biz_product;

  if (base_product == null || biz_product == null) return res;

  // 비즈 상품인 경우 biz_product_.id 존재
  res.is_biz_product = !!biz_product?.id;
  // 순수 비즈 상품 - biz_product.is_biz_view
  // const more_than_one_size = base_product._.base_product_sizes?.length > 1;
  // if (more_than_one_size) return res;
  res.is_pure_biz_product = biz_product?.is_biz_view;

  return res;
}

export function bizOptionValuesFromProductColor(product_color) {
  const biz_option_values = product_color.product_faces2?.[BpOptionConstantS.BIZ_PF2_KEY];
  return UtilObjS.isNotEmpty(biz_option_values)
    ? biz_option_values
    : {
        [BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_URL]: '',
        [BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_NAME]: '',
        [BpOptionConstantS.BIZ_PF2_DATA_KEYS.REQ_MEMO]: '',
        [BpOptionConstantS.BIZ_PF2_DATA_KEYS.QTY]: undefined,
      };
}

export const productPriceInfoHtml = (pc, review_total) => {
  return html`
    <div class="body product_price_wrapper">
      ${makePcNameHtml(pc)} ${OMPCoreUtilS.isMobile() ? '' : makeReviewSummary({ review_total })}
      ${G.collabo_type === 'creator'
        ? ''
        : legacyHtml`
          <div class="price mp-maker-product-price">
            ${calcMakerPrice(pc)}
          </div>
          <div style="display: ${G.is_df ? 'block' : 'none'}"> ${
            G.is_df ? '원 | $' + PriceS.pricify(pc.price_en) : ''
          }</div>
          `}
    </div>
  `;
};
