import { go, map, filter, sortBy, find, reject, tap } from 'fxjs/es';
import { BpOptionSelectedOptionGroupConstantS } from '../Constant/module/BpOptionSelectedOptionGroupConstantS.js';

export const makeOptionNames = (selected_bp_options) => {
  const white_print_add_bp_option = go(
    selected_bp_options,
    find((bp_option) => {
      return bp_option.name.replace(/\s/g, '') === BpOptionSelectedOptionGroupConstantS.WHITE_PRINT_ADD.name;
    }),
  );
  return go(
    selected_bp_options,
    reject((bp_option) => {
      return (
        bp_option._.bp_option_group.name.replace(/\s/g, '') ===
        BpOptionSelectedOptionGroupConstantS.WHITE_PRINT_ADD.bp_option_group_name
      );
    }),
    filter((bp_option) => bp_option._.bp_option_group.is_name),
    map((bp_option) => {
      const bp_option_group = bp_option._.bp_option_group;
      return {
        option_group_name: bp_option_group.name,
        option_group_name_en: bp_option_group.name_en,
        option_group_name_jp: bp_option_group.name_jp,
        option_name: bp_option.name,
        option_name_en: bp_option.name_en,
        option_name_jp: bp_option.name_jp,
        is_name: bp_option_group.is_name,
        no: bp_option_group.no,
      };
    }),
    filter(({ is_name }) => is_name),
    sortBy(({ no }) => no),
    tap((option_names) => {
      if (white_print_add_bp_option) {
        const dependency_bp_option = go(
          option_names,
          find(
            (option_name) =>
              option_name.option_group_name ===
              BpOptionSelectedOptionGroupConstantS.WHITE_PRINT_ADD.dependency_bp_option_group_name,
          ),
        );
        if (dependency_bp_option) {
          dependency_bp_option.option_name += ` (${white_print_add_bp_option.name})`;
          dependency_bp_option.option_name_en += ` (${white_print_add_bp_option.name_en})`;
          dependency_bp_option.option_name_jp += ` (${white_print_add_bp_option.name_jp})`;
        }
      }
    }),
  );
};
