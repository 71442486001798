import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';

export const EMPTY_STORE_IMG =
  '//s3.marpple.co/files/u_218933/2020/6/original/300709261dd47459652b2130f12112c1bfdb495bfd32a5e.png';

export const EMPTY_USER_IMG = UtilS.isNessApp()
  ? '//s3.marpple.co/files/u_2308668/2023/11/original/363393afbadbecbfe5916cf7797df7b8833957b81.svg'
  : '//s3.marpple.co/files/u_1165777/2024/5/original/55dba771a95163e2037529cc4d31803c18d385ab1.png';

// TODO: @jhj ness 유저 기본값을 일단 여기 넣어놨습니다.
export const EMPTY_NESS_USER_IMG =
  '//s3.marpple.co/files/u_2283830/2023/10/original/ce72f8d57c898df4afc4d5ac5ed1519a441c17b91.png';

export const ARTICLE_TYPES = {
  photo: 'photo',
  text: 'text',
  gallery_photo: 'gallery_photo',
  community_text: 'community_text',
  community_photo: 'community_photo',
  review_photo: 'review_photo',
};

export const STORE_SELLER_COMMUNITY_TYPES = [ARTICLE_TYPES.photo, ARTICLE_TYPES.gallery_photo];
export const STORE_COMMUNITY_TYPES = [ARTICLE_TYPES.community_text, ARTICLE_TYPES.community_photo];
export const ARTICLE_PHOTO_TYPES = [
  ARTICLE_TYPES.photo,
  ARTICLE_TYPES.gallery_photo,
  ARTICLE_TYPES.community_photo,
  ARTICLE_TYPES.review_photo,
];

export const ARTICLE_TEXT_TYPES = [ARTICLE_TYPES.text, ARTICLE_TYPES.community_text];
