export const WITHDRAW_SETTINGS_FRAME = '@FRAME/Creator/Withdraw/withdraw_settings';
export const WITHDRAW_SETTINGS_PAGE = '@PAGE/Creator/Withdraw/withdraw_settings';
export const WITHDRAW_SETTINGS_TAB = '@TAB/Creator/Withdraw/withdraw_settings';

export const WITHDRAW_CREWS_FRAME = '@FRAME/Creator/Withdraw/withdraw_crews';
export const WITHDRAW_CREWS_PAGE = '@PAGE/Creator/Withdraw/withdraw_crews';
export const WITHDRAW_CREWS_TAB = '@TAB/Creator/Withdraw/withdraw_crews';

export const WITHDRAW_APPROVE_FRAME = '@FRAME/Creator/Withdraw/withdraw_approve';
export const WITHDRAW_APPROVE_PAGE = '@PAGE/Creator/Withdraw/withdraw_approve';
export const WITHDRAW_APPROVE_TAB = '@TAB/Creator/Withdraw/withdraw_approve';

export const CREW_WITHDRAW_FRAME = '@FRAME/Creator/Withdraw/crew_withdraws';
export const CREW_WITHDRAW_PAGE = '@PAGE/Creator/Withdraw/crew_withdraws';
export const CREW_WITHDRAW_TAB = '@TAB/Creator/Withdraw/crew_withdraws';

export const account_history_fields = [
  'type',
  'number',
  'owner',
  'bank',
  'user_name',
  'phone',
  'company_name',
  'business_license_number',
  'parent_name',
  'parent_birth',
  'nationality',
];

export const WITHDRAW_EXCEL_HEADER = [
  'No.',
  '상태',
  '검증여부',
  '유형',
  '상점이름',
  '디자인 상품 정산액',
  '디지털 상품 정산액',
  '정산액',
  '부가세',
  '소득세',
  '주민세',
  '총세금',
  '원천징수 반영',
  '셀러 상품 정산액',
  '입고 상품 정산액',
  '총 예상 입금액',
  '소득자',
  '주민번호',
  '은행명',
  '계좌번호',
  '예금주',
  '승인/취소',
  '취소사유',
];
export const WITHDRAW_DATE = '11';

export const MSG = {
  check_bank_account_filled_message:
    '<h3>출금 불가</h3>필요한 계좌정보 내용이 모두 기입되지 않았습니다. 계좌정보 페이지에서 내용을 기입해 주세요.',
  check_tax_papaer_message:
    '<h3>세금계산서 발행</h3>마플에서 세금계산서 발행 여부를 확인 후 입금합니다. 세금계산서 발행이 아직 안되었다면 세금계산서 발행 후 출금 신청을 해주셔야합니다. 계속하시겠습니까?',
  check_bank_account_accuracy_message:
    '잠깐! 계좌 정보는 정확히 입력하셨나요?<br/>계좌 정보가 잘못된 경우 출금 신청은 취소되며<br/>재신청하는 출금 신청은 다음 달에 정산 진행됩니다.',
  check_amount_over: '입력한 입금액이 정산 금액보다 많습니다. 계속 진행하려면 확인을 눌러주세요.',
};

export const WITHDRAW_TYPE = {
  integrated: 'integrated',
  separate: 'separate',
};

export const STORE_TYPE = {
  seller: 'seller',
  crew: 'crew',
};

export const MINIMUM_WITHDRAW_AMOUNT = 10000;
