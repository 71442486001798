import { type Html, html, on, View } from 'rune-ts';
import { getSharedData } from '../../../../shared/util/rune';
import klass from './Modal.module.scss';
import { CloseIcon } from '../Icon';
import { classes, htmlIf } from '../../../../shared/util';
import { typo } from '../../../../shared/typography/typo';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';

export interface ModalProps {
  isOpen: boolean;
  backdrop?: boolean;
  backdrop_not_close?: boolean;
  is_bottom_sheet?: boolean;
  size: 'medium' | 'large' | 'free';
  title?: string;
  body: Html | string | View;
  footer?: Html | View;
  disable_escape_keydown?: boolean;
  full_width?: boolean;
  container_klass?: string;
  enable_app_bottom_padding?: boolean;
}

// modal 닫힐 때 호출
export class ModalClosingEvent<T = undefined> extends CustomEvent<T> {}

// modal close animation 끝나고 호출됨
export class ModalClosedEvent<T = undefined> extends CustomEvent<T> {}

export class Modal extends View<ModalProps> {
  private _closed = true;

  protected defaultHeader(title: string) {
    return html`
      <div class="${klass.header} ${typo('16_bold')}">
        ${title}
        <button type="button" class="${klass.close_btn}">${CloseIcon()}</button>
      </div>
    `;
  }

  protected defaultFooter(footer) {
    const webviewapp = getSharedData(this)?.webviewapp;

    return html`<div
      class="${klass.footer}"
      ${webviewapp?.tab_bar_height && this.data.enable_app_bottom_padding
        ? html` style="margin-bottom: ${webviewapp?.tab_bar_height}px" `
        : ''}
    >
      ${footer}
    </div>`;
  }

  protected override template(data: ModalProps) {
    const { isOpen, backdrop = true, size, title, body, footer, is_bottom_sheet } = data;
    const webviewapp = getSharedData(this)?.webviewapp;

    return html`
      <div
        class="${classes(klass.modal_container, {
          [this.data.container_klass || '']: this.data.container_klass,
          [klass.bottom_sheet]: is_bottom_sheet,
          [klass.open]: isOpen,
        })}"
      >
        ${htmlIf(
          html`
            <div
              class="${classes(klass.backdrop, {
                [klass.open]: isOpen,
              })}"
            ></div>
          `,
          backdrop,
        )}
        <div
          class="${classes(klass.modal, {
            [klass.bottom_sheet]: is_bottom_sheet,
            [klass.medium]: size === 'medium',
            [klass.large]: size === 'large',
          })}"
        >
          ${title ? this.defaultHeader(title) : ''}
          <div class="${klass.body} ${classes({ [klass.full_width]: this.data.full_width })}">
            ${body}
            ${webviewapp?.tab_bar_height && this.data.enable_app_bottom_padding
              ? html`<div style="height: ${webviewapp?.tab_bar_height}px"></div> `
              : ''}
          </div>
          ${footer ? this.defaultFooter(footer) : ''}
        </div>
      </div>
    `;
  }

  override onRender() {
    if (!this.data.disable_escape_keydown) {
      window.addEventListener('keydown', (e) => {
        if (e.code !== 'Escape') {
          return;
        }

        if (!this._closed) {
          this.close();
        }
      });
    }
  }

  close<T = undefined>(param?: T) {
    if (this._closed) return;
    const element = this.element();
    if (!element) return;
    const modal = element.querySelector(`.${klass.modal}`);
    const backdrop = element.querySelector(`.${klass.backdrop}`);
    const onEndAnimation = () => {
      modal?.classList.remove(klass.animate, klass.slide_out);
      element.classList.remove(klass.open);
      modal?.removeEventListener('animationend', onEndAnimation);
      MShopUtilF.bodyFixed$(false);
      document.body.classList.remove(klass.modal_fixed);

      this.dispatchEvent(ModalClosedEvent, {
        bubbles: true,
        detail: param,
      });
    };

    modal?.addEventListener('animationend', onEndAnimation);
    modal?.classList.add(klass.animate, klass.slide_out);
    backdrop?.classList.remove(klass.open);

    this.dispatchEvent(ModalClosingEvent, {
      bubbles: true,
      detail: param,
    });
    this._closed = true;
  }

  open() {
    const element = this.element();
    if (!element) return;

    const modal = element.querySelector(`.${klass.modal}`);
    const backdrop = element.querySelector(`.${klass.backdrop}`);
    // modal?.addEventListener('animationend', () => {
    //   modal?.classList.remove(klass.animate, klass.slide_in);
    // });
    element.classList.add(klass.open);
    backdrop?.classList.add(klass.open);
    modal?.classList.add(klass.animate, klass.slide_in);

    MShopUtilF.bodyFixed$(true);
    document.body.classList.add(klass.modal_fixed);
    this._closed = false;
  }

  @on('click', `.${klass.backdrop}`)
  _clickBackDrop() {
    if (this.data.backdrop_not_close) return;
    this.close();
  }

  @on('click', `.${klass.close_btn}`)
  _closeModal() {
    this.close();
  }
}
