import { each, every, filter, find, go, head, map } from 'fxjs/es';
import { getSizeFace } from '../../S/Function/getSizeFace.js';
import { getRealFcanvass } from '../../../../Maker/F/getSth.js';
import { getCvDesigns } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { ungroupAll } from '../../../../Maker/F/util.js';

const cmToInch = (cm) => {
  return cm / 2.54;
};

export function getPrintRatio({ print, dpi }) {
  const { cm, px, result_px } = print;
  if (dpi) {
    const real_width_px = cmToInch(cm.width) * dpi;
    return real_width_px / px.width;
  }
  if (result_px?.width) {
    const { px } = print;
    return result_px.width / px.width;
  }
}

function getDpiRatio(cv_obj) {
  const basic_dpi = 150;
  return basic_dpi / (cv_obj._data.dpi > basic_dpi ? cv_obj._data.dpi : basic_dpi);
}

export function getSmallestRatio({ cv_obj, ratio, parent_ratio = 1 }) {
  const cv_objs = cv_obj._objects || cv_obj.objects;
  if (cv_objs)
    return go(
      map((_cv_obj) =>
        getSmallestRatio({ cv_obj: _cv_obj, ratio, parent_ratio: cv_obj.scaleX * parent_ratio }),
      )(cv_objs),
      (arr) => arr.sort(),
      head,
    );
  if (cv_obj._data.cv_type !== 'cv_image') return;
  if (cv_obj._data.image_color_id) return;
  const dpi_ratio = getDpiRatio(cv_obj);
  return 1 / ((cv_obj.scaleX * cv_obj.width * ratio * parent_ratio) / (cv_obj._data.image_width * dpi_ratio));
}

function isSmallerCvImageThanRequirement({ cv_obj, ratio, parent_ratio = 1 }) {
  const cv_objs = cv_obj._objects || cv_obj.objects;
  if (cv_objs)
    return find((_cv_obj) =>
      isSmallerCvImageThanRequirement({ cv_obj: _cv_obj, ratio, parent_ratio: cv_obj.scaleX * parent_ratio }),
    )(cv_objs);
  if (cv_obj._data.cv_type !== 'cv_image') return;
  if (cv_obj._data.image_color_id) return;
  const dpi_ratio = getDpiRatio(cv_obj);
  return cv_obj.scaleX * cv_obj.width * ratio * parent_ratio > cv_obj._data.image_width * dpi_ratio;
}

function getRatioCvImageThanTargetWidth({ cv_obj, parent_ratio = 1, target_width }) {
  const cv_objs = cv_obj._objects || cv_obj.objects;
  if (cv_objs)
    return go(
      cv_objs,
      find((_cv_obj) =>
        getRatioCvImageThanTargetWidth({
          cv_obj: _cv_obj,
          parent_ratio: cv_obj.scaleX * parent_ratio,
          target_width,
        }),
      ),
      (_cv_obj) =>
        _cv_obj &&
        getRatioCvImageThanTargetWidth({
          cv_obj: _cv_obj,
          parent_ratio: cv_obj.scaleX * parent_ratio,
          target_width,
        }),
    );
  if (!(cv_obj._data?.cv_image_attrs?._data?.name?.includes('sh_x') || cv_obj._data?.name?.includes('sh_x')))
    return;
  const target_ratio_width = (cv_obj.scaleX * cv_obj.width * parent_ratio) / target_width;
  const target_ratio_height = (cv_obj.scaleY * cv_obj.height * parent_ratio) / target_width;
  return (target_ratio_width < 1 && target_ratio_width) || (target_ratio_height < 1 && target_ratio_height);
}
async function moveToTargetLocation(dtp) {
  go(
    G.mp.maker.designs(),
    filter((cv_obj) => cv_obj.evented),
    G.mp.maker.multiple_select,
  );
  const activeGroup = G.mp.maker.active();
  const group_attrs = activeGroup.toJSON();
  G.mp.maker.unselect_all();

  const cv_group = await G.mp.maker.create_cv_group(group_attrs);
  const expected_width = cv_group.width > dtp.width ? dtp.width : cv_group.width;
  const expected_height = cv_group.height > dtp.height ? dtp.height : cv_group.height;
  const x = cv_group.left + (cv_group.width - expected_width) / 2;
  const y = cv_group.top + (cv_group.height - expected_height) / 2;
  const w = expected_width;
  const h = expected_height;
  await ungroupAll(G.mp.maker.editing_canvas(), G.mp.maker.designs());

  go(
    G.mp.maker.designs(),
    each((cv_obj) => {
      const { top, left, width, height } = cv_obj.getBoundingRect();
      const to_left_to_right = x - left;
      if (to_left_to_right > 0) {
        cv_obj.set({ left: cv_obj.left + to_left_to_right }).setCoords();
      }
      const to_right_to_left = x + w - (left + width);
      if (to_right_to_left < 0) {
        cv_obj.set({ left: cv_obj.left + to_right_to_left }).setCoords();
      }
      const to_top_to_bottom = y - top;
      if (to_top_to_bottom > 0) {
        cv_obj.set({ top: cv_obj.top + to_top_to_bottom }).setCoords();
      }
      const to_bottom_to_top = y + h - (top + height);
      if (to_bottom_to_top < 0) {
        cv_obj.set({ top: cv_obj.top + to_bottom_to_top }).setCoords();
      }
    }),
  );
  G.mp.maker.editing_canvas().renderAll();
  await G.mp.maker.reset_layer();
}
export const shinhanPfCondition = async ({ product_faces2, base_product_size_id }) => {
  const sf = go(
    product_faces2.value[0].size_faces,
    find((a) => a.base_product_size_id == base_product_size_id),
  );
  const dtf = { width: 38, height: 48 };
  const bigger_than_dtp_width = product_faces2.value[0].design_size?.width_cm > dtf.width;
  const bigger_than_dtp_height = product_faces2.value[0].design_size?.height_cm > dtf.height;
  if (bigger_than_dtp_width || bigger_than_dtp_height) {
    await moveToTargetLocation({ width: dtf.width * sf.px_per_1cm, height: dtf.height * sf.px_per_1cm });
    return {
      message:
        '커스텀 디자인 전체 크기가 38 x 48 cm 를<br>넘을 수 없습니다.<br>자동으로 안쪽으로 재배치 됩니다.<br>디자인을 다시 확인해주세요.',
    };
  }
};

export function updateAgreeQuality({ cv_obj, ratio, parent_ratio = 1 }) {
  const cv_objs = cv_obj._objects || cv_obj.objects;
  if (cv_objs)
    return go(
      each((_cv_obj) =>
        getSmallestRatio({ cv_obj: _cv_obj, ratio, parent_ratio: cv_obj.scaleX * parent_ratio }),
      )(cv_objs),
    );
  if (cv_obj._data.cv_type !== 'cv_image') return;
  if (cv_obj._data.image_color_id) return;
  const scale = 1 / ((cv_obj.scaleX * cv_obj.width * ratio * parent_ratio) / cv_obj._data.image_width);
  if (scale < 1) {
    cv_obj._data.is_low_quality_agree = true;
  }
}

export const findSmallerCvImageThanRequirement = ({
  product_faces2,
  base_product_size_id,
  base_product_faces,
}) => {
  if (!every([product_faces2, base_product_size_id, base_product_faces]))
    throw Error('require product_faces2, base_product_size_id, base_product_faces');
  return go(
    product_faces2.value,
    find(({ designs, bpf_id }) => {
      const { print, dpi } = getSizeFace({
        base_product_faces,
        base_product_face_id: bpf_id,
        base_product_size_id,
      });
      const ratio = getPrintRatio({ print, dpi });
      if (!ratio) return;
      return go(
        designs,
        find((cv_obj) => isSmallerCvImageThanRequirement({ cv_obj, ratio })),
      );
    }),
  );
};

export const isImageQualityPass = () => {
  const base_product_faces = go(
    box().maker.product_color._.base_product_color._.base_product_color_faces,
    map((bpcf) => bpcf._.base_product_face),
  );
  const { base_product_size_id } = box().maker.product_color;
  const product_faces2 = box().maker.product_color.product_faces2;
  return !findSmallerCvImageThanRequirement({
    product_faces2,
    base_product_size_id,
    base_product_faces,
  });
};
export function everyUserCvImageToLowQualityAgreeFalse() {
  go(
    getRealFcanvass(),
    each((fcanvas) => {
      go(
        getCvDesigns(fcanvas._objects),
        each((cv_obj) => {
          G.mp.maker.cv_objects_deep_each(cv_obj, (co) => {
            if (co._data.cv_type === 'cv_image' && !co._data.image_color_id) {
              co._data.is_low_quality_agree = false;
            }
          });
        }),
      );
    }),
  );
}

export async function alertImageQuality({ cv_obj, ratio }) {
  if (!ratio) return;
  if (location.pathname.includes('shinhan')) {
    const _cv_obj = G.mp.maker.cv_objects_deep_find(cv_obj, function (v) {
      if (v._data?.cv_image_attrs?._data?.name?.includes('sh_x') || v._data?.name?.includes('sh_x'))
        return true;
    });
    if (!_cv_obj) return;
    const cv_obj_ratio = getRatioCvImageThanTargetWidth({ cv_obj, target_width: 4.5 * 7 });
    if (cv_obj_ratio) {
      cv_obj.scale((cv_obj.scaleX * 1) / cv_obj_ratio);
      cv_obj.canvas.renderAll();
      G.mp.maker.reset_data_cm(cv_obj);
      await G.mp.maker.reset_layer();
      $.alert('신한프렌즈 캐릭터 이미지(필수 에셋)은<br>최소 4.5cm 보다 커야합니다.');
    }
  }
  G.mp.maker.cv_objects_deep_each(cv_obj, (co) => {
    if (co._data.cv_type === 'cv_image' && !co._data.image_color_id) {
      co._data.is_low_quality_agree = false;
    }
  });
  const has_row_quality_image = isSmallerCvImageThanRequirement({ cv_obj, ratio });
  if (has_row_quality_image) {
    const bool = await $.confirm(
      T(
        'maker::Message::Images with small resolution may reduce print quality. Do you want to change to the optimal size?',
      ),
    );
    if (bool) {
      cv_obj.scale(cv_obj.scaleX * getSmallestRatio({ cv_obj, ratio }));
      cv_obj.canvas.renderAll();
      G.mp.maker.reset_data_cm(cv_obj);
      await G.mp.maker.reset_layer();
    } else {
      updateAgreeQuality({ cv_obj, ratio });
    }
  }
}
