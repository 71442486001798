import UAParser from 'ua-parser-js';
import { isForceM } from './isForceM.js';

const parser = new UAParser();
const is_mobile_cache = ['mobile'].includes(parser.getDevice().type);
const is_tablet_cache = ['tablet'].includes(parser.getDevice().type);
export const isLegacyMobile = () => {
  if (G.collabo_type === '') {
    if (isForceM()) return true;
    // if (is_tablet_cache && $qs('html.maker_canvas_editor_v1')) return true;
    return is_mobile_cache;
  }
  return G.is_mobile_size();
};

export const isLegacyTablet = () => {
  if (isForceM()) return false;
  if (!isLegacyMobile() && window.navigator.maxTouchPoints === 5) return true;
  /* ipad safari 구별 방법 */
  return is_tablet_cache;
};

export const isLegacyRealTablet = () => {
  if (!is_mobile_cache && window.navigator.maxTouchPoints === 5) return true;
  /* ipad safari 구별 방법 */
  return is_tablet_cache;
};

export const isLegacyRealMobile = () => {
  if (G.collabo_type === '') {
    return is_mobile_cache;
  }
  return G.is_mobile_size();
};
