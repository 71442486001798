import { compact, go, join, map, reject, sort, filter, every } from 'fxjs/es';
import { $data, $find, $findAll } from 'fxdom/es';
import { BpOptionSelectedOptionGroupS } from '../../SelectedOptionGroup/S/Function/module/BpOptionSelectedOptionGroupS.js';
import { getAllBpOptions, getDefaultBpOptions } from './get.js';

export const setSelectedOptionGroupBpOptions = (selected_option_group) => {
  selected_option_group._ = selected_option_group._ || {};
  selected_option_group._.bp_options = go(
    getAllBpOptions(),
    filter((bp_option) => selected_option_group.bp_option_ids.includes(bp_option.id)),
  );
  return selected_option_group;
};

export const setDefaultSelectedOptionGroup = (product) => {
  product._.selected_option_group = product._.selected_option_group || {};
  product._.selected_option_group._ = product._.selected_option_group._ || {};
  const default_bp_option_ids = getDefaultBpOptions();
  const current_bp_options = getAllBpOptions();
  if (default_bp_option_ids?.length) {
    // console.log('bp_option 있는경우');
    const current_bp_option_ids = product._.selected_option_group?.bp_option_ids;
    /* 현재 bp_options 가 선택된 bp_option_ids 와 일치한다면 리턴 */
    if (
      current_bp_option_ids &&
      go(
        current_bp_options,
        every((bp_option) => current_bp_option_ids.includes(bp_option.id)),
      )
    ) {
      // console.log('bp_option 있는데 bp_option이 동일한 경우');
      return;
    }
  } else {
    // console.log('bp_option 이 없는 경우');
    /* bp_options 가 없는 상품이라면 */
    product._.selected_option_group = {};
    return;
  }
  product._.selected_option_group.bp_option_ids = default_bp_option_ids;
  product._.selected_option_group._.bp_options = go(
    current_bp_options,
    filter((bp_option) => default_bp_option_ids.includes(bp_option.id)),
  );
};
export const makeSelectedOptionGroup = (bp_option_groups_el) => {
  const bp_option_ids = go(
    bp_option_groups_el,
    $findAll('.bp_option_group'),
    map($find('select')),
    map((select_el) => parseInt(select_el.value)),
    sort,
  );

  const title_option_els = go(
    bp_option_groups_el,
    $findAll('.bp_option_group[data-is_name="true"] select'),
    reject((el) => el.dataset.id == 89),
    compact,
    map((select_el) => $find(`option[value="${select_el.value}"]`)(select_el)),
    reject((el) => el.dataset?.name === '추가 안함'),
    compact,
  );
  const selected_bp_options = go(
    bp_option_groups_el,
    $findAll('.bp_option_group[data-is_name="true"] select'),
    reject((el) => el.dataset.id == 89),
    compact,
    map((select_el) => {
      const bp_option_group = $data(select_el);
      const option = $data($find(`option[value="${select_el.value}"]`)(select_el));
      return {
        ...option,
        _: {
          bp_option_group,
        },
      };
    }),
  );
  const option_names = BpOptionSelectedOptionGroupS.makeOptionNames(selected_bp_options);

  return go(
    {
      bp_option_ids,
      title:
        go(
          title_option_els,
          map((el) => el?.dataset?.name),
          join('-'),
        ) || '',
      title_en:
        go(
          title_option_els,
          map((el) => el?.dataset?.name_en),
          join('-'),
        ) || '',
      title_jp:
        go(
          title_option_els,
          map((el) => el?.dataset?.name_jp),
          join('-'),
        ) || '',
      option_names,
    },
    setSelectedOptionGroupBpOptions,
  );
};
