import { CustomEventWithDetail, CustomEventWithoutDetail, html, View } from 'rune-ts';
import {
  InputSearch,
  InputSearchProps,
  SearchArrowKeydownEvent,
  SearchDebounceInputEvent,
  SearchInputEvent,
  SearchSubmitEvent,
} from '../../../atoms/InputSearch/InputSearch';
import { AutoCompleteClickEvent, AutoCompleteList, AutoCompleteListData } from './AutoCompleteList';
import { initCancelTokenHelper } from '../helper/initCancelTokenHelper';
import { CanceledError } from 'axios';
import klass from './AutoCompleteSearchInput.module.scss';
import { SearchApiS } from '../../../../../features/Search/outbound/share';
import { AutoComplete } from '../../../../../features/Search/type/autocomplete';

export class NotEmptySubmitEvent extends CustomEventWithDetail<string> {}
export class EmptySubmitEvent extends CustomEventWithoutDetail {}
export class AutoCompleteListHideEvent extends CustomEventWithoutDetail {}
export class AutoCompleteListShowEvent extends CustomEventWithoutDetail {}

interface AutoCompleteSearchInputProps extends InputSearchProps {
  container_klass?: string;
}

export class AutoCompleteSearchInput extends View<AutoCompleteSearchInputProps> {
  container_klass: Readonly<string>;
  already_submit: boolean;

  // 2글자 이상만 자동완성 기능 제공
  MIN_CHARS_FOR_AUTO_COMPLETE: Readonly<number> = 2;

  InputSearchView: InputSearch;
  AutoCompleteListView: AutoCompleteList;

  constructor(data: AutoCompleteSearchInputProps) {
    super(data);
    this.container_klass = data.container_klass ?? '';
    this.InputSearchView = new InputSearch(this.data);
    this.AutoCompleteListView = new AutoCompleteList();
    this.already_submit = false;
  }

  override template() {
    return html`<div class="${klass.auto_complete_input_container} ${this.container_klass}">
      ${this.InputSearchView}${this.AutoCompleteListView}
    </div>`;
  }

  override onMount() {
    // 자동완성 조회하는 api 호출하고 검색어를 다시 입력하거나 submit 할 경우에는 이전 axios call 을 취소한다.
    const { getCancelToken, setCancelToken, cancelPrevRequest } = initCancelTokenHelper();

    this.delegate(SearchInputEvent, InputSearch, (e) => {
      // set value
      this.data.value = e.detail;

      // 자동완성이 가능한 경우는 `SearchDebounceInputEvent` 에서 처리하므로 즉시 종료
      if (this.canAutoComplete()) {
        return;
      }
      // 자동완성 안 되는 경우는 자동완성 목록 가림
      cancelPrevRequest();
      this.hideAutoCompleteList();
    });

    let prev_search: string | undefined;
    this.delegate(SearchDebounceInputEvent, InputSearch, async (e) => {
      if (!this.canAutoComplete()) {
        return;
      }
      // 검색어 같으면 아무 일도 하지 않음
      if (prev_search === this.data.value) {
        return;
      }
      // 이전 검색어 업데이트
      prev_search = this.data.value;

      // 이전에 전송된 요청이 있다면 취소하고 cancel token 재설정
      cancelPrevRequest();
      setCancelToken();

      let auto_completes: AutoComplete[] = [];
      // 상위 레이어로 옮기는 것이 좋아보인다.
      try {
        const res = await SearchApiS.getAutoCompleteListApi({ search: this.data.value });
        auto_completes = res.auto_completes;
      } catch (e) {
        if (!(e instanceof CanceledError)) {
          throw e;
        }
      }
      this.AutoCompleteListView.setData({ auto_completes, value: e.detail });
      this.AutoCompleteListView.redraw();

      if (auto_completes.length) {
        this.dispatchEvent(AutoCompleteListShowEvent, { bubbles: true });
      } else {
        this.dispatchEvent(AutoCompleteListHideEvent, { bubbles: true });
      }
    });

    this.delegate(AutoCompleteClickEvent, AutoCompleteList, (e) => {
      const search = e.detail;
      if (!search) {
        return;
      }

      // 검색어 자동완성 즉시 숨김
      this.already_submit = true;
      this.hideAutoCompleteList();
      // 이전 호출 있다면 취소
      cancelPrevRequest();

      this.InputSearchView.setValue(search);
      this.dispatchEvent(NotEmptySubmitEvent, { bubbles: true, detail: search });
    });

    // submit
    this.delegate(SearchSubmitEvent, InputSearch, async (e) => {
      if (!e.detail) {
        this.dispatchEvent(EmptySubmitEvent, { bubbles: true });
        return;
      }

      // 검색어 자동완성 즉시 숨김
      this.already_submit = true;
      this.hideAutoCompleteList();
      // 이전 호출 있다면 취소
      cancelPrevRequest();

      let search: string = e.detail;
      const focused_el = this.AutoCompleteListView.getFocusedEl();
      if (focused_el?.dataset?.search) {
        search = focused_el.dataset.search;
      }
      this.InputSearchView.setValue(search);
      this.dispatchEvent(NotEmptySubmitEvent, { bubbles: true, detail: search });
    });

    // key up/down event
    this.delegate(SearchArrowKeydownEvent, InputSearch, (e) => {
      const key = e.detail;
      if (key === 'ArrowUp') {
        this.AutoCompleteListView.moveFocusUp();
      } else if (key === 'ArrowDown') {
        this.AutoCompleteListView.moveFocusDown();
      }
    });
  }

  setData(data: AutoCompleteListData) {
    this.AutoCompleteListView.setData(data);
  }

  focus() {
    this.InputSearchView.focus();
  }

  // 2글자 이상 입력되었을 때 자동완성 목록이 보여진다.
  private canAutoComplete(): boolean {
    if (this.already_submit) {
      return false;
    }
    const length = (this.data.value ?? '').length;
    return length >= this.MIN_CHARS_FOR_AUTO_COMPLETE;
  }

  private hideAutoCompleteList() {
    this.AutoCompleteListView.hide();
    this.dispatchEvent(AutoCompleteListHideEvent, { bubbles: true });
  }

  setInputValue(value: string) {
    this.InputSearchView.setValue(value);
  }

  getInputValue() {
    return this.InputSearchView.getValue();
  }
}
