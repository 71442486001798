import { defaults, delay, extend, find, go, sel } from 'fxjs/es';
import { check_only_one_image_color, getMetadataFromImageUrl, selectColorImagesInFlex } from '../image.js';
import { getImageUrlByZoom, getSthForAutoSwiper } from '../util.js';
import { createCvPattern, DEFAULT_PATTERN } from '../CvPattern/fs.js';
import {
  getShadeFcanvas,
  hasFullImageCvImage,
  isShadeBpNotBlackAndWhiteContrast,
  renderMakerPageShade,
} from '../CvPreview/Shade/render.js';
import { getBaseProductColorInMaker, getBaseProductInMaker } from '../getSth.js';
import { NewMakerImageF } from '../../../NewMaker/Image/F/Function/module/NewMakerImageF.js';
import { NewMakerCheckerF } from '../../../NewMaker/Checker/F/Function/module/NewMakerCheckerF.js';
import { NewMakerCvObjAlignmentF } from '../../../NewMaker/CvObjAlignment/F/Function/module/NewMakerCvObjAlignmentF.js';
import { NewMakerCvObjectCvImageF } from '../../../NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { NewMakerMaskingTapeF } from '../../../NewMaker/MaskingTape/F/Function/module/NewMakerMaskingTapeF.js';
import { BpOptionConstantS } from '../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { NewMakerMaskingTapeConstantS } from '../../../NewMaker/MaskingTape/S/Constant/module/NewMakerMaskingTapeConstantS.js';
import { $qs } from 'fxdom/es';
import { loadImageFromUrl, makeCanvasByContrastEffect, makeCanvasByImg } from '../../../Canvas/S/util.js';
import { BpOptionF } from '../../../BpOption/F/Function/module/BpOptionF.js';
import { OMPDosuF } from '../../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { OMPDosuConstantS } from '../../../OMP/Dosu/S/Constant/module/OMPDosuConstantS.js';
import { NewMakerPropertyBpfF } from '../../../NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';
import { getCurrentFcanvas } from '../Fcanvas/cv_object.js';
import { NewMakerPropertyBpfS } from '../../../NewMaker/Property/Bpf/S/Function/module/NewMakerPropertyBpfS.js';
import { applyColorPickerToCvObj } from '../../../OMP/Dosu/F/Function/event.dosu.js';
import { NewMakerCallConditionPopupF } from '../../../NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';

function findSelectedImageColor(image_colors, cv_object) {
  return find((image_color) => {
    return image_color.id === cv_object._data.image_color_id;
  }, image_colors);
}

export async function objForTmpl() {
  async function f(cv_object) {
    const request_bg_removal = NewMakerCvObjectCvImageF.getRequestBgRemoval(cv_object);
    const image_colors = await selectColorImagesInFlex(cv_object);
    const active = G.mp.maker.active();
    if (cv_object !== active && cv_object !== sel('_data.cv_image_attrs', active))
      throw new Error('no_active');
    const selected_image_color = findSelectedImageColor(image_colors, cv_object);
    return { request_bg_removal, image_colors, selected_image_color };
  }
  const cv_object = G.mp.maker.active();

  if (cv_object._data.cv_type === 'cv_image') {
    const pattern_data = DEFAULT_PATTERN;
    const is_fitted_to_print_width = cv_object._data?.is_fitted_to_print_width;
    return extend(await f(cv_object), { pattern_data, cv_object, is_fitted_to_print_width });
  } else {
    const cv_image = cv_object._data.cv_image_attrs;
    const is_fitted_to_print_width = cv_object._data?.is_fitted_to_print_width;
    const pattern_data = defaults(cv_object._data.pattern_data, DEFAULT_PATTERN);
    return extend(await f(cv_image), { pattern_data, cv_object: cv_image, is_fitted_to_print_width });
  }
}

export function getCvImage(cv_image) {
  if (cv_image._data.cv_type === 'cv_image') return cv_image;
  return sel('_data.cv_image_attrs', cv_image);
}

export async function objForCvImageFrameTmpl() {
  const cv_image = getCvImage(G.mp.maker.active());
  const image_colors = await selectColorImagesInFlex(cv_image);
  const { app_width, quantity_of_item_in_one_slide } = getSthForAutoSwiper(
    {
      width: 55,
      height: 55,
      margin: 14,
      whole_width: $.width(window),
      whole_height: 150,
    },
    image_colors.length,
  );
  const selected_image_color = findSelectedImageColor(image_colors, cv_image);
  if (!selected_image_color)
    $.alert(T('The image color is not available for this product. Please choose the others.'));
  const image_colorss = _p.how_many(image_colors, quantity_of_item_in_one_slide);
  return {
    image_colorss,
    slide_length: image_colorss.length,
    selected_image_color,
    app_width,
  };
}
export async function createCvImageP(cv_attrs, zoom = 1) {
  const is_black_and_white_contrast = sel('preview.shade.is_black_and_white_contrast', getShadeFcanvas());
  if (G.mp.maker.is_auto_print || G.mp.maker.sharp_resize_print) {
    const real_width = Math.round(G.mp.maker.fabric_get_width(cv_attrs));
    cv_attrs.src = await go(undefined, () => {
      if (cv_attrs._data.image_color_id && real_width > cv_attrs._data.image_width) {
        return cv_attrs._data.image_url;
      }
      return NewMakerImageF.makeResizedImage({
        width: real_width,
        url: cv_attrs._data.image_url,
        need_resize: true,
      });
    });
    cv_attrs.scaleX = 1;
    cv_attrs.scaleY = 1;
    const ratio = real_width / cv_attrs.width;
    cv_attrs.width = real_width;
    cv_attrs.height = Math.round(cv_attrs.height * ratio);
  } else {
    const resize_src =
      (is_black_and_white_contrast || cv_attrs?._data?.is_black_and_white_contrast) && zoom >= 0.4
        ? G.to_600(cv_attrs.src)
        : getImageUrlByZoom(cv_attrs.src, zoom);

    cv_attrs.src = resize_src;
  }
  return new Promise(function (resolve) {
    fabric.Image.fromURL(
      G.mp.maker.url2(cv_attrs.src),
      async function (cv_image) {
        cv_image.set(_p.omit(cv_attrs, 'src'));
        cv_image.src = G.remove_protocol2(cv_image.src);
        cv_image.set(G.mp.maker.IMAGE);
        cv_image._data.cv_type = 'cv_image';
        if (is_black_and_white_contrast || cv_image._data.is_black_and_white_contrast) {
          cv_image._element = await go(
            makeCanvasByContrastEffect(makeCanvasByImg(cv_image._element)),
            (c) => c.toDataURL('image/png', 1),
            loadImageFromUrl,
          );
        }

        // dosu - cv_image 생성 시, 도수 컬러가 있을 떄 이미지에 적용
        const image_dosu_color = OMPDosuF.cvObj.get.dosuColor(cv_image);

        if (image_dosu_color && image_dosu_color.startsWith('#')) {
          // await OMPDosuF.cvDesign.update.originalDosuColors({ cv_obj: cv_image });
          await OMPDosuF.cvObj.update.dosuColorToCvObj({ cv_obj: cv_image });
        }

        if (cv_image._data.color_code2) {
          await NewMakerPropertyBpfF.bpcColorCode2Render.applyColor(cv_image, cv_image._data.color_code2);
        }

        go(renderMakerPageShade(getShadeFcanvas(), cv_image), () => resolve(cv_image));
      },
      cv_attrs,
    );
  });
}

export async function changeColorOfCvImage(image_color) {
  /* 도수제한일때는 동작 안함 */
  const fcanvas = G.mp.maker.editing_canvas();
  const cv_image = G.mp.maker.active();

  const idx = fcanvas._objects.indexOf(cv_image);
  const cv_attrs = G.mp.maker.to_attrs(cv_image);

  function f(cv_attrs, image_color) {
    cv_attrs.src = G.to_600(image_color.url);
    cv_attrs._data.image_color_id = image_color.id;
    cv_attrs._data.image_url = image_color.url;
    cv_attrs._data.press_color_code = image_color.press_color_code;
    cv_attrs._data.press_color_id = image_color.press_color_id;
    cv_attrs._data.press_color_name = image_color.press_color_name;
    cv_attrs._data.only_flex = image_color.only_flex;
    delete cv_attrs._data[OMPDosuConstantS._DATA_NAMES.DOSU_ORIGINAL_COLORS];
    delete cv_attrs._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR];
  }
  if (cv_attrs._data.cv_type === 'cv_image') f(cv_attrs, image_color);
  else if (cv_attrs._data.cv_type === 'cv_pattern') {
    if (image_color.only_flex === true) throw Error('not_available_color');
    f(cv_attrs._data.cv_image_attrs, image_color);
  }
  cv_attrs.height = (image_color.height / image_color.width) * cv_attrs.width;
  const last_cleared_pass = G.mp.maker.cleared_pass;
  const last_selected_pass = G.mp.maker.selected_pass;
  G.mp.maker.cleared_pass = true;
  G.mp.maker.selected_pass = true;
  return _p.go(
    cv_attrs,
    cv_image._data.cv_type === 'cv_image' ? createCvImageP : createCvPattern,
    async function (target) {
      // dosu - 이미지 색상 변경시 (무료 디자인) 도수 로직 적용
      if (BpOptionF.biz.get.currentFace.hasDosuColorOptions()) {
        await OMPDosuF.cvObj.update.originalDosuColors({ cv_obj: cv_image });
        await OMPDosuF.cvObj.update.dosuColorToCvObj({ cv_obj: cv_image });
      }

      G.mp.maker.nscreen(target);
      fcanvas.remove(cv_image);
      fcanvas.insertAt(
        target,
        fcanvas?.fcanvas_data?.maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR
          ? idx - NewMakerMaskingTapeConstantS.CLONE_OBJ_LENGTH
          : idx,
      );
      fcanvas.setActiveObject2(target);
      G.mp.maker.cleared_pass = last_cleared_pass;
      G.mp.maker.selected_pass = last_selected_pass;
      G.mp.maker.modified();
      NewMakerCallConditionPopupF.setAndRender(target);
      if (BpOptionF.biz.get.currentFace.hasDosuColorOptions()) {
        OMPDosuF.dosuColorPickerEvent.updateSelectedToOriginal();
      }
      return target;
    },
  );
}

export function makeDefaultCvImageAttrs(image) {
  return {
    src: image.url,
    _data: {
      only_flex: image.only_flex,
      folder_type: image.folder_type,
      folder_id: image.folder_id,
      image_id: image.id,
      image_color_id: image.image_color_id,
      image_url: image.url,
      image_width: image.width,
      image_height: image.height,
      image_width_cm: image.width_cm,
      image_height_cm: image.height_cm,
      image_original_url: image.original_url,
      design_collection_type: _p.v(image, '_.design_collection_type') || null,
      is_marpple_image: !!image.image_color_id,
      press_color_code: image.press_color_code,
      press_color_id: image.press_color_id,
      press_color_name: image.press_color_name,
      original_is_ai: _p.last((image.original_url || '').split('.')).toLowerCase() == 'ai',
      name: (image.name || '').replace(/</g, '').replace(/>/g, ''),
      name_en: (image.name_en || '').replace(/</g, '').replace(/>/g, ''),
      is_embro: !!image.width_cm,
      price: image.price,
      price_en: parseFloat(image.price_en || 0),
    },
  };
}

export async function changeCvImageByImage(image, cv_image) {
  const fcanvas = cv_image.canvas;
  const index = fcanvas._objects.indexOf(cv_image);
  const new_cv_image = await addCvImage({ image, idx: index });
  new_cv_image.set({ top: cv_image.top, left: cv_image.left, angle: cv_image.angle });
  if (new_cv_image.width > new_cv_image.height) {
    new_cv_image.scale((cv_image.width * cv_image.scaleX) / new_cv_image.width);
  } else {
    new_cv_image.scale((cv_image.height * cv_image.scaleX) / new_cv_image.height);
  }
  cv_image.remove();
  NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(new_cv_image);
  await G.mp.maker.modified();
  return new_cv_image;
}

export async function makeTempCvImageByUrl(url) {
  const cv_image = await createCvImageP(makeDefaultCvImageAttrs({ url }));
  cv_image._data.cv_type = 'temp_cv_type';
  return cv_image;
}

export async function addCvImage({ image, idx, before_add_to_fcanvas, scale_to_width }) {
  const prev_don_loader_ing = !!$qs('.don_loader_wrap');
  if (!prev_don_loader_ing) {
    $.don_loader_start();
    await delay(10, () => {});
  }
  return _p.go(
    makeDefaultCvImageAttrs(image),
    (cv_attrs) => {
      if (NewMakerPropertyBpfF.bpcColorCode2Render.isTarget(getCurrentFcanvas())) {
        const bpc = getBaseProductColorInMaker();
        NewMakerPropertyBpfS.bpcColorCode2Render.setColorCode2(cv_attrs, bpc);
      }
      return cv_attrs;
    },
    createCvImageP,
    async function (cv_image) {
      // $도수 - 가드(메이커, 도수관련 옵션)
      const canvas = G.mp.maker.editing_canvas();

      const print = G.mp.maker.cv_print_area(canvas);

      if (window?.ENV?.app !== '"dream_factory"') {
        const metadata = !image.image_color_id ? await getMetadataFromImageUrl({ url: image.url }) : null;
        if (metadata?.density >= 150 && metadata?.width) {
          cv_image._data.dpi = metadata.density;
        }
      }

      if (image.width_cm) {
        const width = G.mp.maker.to_px('width_cm', image);
        const height = G.mp.maker.to_px('height_cm', image);
        const scale = 1;
        cv_image.set({
          width,
          height,
          scaleX: scale,
          scaleY: scale,
        });

        scale_to_width && cv_image.scaleToWidth(scale_to_width);
      } else {
        let zoom = cv_image._data.dpi ? 1 : 0.7;
        zoom *= 0.95;

        const { width, height } = G.mp.maker.make_img_width(image, 600);
        let scale = (G.mp.maker.fabric_get_width(print) * zoom) / width;
        if (print.scaleY * print.height * zoom < height * scale) {
          scale = (print.scaleY * print.height * zoom) / height;
        }
        cv_image.set({
          width,
          height,
          scaleX: scale,
          scaleY: scale,
        });
        scale_to_width && cv_image.scaleToWidth(scale_to_width);
      }
      const smallest_scale = NewMakerCheckerF.getSmallestRatio({
        cv_obj: cv_image,
        ratio: canvas._print_ratio,
      });
      if (smallest_scale < 1) cv_image.scale(cv_image.scaleX * smallest_scale);
      G.mp.maker.nscreen(cv_image);

      const bp = getBaseProductInMaker();
      G.mp.maker.align_print_area_center(cv_image);
      NewMakerCvObjAlignmentF.makeAlignmentCenter(cv_image, canvas);

      G.mp.maker.cleared_pass_discardActiveGroup(canvas);
      if (cv_image._data.dpi && cv_image._data.image_width) {
        const dpcm = cv_image._data.dpi / 2.54;
        const cm_from_original_image = parseInt(cv_image._data.image_width / dpcm);
        const cm_on_maker = parseInt(cv_image._data.width_cm);

        if (cm_from_original_image !== cm_on_maker) {
          OMPDosuF.showToastMsg({
            text: TT('maker::warning::image_size'),
            target_el: $qs('#maker'),
            timeout: 3000,
          });
        }
      }
      const dosu_ctn = BpOptionF.biz.get.currentFace.maxDosuCount(canvas.bpf_id);
      const initial_forced_dosu_color = await OMPDosuF.cvObj.initialize(cv_image, dosu_ctn > 1);
      /* 테스트 필요 도수 카운트 2개 일때, 1개일때 */
      if (initial_forced_dosu_color)
        await applyColorPickerToCvObj({ apply_color: initial_forced_dosu_color });
      if (before_add_to_fcanvas) {
        before_add_to_fcanvas(cv_image);
      }
      idx ? canvas.insertAt(cv_image, idx) : canvas.add(cv_image);

      canvas.setActiveObject(cv_image).renderAll();
      if (!prev_don_loader_ing) {
        $.don_loader_end();
      }
      if (isShadeBpNotBlackAndWhiteContrast(bp) && (await hasFullImageCvImage(cv_image))) {
        $.alert(
          T(
            'maker::Message::If you do not delete the background image of what you want to engrave, it will be engraved as it is, so be sure to delete the background before uploading.',
          ),
        );
      }

      await check_only_one_image_color(cv_image);

      return cv_image;
    },
  );
}

function makeImageFileFromBase64(base64, image_type) {
  const blobBin = atob(base64.split(',')[1]);
  const array = [];
  for (let i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }
  const file = new Blob([new Uint8Array(array)], { type: `image/${image_type}` });
  const formData = new FormData();
  formData.append('file', file, `cv_image_makeImageFileFromToDataUrl.${image_type}`);
  return $.upload(formData, {
    url: '/@fileUpload/maker/add_img',
    data: {},
  });
}

export async function changeCvImageByBase64(cv_image, base64) {
  return go(makeImageFileFromBase64(base64, 'png'), ({ result, msg, image }) => {
    if (!result) {
      $.alert(msg);
      return undefined;
    } else {
      return changeCvImageByImage(image, cv_image);
    }
  });
}
