import axios from 'axios';
import { each, find, map, filter } from 'fxjs';
import { AdScriptNaverF } from '../../../../../modules/AdScript/Naver/F/Function/module/AdScriptNaverF.js';
import { requiringBorder } from '../../../../../modules/Maker/F/Product/bpc_tmpl.js';
import { isNeedPreview } from '../../../../../modules/Maker/S/categorize.js';
import { resetUserCounts } from '../../../../../modules/MPay/Cart/F/fs.js';
import { MuiF } from '../../../../../modules/Mui/F/Function/module/MuiF.js';
import { OMPCartAddColorTmplS } from '../../../../../modules/OMP/Cart/AddColor/S/Tmpl/module/OMPCartAddColorTmplS.js';
import { rerenderCart } from '../../../../../modules/OMP/Cart/F/Function/util.js';
import { OMPCoreUtilF } from '../../../../../modules/OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { PriceS } from '../../../../../modules/Price/S/Function/module/PriceS.js';
import { discontinuedSizeIds, soldOutSizeIds } from '../../../../../modules/ProductColor/S/fs.js';
import {
  $addClass,
  $closest,
  $delegate,
  $findAll,
  $hasClass,
  $qs,
  $removeClass,
  $data,
  $find,
} from 'fxdom/es';
import { delay, go, html, strMap, reject, every, sel, omit, flatMap, sumBy } from 'fxjs/es';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

const activateBtnAdd = ({ sizes_body_el }) => {
  if (sizes_body_el) {
    const size_not_added = go(
      sizes_body_el,
      $findAll('input.quantity'),
      every((el) => parseInt(el.value) === 0),
    );

    const btn_add = go(sizes_body_el, $find('.btn_add'));
    btn_add.classList.toggle('disabled', size_not_added);
  }
};

!(function (LF) {
  G.mp.maker.picker = {};

  $.frame.defn_frame({
    frame_name: 'maker.picker.bpcs',
    page_name: 'maker.picker.bpcs',
    el_class: 'picker bpcs maker_dialog no_header prevent_bg_event',
    animation: true,
    prev_frame_show: true,
    always_remove: true,
    height: !UtilF.isLegacyMobile() ? '' : 245 + G.mp.frame.v_height,
    header_height: G.mp.frame.v_height,
    hide_frame_button_type: !UtilF.isLegacyMobile() ? '' : 'V',
    showed: !UtilF.isLegacyMobile() ? _p.noop : $.on_prevent_default,
    hiding: !UtilF.isLegacyMobile() ? _p.noop : $.off_prevent_default,
    appended: UtilF.isLegacyMobile() ? $.frame_close_bg : _p.noop,
    // appended: $.frame_close_bg,
    is_modal: !UtilF.isLegacyMobile(),
  });

  $.frame.defn_page({
    page_name: 'maker.picker.bpcs',
    hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : '',
    tabs: [
      {
        tab_name: 'maker.picker.bpcs',
        inner_scroll_target: '.colors_container',
        data_func: function () {
          return {
            selector: 'maker->product_color->_->base_product->_->base_product_colors',
            base_product_colors: box.sel('maker->product_color->_->base_product->_->base_product_colors'),
            base_product: box.sel('maker->product_color->_->base_product'),
            selected_bpc_id: box.sel('maker->product_color->base_product_color_id'),
          };
        },
        template: function (data) {
          go(
            data.base_product_colors,
            each((color) => {
              color['need_ccc'] = requiringBorder([color.color_code, color.color_code2]);
            }),
          );
          data.is_transparent = isNeedPreview(data.base_product);

          data.done_type = data.done_type || 'Done';
          return _p.go(
            data,
            UtilF.isLegacyMobile()
              ? _p.t(
                  'data',
                  '\
        .color[is_transparent={{data.is_transparent}}]\
          .options\
            .preview_title {{T("maker::Preview")}}\
            button[type="button" done_type={{data.done_type}}].done {{T("color::" + data.done_type)}}\
          .body.iscroll_wrapper[_sel="{{data.selector}}"]\
            .wrapper.iscroll_ul_wrapper\
              {{G.mp.maker.picker.bpc_items_tmpl(data.selected_bpc_id, data.base_product_colors)}}',
                )
              : _p.t(
                  'data',
                  '\
        .color_body\
          .title {{data.is_transparent ? T("maker::Preview") : T("Add Color")}}\
          .colors_container[_sel="{{data.selector}}"] \
            {{G.mp.maker.picker.bpc_items_tmpl2(data.selected_bpc_id, data.base_product_colors)}}\
          button[type="button" done_type={{data.done_type}} class="done"] {{T("color::Add")}}',
                ),
          );
        },
        appended: _pipe(
          $.on('click', '.done[done_type="Done"]', function (e) {
            const don_page = $.closest(e.delegateTarget, '.don_page');
            _p.go(
              null && don_page.page_opt.hiding(box.sel($.find1(don_page, '.color_item.selected'))),
              function () {
                $.frame.close();
              },
            );
          }),
          $.on('click', '.done[done_type="Add"]', function (e) {
            const button = e.target;
            const don_page = $.closest(e.delegateTarget, '.don_page');
            const color_items = go(don_page, $findAll('.color_item'));
            const every_color_added = go(
              color_items,
              every((item) => $hasClass('added', item)),
            );
            if (go(button, $hasClass('every_added')) && every_color_added) {
              return;
            }

            _p.go(don_page, $.find1('.color_item.selected'), box.sel, don_page.page_opt.adding, function () {
              $.frame.close();
            });
          }),
          $.on3('click', '.color_item', (e) => {
            const added = go(e.currentTarget, $hasClass('added'));
            if (added) return;

            return _p.go(e, $.selectable, box.sel, async function (bpc) {
              $.don_loader_start();
              await delay(10, () => {});
              if (UtilF.isLegacyMobile()) {
                const scroll_wrapper_el = $.closest(e.currentTarget, '.iscroll_wrapper');
                $.iscroll_to(scroll_wrapper_el, e.currentTarget);
              }
              const el_don_page = $.closest(e.delegateTarget, '.don_page');

              await el_don_page.page_opt.selecting(bpc);
              $.don_loader_end();
            });
          }),
        ),
        ending: _p.identity,
      },
    ],
    adding: _p.identity,
    selecting: _p.identity,
    showing: __($.find1('.body'), $.css({ opacity: 0, transition: 'opacity 0.2s' })),
    showed: __(_p.tap($.iscroll_on), $.find1('.body'), $.css({ opacity: 1 })),
    hiding: $.iscroll_off,
  });

  G.mp.maker.picker.bpc_items_tmpl = _p.sum(
    _p.t(
      'select_bpc_id, bpc',
      '\
    .item.color_item.i_item[_id="{{bpc.id}}" _sel="./(#{{bpc.id}})" class="{{bpc.id == select_bpc_id ? "selected" : ""}}" color_code="{{bpc.color_code}}" need_ccc="{{bpc.need_ccc}}"]\
      .code[style="background: {{bpc.color_code2 ? \'linear-gradient(135deg, \' + bpc.color_code + \' 50%, \' + bpc.color_code2 + \' 50%)\' : bpc.color_code}}"]\
      .name {{bpc["name"+_en]}}\
      ',
    ),
  );

  G.mp.maker.picker.bpc_items_tmpl2 = _p.sum(
    _p.t(
      'select_bpc_id, bpc',
      '\
    .color_item[_id="{{bpc.id}}" _sel="./(#{{bpc.id}})" class="{{bpc.id == select_bpc_id ? "selected" : ""}} {{bpc.added ? "added" : ""}}" color_code="{{bpc.color_code}}" need_ccc="{{bpc.need_ccc}}"]\
      .code_wrap\
        .code[style="background: {{bpc.color_code2 ? \'linear-gradient(135deg, \' + bpc.color_code + \' 50%, \' + bpc.color_code2 + \' 50%)\' : bpc.color_code}}"]\
      .name {{bpc["name"+_en]}}\
      ',
    ),
  );

  $.frame.defn_frame({
    frame_name: 'cart.add.color.colors',
    page_name: 'cart.add.color.colors',
    animation: true,
    prev_frame_show: true,
    always_remove: true,
    height: !UtilF.isLegacyMobile() ? '' : 534,
    header_height: G.mp.frame.v_height,
    hide_frame_button_type: !UtilF.isLegacyMobile() ? '' : 'V',
    showed: !UtilF.isLegacyMobile() ? _p.noop : $.on_prevent_default,
    hiding: !UtilF.isLegacyMobile() ? _p.noop : $.off_prevent_default,
    appended: UtilF.isLegacyMobile() ? $.frame_close_bg : _p.noop,
    is_modal: !UtilF.isLegacyMobile(),
    closed: (frame_el$, result_up_c_s) => {
      if (result_up_c_s?.up_c_id) {
        const up_c_el = document.querySelector(
          `.omp-cart__product[data-up_c_id="${result_up_c_s?.up_c_id}"]`,
        );
        if (up_c_el) {
          setTimeout(function () {
            up_c_el.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            });
          }, 100);
        }
      }
    },
  });

  $.frame.defn_page({
    page_name: 'cart.add.color.colors',
    hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : '',
    tabs: [
      {
        tab_name: 'cart.add.color.colors',
        inner_scroll_target: '.colors_wrapper',
        data_func: function () {
          return {
            selector: 'maker->product_color->_->base_product->_->base_product_colors',
            base_product_colors: box.sel('maker->product_color->_->base_product->_->base_product_colors'),
            base_product: box.sel('maker->product_color->_->base_product'),
            selected_bpc_id: box.sel('maker->product_color->base_product_color_id'),
          };
        },
        template: function (data) {
          go(
            data.base_product_colors,
            each((color) => {
              color['need_ccc'] = requiringBorder([color.color_code, color.color_code2]);
            }),
          );

          return OMPCoreUtilF.isMobile()
            ? OMPCartAddColorTmplS.cartAddColorColorsTmplMo(data)
            : OMPCartAddColorTmplS.cartAddColorColorsTmplPc(data);
        },
        appended: _pipe(
          $.on3('click', '.color_item', (e) => {
            const added = go(e.currentTarget, $hasClass('added'));
            if (added) return;

            return _p.go(e, $.selectable, box.sel, async function (bpc) {
              $.don_loader_start();
              const container = go(e.currentTarget, $closest('.color_body'));
              const { user_product } = $data(container);
              await MuiF.pushPage(
                {
                  page_name: 'cart.add.color.sizes',
                  hide_frame_button_type: OMPCoreUtilF.isMobile() ? '' : 'X',
                  custom_header: () =>
                    html`${OMPCoreUtilF.isMobile()
                      ? `<div></div>`
                      : `<button type="button" class="don_hide_frame"></button>`}`,
                  tabs: [
                    {
                      tab_name: 'cart.add.color.sizes',
                      inner_scroll_target: '.sizes_container',
                      template: async function (data) {
                        const { data: base_product_sizes } = await axios.get(
                          '/@api/base_product_sizes/by_base_product_color_id',
                          {
                            params: { id: bpc.id },
                          },
                        );
                        const filtered_bpss = go(
                          base_product_sizes,
                          reject((bps) => bps._is_discontinued),
                        );

                        return OMPCoreUtilF.isMobile()
                          ? OMPCartAddColorTmplS.cartAddColorSizesTmplMo({
                              filtered_bpss,
                              user_product,
                              bpc: data,
                            })
                          : OMPCartAddColorTmplS.cartAddColorSizesTmplPc({
                              filtered_bpss,
                              user_product,
                              bpc: data,
                            });
                      },
                      appended(tab_el) {
                        go(
                          tab_el,
                          $delegate('click', '.btn_prev', (e) => MuiF.popPage()),
                          $delegate('click', '.btn_add', async (e) => {
                            try {
                              const sizes = go(
                                tab_el,
                                $findAll('.quantity-editor'),
                                map((size_el) => {
                                  const input = go(size_el, $find('input'));
                                  return {
                                    ...$data(size_el),
                                    quantity: Number(input.value),
                                  };
                                }),
                              );

                              const size_not_added = go(
                                sizes,
                                every(({ quantity }) => quantity === 0),
                              );

                              if (size_not_added) {
                                return;
                              }

                              $.don_loader_start();

                              const { data: up } = await axios.post('/@api/up_c/add', {
                                user_product,
                                base_product_color: bpc,
                              });

                              if (up.is_failed) return $.alert('Adding failed. Please try again.');

                              const added_up_c = go(
                                up._.up_cs,
                                find(({ base_product_color_id }) => base_product_color_id === bpc.id),
                              );

                              const up_c_ss_by_new_color = go(
                                sizes,
                                filter((up_c_s) => up_c_s.quantity),
                                each((up_c_s) => {
                                  up_c_s.up_id = added_up_c.up_id;
                                  up_c_s.up_c_id = added_up_c.id;
                                  up_c_s.base_product_color_id = bpc.id;
                                  up_c_s.base_product_size_id = up_c_s.id;
                                }),
                                map((up_c_s) => {
                                  return go(up_c_s, omit(['color', 'size', 'no', 'id']));
                                }),
                              );

                              axios
                                .post('/@api/up_c_ss/update', up_c_ss_by_new_color)
                                .then(async (res) => {
                                  const { data: user_products } = res;
                                  box.set('user_products', user_products);
                                  resetUserCounts();
                                  await rerenderCart(user_products);
                                  const {
                                    _: { product_color },
                                  } = up;
                                  const {
                                    _: { base_product },
                                  } = product_color;
                                  const result_up_c_ss = go(
                                    user_products,
                                    flatMap((user_product) => user_product._.up_cs),
                                    find(
                                      (up_c) =>
                                        up_c.base_product_id === user_product.base_product_id &&
                                        up_c.base_product_color_id === bpc.id,
                                    ),
                                    (up_c) => up_c._.up_c_ss,
                                  );
                                  const new_total_quantity = go(result_up_c_ss, sumBy(sel('quantity'))) || 0;

                                  try {
                                    const quantity_added = new_total_quantity;
                                    const base_product_id = base_product.id;
                                    const base_product_name = base_product['name' + G._en];
                                    if (typeof gtag == 'function') {
                                      gtag('event', 'add_to_cart', {
                                        dynx_itemid: [base_product_id], // 장바구니 담은 상품의 아이디 배열
                                        dynx_itemid2: [base_product_id], // 장바구니 담은 상품의 아이디 배열
                                        dynx_pagetype: 'conversionintent',
                                        ecomm_prodid: [base_product_id],
                                        ecomm_pagetype: 'conversionintent',
                                        dynx_totalvalue: PriceS.mult(
                                          quantity_added,
                                          added_up_c['price' + G._en],
                                        ), // 장바구니 담은 상품 총 금액
                                        items: [
                                          {
                                            id: base_product_id,
                                            name: base_product_name,
                                            list_name: '장바구니',
                                            item_category: sel('_.cate_list.name', base_product),
                                            item_category2: sel('_.cate_item.name', base_product),
                                            list_position: 1,
                                            quantity: quantity_added,
                                            price: added_up_c['price' + G._en],
                                          },
                                        ],
                                      });
                                    }

                                    if (typeof fbq == 'function') {
                                      fbq('track', 'AddToCart', {
                                        value: sumBy(function (up_c_s) {
                                          return PriceS.mult(up_c_s.quantity, up_c_s['price' + G._en]);
                                        }, result_up_c_ss),
                                        currency: T.lang == 'kr' ? 'KRW' : 'USD',
                                        content_type: 'product',
                                        contents: [
                                          {
                                            id: base_product_id,
                                            name: base_product_name,
                                            price: added_up_c['price' + G._en],
                                            quantity: sumBy(function (up_c_s) {
                                              return up_c_s.quantity;
                                            }, result_up_c_ss),
                                          },
                                        ],
                                      });
                                    }
                                  } catch (err) {}

                                  const [result_up_c_s] = result_up_c_ss;
                                  MuiF.closeFrame(result_up_c_s);
                                  $.don_loader_end();
                                })
                                .catch((error) => {
                                  $.don_loader_end();
                                  return $.alert('Adding failed. Please try again.', error);
                                });
                            } catch (error) {
                              $.don_loader_end();
                              return $.alert('Adding failed. Please try again.', error);
                            }
                          }),
                          $delegate('focusout', 'input.quantity', (e) => {
                            const input_el = e.target;
                            let input_quantity = Number(input_el.value);
                            const { quantity } = {
                              quantity: Math.min(input_el.max, Math.max(input_quantity, input_el.min)),
                            };
                            input_el.value = quantity;

                            activateBtnAdd({ sizes_body_el: go(input_el, $closest('.sizes_body')) });
                          }),
                          $delegate('click', '.omp-atom__input-number-plus-minus-button button', (e) => {
                            const input_el = go(
                              e.currentTarget,
                              $closest('.omp-atom__input-number-plus-minus-button'),
                              $find('input[type="number"]'),
                            );

                            const wrapper_el = go(input_el, $closest('.quantity-editor'));
                            if (wrapper_el) {
                              const { is_not_stock } = wrapper_el.dataset;
                              if (is_not_stock === 'true') {
                                return;
                              }
                            }

                            go(input_el, (input_el) => {
                              const operator = e.currentTarget.dataset.operator;
                              let input_quantity = Number(input_el.value);
                              if (operator === '+') {
                                ++input_quantity;
                              } else if (operator === '-') {
                                --input_quantity;
                              }
                              const { quantity } = {
                                quantity: Math.min(input_el.max, Math.max(input_quantity, input_el.min)),
                              };
                              input_el.value = quantity;
                            });

                            const sizes_body_el = go(e.currentTarget, $closest('.sizes_body'));
                            activateBtnAdd({ sizes_body_el });
                          }),
                        );
                      },
                    },
                  ],
                  showed: $.iscroll_on,
                  hiding: $.iscroll_off,
                },
                async (page, [tab]) => {
                  tab.makeData = () => bpc;
                },
              );
              $.don_loader_end();
            });
          }),
          $.on3('click', '.colors_title.added', (e) => {
            if (OMPCoreUtilF.isMobile()) {
              $.iscroll_off();

              const checkbox = go(e.target, $closest('.added_colors'), $find('#added_colors_title'));
              checkbox.checked = !checkbox.checked;

              // css transition이 끝난 후 scroll 계산을 위해
              setTimeout(() => {
                $.iscroll_on();
              }, 600);
            }
          }),
        ),
        ending: _p.identity,
      },
    ],
    adding: _p.identity,
    selecting: _p.identity,
    showed: $.iscroll_on,
    hiding: $.iscroll_off,
  });

  $.frame.defn_frame({
    frame_name: 'maker.picker.bpss',
    page_name: 'maker.picker.bpss',
    el_class: 'picker bpss maker_dialog no_header prevent_bg_event',
    animation: true,
    prev_frame_show: true,
    always_remove: true,
    height: 183 + G.mp.frame.v_height,
    header_height: G.mp.frame.v_height,
    hide_frame_button_type: 'V',
    appended: $.frame_close_bg,
    showed: $.on_prevent_default,
    hiding: $.off_prevent_default,
  });

  $.frame.defn_page({
    page_name: 'maker.picker.bpss',
    tabs: [
      {
        tab_name: 'maker.picker.bpss',
        template: _p.pipe(
          function () {
            const pc = box.sel('maker->product_color');
            const bpcs = _p.v(pc, '_.base_product._.base_product_colors');
            const sold_out_size_ids = soldOutSizeIds(pc, bpcs);
            const discontinued_size_ids = discontinuedSizeIds(pc, bpcs);
            return _p.mr(
              box.sel('maker->product_color->_->base_product->_->base_product_sizes'),
              sold_out_size_ids,
              discontinued_size_ids,
            );
          },
          _p.t(
            'bpss, sold_out_size_ids, discontinued_size_ids',
            '\
          .size\
            .options\
              button[type="button" style="display: none;"].size_table 사이즈표\
              button[type="button"].done {{T("Done")}}\
            .body.iscroll_wrapper[_sel="maker->product_color->_->base_product->_->base_product_sizes"]\
              .wrapper.iscroll_ul_wrapper\
                {{G.mp.maker.picker.bps_items_tmpl(bpss, sold_out_size_ids, discontinued_size_ids)}}',
          ),
        ),
        appended: _pipe(
          $.on('click', '.done', function () {
            $.frame.close();
          }),
          $.on3('click', '.item', async function (e) {
            $.don_loader_start();
            await delay(10, () => {});
            const el_don_page = $.closest(e.currentTarget, '.don_page');
            return _p.go($.selectable2(e.currentTarget), box.sel, async function (bps) {
              $.iscroll_to($.closest(e.currentTarget, '.iscroll_wrapper'), e.currentTarget);
              const maker_frame = $qs('#maker_frame');

              if (maker_frame) $addClass('pc_canvas_change_ing')(maker_frame);
              await el_don_page.page_opt.selecting(bps);
              if (maker_frame) $removeClass('pc_canvas_change_ing')(maker_frame);
              $.don_loader_end();
            });
          }),
        ),
      },
    ],
    showing: __($.find1('.body'), $.css({ opacity: 0, transition: 'opacity 0.2s' })),
    showed: __(_p.tap($.iscroll_on), $.find1('.body'), $.css({ opacity: 1 })),
    hiding: $.iscroll_off,
  });

  G.mp.maker.picker.bps_items_tmpl = function (bpss, sold_out_size_ids, discontinued_size_ids) {
    const avaiableSizes = go(
      bpss,
      reject(({ id }) => discontinued_size_ids.includes(id)),
    );

    return go(
      avaiableSizes,
      strMap(
        (bps) => html`
          <div
            class="sold-out-select-box-view-v1 item i_item ${bps.id ==
            box.sel('maker->product_color->base_product_size_id')
              ? 'selected'
              : ''} ${_.contains(sold_out_size_ids, bps.id) ? 'sold_out' : ''}"
            _id="${bps.id}"
            _sel="./(#${bps.id})"
          >
            <span class="sold-out-select-box-view-v1__name">${bps['name' + _en]}</span>
            <span class="sold-out-select-box-view-v1__no_stock">${T('cart_modal::일시품절')}</span>
          </div>
        `,
      ),
    );
  };
})({});
