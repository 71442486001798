import { NewMakerPropertyBaseProductConstantS } from '../Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { BpOptionS } from '../../../../../BpOption/S/Function/module/BpOptionS.js';
import { every, find, go, html } from 'fxjs/es';

export const bpOptionPreviews = {
  getObject: (base_product) => {
    return base_product.maker_features?.[NewMakerPropertyBaseProductConstantS.BP_OPTION_PREVIEWS];
  },
  getPreview: (product) => {
    const selected_bp_option_ids = BpOptionS.getSelectedBpOptionIds(product);
    const bp_option_previews = bpOptionPreviews.getObject(product._.base_product);

    const preview = go(
      bp_option_previews,
      find((bp_option_preview) =>
        go(
          bp_option_preview.bp_option_ids,
          every((bp_option_id) => selected_bp_option_ids.includes(bp_option_id)),
        ),
      ),
    );
    return preview;
  },
  makePreviewHtml: (product) => {
    const preview = bpOptionPreviews.getPreview(product);
    const cate_item_name = product._.base_product._.cate_item['name' + G._en];

    return html`
      <div class="maker-bp-option-preview">
        <div class="maker-bp-option-preview__text">
          ${TT('maker_upload_type::preview_text', { cate_item_name })}
        </div>
        <div class="maker-bp-option-preview__image">
          <img src="${preview?.img_url || ''}" alt="이미지가 없습니다." />
        </div>
      </div>
    `;
  },
};
