import axios from 'axios';
import { $delegate, $find, $qs } from 'fxdom/es';
import { each, go, indexBy, partition, tap, map } from 'fxjs/es';
import { pushLoginStack } from '../../../../../../Creator/Login/F/fs.js';
import { UtilF } from '../../../../../../Util/F/Function/module/UtilF.js';
import { MShopCoreF } from '../../../../../Core/F/Function/module/MShopCoreF.js';
import { MShopShareFramePopUpF } from '../../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';

function changeHeart$(target) {
  const { already_like } = target.dataset;
  const heart_el = $find('.app-product-item__heart', target);
  if (heart_el) {
    MShopCoreF.toggleIconShape(heart_el);
  }
  target.dataset.already_like = !(already_like === 'true');
}

export const updateLike = async (already_like, stores_product_id) => {
  const {
    data: { ok },
  } = await axios.post(`/${T.lang}/@api/stores_products_like`, {
    already_like,
    stores_product_id,
  });
  if (!ok) await Promise.reject(Error('Not ok'));

  MShopUtilF.popToastMsg(
    already_like ? T('mshop::좋아요 목록에서 삭제되었습니다.') : T('mshop::좋아요 목록에 추가되었습니다.'),
    already_like ? 'error' : 'like',
  );
};
const my_heart_list = {};

const p = { promise: null };

export const getMyHeartList = async (is_ajax, params) => {
  if (p.promise) await p.promise.then(() => (p.promise = null));
  if (!is_ajax && my_heart_list.data) return my_heart_list.data;
  p.promise = go(
    axios.get(`/${T.lang}/@api/my_stores_products_like`, {
      params: {
        store_id: box.sel('store_id'),
        d: Date.now(),
        ...params,
      },
    }),
    ({ data }) => (my_heart_list.data = data),
  );
  return p.promise;
};

export const replaceHeartData = async (data) => {
  const [activated_likes, deactivated_likes] = await getMyHeartList();
  const index_activated_likes = indexBy((s) => s.stores_product_id, activated_likes);
  const index_deactivated_likes = indexBy((s) => s.stores_product_id, deactivated_likes);

  return go(
    data,
    partition(({ is_liked }) => !!is_liked),
    ([already_likes, not_likes]) => {
      return [
        ...map((product) => {
          if (index_deactivated_likes[product.stores_product_id]) {
            return {
              ...product,
              is_liked: false,
            };
          }
          return product;
        }, already_likes),
        ...map((product) => {
          const spl = index_activated_likes[product.stores_product_id];
          if (spl) {
            return {
              ...product,
              is_liked: true,
            };
          }
          return product;
        }, not_likes),
      ];
    },
  );
};

const setChangeHeart$ =
  (already) =>
  ({ stores_product_id }) => {
    const like_el = $qs(
      `.app-product-item__like[data-already_like="${already}"][data-stores_product_id="${stores_product_id}"]`,
    );
    if (like_el) changeHeart$(like_el);
  };

const replaceHeart = async (is_persisted) => {
  if (window.box.sel('is_user->id') && window.box.sel('is_user->type') != 'TEMP' && is_persisted)
    return go(await getMyHeartList(true), ([activated_likes, deactivated_likes]) => {
      each(setChangeHeart$(false), activated_likes);
      each(setChangeHeart$(true), deactivated_likes);
    });
};

const appListener = async function (e) {
  try {
    const { is_focused } = JSON.parse(e.data);
    if (is_focused) await replaceHeart(true);
  } catch (err) {}
};

const resetLikeBackForward = () => {
  if (!MShopUtilF.isApp()) return UtilF.initBackForward(replaceHeart);
  window.addEventListener('message', appListener);
  document.addEventListener('message', appListener);
};

export const delegateUpdateLike = async (tab_el$) =>
  go(
    tab_el$,
    tap(resetLikeBackForward),
    $delegate('click', '.app-product-item__like', async (e) => {
      if (!window.box.sel('is_user->id') || window.box.sel('is_user->type') == 'TEMP')
        return (
          (await MShopShareFramePopUpF.confirm({
            title: T('community::로그인 후 이용해 주세요. 로그인 하시겠습니까?'),
          })) &&
          (MShopUtilF.isApp()
            ? pushLoginStack()
            : (location.href = `/${T.lang}/@/login?url=${location.pathname + location.search}`))
        );

      const { already_like, stores_product_id } = e.currentTarget.dataset;
      changeHeart$(e.currentTarget);
      try {
        await updateLike(already_like == 'true', stores_product_id);
      } catch (error) {
        changeHeart$(e.currentTarget);
      }
    }),
  );
