import { html } from 'fxjs/es';
import { ConfigSentryF } from '../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { OMPCoreUtilF } from '../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';

class ProEditorError extends Error {
  constructor(message, title, type) {
    super(message);
    this.editor = 'pro_editor';
    this.name = 'pro_editor_error';
    this.title = title;
    this.message = message || 'Unknown message';

    if (!['user', 'dev'].includes(type)) throw new Error(`ProEditorError type must not allowed. ${type}]`);
    this.type = type;
  }
}

export const throwProEditorErr = {
  user: ({ msg, title }) => {
    throw new ProEditorError(msg, title, 'user');
  },
  dev: ({ msg, title }) => {
    throw new ProEditorError(msg, title, 'dev');
  },
};

export function isProEditorError(err) {
  return err instanceof ProEditorError;
}

export function isProEditorUserError(err) {
  return err instanceof ProEditorError && err.type === 'user';
}

export async function alertProEditorError(err) {
  let send_sentry = false;

  if (isProEditorError(err)) {
    if (isProEditorUserError(err)) {
      // 유저에 의한 프로에디터 에러
      await OMPCoreUtilF.titleAlert(err);
    } else {
      // 개발상에 의한 프로에디터 에러 -> 에러 메세지 숨기고 cs 유도
      await alertProEditorCsError({ title: TT('pro_editor::alert::title::dev') });
      send_sentry = true;
    }
  } else {
    // uncaught 에러 -> 에러 메세지 숨기고 cs 유도
    await alertProEditorCsError({ title: TT('pro_editor::alert::title::unknown') });
    send_sentry = true;
  }

  send_sentry &&
    ConfigSentryF.error(err, {
      tags: { editor: err?.editor, error_name: err?.name, editor_type: err?.type },
    });
}

export async function alertProEditorCsError({ title }) {
  await OMPCoreUtilF.titleAlert({
    title,
    content: getCsErrorAlertHtml(),
  });
}

function getCsErrorAlertHtml() {
  const is_kr = G._en === '';
  const is_en = G._en === '_en';
  const is_jp = G._en === '_jp';

  return html` <div
    style="display:flex;flex-direction:column;gap:0.5rem;justify-content: center;align-items: start;padding:0.5rem 1.5rem;border-radius: 0.5rem;background-color:#F6F6F6"
  >
    <div style="display:flex;gap:0.75rem">
      <img
        src="//s3.marpple.co/files/u_1187078/2023/11/original/2d6938a8d068d4ffa1b7a31fc95bb14db00944f21.png"
        alt="help mail"
        style="width:1.5rem;height:1.5rem;"
      />
      <span>${is_kr ? 'help@marpple.com' : 'cs@marpple.com'}</span>
    </div>
    ${!is_en
      ? html`<div style="display:flex;gap:0.75rem">
          <img
            src="//s3.marpple.co/files/u_1187078/2023/11/original/58d6e1572abc2fc84f9bdd100bfccad1852c91891.png"
            alt="help call"
            style="width:1.5rem;height:1.5rem;"
          />
          <span>${is_kr ? '1566-9437' : is_jp ? '050-5840-0207' : ''} </span>
        </div>`
      : ''}
  </div>`;
}
