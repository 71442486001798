import { html } from 'fxjs/es';
import { hasPdfFile } from '../Function/mobilepdfuploader.js';

/**
 * @param {PdfPrintingFileRow | undefined} pdf_printing_file
 * @return {string} PDF 업로드 프레임 헤더 HTML 문자열
 * */
export function header({ pdf_printing_file }) {
  const has_pdf_file = hasPdfFile({ pdf_printing_file });

  return html`<div class="pdf_uploader__header">
    <button class="fs-12 fw-400 lh-100" name="cancel">취소</button>
    <span class="title fs-14 fw-700">도안 업로드</span>
    <button class="fs-12 fw-400 lh-100" name="confirm" ${has_pdf_file ? '' : 'disabled'}>완료</button>
  </div>`;
}
