import { go, map, deepFlat, identity, some } from 'fxjs/es';
import { createCanvasElement } from '../../../../Canvas/S/util.js';

export const cmykToRgb = ([c, m, y, k]) => {
  return [
    Math.round(255 * (1 - c / 100) * (1 - k / 100)),
    Math.round(255 * (1 - m / 100) * (1 - k / 100)),
    Math.round(255 * (1 - y / 100) * (1 - k / 100)),
  ];
};
export const rgbToCmyk = ([r, g, b]) => {
  const rc = r / 255;
  const gc = g / 255;
  const bc = b / 255;
  const k = 1 - Math.max(rc, gc, bc);
  const c = (1 - rc - k) / (1 - k);
  const m = (1 - gc - k) / (1 - k);
  const y = (1 - bc - k) / (1 - k);
  return go(
    [c * 100, m * 100, y * 100, k * 100],
    map((n) => Math.round(n) || 0),
  );
};

export function getDotLengthFromCanvas(canvas) {
  const ctx = canvas.getContext('2d');
  const image_data = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const { width, height } = canvas;
  let i = 0;

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const idx = (x + width * y) * 4;
      if (image_data.data[idx + 3] === 0) continue;
      i++;
    }
  }
  return i;
}

export function makeTransparentCanvas(canvas, strength) {
  const ctx = canvas.getContext('2d');
  const image_data = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const { width, height } = canvas;
  const new_canvas = createCanvasElement(canvas);
  const new_ctx = new_canvas.getContext('2d');
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const idx = (x + width * y) * 4;
      if (image_data.data[idx + 3] === 0) continue;
      image_data.data[idx + 3] = strength;
    }
  }
  new_ctx.putImageData(image_data, 0, 0);
  return new_canvas;
}

function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  let h, s;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  const l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
}

export function makeComplementaryColorCode(canvas) {
  const ctx = canvas.getContext('2d');
  const image_data = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const { width, height } = canvas;
  let average_h = 0;
  let i = 0;
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const idx = (x + width * y) * 4;
      if (image_data.data[idx + 3] === 0) continue;
      i++;
      average_h += rgbToHsl(image_data.data[idx], image_data.data[idx + 1], image_data.data[idx + 2])[0];
    }
  }
  average_h = average_h / i;
  if (average_h < 20 || average_h > 330 || (average_h > 137 && average_h < 282)) {
    return '#ff0000';
  } else {
    return '#00ffff';
  }
}

// export const getDFromAeqBandCeqD = (a,b,c) => {
//   return b*c/a
// };

// export const getCFromAeqBandCeqD = (a,b,d) => {
//   return a * d /b
// };

export function flatPfDesigns(product_faces, with_bpf) {
  return go(
    product_faces,
    map((pf) =>
      go(
        pf.designs,
        map(function flat_cv_group(d) {
          return d.objects ? d.objects.map((d) => flat_cv_group(d)) : d;
        }),
        deepFlat,
        map((cv_obj) => {
          const _data = cv_obj._data;
          if (_data.cv_type === 'cv_image') {
            return {
              image_url: G.to_original(_data.image_url),
              src: G.to_original(cv_obj.src),
            };
          }
          if (_data.cv_type === 'cv_pattern') {
            const cv_image_data = _data.cv_image_attrs._data;
            return {
              image_url: G.to_original(cv_image_data.image_url),
            };
          }
          if (_data.cv_type === 'cv_text_image') {
            const text_info = _data.text_info;
            return {
              text: text_info.text,
            };
          }
          if (_data.cv_type === 'cv_text_image_pattern') {
            const text_info = _data.cv_text_image_attrs._data.text_info;
            return {
              text: text_info.text,
            };
          }
        }),
        with_bpf ? (arr) => arr.concat(pf.bpf_id) : identity,
      ),
    ),
  );
}
// async function textSamePf() {
//   // 15652147 15653689
//   const bps =
//     await RR.QUERY`SELECT id, name FROm base_products WHERE is_use_creator = true AND id > 4292 order by id`;
//   console.log(bps.length);
//   let i = bps.length;
//   await go(
//     bps,
//     each(async (bp) => {
//       console.log('-0----------', bp.name, '-----', i--);
//       const test_sets = await RR.QUERY`SELECT p.product_id original_product_id FROM products as p
//         INNER JOIN ups as u ON u.product_color_id = p.id
//         INNER JOIN stores_products as sp ON sp.product_id = p.product_id AND sp.is_original
//         WHERE u.quantity > 0 AND u.type = 'cart' AND ${IN('p.base_product_id', [
//         bp.id,
//       ])} AND p.collabo_type = 'creator' ANd p.created_at > sp.public_at ANd sp.created_at > '2023-11-28' group by p.product_id order by p.product_id desc limit 10
//       `;
//       console.log(test_sets.length, '--------------');
//       let j = test_sets.length;
//       await go(
//         test_sets,
//         each(async ({ original_product_id }) => {
//           console.log(j--);
//           const { target_product_id } = await RR.QUERY1`SELECT p.id target_product_id FROM products as p
//                                INNER JOIN stores_products as sp ON sp.product_id = p.product_id AND sp.is_original
//             INNER JOIN ups as u ON u.product_color_id = p.id
//             WHERE u.quantity > 0 AND u.type = 'cart' AND p.product_id = ${original_product_id} ANd p.id != p.product_id AND p.created_at > sp.public_at order by p.id desc limit 1
//             `;
//           // console.log({ original_product_id, target_product_id });
//           await go(undefined, async () => {
//             const is_same_price = await isSamePrintPrice(original_product_id, target_product_id, RR);
//             if (!is_same_price) {
//               console.log('bp_Id', bp.id);
//               console.log(original_product_id, target_product_id);
//               throw new Error('------------------is_same_price-----------------ERROR');
//             }
//             const original_product =
//               await RR.QUERY1`SELECT * FROM products WHERE id = ${original_product_id}`;
//             const _pc = await RR.QUERY1`SELECT * FROM products WHERE id = ${target_product_id}`;
//             const is_design_same = go(undefined, () => {
//               const original_pfs = original_product.product_faces2.value;
//               const pfs = _pc.product_faces2.value;
//               return deepEqual(flatPfDesigns(pfs), flatPfDesigns(original_pfs));
//             });
//             if (!is_design_same) {
//               console.log('bp_Id', bp.id);
//               console.log(original_product_id, target_product_id);
//               throw new Error('----------------DESIGN-------------------ERROR');
//             }
//           });
//         }),
//       );
//     }),
//   );
// }

export const hasDesignsInFaces = (product_color) => {
  return go(
    product_color.product_faces2?.value,
    some(({ designs }) => designs.length > 0),
  );
};
