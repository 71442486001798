export const ga = () => {};

export const signUpMpCreator = (user) => {
  try {
    const email = user?.email || box.sel('is_user->email') || '';
    window.email_address = email;
    window.phone_number = '';

    if (typeof gtag == 'function') {
      gtag('event', 'sign_up');
      if (G.collabo_type == '') {
        if (T.lang == 'kr') {
          gtag('set', 'user_data', {
            email,
          });
          gtag('event', 'conversion', {
            send_to: 'AW-881715956/cDuKCL252GYQ9NW3pAM',
          });
          gtag('event', 'conversion', { send_to: 'AW-881715956/kz_HCO776J8YEPTVt6QD' });
        } else if (T.lang == 'jp') {
          gtag('event', 'conversion', { send_to: 'AW-828951906/_vH5CPGf7cIBEOKao4sD' });
        } else {
          gtag('event', 'conversion', {
            send_to: 'AW-828951906/BZPzCKSE5o8BEOKao4sD',
          });
        }
      }
    }
  } catch (err) {}
};

export const bizGoodsTalk = async (collabo_type) => {
  return new Promise((rs) => {
    if (typeof gtag == 'function' && collabo_type == '') {
      const callback = function () {
        rs(true);
      };
      gtag('event', 'conversion', {
        send_to: 'AW-881715956/Ow-xCL-k9pcYEPTVt6QD',
        event_callback: callback,
      });
    } else {
      rs(true);
    }
  });
};

export function youtubeLinking() {
  if (typeof gtag == 'function' && G.collabo_type == 'creator') {
    gtag('event', 'conversion', {
      send_to: 'AW-881715956/vfO1CMeclJkZEPTVt6QD',
    });
    gtag('event', '유튜브 x 마플샵 연결하기', { event_category: '유튜브 x 마플샵 연결하기' });
  }
}

export function offlinePopupStoreApp() {
  if (typeof gtag == 'function' && G.collabo_type == 'creator') {
    gtag('event', 'conversion', {
      send_to: 'AW-881715956/ZnXcCKiX36cZEPTVt6QD',
    });
    gtag('event', '오프라인 팝업 스토어 신청', { event_category: '오프라인 팝업 스토어 신청' });
  }
}

// TODO 병진
export function brandCommerceRequest() {
  if (typeof gtag == 'function' && G.collabo_type == 'creator') {
    //   gtag('event', 'conversion', {
    //     send_to: 'AW-881715956/ZnXcCKiX36cZEPTVt6QD',
    //   });
    gtag('event', '브랜드커머스 신청', { event_category: '브랜드커머스 신청' });
  }
}

export function applyCreator() {
  if (typeof gtag == 'function' && G.collabo_type == 'creator') {
    gtag('event', '크리에이터 신청', { event_category: '크리에이터 신청' });
  }
}

export function applyPlus() {
  if (typeof gtag == 'function' && G.collabo_type == 'creator') {
    gtag('event', 'conversion', { send_to: 'AW-881715956/VRuBCIrgo9oBEPTVt6QD' });
    gtag('event', '마플샵플러스 입점신청', { event_category: '마플샵플러스 입점신청' });
  }
}
