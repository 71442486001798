import { html, strMap, values } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreLayoutTmplS } from '../../../Core/Layout/S/Tmpl/module/OMPCoreLayoutTmplS.js';
import { OMPAuthConstantS } from '../Constant/module/OMPAuthConstantS.js';
import { OMPAuthTmplS } from './module/OMPAuthTmplS.js';

export const loginModalTmpl = () => {
  const login_button = TT('auth::login::login_01');
  const fb_login = TT('auth::login::login_01_01');
  const find_id = TT('auth::login::login_04');
  const reset_password = TT('auth::login::login_05');

  return html`
    <div class="omp-auth-login">
      <form class="omp-auth-login__data" method="POST" action="/${TT.lang}/@api/login">
        <div class="omp-auth-login__inputs">
          ${strMap((input) => {
            return html` <span class="omp-auth-signup__input-wrapper"> ${input.template()} </span> `;
          }, values(OMPAuthConstantS.LOGIN().inputs))}
        </div>
        <div class="omp-auth-login__button-wrapper">
          ${OMPCoreAtomTmplS.actionButton({
            klass: 'omp-auth-login__data-submit',
            text: login_button,
            solidity: 'thick',
          })}
          <div class="omp-auth-login__or">OR</div>
          ${OMPCoreAtomTmplS.actionButton({
            klass: 'omp-auth__facebook',
            text: fb_login,
            solidity: 'thick',
            type: 'button',
            data: { color: 'blue' },
          })}
        </div>
      </form>
      <div class="omp-auth-login__find">
        ${TT.lang === 'kr'
          ? html`<span class="omp-auth-login__find-id"> ${find_id} </span>
              <span class="omp-auth-login__find-pw"> ${reset_password} </span>`
          : html`<span class="omp-auth-login__find-pw"> ${reset_password} </span>`}
      </div>
    </div>
  `;
};

export const loginPageTmpl = (locals) => {
  return OMPCoreLayoutTmplS.bodyTmpl({
    locals,
    body: html`
      <div class="omp-auth-page">
        <div class="omp-auth-page__title">
          <a href="/${TT.lang}/signup" data-active="false"> ${TT('auth::signup::signup_01')} </a>
          <a href="/${TT.lang}/login" data-active="true"> ${TT('auth::login::login_01')} </a>
        </div>

        ${OMPAuthTmplS.loginModalTmpl()}
      </div>
    `,
  });
};
