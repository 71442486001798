export const makePlusUrl = (domain_name?: string, path = '') => {
  const is_prod = process.env.NODE_ENV === 'production';

  if (!domain_name) return path;

  if (is_prod) {
    return `https://${domain_name}.marpple.shop${path}`;
  }

  const is_stg = process.env.NODE_ENV === 'staging';

  if (is_stg) {
    return `https://${domain_name}.marpple.cc${path}`;
  }

  return `http://${domain_name}.${process.env.MYDOMAIN ?? 'dev.localhost'}:11001${path}`;
};

export const makeMainUrl = (path = '') => {
  const is_prod = process.env.NODE_ENV === 'production';

  if (is_prod) {
    return `https://marpple.shop${path}`;
  }

  const is_stg = process.env.NODE_ENV === 'staging';

  if (is_stg) {
    return `https://marpple.cc${path}`;
  }

  return `http://${process.env.MYDOMAIN ?? 'dev.localhost'}:11001${path}`;
};
