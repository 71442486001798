import { each, entries, find, go, head, ippL, last, map, mapC, pick, sel, sortByDesc, tap } from 'fxjs/es';
import { BpOptionConstantS } from '../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import {
  createCanvasElement,
  evaluateColorBrightness,
  evaluateImageBrightness,
  fillColorOnCanvas,
  loadImageFromUrl,
  makeCanvasByImg,
  makeCanvasByUrl,
  resizeImage,
  setMaskedCanvas,
} from '../../../Canvas/S/util.js';
import { CateListConstantS } from '../../../CateList/S/Constant/module/CateListConstantS.js';
import { NewMakerProductStyleS } from '../../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { NewMakerWeS } from '../../../NewMaker/We/S/Function/module/NewMakerWeS.js';
import { UtilImageS } from '../../../Util/Image/S/Function/module/UtilImageS.js';
import { NewMakerPropertyBpfS } from '../../../NewMaker/Property/Bpf/S/Function/module/NewMakerPropertyBpfS.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';

const pixelToIdx = (x, y, width) => (x + y * width) * 4;

function pureColorLayer(mask_canvas, cm_base_product_color) {
  let abs_color_code = cm_base_product_color.abs_color_code || cm_base_product_color.color_code;
  abs_color_code = cm_base_product_color.color_code === '#000000' ? '#212121' : abs_color_code;
  fillColorOnCanvas(mask_canvas, abs_color_code);
}

function textureLayer(mask_canvas, composite_texture_img) {
  const mask_canvas_ctx = mask_canvas.getContext('2d');
  mask_canvas_ctx.drawImage(
    composite_texture_img,
    0,
    0,
    composite_texture_img.width,
    composite_texture_img.height,
    0,
    0,
    mask_canvas.width,
    mask_canvas.height,
  );
}

function layerDesignReady(canvas, design_ready_canvas) {
  const mask_canvas_ctx = canvas.getContext('2d');
  mask_canvas_ctx.drawImage(
    design_ready_canvas,
    0,
    0,
    design_ready_canvas.width,
    design_ready_canvas.height,
    0,
    0,
    canvas.width,
    canvas.height,
  );
  return canvas;
}

function getCMBaseProductColor(cm, selected_base_product_color_id) {
  return go(
    cm._.base_product._.base_product_colors,
    find((bpc) => bpc.id === selected_base_product_color_id),
  );
}
async function renderColorLayer(canvas, cm, selected_base_product_color_id, has_colors) {
  if (has_colors) {
    const cm_base_product_color = getCMBaseProductColor(cm, selected_base_product_color_id);
    const composite_texture = go(
      cm._.composite_textures,
      find((ct) => ct.base_product_color_id === cm_base_product_color.id),
    );
    if (composite_texture && composite_texture.texture_url) {
      textureLayer(canvas, await makeCanvasByUrl(composite_texture.texture_url));
    } else if (composite_texture && composite_texture.image_url) {
      textureLayer(canvas, await makeCanvasByUrl(composite_texture.image_url));
    } else {
      pureColorLayer(canvas, cm_base_product_color);
    }
  }
  return canvas;
}

export function makeCanvasBrighter(c, num) {
  const img_data = c.getContext('2d').getImageData(0, 0, c.width, c.height);
  const { width, height } = c;
  const canvas = createCanvasElement(c);
  const ctx = canvas.getContext('2d');
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const idx = (x + width * y) * 4;
      img_data.data[idx] += num;
      img_data.data[idx + 1] += num;
      img_data.data[idx + 2] += num;
    }
  }
  ctx.putImageData(img_data, 0, 0);
  return canvas;
}

function makeCloneC(canvas) {
  const c = createCanvasElement({ width: canvas.width, height: canvas.height });
  const ctx = c.getContext('2d');
  ctx.drawImage(canvas, 0, 0);
  return c;
}

function to900(url) {
  return UtilImageS.getResizedUrl({ url, width: 900 });
}

/*
필수 인자
assoc_compsite_template,
canvas_el,
design_ready_url || _design_ready_canvas

 * composite_template {bg_url, top_layer_url}
 *  composite_masks {mask_transparent_url, map_mask_url, design_shade_url, product_url, shade_url}
 *    composite_textures {etc_meta}
 *    composite_faces { id, texture_url, image_url, base_product_color_id }
 *    base_products {id, can_full_cover, maker_type, print_type, cate_list_id}
 *      base_product_colors {id, base_product_id, abs_color_code, color_code, color_code2 }
 *
 * */
export async function render_canvas({
  assoc_composite_template,
  canvas_el,
  selected_base_product_colors,
  design_ready_canvas: _design_ready_canvas,
  design_ready_url,
}) {
  const canvas_width = 900;
  const canvas_height = 900;
  // await go(
  //   assoc_composite_template._.composite_masks,
  //   flatMap((cm) => {
  //     return [cm.mask_transparent_url, cm.map_mask_url, cm.design_shade_url, cm.product_url, cm.shade_url];
  //   }),
  //   (arr) =>
  //     arr.concat([assoc_composite_template.bg_url, assoc_composite_template.top_layer_url, design_ready_url]),
  //   compact,
  //   mapC(to900),
  //   mapC(loadImageFromUrl),
  // );
  if (selected_base_product_colors.length !== assoc_composite_template._.composite_masks.length) {
    selected_base_product_colors = go(
      assoc_composite_template._.composite_masks,
      map((cm) => find((bpc) => bpc.base_product_id === cm.base_product_id, selected_base_product_colors)),
    );
  }
  const bg_canvas = await go(undefined, async () => {
    try {
      const c = await makeCanvasByUrl(G.to_900(assoc_composite_template.bg_url));
      return c;
    } catch (e) {
      return makeCanvasByUrl(to900(assoc_composite_template.bg_url));
    }
  });
  const design_texture_c =
    assoc_composite_template.design_texture_url &&
    (await makeCanvasByUrl(to900(assoc_composite_template.design_texture_url)));
  let top_layer_img =
    assoc_composite_template.top_layer_url &&
    (await loadImageFromUrl(to900(assoc_composite_template.top_layer_url)));
  /* TODO 카테고리 하드코딩 */
  const is_digital_product =
    assoc_composite_template._.composite_masks[0]._.base_product?.cate_list_id ==
    CateListConstantS.DIGITAL_LIST_ID;
  canvas_el.width = canvas_width;
  canvas_el.height = canvas_height;

  const ctx = canvas_el.getContext('2d');
  ctx.drawImage(bg_canvas, 0, 0);
  const magic_color_code = '#00fffb';
  /*is_shade_composite_ignore 변경시*/
  const is_masking_tape =
    assoc_composite_template._.composite_masks[0]._?.base_product?.maker_type ===
    BpOptionConstantS.MASKING_TAPE_EDITOR;
  await go(
    // assoc_composite_template._.composite_masks,
    // is_masking_tape ? take(1) : reject((cm) => cm.is_shade_composite_ignore),
    is_masking_tape
      ? [last(assoc_composite_template._.composite_masks)]
      : assoc_composite_template._.composite_masks,
    ippL,
    mapC(async ([idx, cm]) => {
      const color_mask = cm.mask_transparent_url && (await loadImageFromUrl(to900(cm.mask_transparent_url)));
      const map_mask_img = cm.map_mask_url && (await loadImageFromUrl(to900(cm.map_mask_url)));
      const design_shade_img = cm.design_shade_url && (await loadImageFromUrl(to900(cm.design_shade_url)));
      const product_img = cm.product_url && (await makeCanvasByUrl(to900(cm.product_url)));
      const design_area_shade_canvas =
        cm._.base_product.print_type === 'none_fabric' &&
        cm.shade_url &&
        (await makeCanvasByUrl(to900(cm.shade_url)));
      /* TODO 카테고리 하드코딩 */
      const is_shirt =
        cm._.base_product.cate_list_id == CateListConstantS.CLOTH_LIST_ID ||
        cm._.base_product.cate_list_id == CateListConstantS.KIDS_LIST_ID ||
        cm._.base_product.print_type === 'with_white';
      const is_slipper = NewMakerProductStyleS.isSlipper(cm._.base_product);
      const color_mask_design_mask_is_different =
        NewMakerWeS.isWeBaseProduct(cm._.base_product.id) || is_slipper;
      const bright_strength = is_shirt ? -0.2 : 0.5;
      const dark_strength = is_shirt ? -0.2 : 0.5;
      const shade_status = is_shirt ? 'bright' : 'dark';
      const br_strength = is_shirt ? -10 : 0;
      const can_full_cover = cm._.base_product.can_full_cover;
      const selected_base_product_color_id = selected_base_product_colors[idx].id;
      const cm_base_product_color = getCMBaseProductColor(cm, selected_base_product_color_id);
      const is_none_white_with_color = cm._.base_product.print_type === 'none_white_with_color';
      const has_colors =
        is_slipper ||
        cm._.base_product._.base_product_colors.length > 1 ||
        cm_base_product_color.color_code === '#ffffff';
      let is_none_white_with_white = !is_shirt;
      if (is_none_white_with_white && !has_colors) {
        is_none_white_with_white = false;
      }
      const composite_texture_image_url = go(
        cm._.composite_textures,
        find((ct) => ct.base_product_color_id === cm_base_product_color.id),
        sel('image_url'),
      );
      const is_cylinder = cm._.composite_faces[0]?.etc_meta?.is_cylinder;
      const cf_opacity = cm._.composite_faces[0]?.etc_meta?.opacity;
      const reflection_meta = cm._.composite_faces[0]?.etc_meta?.reflection_meta;
      const is_composite_bpc_color_code2 =
        cm._.base_product.maker_features?.[NewMakerPropertyBaseProductConstantS.COMPOSITE_BPC_COLOR_CODE2];
      return go(
        Promise.all([
          renderColorLayer(
            createCanvasElement({ width: canvas_width, height: canvas_height }),
            cm,
            selected_base_product_color_id,
            has_colors,
          ),
          go(
            createCanvasElement({ width: canvas_width, height: canvas_height }),
            async (canvas) => {
              const design_ready_img = await go(_design_ready_canvas, async (c) => {
                if (design_ready_url)
                  return loadImageFromUrl(
                    is_composite_bpc_color_code2 ? design_ready_url : to900(design_ready_url),
                  );
                if (!c) return;
                if (c.width !== canvas_width) {
                  return resizeImage(c, 0, 0, c.width, c.height, 0, 0, canvas_width, canvas_height);
                }
                return c;
              });
              if (design_ready_img)
                return go(
                  layerDesignReady(
                    canvas,
                    is_composite_bpc_color_code2
                      ? await NewMakerPropertyBpfS.bpcColorCode2Render.makeRenderedCanvas(design_ready_img, {
                          color_code: cm_base_product_color.color_code2,
                        })
                      : design_ready_img,
                  ),
                  (c) => (map_mask_img ? setMaskedCanvas(c, map_mask_img) : c),
                );
              return canvas;
            },
            (canvas) => {
              if (!design_texture_c) return canvas;
              const design_ready_texture_c = go(undefined, () => {
                const new_canvas = createCanvasElement(canvas);
                const new_ctx = new_canvas.getContext('2d');
                new_ctx.fillStyle = '#000';
                new_ctx.fillRect(0, 0, new_canvas.width, new_canvas.height);
                new_ctx.drawImage(canvas, 0, 0);
                return new_canvas;
              });
              const ctx = design_ready_texture_c.getContext('2d');
              ctx.globalCompositeOperation = 'multiply';
              ctx.drawImage(design_texture_c, 0, 0);
              ctx.globalCompositeOperation = 'destination-in';
              ctx.drawImage(canvas, 0, 0);
              ctx.globalCompositeOperation = 'source-over';
              return design_ready_texture_c;
            },
          ),
        ]),
        async ([color_layer_canvas, design_layer_canvas]) => {
          const ccc = createCanvasElement({ width: canvas_width, height: canvas_height });
          const ccc_ctx = ccc.getContext('2d');
          const default_color_code = '#ffffff';
          const cm_base_product_color = getCMBaseProductColor(cm, selected_base_product_color_id);
          const color_code = cm_base_product_color.abs_color_code || cm_base_product_color.color_code;
          const is_no_change_color = is_digital_product || color_code === magic_color_code;
          const default_br_strength = 25;
          cm._reflection_meta = cm._reflection_meta || {};
          if (is_cylinder) {
            if (has_colors) {
              if (composite_texture_image_url) {
                ccc_ctx.drawImage(color_layer_canvas, 0, 0);
              } else if (color_mask) {
                ccc_ctx.drawImage(
                  giveCanvasShadeAndLightByTarget(
                    makeCloneC(color_layer_canvas),
                    go(cm._reflection_meta, (rm) => {
                      if (rm.bb) {
                        return { ...rm.bb, target_canvas: makeCloneC(product_img || bg_canvas) };
                      }
                      const obj = makeOptionShadeAndLight(
                        product_img || bg_canvas,
                        color_mask,
                        has_colors,
                        default_color_code,
                        0,
                      );
                      rm.bb = pick(['target_brightness', 'minus'])(obj);
                      return obj;
                    }),
                    {
                      br_strength: default_br_strength + br_strength,
                      bright_strength,
                      dark_strength,
                      shade_status: color_code === default_color_code ? 'neutral' : shade_status,
                    },
                  ),
                  0,
                  0,
                );
              }
            }

            ccc_ctx.drawImage(
              giveCanvasShadeAndLightByTarget(
                design_layer_canvas,
                reflection_meta?.target_brightness
                  ? { ...reflection_meta, target_canvas: makeCloneC(design_shade_img || bg_canvas) }
                  : go(cm._reflection_meta, (rm) => {
                      const obj = makeOptionShadeAndLight(
                        design_shade_img || bg_canvas,
                        design_layer_canvas && makeCloneC(design_layer_canvas),
                        true,
                        default_color_code,
                        is_none_white_with_white ? 0 : -10,
                      );
                      rm.a = pick(['target_brightness', 'minus'])(obj);
                      return obj;
                    }),
                {
                  br_strength: default_br_strength,
                  bright_strength: 0.5,
                  dark_strength: 1,
                  shade_status: 'neutral',
                },
              ),
              0,
              0,
            );
            return ccc;
          }
          if (!color_mask_design_mask_is_different && can_full_cover && color_mask) {
            if (!is_no_change_color)
              ccc_ctx.drawImage(
                composite_texture_image_url
                  ? color_layer_canvas
                  : giveCanvasShadeAndLightByTarget(
                      makeCloneC(color_layer_canvas),
                      go(cm._reflection_meta, (rm) => {
                        if (rm.b) {
                          return { ...rm.b, target_canvas: makeCloneC(product_img || bg_canvas) };
                        }
                        const obj = makeOptionShadeAndLight(
                          product_img || bg_canvas,
                          color_mask,
                          has_colors,

                          default_color_code,
                          0,
                        );
                        rm.b = pick(['target_brightness', 'minus'])(obj);
                        return obj;
                      }),
                      {
                        br_strength: default_br_strength + br_strength,
                        bright_strength,
                        dark_strength,
                        shade_status: color_code === default_color_code ? 'neutral' : shade_status,
                      },
                    ),
                0,
                0,
              );
            if (is_none_white_with_color) {
              if (!has_colors) {
                ccc_ctx.drawImage(bg_canvas, 0, 0);
              }
              ccc_ctx.globalCompositeOperation = 'multiply';
              ccc_ctx.drawImage(design_layer_canvas, 0, 0);
            } else {
              if (is_digital_product) {
                ccc_ctx.drawImage(design_layer_canvas, 0, 0);
                if (top_layer_img) {
                  const n_c = createCanvasElement(ccc);
                  fillColorOnCanvas(n_c, '#000000');
                  const n_ctx = n_c.getContext('2d');
                  n_ctx.drawImage(ccc, 0, 0);
                  n_ctx.globalCompositeOperation = 'destination-in';
                  n_ctx.drawImage(top_layer_img, 0, 0);
                  const color_avg = evaluateImageBrightness(n_c);
                  if (color_avg > 0.9) {
                    top_layer_img = go(
                      createCanvasElement(design_layer_canvas),
                      tap((c) => fillColorOnCanvas(c, '#111111')),
                      tap((c) => {
                        const ctx = c.getContext('2d');
                        ctx.globalCompositeOperation = 'destination-in';
                        ctx.drawImage(top_layer_img, 0, 0);
                      }),
                    );
                  }
                }
              } else {
                ccc_ctx.drawImage(
                  giveCanvasShadeAndLightByTarget(
                    makeCloneC(design_layer_canvas),
                    go(cm._reflection_meta, (rm) => {
                      if (rm.c) {
                        return {
                          ...rm.c,
                          target_canvas: makeCloneC(
                            design_shade_img || design_area_shade_canvas || product_img || bg_canvas,
                          ),
                        };
                      }
                      const obj = makeOptionShadeAndLight(
                        design_shade_img || design_area_shade_canvas || product_img || bg_canvas,
                        design_shade_img || color_mask,
                        design_shade_img || is_none_white_with_white || has_colors,
                        default_color_code,
                        is_none_white_with_white ? 0 : -10,
                      );
                      rm.c = pick(['target_brightness', 'minus'])(obj);
                      return obj;
                    }),
                    {
                      br_strength: default_br_strength,
                      bright_strength: design_area_shade_canvas ? 0 : 0.5,
                      dark_strength: design_area_shade_canvas ? 0 : 1,
                      shade_status: design_area_shade_canvas ? 'neutral' : 'dark',
                    },
                  ),
                  0,
                  0,
                );
              }
            }
            if (color_mask) setMaskedCanvas(ccc, color_mask);
            return ccc;
          } else {
            if (!is_no_change_color) {
              if (composite_texture_image_url) {
                ccc_ctx.drawImage(color_layer_canvas, 0, 0);
              } else if (color_mask) {
                ccc_ctx.drawImage(
                  go(
                    makeCloneC(color_layer_canvas),
                    (c) =>
                      giveCanvasShadeAndLightByTarget(
                        c,
                        go(cm._reflection_meta, (rm) => {
                          if (rm.d) {
                            return { ...rm.d, target_canvas: makeCloneC(product_img || bg_canvas) };
                          }
                          const obj = makeOptionShadeAndLight(
                            product_img || bg_canvas,
                            color_mask,
                            has_colors,
                            default_color_code,
                            0,
                          );
                          rm.d = pick(['target_brightness', 'minus'])(obj);
                          return obj;
                        }),
                        {
                          br_strength: default_br_strength + br_strength,
                          bright_strength,
                          dark_strength,
                          shade_status: color_code === default_color_code ? 'neutral' : shade_status,
                        },
                      ),
                    (c) => setMaskedCanvas(c, color_mask),
                  ),
                  0,
                  0,
                );
              }
            }

            if (is_none_white_with_color) {
              if (!has_colors) {
                ccc_ctx.drawImage(bg_canvas, 0, 0);
              }
              ccc_ctx.globalCompositeOperation = 'multiply';
              ccc_ctx.drawImage(design_layer_canvas, 0, 0);
              color_mask && setMaskedCanvas(ccc, color_mask);
              return ccc;
            } else {
              ccc_ctx.drawImage(
                giveCanvasShadeAndLightByTarget(
                  design_layer_canvas,
                  go(cm._reflection_meta, (rm) => {
                    if (rm.e) {
                      return {
                        ...rm.e,
                        target_canvas: makeCloneC(
                          design_shade_img || design_area_shade_canvas || product_img || bg_canvas,
                        ),
                      };
                    }
                    const obj = makeOptionShadeAndLight(
                      design_shade_img || design_area_shade_canvas || product_img || bg_canvas,
                      map_mask_img ? makeCanvasByImg(map_mask_img) : makeCloneC(design_layer_canvas),
                      design_shade_img || is_none_white_with_white || has_colors,
                      default_color_code,
                      is_none_white_with_white ? 0 : -10,
                    );
                    rm.e = pick(['target_brightness', 'minus'])(obj);
                    return obj;
                  }),
                  {
                    br_strength: is_slipper ? 50 : default_br_strength,
                    bright_strength: design_area_shade_canvas ? 0 : 0.5,
                    dark_strength: design_area_shade_canvas ? 0 : 1,
                    shade_status: design_area_shade_canvas ? 'neutral' : 'dark',
                  },
                ),
                0,
                0,
              );
            }
            return ccc;
          }
        },
        (ccc) => {
          if (!cf_opacity) return ccc;
          const canvas = createCanvasElement(ccc);
          const ctx = canvas.getContext('2d');
          ctx.globalAlpha = cf_opacity;
          ctx.drawImage(ccc, 0, 0);
          return canvas;
        },
      );
    }),
    each((canvas) => {
      ctx.globalCompositeOperation = 'source-over';
      ctx.drawImage(canvas, 0, 0);
    }),
  );
  if (top_layer_img) {
    ctx.drawImage(top_layer_img, 0, 0);
  }
  return canvas_el;
}

export function makeOptionShadeAndLight(
  bg_canvas,
  target_mask,
  has_colors,
  default_color_code = '#ffffff',
  minus = 0,
) {
  const target_canvas = makeCloneC(bg_canvas);
  const [target_brightness, lightest_brightness] = go(
    bg_canvas,
    makeCloneC,
    (c) => (target_mask ? setMaskedCanvas(c, target_mask) : c),
    getTargetBrightness,
  );

  return {
    target_brightness,
    target_canvas,
    minus: has_colors
      ? target_brightness -
        lightest_brightness -
        (evaluateColorBrightness(default_color_code) * 255 - lightest_brightness) -
        minus
      : target_brightness - lightest_brightness,
  };
}

function countBrightness(canvas, from_value) {
  const ctx = canvas.getContext('2d');
  const image_data = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const { width, height } = canvas;
  const count = [];
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const idx = (x + width * y) * 4;
      if (image_data.data[idx + 3] < 200) continue;
      const v = go(
        (image_data.data[idx] + image_data.data[idx + 1] + image_data.data[idx + 2]) / 3,
        Math.round,
      );
      if (v >= from_value) {
        count.push(v);
      }
    }
  }
  return _p.countBy(count);
}

function fa(x, w, f, h, u, v) {
  return (Math.pow(x * u - f, 1 / w) + h) / v;
}
// function fb(x, a, b, c, s, t) {
//   return (-Math.pow(b - x * s, a) + c) / t;
// }

// function fLinear(x, target_point) {
//   if (x < target_point) return 0;
//   const xx = 1 - target_point;
//   const ratio = 1 / xx;
//   return ratio * x - ratio * target_point;
// }
//
// function curveF(x, avg_brightness, aStrength, bStrength) {
//   if (x < avg_brightness) {
//     return fa(x, aStrength, 0, 0, 1 / avg_brightness, 1 / avg_brightness);
//   } else {
//     const a = 1 - avg_brightness;
//     return fb(x, bStrength, 1 / a, 1 / a, 1 / a, 1 / a);
//   }
// }
export function giveCanvasShadeAndLightByTarget(
  canvas,
  { minus, target_canvas, target_brightness },
  { bright_strength = 0, dark_strength = 0, shade_status = 'neutral', br_strength = 25 },
) {
  const target_ctx = target_canvas.getContext('2d');
  const target_image_data = target_ctx.getImageData(0, 0, target_canvas.width, target_canvas.height);
  const ctx = canvas.getContext('2d');
  const { width, height } = canvas;
  const image_data = ctx.getImageData(0, 0, width, height);
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const idx = pixelToIdx(x, y, width);
      if (target_image_data.data[idx + 3] < 255 / 2) continue;

      let r = image_data.data[idx] + minus;
      let g = image_data.data[idx + 1] + minus;
      let b = image_data.data[idx + 2] + minus;

      const brighter_strength = Math.floor(
        br_strength -
          ((image_data.data[idx] + image_data.data[idx + 1] + image_data.data[idx + 2]) / 3 / 255) *
            br_strength,
      );

      r += brighter_strength;
      g += brighter_strength;
      b += brighter_strength;
      const target_image_data1 = target_image_data.data[idx];
      const target_image_data2 = target_image_data.data[idx + 1];
      const target_image_data3 = target_image_data.data[idx + 2];
      const target_image_data_avg = (target_image_data1 + target_image_data2 + target_image_data3) / 3;

      const color_brightness = (r + g + b) / 3 / 255;
      const brightness = fa(color_brightness, 2, 0, 0, 1, 1);
      const bri_strength =
        shade_status === 'neutral' ? 0 : shade_status === 'bright' ? brightness : 1 - brightness;
      const strength =
        (target_brightness < target_image_data_avg
          ? 1 + bright_strength
          : 1 + dark_strength * (1 - brightness)) *
        (bri_strength + 1);
      // const strength = 1;
      const diff1 =
        target_brightness === target_image_data_avg
          ? 0
          : (target_image_data_avg - target_brightness) * strength;
      image_data.data[idx] = r + diff1;
      image_data.data[idx + 1] = g + diff1;
      image_data.data[idx + 2] = b + diff1;
    }
  }

  ctx.putImageData(image_data, 0, 0);
  return canvas;
}
function getTargetBrightness(target_canvas) {
  return go(
    target_canvas,
    (c) => {
      return countBrightness(c, 0);
    },
    entries,
    sortByDesc(([_, d]) => d),
    map(([c, d]) => [parseInt(c), d]),
    (arr) => [arr, sortByDesc(([c, _]) => c, arr)],
    map(head),
    map(head),
  );
}
