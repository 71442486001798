import {
  $addClass,
  $attr,
  $closest,
  $delegate,
  $find,
  $height,
  $hide,
  $on,
  $parent,
  $qs,
  $qsa,
  $removeClass,
  $setAttr,
  $setCss,
  $setHTML,
} from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { OMPAuthF } from '../../../../Auth/F/Function/module/OMPAuthF.js';
import { OMPSearchF } from '../../../../Search/F/Function/module/OMPSearchF.js';
import { OMPCoreChannelTalkF } from '../../../ChannelTalk/F/Function/module/OMPCoreChannelTalkF.js';
import { OMPCoreUtilF } from '../../../Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreLayoutNavigationF } from '../../Navigation/F/Function/module/OMPCoreLayoutNavigationF.js';
import { OMPCoreLayoutNavigationTmplS } from '../../Navigation/S/Tmpl/module/OMPCoreLayoutNavigationTmplS.js';
import { OMPCoreLayoutConstantS } from '../../S/Constant/module/OMPCoreLayoutConstantS.js';

export const ready = () => {
  /*------- channel talk ------*/
  OMPCoreChannelTalkF.loadChannelIO();

  // 채널톡 오픈하기 전에 이메일로 대체할 코드
  // const twcChat = html`
  //   <div id="twc-chat-icon">
  //     <button type="button" id="twc-chat-icon-btn">
  //       <img
  //         src="${UtilImageS.getResizedUrl({
  //           url: '//s3.marpple.co/files/u_218933/2023/12/original/e8b36741af39dc092c3ef19fc1a419d9108760131.jpg',
  //           width: 200,
  //           height: 200,
  //           quality: 80,
  //           format: 'jpeg',
  //         })}"
  //         alt="굿즈 단체 상품 제작, 마플 상담 버튼"
  //       />
  //     </button>
  //   </div>
  // `;
  // const body_el = $qs('body');
  // body_el.append($el(twcChat));
  //
  // // const mail_link = TT.lang == 'kr' ? 'mailto:help@marpple.com' : 'mailto:cs@marpple.com';
  // go(
  //   body_el,
  //   $delegate('click', '.live_chat, #fake_channel_io', function () {
  //     return OMPChannelTalkModalF.open();
  //     // window.location.href = mail_link;
  //   }),
  //   $delegate('click', '#twc-chat-icon-btn', function () {
  //     return OMPChannelTalkModalF.open();
  //     // window.location.href = mail_link;
  //   }),
  // );
  // end 채널톡 오픈하기 전에 이메일로 대체할 코드

  /*-------------*/

  // TWC webchat Scripts
  // (function () {
  //   if (typeof window.Twc != 'function') {
  //     const body_el = $qs('body');
  //
  //     const mail_link = TT.lang == 'kr' ? 'mailto:help@marpple.com' : 'mailto:cs@marpple.com';
  //
  //     go(
  //       body_el,
  //       $delegate('click', '.live_chat, #fake_channel_io', function () {
  //         window.location.href = mail_link;
  //       }),
  //       $delegate('click', '#twc-chat-icon-btn', function () {
  //         window.location.href = mail_link;
  //       }),
  //     );
  //
  //     return;
  //   }
  //
  //   const scenario_id = go(TT.lang, (lang) => {
  //     if (lang == 'kr') return 'MjgxNg==';
  //     else if (lang == 'jp') return 'MzA3NQ==';
  //     else return 'MzA3Ng==';
  //   });
  //
  //   window.Twc(
  //     'init',
  //     {
  //       brandKey: '20230917034527UkKvfxwYUC2baZUyF1Djjg',
  //       channelType: 'scenario',
  //       scenarioId: scenario_id,
  //       buttonOption: {
  //         showLauncher: false,
  //       },
  //     },
  //     () => {
  //       window.Twc.Chat.on('EndCounsel', function () {
  //         if (OMPCoreUtilF.isMpApp()) {
  //           const parameters = {
  //             func: 'onCloseWebChat',
  //           };
  //           if (OMPCoreUtilF.isAndroid()) {
  //             window.AppJSInterface.onCloseWebChat();
  //           }
  //           if (OMPCoreUtilF.isIOS()) {
  //             window.webkit.messageHandlers.AppJSInterface.postMessage(parameters);
  //           }
  //         }
  //       });
  //     },
  //   );
  //
  //   const twcChat = html`
  //     <div id="twc-chat-icon">
  //       <button type="button" id="twc-chat-icon-btn">
  //         <img
  //           src="${UtilImageS.getResizedUrl({
  //             url: '//s3.marpple.co/files/u_218933/2023/12/original/e8b36741af39dc092c3ef19fc1a419d9108760131.jpg',
  //             width: 200,
  //             height: 200,
  //             quality: 80,
  //             format: 'jpeg',
  //           })}"
  //           alt="굿즈 단체 상품 제작, 마플 상담 버튼"
  //         />
  //       </button>
  //     </div>
  //   `;
  //   const body_el = $qs('body');
  //   body_el.append($el(twcChat));
  //
  //   go(
  //     body_el,
  //     $delegate('click', '.live_chat, #fake_channel_io', function () {
  //       window.Twc.Chat.open();
  //     }),
  //     $delegate('click', '#twc-chat-icon-btn', function () {
  //       window.Twc.Chat.open();
  //     }),
  //   );
  // })();
  // End TWC webchat Scripts

  /*
   * query.verified 존재 시 안내 팝업
   * */

  const params = new URLSearchParams(location.search);
  const verified = params.get('verified');
  const url = params.get('url');

  if (verified) {
    if (verified === 'true') {
      OMPCoreUtilF.alert({ content: TT('auth::email_verify::email_verify_07') });
      window.history.replaceState(
        window.history.state,
        '',
        url ? location.pathname + '?url=' + url : location.pathname,
      );
    }

    if (verified === 'false') {
      OMPCoreUtilF.alert({ content: TT('auth::email_verify::email_verify_08') });
      window.history.replaceState(
        window.history.state,
        '',
        url ? location.pathname + '?url=' + url : location.pathname,
      );
    }
  }

  if (!OMPCoreUtilF.isMobile()) {
    const omp_frame_el = $qs('.omp-header__body-scroll');
    if (!omp_frame_el) return;
    OMPSearchF.delegateSearchFormEvent(omp_frame_el);
  }

  const omp_body_el = $qs('.omp-layout-body');
  const left_mode = omp_body_el?.dataset?.left_mode;
  if (!OMPCoreUtilF.isMobile() && omp_body_el) {
    const left_sub_menu = OMPCoreLayoutConstantS.LEFT_SUB_MENU();
    const header$ = $qs('.don_frame[frame_index="0"] > .don_wrapper > .header');

    let is_open_head_lang = false;

    $on('click', (e) => {
      const lang_wrap_el = $qs('.omp-cell__side-header-lang-wrapper');
      if (!lang_wrap_el) return;

      if (!is_open_head_lang && $closest('.omp-cell__side-header-lang-head', e.target)) {
        is_open_head_lang = true;
        lang_wrap_el.dataset.list_show = 'true';
        each((el) => {
          $setCss(
            {
              'z-index': 2,
            },
            el,
          );
        }, $qsa('html.marpple.omp.product.detail .omp-header'));
        return;
      }
      each((el) => {
        $setCss(
          {
            'z-index': 'auto',
          },
          el,
        );
      }, $qsa('html.marpple.omp.product.detail .omp-header'));
      is_open_head_lang = false;
      lang_wrap_el.dataset.list_show = 'false';
    })(document);

    const is_login_page = $qs('html.omp.login');

    if (!is_login_page) {
      go(
        header$,
        $delegate('click', `.omp-cell__side-header-my-page`, async (e) => {
          const is_session = $attr('is_session', $qs('html')) === 'true';

          if (!is_session && !$qs('body.omp.product-list')) {
            e.originalEvent.preventDefault();
            await OMPAuthF.openLogin();
          }
        }),
      );

      go(
        omp_body_el,
        $delegate('click', `.omp-left__menu2-li[data-action="${left_sub_menu.sign_up.key}"]`, async (e) => {
          e.originalEvent.preventDefault();
          await OMPAuthF.openSignUp();
        }),
        $delegate('click', `.omp-left__menu2-li[data-action="${left_sub_menu.login.key}"]`, async (e) => {
          e.originalEvent.preventDefault();
          await OMPAuthF.openLogin();
        }),
      );
    }
  }
  if (!OMPCoreUtilF.isMobile() && left_mode === OMPCoreLayoutConstantS.LEFT_MODE.left_fixed) {
    if (omp_body_el) {
      const layout_left_el = $find('.omp-layout-sidebar', omp_body_el);
      go(
        omp_body_el,
        $delegate('click', '.omp-layout-sidebar__btn-open', (e) => {
          $setAttr(
            {
              'data-left_is_show': 'true',
            },
            layout_left_el,
          );
        }),
        $delegate('click', '.omp-layout-sidebar__btn-close', (e) => {
          $setAttr(
            {
              'data-left_is_show': 'false',
            },
            layout_left_el,
          );
        }),
      );
    }
  }
  if (!OMPCoreUtilF.isMobile()) {
    const header$ = $qs('.don_frame[frame_index="0"] > .don_wrapper > .header');
    go(
      header$,
      $delegate('click', '.header-banner__btn-close', () => {
        $.post('/@api/header_banner_cookie', {});
        $hide($qs('#header-banner'));
        $qs('.omp-header').dataset.header_banner = '';
        $qs('.omp-layout-body').dataset.header_banner = '';
      }),
    );

    const body$ = $qs('#body');
    const is_maker_page = location.href.includes('/product/detail');
    const forceAdjustArrow = (is_on) => {
      go(
        $qs('.omp-header'),
        $find('.omp-atom__icon-arrow'),
        $setCss({ transform: `rotate(${is_on ? '+' : '-'}90deg)` }),
      );
    };
    if (is_maker_page) {
      go(
        body$,
        $on('mouseenter', '.omp-header__menus .omp-atom__header-menu-arrow', () => {
          if (!$qs('html.omp-gnb-hover')) {
            forceAdjustArrow(false);
          }
        }),
        $on('mouseleave', '.omp-header', () => {
          forceAdjustArrow(false);
        }),
        $on('mouseenter', '.omp-atom__header-menu-button', () => {
          forceAdjustArrow(false);
          OMPCoreLayoutNavigationF.hide();
        }),
        $on('click', '.omp-header__menus .omp-atom__header-menu-arrow', () => {
          if ($qs('html.omp-gnb-hover')) {
            forceAdjustArrow(false);
            OMPCoreLayoutNavigationF.hide();
          } else {
            forceAdjustArrow(true);
            OMPCoreLayoutNavigationF.show();
          }
        }),
        $on('mouseenter', '.omp-header__navi-bg', () => {
          OMPCoreLayoutNavigationF.hide();
          forceAdjustArrow(false);
        }),
        $on('click', '.omp-header__navi-bg', () => {
          OMPCoreLayoutNavigationF.hide();
          forceAdjustArrow(false);
        }),
      );
    } else {
      go(
        body$,
        $on('mouseenter', '.omp-header__menus .omp-atom__header-menu-arrow', (e) => {
          OMPCoreLayoutNavigationF.show();
        }),
        $on('mouseenter', '.omp-header__menus .omp-atom__header-menu-button', (e) => {
          OMPCoreLayoutNavigationF.show(e.currentTarget.dataset.id);
        }),
        $on('mouseenter', '.omp-header__navi-bg', (e) => {
          OMPCoreLayoutNavigationF.hide();
        }),
        $on('click', '.omp-header__navi-bg', (e) => {
          OMPCoreLayoutNavigationF.hide();
        }),
      );
    }
  }
  if (OMPCoreUtilF.isMobile() && omp_body_el) {
    const header$ = $qs('.don_frame[frame_index="0"] > .don_wrapper > .header');
    if (header$) {
      const h_height = $height(header$);
      new Headroom(header$, {
        scroller: window,
        offset: h_height,
      }).init();
    }

    go(
      header$,
      $delegate('click', '.mp-header-mo__btn-prev', () => {
        window.history.back();
      }),
      $delegate('click', '.mp-header-mo__h1-menu-title', (e) => {
        const is_show = $find('.omp-header__product-list-menus', e.delegateTarget).dataset.show;
        if (is_show == 'true') {
          e.currentTarget.dataset.show = 'false';
          OMPCoreLayoutNavigationF.moPdListCateHide();
        } else {
          e.currentTarget.dataset.show = 'true';
          OMPCoreLayoutNavigationF.moPdListCateShow();
        }
      }),
      $delegate('click', '.mp-header-ham__section--cate-list-area .mp-header-ham__item', (e) => {
        const mp_navigations = box.sel('mp_navigations');
        const cate_lists = box.sel('cate_lists');
        const is_mobile = OMPCoreUtilF.isMobile();
        const login_user = box.sel('is_user');
        const prev_el = $find('.mp-header-ham__item[data-active="true"]', e.delegateTarget);
        if (prev_el) {
          $setAttr(
            {
              'data-active': false,
            },
            prev_el,
          );
        }

        const cate_list_id = e.currentTarget.dataset.id;
        go(
          e.currentTarget,
          $setAttr({
            'data-active': true,
          }),
        );
        const navi_el = OMPCoreLayoutNavigationTmplS.makeNavi({
          cate_list_id,
          is_mobile,
          cate_lists,
          mp_navigations,
          login_user,
        });
        const right_el = $find('.mp-header-ham__bottom-right', e.delegateTarget);
        right_el.scrollTo({ top: 0 });
        $setHTML(navi_el, right_el);
      }),
      $delegate('click', '.mp-header-ham__lang-selected', (e) => {
        const lang_el = $parent('.mp-header-ham__lang', e.currentTarget);
        lang_el.dataset.is_show = lang_el.dataset.is_show == 'true' ? 'false' : 'true';
      }),
    );

    go(
      header$,
      $on('click', '.mp-sub-menus .omp-atom__header-menu-arrow', (e) => {
        const current_el = e.currentTarget;
        const is_active = current_el.dataset.active;
        if (is_active == 'true') {
          OMPCoreLayoutNavigationF.moAllHide();
          current_el.dataset.active = 'false';
        } else {
          OMPCoreLayoutNavigationF.moAllShow();
          current_el.dataset.active = 'true';
        }
      }),
    );

    go(
      omp_body_el,
      $delegate('click', '.omp-footer__footer-menu-h4', (e) => {
        const footer_menu_el = $parent('.omp-footer__footer-menu', e.currentTarget);
        const is_open = footer_menu_el.dataset.open == 'true';

        $setAttr(
          {
            'data-open': !is_open,
          },
          footer_menu_el,
        );

        const arrow_el = $find('.omp-atom__icon-arrow', e.currentTarget);
        if (!is_open) {
          $removeClass('omp-atom__icon-arrow--down', arrow_el);
          $addClass('omp-atom__icon-arrow--up', arrow_el);
        } else {
          $removeClass('omp-atom__icon-arrow--up', arrow_el);
          $addClass('omp-atom__icon-arrow--down', arrow_el);
        }
      }),
      $delegate('click', '.omp-footer__company-info-title', (e) => {
        const company_info_el = $parent('.omp-footer__company-info', e.currentTarget);
        const is_open = company_info_el.dataset.open == 'true';

        $setAttr(
          {
            'data-open': !is_open,
          },
          company_info_el,
        );

        const arrow_el = $find('.omp-atom__icon-arrow', e.currentTarget);
        if (!is_open) {
          $removeClass('omp-atom__icon-arrow--down', arrow_el);
          $addClass('omp-atom__icon-arrow--up', arrow_el);
        } else {
          $removeClass('omp-atom__icon-arrow--up', arrow_el);
          $addClass('omp-atom__icon-arrow--down', arrow_el);
        }
      }),
      $delegate('click', '.omp-my-page-orders__status-wrapper', (e) => {
        const href = $attr('href', e.currentTarget);
        if (href) {
          const [, qs] = href.split('?');
          window.history.replaceState(null, '', '?' + qs);
        }
      }),
    );
  }

  $delegate('click', 'a.omp-change-lang', (e) => {
    e.originalEvent.preventDefault();
    location.href = location.pathname.replace(TT.lang, e.currentTarget.dataset.lang) + location.search;
  })($qs('body'));
};
