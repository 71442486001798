import { html, strMap, values } from 'fxjs/es';
import { OMPMyPageConstantS } from '../Constant/module/OMPMyPageConstantS.js';

/**
 * @param {{ user, order_count, review_count, wish_count }} data
 */
export const myPageLandingTmplMo = (data) => {
  const { user } = data;
  const hello = TT('my_page::landing::landing_01', {
    name: user.email?.split('@')?.[0] || user.nick_name || user.name || '',
  });

  const data_pair = OMPMyPageConstantS.getMyPageMenuDataPair();
  const my_page_shopping_menu = OMPMyPageConstantS.MyPageMenuMo();
  const my_page_mobile_customer_menu = OMPMyPageConstantS.MyPageMobileCustomerMenu();
  const my_page_shopping_menu_title = TT('my_page::landing::landing_16');
  const my_page_mobile_customer_menu_title = TT('my_page::landing::landing_17');
  return html`
    <div class="omp-my-page-landing">
      <div class="omp-my-page-landing__dashboard">
        <div class="omp-my-page-landing__dashboard-owner">
          <span class="omp-my-page-landing__dashboard-owner__title">${hello}</span>
        </div>
        <div class="omp-my-page-landing__dashboard-data">
          ${strMap((value) => {
            return html`<a href="/${TT.lang}/${value.url}" class="omp-my-page-landing__dashboard-data-pair">
              <span class="omp-my-page-landing__grey">${value.title}</span>
              <span class="omp-my-page-landing__title">${value.data(data)}</span>
            </a>`;
          }, values(data_pair))}
        </div>
        <a href="" class="omp-my-page-landing__dashboard-content" style="display: none;">
          <img
            src="//s3.marpple.co/files/u_2283830/2023/7/original/3f36fbbe5a1c5c5c17ff06537b498100b38e009a1.png"
            alt=""
          />
        </a>
      </div>
      <div class="omp-my-page-landing__menus">
        <div class="omp-my-page-landing__shopping-menus">
          <span class="omp-my-page-landing__menu-title"> ${my_page_shopping_menu_title} </span>
          ${strMap((menu) => {
            return html`
              <a href="/${TT.lang}/${menu.url}" class="omp-my-page-landing__menu">
                <span class="omp-my-page-landing__menu-icon">${menu.icon}</span>
                <span>${menu.title}</span>
              </a>
            `;
          }, values(my_page_shopping_menu))}
        </div>
        <div class="omp-my-page-landing__customer-menus">
          <span class="omp-my-page-landing__menu-title"> ${my_page_mobile_customer_menu_title} </span>
          ${strMap((menu) => {
            return html`
              <a
                ${menu.url ? html`href="/${TT.lang}/${menu.url}"` : ''}
                class="omp-my-page-landing__menu ${menu.klass ? html`${menu.klass}` : ''}"
              >
                <span class="omp-my-page-landing__menu-icon">${menu.icon}</span>
                <span>${menu.title}</span>
              </a>
            `;
          }, values(my_page_mobile_customer_menu))}
        </div>
      </div>
    </div>
  `;
};
