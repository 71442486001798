import typo_classes from '../style/typography.scss';

export const typography_map = {
  // kr
  '48_bold_kr': typo_classes.font_48_bold_kr,
  '40_bold_kr': typo_classes.font_40_bold_kr,
  '40_medium_kr': typo_classes.font_40_medium_kr,
  '36_bold_kr': typo_classes.font_36_bold_kr,
  '36_medium_kr': typo_classes.font_36_medium_kr,
  '32_bold_kr': typo_classes.font_32_bold_kr,
  '32_medium_kr': typo_classes.font_32_medium_kr,
  '28_bold_kr': typo_classes.font_28_bold_kr,
  '28_medium_kr': typo_classes.font_28_medium_kr,
  '28_regular_kr': typo_classes.font_28_regular_kr,
  '24_bold_kr': typo_classes.font_24_bold_kr,
  '24_medium_kr': typo_classes.font_24_medium_kr,
  '24_regular_kr': typo_classes.font_24_regular_kr,
  '22_bold_kr': typo_classes.font_22_bold_kr,
  '22_medium_kr': typo_classes.font_22_medium_kr,
  '22_regular_kr': typo_classes.font_22_regular_kr,
  '20_bold_kr': typo_classes.font_20_bold_kr,
  '20_medium_kr': typo_classes.font_20_medium_kr,
  '20_regular_kr': typo_classes.font_20_regular_kr,
  '16_bold_kr': typo_classes.font_16_bold_kr,
  '16_medium_kr': typo_classes.font_16_medium_kr,
  '16_regular_kr': typo_classes.font_16_regular_kr,
  '14_bold_kr': typo_classes.font_14_bold_kr,
  '14_medium_kr': typo_classes.font_14_medium_kr,
  '14_regular_kr': typo_classes.font_14_regular_kr,
  '12_bold_kr': typo_classes.font_12_bold_kr,
  '12_medium_kr': typo_classes.font_12_medium_kr,
  '12_regular_kr': typo_classes.font_12_regular_kr,
  '10_medium_kr': typo_classes.font_10_medium_kr,
  '10_regular_kr': typo_classes.font_10_regular_kr,
  // bebas
  bebas_96_bold: typo_classes.font_bebas_96_bold,
  bebas_90_bold: typo_classes.font_bebas_90_bold,
  bebas_60_bold: typo_classes.font_bebas_60_bold,
  bebas_48_bold: typo_classes.font_bebas_48_bold,
  bebas_40_bold: typo_classes.font_bebas_40_bold,
  bebas_32_bold: typo_classes.font_bebas_32_bold,
  bebas_24_bold: typo_classes.font_bebas_24_bold,
  bebas_20_bold: typo_classes.font_bebas_20_bold,
  bebas_16_bold: typo_classes.font_bebas_16_bold,
  bebas_14_bold: typo_classes.font_bebas_14_bold,
  // unica
  unica_32_bold: typo_classes.font_32_unica_bold,
  unica_24_bold: typo_classes.font_24_unica_bold,
  unica_24_medium: typo_classes.font_24_unica_medium,
  unica_24_regular: typo_classes.font_24_unica_regular,
  unica_20_bold: typo_classes.font_20_unica_bold,
  unica_20_medium: typo_classes.font_20_unica_medium,
  unica_20_regular: typo_classes.font_20_unica_regular,
  unica_16_bold: typo_classes.font_16_unica_bold,
  unica_16_medium: typo_classes.font_16_unica_medium,
  unica_16_regular: typo_classes.font_16_unica_regular,
  unica_14_bold: typo_classes.font_14_unica_bold,
  unica_14_medium: typo_classes.font_14_unica_medium,
  unica_14_regular: typo_classes.font_14_unica_regular,
  unica_12_bold: typo_classes.font_12_unica_bold,
  unica_12_medium: typo_classes.font_12_unica_medium,
  unica_12_regular: typo_classes.font_12_unica_regular,
  unica_10_regular: typo_classes.font_10_unica_regular,
};

export const typography_langs = {
  '48_bold': {
    kr: typography_map['48_bold_kr'],
    en: typography_map['48_bold_kr'],
    jp: typography_map['48_bold_kr'],
  },
  '40_bold': {
    kr: typography_map['40_bold_kr'],
    en: typography_map['40_bold_kr'],
    jp: typography_map['40_bold_kr'],
  },
  '40_medium': {
    kr: typography_map['40_medium_kr'],
    en: typography_map['40_medium_kr'],
    jp: typography_map['40_medium_kr'],
  },
  '36_bold': {
    kr: typography_map['36_bold_kr'],
    en: typography_map['36_bold_kr'],
    jp: typography_map['36_bold_kr'],
  },
  '36_medium': {
    kr: typography_map['36_medium_kr'],
    en: typography_map['36_medium_kr'],
    jp: typography_map['36_medium_kr'],
  },
  '32_bold': {
    kr: typography_map['32_bold_kr'],
    en: typography_map['32_bold_kr'],
    jp: typography_map['32_bold_kr'],
  },
  '32_medium': {
    kr: typography_map['32_medium_kr'],
    en: typography_map['32_medium_kr'],
    jp: typography_map['32_medium_kr'],
  },
  '28_bold': {
    kr: typography_map['28_bold_kr'],
    en: typography_map['28_bold_kr'],
    jp: typography_map['28_bold_kr'],
  },

  '28_medium': {
    kr: typography_map['28_medium_kr'],
    en: typography_map['28_medium_kr'],
    jp: typography_map['28_medium_kr'],
  },
  '28_regular': {
    kr: typography_map['28_regular_kr'],
    en: typography_map['28_regular_kr'],
    jp: typography_map['28_regular_kr'],
  },
  '24_bold': {
    kr: typography_map['24_bold_kr'],
    en: typography_map['24_bold_kr'],
    jp: typography_map['24_bold_kr'],
  },
  '24_medium': {
    kr: typography_map['24_medium_kr'],
    en: typography_map['24_medium_kr'],
    jp: typography_map['24_medium_kr'],
  },
  '24_regular': {
    kr: typography_map['24_regular_kr'],
    en: typography_map['24_regular_kr'],
    jp: typography_map['24_regular_kr'],
  },
  '22_bold': {
    kr: typography_map['22_bold_kr'],
    en: typography_map['22_bold_kr'],
    jp: typography_map['22_bold_kr'],
  },
  '22_medium': {
    kr: typography_map['22_medium_kr'],
    en: typography_map['22_medium_kr'],
    jp: typography_map['22_medium_kr'],
  },
  '22_regular': {
    kr: typography_map['22_regular_kr'],
    en: typography_map['22_regular_kr'],
    jp: typography_map['22_regular_kr'],
  },
  '20_bold': {
    kr: typography_map['20_bold_kr'],
    en: typography_map['20_bold_kr'],
    jp: typography_map['20_bold_kr'],
  },
  '20_medium': {
    kr: typography_map['20_medium_kr'],
    en: typography_map['20_medium_kr'],
    jp: typography_map['20_medium_kr'],
  },
  '20_regular': {
    kr: typography_map['20_regular_kr'],
    en: typography_map['20_regular_kr'],
    jp: typography_map['20_regular_kr'],
  },
  '16_bold': {
    kr: typography_map['16_bold_kr'],
    en: typography_map['16_bold_kr'],
    jp: typography_map['16_bold_kr'],
  },
  '16_medium': {
    kr: typography_map['16_medium_kr'],
    en: typography_map['16_medium_kr'],
    jp: typography_map['16_medium_kr'],
  },
  '16_regular': {
    kr: typography_map['16_regular_kr'],
    en: typography_map['16_regular_kr'],
    jp: typography_map['16_regular_kr'],
  },
  '14_bold': {
    kr: typography_map['14_bold_kr'],
    en: typography_map['14_bold_kr'],
    jp: typography_map['14_bold_kr'],
  },
  '14_medium': {
    kr: typography_map['14_medium_kr'],
    en: typography_map['14_medium_kr'],
    jp: typography_map['14_medium_kr'],
  },
  '14_regular': {
    kr: typography_map['14_regular_kr'],
    en: typography_map['14_regular_kr'],
    jp: typography_map['14_regular_kr'],
  },
  '12_bold': {
    kr: typography_map['12_bold_kr'],
    en: typography_map['12_bold_kr'],
    jp: typography_map['12_bold_kr'],
  },
  '12_medium': {
    kr: typography_map['12_medium_kr'],
    en: typography_map['12_medium_kr'],
    jp: typography_map['12_medium_kr'],
  },
  '12_regular': {
    kr: typography_map['12_regular_kr'],
    en: typography_map['12_regular_kr'],
    jp: typography_map['12_regular_kr'],
  },
  '10_medium': {
    kr: typography_map['10_medium_kr'],
    en: typography_map['10_medium_kr'],
    jp: typography_map['10_medium_kr'],
  },
  '10_regular': {
    kr: typography_map['10_regular_kr'],
    en: typography_map['10_regular_kr'],
    jp: typography_map['10_regular_kr'],
  },
};

export type Lang = 'kr' | 'en' | 'jp';
export type typo_names = keyof typeof typography_langs;
export type typo_raws = keyof typeof typography_map;

export const getTypo = (name: typo_names, lang?: Lang): string => {
  const i18n_lang = ET.lang;
  if (!i18n_lang || typeof i18n_lang !== 'string') {
    throw new Error('getTypo: i18next.language 가 없거나 문제가 발생했습니다.');
  }
  return typography_langs[name][lang || i18n_lang];
};

export const getTypoRaw = (name: typo_raws): string => {
  return typography_map[name];
};

interface TypoPcMo {
  is_mobile: boolean;
  pc: typo_names;
  mo: typo_names;
}
export const getTypoPcMo = ({ is_mobile, pc, mo }: TypoPcMo) => {
  if (is_mobile) {
    return getTypo(mo);
  }

  return getTypo(pc);
};

interface StaticTypoPcMo {
  is_mobile: boolean;
  pc: typo_raws;
  mo: typo_raws;
}

export const getStaticTypoPcMo = ({ is_mobile, pc, mo }: StaticTypoPcMo) => {
  if (is_mobile) {
    return getTypoRaw(mo);
  }

  return getTypoRaw(pc);
};
