import { go, html, sel, sortBy, strMap } from 'fxjs/es';
import { MShopUtilConstantS } from '../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { messages } from './messages_id.js';

export const makeAccountSettingsBodyHtml = (data) => html`
  <div class="header">
    <h2>${T(messages.menus.account)}</h2>
    <p>${T(messages.account.p1)}</p>
  </div>

  <div class="body">
    <div class="info_form form">
      <div class="controls form-group">
        <div class="control form-item">
          <label class="form-label">${T(messages.account.email)}</label>
          <div class="input">
            <span class="form-control disabled">${UtilS.escape(sel('user.email', data))}</span>
            ${sel('user._.crew.id', data) === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID
              ? ''
              : // 일단 디자인이 없는 관계로 가리기로 결정
                // : html`
                //     <button type="button" class="btn_change_email form-control">
                //       ${T(messages.account.save_changes)}
                //     </button>
                //   `
                ''}
          </div>
        </div>
        ${sel('user._.crew.id', data) === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID
          ? ''
          : html`
              <div class="control form-item">
                <label class="form-label">${T(messages.account.pw)}</label>
                <div class="input">
                  <button type="button" class="form-control btn_change_pw">
                    ${T(messages.account.reset_pw)}
                  </button>
                </div>
              </div>
            `}
        <div class="control form-item">
          <label class="form-label">${T(messages.account.my_point)}</label>
          <div class="input">
            <span class="form-control disabled">${UtilS.commify(sel('user.point', data))}P</span>
          </div>
        </div>
        <div class="control form-item">
          <label class="form-label">${T(messages.account.name)}</label>
          <div class="input">
            <input type="text" name="name" value="${UtilS.escape(sel('user.name', data))}" />
          </div>
        </div>
        ${go(void 0, () => {
          if (T.lang == 'kr')
            return html`
              <div class="control form-item" }>
                <label class="form-label">${T(messages.account.phone)}</label>
                <div class="input">
                  <input
                    type="text"
                    name="owner_mobile"
                    inputmode="numeric"
                    pattern="[0-9]"
                    value="${UtilS.escape(sel('user._.store.owner_mobile', data)) || ''}"
                  />
                </div>
              </div>
            `;
          else
            return html`
              <div class="control form-item mobile">
                <label class="form-label">${T(messages.account.phone)}</label>
                <div class="input">
                  <select name="owner_dialing_code" class="dialing_code">
                    ${go(
                      data.countries,
                      sortBy((country) => parseInt(country.dialing_code)),
                      strMap(
                        (country) => html`
                          <option
                            value="${country.dialing_code || ''}"
                            code="${country.code}"
                            ${country.dialing_code == sel('user._.store.owner_dialing_code', data)
                              ? ' selected'
                              : ''}
                          >
                            +${country.dialing_code}
                          </option>
                        `,
                      ),
                    )}
                  </select>
                  <input
                    type="text"
                    name="owner_mobile1"
                    inputmode="numeric"
                    pattern="[0-9]"
                    value="${UtilS.escape(sel('user._.store.owner_mobile1', data)) || ''}"
                  />
                </div>
              </div>
            `;
        })}
        ${sel('user._.store.is_disapproval', data)
          ? ''
          : html`
              <div
                class="control form-item"
                ${sel('user._.crew.id', data) === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID
                  ? 'style="display: none;"'
                  : ''}
              >
                <label class="form-label">${T(messages.account.store_url)}</label>
                <div class="input">
                  <input
                    type="text"
                    name="domain_name"
                    value="${UtilS.escape(sel('user._.store.domain_name', data))}"
                  />
                </div>
              </div>
            `}
      </div>
      <div class="buttons">
        <button type="button" class="btn_modify">${T(messages.account.modify)}</button>
      </div>
    </div>
  </div>
`;

export const makeInviteInfoSettingsBodyHtml = (data) => html`
  <div class="header"><h2>${T(messages.menus.invite_info)}</h2></div>
  <div class="body">
    <div class="info_form form">
      <h3>
        ${T(messages.invite_info.p1, {
          name: UtilS.escape(
            sel('invited_store._.user.name', data) ||
              sel('invited_store.name', data) ||
              T(messages.marpple_seller),
          ),
        })}
      </h3>
      <ul>
        <li>${T(messages.invite_info.p2)}</li>
        <li>
          ${T(messages.invite_info.p3, {
            name: UtilS.escape(
              sel('invited_store._.user.name', data) ||
                sel('invited_store.name', data) ||
                T(messages.marpple_seller),
            ),
          })}
        </li>
      </ul>
      <p>${T(messages.invite_info.p4)}</p>
      <h3>${T(messages.invite_info.t1)}</h3>
      <div class="youtube_list">
        ${!data.is_mobile
          ? html`
              <div class="youtube_list_body">
                <iframe
                  style="margin-right: 0.5%; ${data.is_mobile ? 'margin-bottom: 10px;' : ''}"
                  width="${data.is_mobile ? 100 : 33}%"
                  height="${data.is_mobile ? '100%' : '100%'}"
                  src="https://www.youtube.com/embed/9rhmaE5vNVM"
                  frameborder="0"
                  title="YouTube video player"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="false"
                ></iframe>
                <iframe
                  style="margin-right: 0.5%;"
                  width="${data.is_mobile ? 100 : 33}%"
                  height="${data.is_mobile ? '100%' : '100%'}"
                  src="https://www.youtube.com/embed/uwt0z_KttNQ"
                  frameborder="0"
                  title="YouTube video player"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="false"
                ></iframe>
                <iframe
                  width="${data.is_mobile ? 100 : 33}%"
                  height="${data.is_mobile ? '100%' : '100%'}"
                  src="https://www.youtube.com/embed/WaAyyB7z5Dg"
                  frameborder="0"
                  title="YouTube video player"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="false"
                ></iframe>
              </div>
            `
          : html`
              <div class="youtube_list_body">
                <div class="_iframe">
                  <iframe
                    style="margin-right: 0.5%; ${data.is_mobile ? 'margin-bottom: 10px;' : ''}"
                    width="${data.is_mobile ? 100 : 33}%"
                    height="${data.is_mobile ? '100%' : '100%'}"
                    src="https://www.youtube.com/embed/9rhmaE5vNVM"
                    frameborder="0"
                    title="YouTube video player"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="false"
                  ></iframe>
                </div>
                <div class="_iframe">
                  <iframe
                    style="margin-right: 0.5%;"
                    width="${data.is_mobile ? 100 : 33}%"
                    height="${data.is_mobile ? '100%' : '100%'}"
                    src="https://www.youtube.com/embed/uwt0z_KttNQ"
                    frameborder="0"
                    title="YouTube video player"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="false"
                  ></iframe>
                </div>
                <div class="_iframe">
                  <iframe
                    width="${data.is_mobile ? 100 : 33}%"
                    height="${data.is_mobile ? '100%' : '100%'}"
                    src="https://www.youtube.com/embed/WaAyyB7z5Dg"
                    frameborder="0"
                    title="YouTube video player"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="false"
                  ></iframe>
                </div>
              </div>
            `}
      </div>
      <h3>${T(messages.invite_info.h2)}</h3>
      <ul class="marpple_shop">
        <li><a href="https://marpple.shop/" target="_blank">마플샵 홈 보러가기</a></li>
      </ul>
      <h3 class="invite_info_description">마플샵 오픈을 위해 아래 정보를 입력해 주세요.</h3>
      <div class="controls form-group">
        <div class="control form-item">
          <label class="form-label">샵 이름</label>
          <div class="input">
            <input type="text" name="name" value="" />
          </div>
        </div>
        ${UtilS.htmlIfElse(
          T.lang == 'kr',
          html`
            <div class="control form-item">
              <label class="form-label">휴대전화번호</label>
              <div class="input">
                <input
                  type="text"
                  name="owner_mobile"
                  inputmode="numeric"
                  pattern="[0-9]"
                  value="${UtilS.escape(sel('user._.store.owner_mobile', data)) || ''}"
                />
              </div>
            </div>
          `,
          html`
            <div class="control form-item mobile">
              <label class="form-label">휴대전화번호</label>
              <div class="input">
                <select name="owner_dialing_code" class="dialing_code">
                  ${go(
                    data.countries,
                    sortBy((country) => parseInt(country.dialing_code)),
                    strMap(
                      (country) => html`
                        <option
                          value="${country.dialing_code || ''}"
                          code="${country.code}"
                          ${country.dialing_code == sel('user._.store.owner_dialing_code', data)
                            ? ' selected'
                            : ''}
                        >
                          +${country.dialing_code}
                        </option>
                      `,
                    ),
                  )}
                </select>
                <input
                  type="text"
                  name="owner_mobile1"
                  inputmode="numeric"
                  pattern="[0-9]"
                  value="${UtilS.escape(sel('user._.store.owner_mobile1', data)) || ''}"
                />
              </div>
            </div>
          `,
        )}
        <div class="control form-item domain_name">
          <div class="domain_name_ex">
            <label class="form-label">마플샵 도메인명 (영문/숫자 2자 이상 20자 이내)</label>
            <div class="link">
              https://marpple.shop/<span class="name"
                >${UtilS.escape(sel('user._.store.domain_name', data))}</span
              >
            </div>
          </div>
          <div class="input">
            <input type="text" name="domain_name" value="" />
          </div>
        </div>
        <div class="control form-item">
          <label class="form-label">대표 SNS url (선택)</label>
          <div class="input">
            <input
              type="text"
              name="sns_url"
              value="${UtilS.escape(sel('user._.store.sns_url', data)) || ''}"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="buttons">
        <button type="button" class="btn_invite_info_submit">${T(messages.invite_info.submit)}</button>
      </div>
    </div>
  </div>
`;
