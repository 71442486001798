import { $on } from 'fxdom/es';
import { curry2, delay } from 'fxjs/es';

import ScrollMagic from 'scrollmagic';

export const scrollState = {
  scenes: [],
  controllers: [new ScrollMagic.Controller()],
  scrollY: 0,
};

const globalController = scrollState.controllers[0];

$on('optimizedScroll', () => {
  scrollState.scrollY = window.pageYOffset;
})(window);

// element, position, duration?, controller?
export const onScroll = (option) =>
  !option.element
    ? (element) => onScroll({ ...option, element })
    : new ScrollMagic.Scene({
        triggerElement: option.element,
        triggerHook: option.position || 0,
        offset: option.offset || 0,
        duration: option.duration || 0,
      }).addTo(option.controller || globalController);

export const addScrollEvent = curry2((event, f, scene) => scene.on(event, f));

export const setPin =
  (element, isPush = true) =>
  (scene) =>
    scene.setPin(element, { pushFollowers: isPush });

export const triggerAfter = curry2((f, point, scene) => {
  let enter = false;
  scene.on('progress', (e) => {
    const trigger = e.progress > point;
    if (trigger && !enter) {
      enter = true;
      return f(enter, e.progress);
    }
    if (!trigger && enter) {
      enter = false;
      return f(enter, e.progress);
    }
  });
  return scene;
});

export const getElementPosition = (el, toBottom) => {
  const { top, bottom } = el.getBoundingClientRect();
  const bodyTop = document.body.getBoundingClientRect().top;
  return (toBottom ? bottom : top) - bodyTop;
};

export const getOuterHeight = (el) => {
  const { marginTop, marginBottom } = window.getComputedStyle(el);

  return el.offsetHeight + parseFloat(marginTop) + parseFloat(marginBottom);
};

export const isEnter = (e) => e.type == 'enter';
export const isForward = (e) => e.scrollDirection == 'FORWARD';

export const scrollTo = (top, duration) => {
  if (duration == 0) return;

  const start = window.pageYOffset;
  const now = new Date();

  const moveScroll = () => {
    const timeDiff = new Date() - now;

    if (timeDiff > duration) return window.scrollTo(0, top);

    const percent = timeDiff / duration;
    window.scrollTo(0, start + percent * (top - start));

    return true;
  };

  const loop = () =>
    requestAnimationFrame(() => {
      const result = moveScroll();
      if (result) loop();
    });

  loop();

  return delay(duration, true);
};
