import { SVGEditorUtilF } from '../../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { $closest, $data, $find, $on, $qs, $qsa, $setCss, $setText } from 'fxdom/es';
import { VectorEditorConstantS } from '../../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { each, go } from 'fxjs/es';
import { VectorEditorF } from '../../../../VectorEditor/F/Function/module/VectorEditorF.js';
import { SVGEditorProductDetailF } from '../../../../SVGEditor/ProductDetail/F/Function/module/SVGEditorProductDetailF.js';
import { PdfF } from '../../../../Pdf/F/Function/module/PdfF.js';
import { BpOptionUploadTypeS } from '../../S/Function/module/BpOptionUploadTypeS.js';
import { getBaseProductInMaker } from '../../../../Maker/F/getSth.js';
import { marpplizerProxy } from '../../../../Maker/F/Marpplizer/marpplizer.js';
import { NewMakerPropertyBaseProductS } from '../../../../NewMaker/Property/BaseProduct/S/Function/module/NewMakerPropertyBaseProductS.js';
import tippy from 'tippy.js';
import { setDataUploadTypeIsDesigned } from '../../../../NewMaker/Util/F/Function/makeDataIsDesigned.js';
import { alertProEditorCsError } from '../../../../VectorEditor/F/Function/pro_editor.error.js';

function dataURLtoBlob(canvas) {
  const dataURL = canvas.toDataURL('image/png', 1);
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const blob = new Blob([u8arr], { type: mime });
  blob.lastModifiedDate = new Date();
  blob.name = 'printable_file.png';
  return blob;
}

function svgElToBlob(svg_el) {
  const svgString = new XMLSerializer().serializeToString(svg_el);

  // SVG 문자열을 Blob 객체로 변환
  const blob = new Blob([svgString], { type: 'image/svg+xml' });

  // Blob 객체를 파일 객체로 변환 (선택적)
  blob.lastModifiedDate = new Date();
  blob.name = 'cutting_line.svg';

  // 변환된 Blob 객체를 사용하거나 전송 등의 작업을 수행
  return blob;
}
const svgUpload = async (ct) => {
  /*키링, 아크릴스탠드*/
  let loaderDone = null;
  try {
    loaderDone = SVGEditorUtilF.percentLoader({
      message: T('modules::VectorEditor::AcrylicFigure::message::make_images'),
      time: 6 * 1000,
      clazz: UtilF.isLegacyMobile() ? 'mobile' : 'pc',
    });
    const file = ct.files[0];
    const original_svg_file_name = file.name;

    if (file) {
      const maker_type = $closest('#maker_frame', ct).dataset?.maker_type;

      const options = (() => {
        if (maker_type === VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR) {
          const acryl_stand_size_option_el = go(
            ct,
            $closest('#marpplizer_home'),
            $find('.bp_option_group[data-dev_name="아크릴_스탠드_사이즈"]'),
          );

          if (acryl_stand_size_option_el) {
            const size_select_el = $find('select', acryl_stand_size_option_el);
            const selected_option = $data(size_select_el.options[size_select_el.selectedIndex]);

            return selected_option._.maker_meta.value.free_meta;
          }
        }
      })();
      const result = await VectorEditorF.pro.processSvgUploadFile({ file, maker_type, options });

      const { printable_image_canvas, key_item_coord_ratio, cut_svg_el, toPrintableImageCanvas } = result;

      await SVGEditorProductDetailF.directFileUploadSvgProcess({
        cutting_line_svg_file: svgElToBlob(cut_svg_el),
        original_svg_file: file,
        key_item_coord_ratio,
        original_svg_file_name,
        printable_png_file: dataURLtoBlob(printable_image_canvas),
        thumbnail_png_file: dataURLtoBlob(await toPrintableImageCanvas(800)),
      });
      go(
        ct,
        $closest('.maker-upload-type-template__upload-file'),
        $find('.maker-upload-type-template__file-name'),
        $setText(original_svg_file_name),
      );
    }
  } catch (err) {
    if (loaderDone) {
      await loaderDone(true).catch(() => {});
      loaderDone = null;
    }
    await VectorEditorF.alertProEditorError(err);
  } finally {
    $.don_loader_end();
    if (loaderDone) {
      await loaderDone().catch(() => {});
      loaderDone = null;
    }
    ct.value = null;
  }
};
const pdfUploadPcEvent = async (ct) => {
  let loaderDone = null;
  try {
    loaderDone = SVGEditorUtilF.percentLoader({
      message: T('modules::VectorEditor::AcrylicFigure::message::make_images'),
      time: 6 * 1000,
      clazz: UtilF.isLegacyMobile() ? 'mobile' : 'pc',
    });
    const file = ct.files[0];

    if (file) {
      const result = await PdfF.uploadPdfFile({ file });
      if (result.success) {
        const bp_id = box.sel('maker->product_color->base_product_id');
        const pdf_printing_file = result.data[0];

        uploadTypeDirect.pdfSetMakerProduct(pdf_printing_file);
        await go(
          $qsa('.board__upload-type-direct-result'),
          each(async (el) => {
            const printing_template = getBaseProductInMaker()._.printing_templates?.[0];
            el.innerHTML = await BpOptionUploadTypeS.UPLOAD_TYPE_DIRECT.makePdfResultTmpl({
              pdf_printing_file,
              printing_template,
            });
          }),
        );

        await marpplizerProxy();
      } else {
        await alertProEditorCsError({ title: TT('pro_editor::alert::title::dev') });
      }
    }
  } finally {
    $.don_loader_end();
    if (loaderDone) {
      await loaderDone().catch(() => {});
      loaderDone = null;
    }
    ct.value = null;
  }
};
export const uploadTypeDirect = {
  fileUploadEvent: (ct) => {
    if (NewMakerPropertyBaseProductS.pdfFilePossible.isIt(getBaseProductInMaker())) {
      return pdfUploadPcEvent(ct);
    } else {
      return svgUpload(ct);
    }
  },
  pdfSetMakerProduct: (pdf_printing_file) => {
    box.sel('maker->product_color').pdf_printing_file_id = pdf_printing_file.id;
    box.sel('maker->product_color')._.pdf_printing_file = pdf_printing_file;
    setDataUploadTypeIsDesigned();
  },
  setProductFaceThumbnail: (pdf_printing_file) => {
    box.sel('maker->product_color').product_faces2.value[0].pdf_thumb_url =
      pdf_printing_file.thumbnail_urls[0];
  },
  isPassUploadTypeDirect: (product) => {
    if (!BpOptionUploadTypeS.UPLOAD_TYPE_DIRECT.isDirectType(product)) return true;
    if (NewMakerPropertyBaseProductS.pdfFilePossible.isIt(product._.base_product)) {
      return !!box.sel('maker->product_color').pdf_printing_file_id;
    }
  },
  activeTippy: () => {
    if (!UtilF.isLegacyMobile()) return;
    const upload_popup_open_el = $qs('#mp-maker-pdf-file-upload-pop-up');
    if (!upload_popup_open_el) return;
    upload_popup_open_el.__tippy = tippy(upload_popup_open_el, {
      content: `<div style="display: flex;align-items: center;"><span>${TT(
        'maker_upload_type::만들기 툴은 곧 제공 예정이에요!',
      )}</span><img src="//s3.marpple.co/files/u_193535/2024/2/original/6e76d55351484915787811d054dd869df3348b4c1.png" style="width:8px;height:8px;margin-left:8px;" alt=""></img></div>`,
      allowHTML: true,
      theme: 'maker-select-face-mobile',
      showOnCreate: true,
      placement: 'top',
      appendTo: upload_popup_open_el,
      maxWidth: 'none',
      trigger: 'click',
      onShown(instance) {
        go(
          instance.popper,
          $on('click', () => {
            instance.destroy();
          }),
          $setCss({ 'pointer-events': 'initial' }),
        );
        // ...
      },
      hideOnClick: false,
    });
  },
};
