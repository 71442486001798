import { each, go, html } from 'fxjs/es';
import { $qsa, $setText } from 'fxdom/es';
import { productPriceInfoHtml } from '../../S/product.js';
import { calcMakerPrice } from '../../../ProductColorPrice/S/fs.js';
import { getProductColorInMaker } from '../getSth.js';

export const product_editor_product_tmpl = (pc) => {
  const review_total = box.sel('review_list->review_total');
  const is_shared_product = box.sel('shared_product');
  return html`
    <div class="product section product_editor_ev">
      ${productPriceInfoHtml(pc, review_total)}
      <div class="options">
        <button class="open_chooser ${is_shared_product ? 'disabled' : ''}" type="button">
          <span> ${T('Change Product')}</span>
        </button>
      </div>
    </div>
  `;
};

export const renderMpMakerProductPrice = () => {
  const price_text = calcMakerPrice(getProductColorInMaker());
  go($qsa('.mp-maker-product-price'), each($setText(price_text)));
};
