import { html, View } from 'rune-ts';
import { ProductCardData } from '../../../../features/ProductList/type';
import klass from './ProductBadgeList.module.scss';
import { ProductCardHelper } from '../ProductCard/ProductCardHelper';
import { ProductBadge } from '../ProductBadge/ProductBadge';
import { compact, map, pipe, take, toArray } from '@fxts/core';

export type ProductBadgeListData = Pick<
  ProductCardData,
  | 'is_public'
  | 'ranking'
  | 'sell_start_at'
  | 'sell_end_at'
  | 'quantity'
  | 'order_count'
  | 'is_quantity_public'
  | 'out_of_stock'
  | 'is_token_gate'
>;

interface ProductBadgeListOption {
  is_mobile: boolean;

  // 표시할 최대 뱃지 수
  max_length?: number;

  klass?: string;

  // 타이머를 원하는 시점에 실행하고 싶을 때 true
  is_lazy_timer?: boolean;
}

export class ProductBadgeList extends View<Readonly<ProductBadgeListData>> {
  BadgeViews: ProductBadge[];

  private interval_timer_ids: Array<ReturnType<typeof setInterval>> = [];

  constructor(data: Readonly<ProductBadgeListData>, private option: Readonly<ProductBadgeListOption>) {
    super(data, option);
    this.BadgeViews = pipe(this.setProductBadges(data, option), take(option.max_length ?? Infinity), toArray);
  }

  override template() {
    const hidden_klass = this.BadgeViews.length ? '' : klass.hidden;
    return html`<div class="${this.option.klass ?? ''} ${klass.badge_container} ${hidden_klass}">
      ${this.BadgeViews}
    </div>`;
  }

  public startBadgesTimer(externalCallback?: () => void): Array<ReturnType<typeof setInterval>> {
    if (!this.option.is_lazy_timer) {
      throw new Error('ProductBadgeList에 is_lazy_timer옵션이 적용되어 있지 않습니다.');
    }

    return (this.interval_timer_ids = pipe(
      this.BadgeViews,
      map((badgeView) => badgeView.startLazyIntervalTimer(externalCallback)),
      compact,
      toArray,
    ));
  }

  /**
   * 상품 뱃지를 생성하여 반환하는 함수입니다.
   * - 개수 제한 없이 상품이 가질 수 있는 모든 뱃지를 반환하므로, `take(n)`을 하여 사용하도록 합니다.
   */
  private setProductBadges(
    data: Pick<
      ProductCardData,
      | 'is_public'
      | 'ranking'
      | 'sell_start_at'
      | 'sell_end_at'
      | 'quantity'
      | 'order_count'
      | 'is_quantity_public'
      | 'out_of_stock'
      | 'is_token_gate'
    >,
    option: { is_mobile: boolean; is_lazy_timer?: boolean },
  ): ProductBadge[] {
    const badges: ProductBadge[] = [];

    // `Closed` 비공개 상품일 경우 노출, 단독으로 표시
    if (!data.is_public) {
      badges.push(new ProductBadge({ type: ProductBadge.Type.closed }, option));
      return badges;
    }

    // `Upcoming` 출시 예정
    if (ProductCardHelper.isUpcoming(data)) {
      badges.push(new ProductBadge({ type: ProductBadge.Type.upcoming, value: data.sell_start_at }, option));
      // `NFT`
      if (data.is_token_gate) {
        badges.push(new ProductBadge({ type: 'nft' }, option));
      }
      return badges;
    }

    // `Ranking` 랭킹, 주입한 경우에만 표시
    if (data.ranking) {
      badges.push(new ProductBadge({ type: ProductBadge.Type.ranking, value: data.ranking }, option));
    }

    // `Sold out` 품절
    if (ProductCardHelper.isSoldOut(data)) {
      badges.push(new ProductBadge({ type: ProductBadge.Type.sold_out }, option));
    }

    if (ProductCardHelper.isPeriodLimited(data) || ProductCardHelper.isQuantityLimited(data)) {
      const { sell_end_at, quantity } = data;

      // `Limited` 기간 한정 OR 수량 한정
      badges.push(new ProductBadge({ type: ProductBadge.Type.limited }, option));

      // 수량 한정이면서 기간 한정 일 때
      if (ProductBadge.isBothQuantityAndPeriodLimitedBadgeVisible(data) && sell_end_at) {
        const period_limited_badge_priority = ProductBadge.getPeriodLimitedBadgePriority(sell_end_at);
        const quantity_limited_badge_priority = ProductBadge.getQuantityLimitedBadgePriority(quantity);

        // 기간 한정뱃지가 수량한정보다 먼저 보여지는 조건
        if (period_limited_badge_priority < quantity_limited_badge_priority) {
          badges.push(
            new ProductBadge({ type: ProductBadge.Type.period_limited, value: sell_end_at }, option),
          );
        } else {
          badges.push(
            new ProductBadge(
              { type: ProductBadge.Type.quantity_limited, value: data.quantity - data.order_count },
              option,
            ),
          );
        }
      } else {
        // 수량한정 only
        if (ProductBadge.isQuantityLimitedBadgeVisible(data)) {
          badges.push(
            new ProductBadge(
              { type: ProductBadge.Type.quantity_limited, value: data.quantity - data.order_count },
              option,
            ),
          );
        }
        // 기간한정 only
        else if (ProductBadge.isPeriodLimitedBadgeVisible(data) && sell_end_at) {
          badges.push(
            new ProductBadge({ type: ProductBadge.Type.period_limited, value: sell_end_at }, option),
          );
        }
      }
    }

    // `NFT`
    if (data.is_token_gate) {
      badges.push(new ProductBadge({ type: 'nft' }, option));
    }

    return badges;
  }

  public clearIntervalTimers(): void {
    this.interval_timer_ids.forEach((timer_id) => {
      clearInterval(timer_id);
    });

    // flush
    this.interval_timer_ids = [];
  }
}
