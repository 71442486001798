import { find, go, strMap } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';

export function requiringBorder(color_codes) {
  const colors = ['#ffffff', '#FFFFFF', '#fefefe', '#FFFDFA', 'white'];
  return !!go(
    colors,
    find((color) => color_codes.includes(color)),
  );
}

function colorItemTmpl(bpc, current_bpc_id) {
  const selected = bpc.id == current_bpc_id ? 'selected' : '';

  return legacyHtml`
    <div class="color_item ${selected}" _id="${bpc.id}" _sel="./(#${bpc.id})" color_code="${
    bpc.color_code
  }" need_ccc=${requiringBorder([bpc.color_code, bpc.color_code2])}>
    ${
      bpc.color_code2
        ? `<span style="background: linear-gradient(135deg, ${bpc.color_code} 50%, ${
            bpc.color_code2 === '#ffffff' || bpc.color_code2 === '#fefefe' ? '#f8f8f8' : bpc.color_code2
          } 50%)"></span>`
        : `<span style="background: ${bpc.color_code}"></span>`
    }
    </div>
  `;
}

export const makeProductBpcMobileHtml = (bpcss, slide_length, current_bpc_id) => {
  return legacyHtml`
    <div class="color product_editor_ev">
      <div class="body">
        <div
          class="mkf2 swiper-container style1 color_picker"
          _sel="maker->product_color->_->base_product->_->base_product_colors"
          items_in_slide="${slide_length}"
        >
          <div class="bp_color_name"></div>
          <div class="swiper-wrapper">
            ${strMap(
              (bpcs) => legacyHtml`
                <div class="swiper-slide">${strMap((bpc) => colorItemTmpl(bpc, current_bpc_id), bpcs)}</div>
              `,
              bpcss,
            )}
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  `;
};

export function makeProductBpcPcHtml(bpcs, color_name, title, current_bpc_id) {
  if (!color_name) return ``;
  return legacyHtml`
    <div class="color section">
      <div class="head">
        ${title}
        <span class="bp_color_name"> ${color_name} </span>
      </div>
      <div
        class="mkf style1 color_picker"
        _sel="maker->product_color->_->base_product->_->base_product_colors"
      >
        ${strMap((bpc) => colorItemTmpl(bpc, current_bpc_id), bpcs)}
      </div>
    </div>
  `;
}
