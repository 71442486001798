import { CustomEventWithDetail, html, on, View } from 'rune-ts';
import { classes, debounce, htmlIf } from '../../../../shared/util';
import { typo } from '../../../../shared/typography/typo';
import { SearchIcon } from '../Icon';
import klass from './InputSearch.module.scss';
import { ButtonClose } from '../ButtonClose/ButtonClose';

export class SearchInputEvent extends CustomEventWithDetail<string> {}
export class SearchSubmitEvent extends CustomEventWithDetail<string> {}
export class SearchDebounceInputEvent extends CustomEventWithDetail<string> {}

export class SearchArrowKeydownEvent extends CustomEventWithDetail<'ArrowUp' | 'ArrowDown'> {}

export interface InputSearchProps {
  placeholder?: string;
  value?: string;

  // input klass
  klass?: string;

  debounce_ms?: number;

  // hide reset button
  off_reset?: boolean;

  backdrop_filter?: boolean;
  transparent?: boolean;
  placeholder_gray50?: boolean;
}

class InputView extends View<InputSearchProps> {
  unique_id: Readonly<string>;

  constructor(data: InputSearchProps) {
    data.value = (data.value ?? '').trim();
    super(data);

    // generate unique input id
    const random = Math.floor(Math.random() * 100_000_000);
    this.unique_id = `search_input_${random}`;
  }

  override template() {
    return html`
      <input
        id="${this.unique_id}"
        class="${klass.input}"
        type="text"
        placeholder="${this.data.placeholder}"
        value="${html.preventEscape(this.data.value ?? '')}"
      />
    `;
  }

  @on('input')
  private trimLeft(e) {
    const value = e.target.value?.trimLeft() ?? '';
    this.setValue(value);
  }

  override element(): HTMLInputElement {
    return super.element() as HTMLInputElement;
  }

  reset() {
    this.setValue('');
  }

  setValue(value: string) {
    this.element().value = value.trimStart();
  }
}

export class InputSearch extends View<InputSearchProps> {
  static TRANSPARENT_KLASS: Readonly<string> = klass.transparent;

  private InputView: InputView;

  constructor(data: InputSearchProps) {
    data.value = (data.value ?? '').trim();
    super(data);
    this.InputView = new InputView(this.data);
  }

  override template() {
    return html`<form
      action=""
      class="${klass.input_search} ${typo('14_medium')} ${classes({
        [klass.transparent]: this.data.transparent,
        [klass.backdrop_filter]: this.data.backdrop_filter,
        [klass.placeholder_gray50]: this.data.placeholder_gray50,
        [this.data.klass ?? '']: this.data.klass,
      })}"
    >
      ${this.InputView}
      ${htmlIf(new ButtonClose({ theme: 'dark', klass: klass.reset_icon }), !this.data.off_reset)}
      <label for="${this.InputView.unique_id}" class="${klass.search_icon}">${SearchIcon}</label>
    </form>`;
  }

  override onRender() {
    if (this.data.debounce_ms) {
      const handleDebounceInput = debounce(() => {
        this.dispatchEvent(SearchDebounceInputEvent, {
          bubbles: true,
          detail: this.InputView.element().value,
        });
      }, this.data.debounce_ms);

      this.delegate('input', InputView, handleDebounceInput);
    }

    this.delegate('input', InputView, (e) => {
      this.dispatchEvent(SearchInputEvent, {
        bubbles: true,
        detail: this.InputView.element().value,
      });
    });

    this.delegate('keydown', InputView, (e) => {
      // input 에서 한글자판 사용시 IME 에서 메시지를 가로채기 때문에 keyup/keydown 이벤트가 여러 번 울린다.
      // 이를 방지하기 위해 `isComposing`이 `true`일 경우에는 이벤트를 무시한다.
      if (e.isComposing) {
        return;
      }
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.originalEvent.preventDefault();
        this.dispatchEvent(SearchArrowKeydownEvent, {
          bubbles: true,
          detail: e.key,
        });
      }
      if (e.key === 'Enter') {
        e.originalEvent.preventDefault();
        this.dispatchEvent(SearchSubmitEvent, {
          bubbles: true,
          detail: this.InputView.element().value,
        });
      }
    });

    // click reset button
    this.delegate('click', ButtonClose, () => {
      this.InputView.reset();
      this.dispatchEvent(SearchInputEvent, {
        bubbles: true,
        detail: '',
      });
    });

    // click search icon
    this.delegate('click', `.${klass.search_icon}`, () => {
      this.dispatchEvent(SearchSubmitEvent, {
        bubbles: true,
        detail: this.InputView.element().value,
      });
    });
  }

  focus() {
    const el = this.InputView.element();
    const value = el.value;

    // focus 해줄 때 커서 맨 뒤로 보내기
    el.value = '';
    el.focus();
    el.value = value;
  }

  addTransparency() {
    this.element().classList.add(klass.transparent);
  }

  removeTransparency() {
    this.element().classList.remove(klass.transparent);
  }

  setValue(value: string) {
    this.InputView.setValue(value);
  }

  getValue(): string {
    return this.InputView.element().value;
  }
}
