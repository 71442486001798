export const ACTION_TARGET = {
  STORE: 'STORE',
  CREW: 'CREW',
};

export const SERVICE = {
  PB: 'pb',
  ADMIN: 'admin',
  WITHDRAW: 'withdraw',
  CONFIRMATION: 'product_confirmation',
  INDIVIDUAL_SELLER: 'individual_seller',
  FIXED_PROFIT: 'fixed_bp_profit',
  FIXED_SELLER_SHARE: 'fixed_seller_share',
  INDEPENDENT_HOME: 'independent_home',
  INHOUSE: 'inhouse',
};

// ORDER BY name ASC 정렬에 맞춰서 추가할 것
export const ACTION = {
  ACCESS_ALL_STUDIO: 'access_all_studio',
  ACCESS_CREW_ADMIN: 'access_crew_admin',
  APPROVE_CRWE_WITHDRAWS: 'approve_crew_withdraws',
  APPROVE_WITHDRAW: 'approve_withdraw',
  CANCEL_WITHDRAW: 'cancel_withdraw',
  CREATE_FEED: 'create_feed',
  CREATE_MANAGER: 'create_manager',
  CREATE_STORE: 'create_store',
  DELETE_MANAGER: 'delete_manager',
  DELETE_PRODUCT: 'delete_product',
  DELETE_STORE: 'delete_store',
  HIDE_CREW_PROFIT: 'hide_crew_profit',
  INVITE_FRIEND: 'invite_friend',
  MAKE_PB_PRODUCT: 'make_pb_product',
  MAKE_PRODUCT: 'make_product',
  OPEN_PB_PRIVATE_INFO: 'open_pb_private_info',
  PROHIBIT_PRODUCT: 'prohibit_product',
  READ_ADJUSTMENTS: 'read_adjustments',
  READ_BANK_ACCOUNT: 'read_bank_account',
  READ_CREW_WITHDRAWS: 'read_crew_withdraws',
  READ_MARPPLESHOP_ANALYTICS: 'read_marppleshop_analytics',
  READ_ORDERS: 'read_orders',
  READ_PB_PROJECTIONS: 'read_pb_projections',
  READ_SALES_AMOUNT: 'read_sales_amount',
  READ_SALES_ANALYTICS: 'read_sales_analytics',
  READ_WITHDRAWS: 'read_withdraws',
  REGISTER_CREW_BANK_ACCOUNT: 'register_crew_bank_account',
  REQUEST_CREW_WITHDRAWS: 'request_crew_withdraws',
  REQUEST_WITHDRAW: 'request_withdraw',
  UPDATE_CREW_STORE_LABELS: 'update_crew_store_labels',
  UPDATE_IS_BRAND_HOME: 'update_is_brand_home',
  UPDATE_IS_SELLING_TATTOO: 'update_is_selling_tatttoo',
  UPDATE_MANAGER_LEVEL: 'update_manager_level',
  UPDATE_MARPPLESHOP_MARKETING_CONFIRM: 'update_marppleshop_marketing_confirm',
  UPDATE_MARPPLESHOP_MARKETING_THUMBNAIL: 'update_marppleshop_marketing_thumbnail',
  UPDATE_PRODUCT_CONFIRM: 'update_product_confirm',
  UPDATE_STORE_CAN_SELLING: 'update_store_can_selling',
  UPDATE_STORE_NAME: 'update_store_name',
  UPDATE_STORE_SELLER: 'update_store_seller',
  UPDATE_SUSPEND_STORE: 'update_suspend_store',
  UPDATE_WANT_WITHDRAWAL: 'update_want_withdrawal',
  MANAGE_SITE: 'manage_site',
  SET_FIXED_SELLER_SHARE: 'set_fixed_seller_share',
};
