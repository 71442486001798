export const STICKER_EDITOR = 'STICKER';
export const KEYRING_EDITOR = 'KEYRING';
export const PHOTO_CARD_EDITOR = 'PHOTO_CARD';
export const ACRYLIC_FIGURE_EDITOR = 'ACRYLIC_FIGURE';
export const EX_MAKER_EDITOR = 'EX_MAKER';
export const EDITOR_TYPES = [
  STICKER_EDITOR,
  KEYRING_EDITOR,
  PHOTO_CARD_EDITOR,
  ACRYLIC_FIGURE_EDITOR,
  EX_MAKER_EDITOR,
];
export const PREVIEW_TYPES = [STICKER_EDITOR, KEYRING_EDITOR, ACRYLIC_FIGURE_EDITOR];

// @description 제품에 따라 템플릿 제공되는 필수 레이어 아이디
export const PRO_EDITOR_KEY_ITEM = {
  [KEYRING_EDITOR]: 'ring',
  [ACRYLIC_FIGURE_EDITOR]: 'stand',
};

// @description 프로 에디터의 템플릿에 필수 요소로 있어야 할 레이어 이름 구성 (제품별)
export const PRO_EDITOR_LAYER_IDS = {
  [KEYRING_EDITOR]: ['image', 'cut', PRO_EDITOR_KEY_ITEM[KEYRING_EDITOR], 'description'],
  [ACRYLIC_FIGURE_EDITOR]: ['image', 'cut', PRO_EDITOR_KEY_ITEM[ACRYLIC_FIGURE_EDITOR], 'description'],
};

// @description 프로 에디터의 템플릿에 존재해도 validation 체크를 우회하는 레이어 이름 구성
export const PRO_EDITOR_BYPASS_LAYER_IDS = ['description'];
