import { go, pluck } from 'fxjs/es';

export const initSelectedOptionGroup = (product) => {
  if (product._.selected_option_group?.bp_option_ids?.length) return;
  if (!product._.base_product._.base_products_bp_option_groups?.length) return;
  const bp_option_ids = go(
    product._.base_product._.base_products_bp_option_groups,
    pluck('default_bp_option_id'),
  );
  product._.selected_option_group.bp_option_ids = bp_option_ids;
};

export const getSelectedBpOptionIds = (product) => {
  return product._.selected_option_group?.bp_option_ids;
};
