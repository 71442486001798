import { html } from 'fxjs/es';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { NewMakerCheckConditionF } from '../../../CheckCondition/F/Function/module/NewMakerCheckConditionF.js';
import { NewMakerConditionPopupF } from '../../../ConditionPopup/F/Function/module/NewMakerConditionPopupF.js';

export const pc = () => {
  if (UtilF.isLegacyMobile()) return html``;

  return html`<div class="condition_tooltip" style="display: none;">
    <div class="condition_tooltip_text">
      ${TT('maker::tooltip::pc_title')}

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.0008 1.7998C6.36747 1.7998 1.80078 6.3665 1.80078 11.9998C1.80078 17.6331 6.36747 22.1998 12.0008 22.1998C17.6341 22.1998 22.2008 17.6331 22.2008 11.9998C22.2008 6.3665 17.6341 1.7998 12.0008 1.7998Z"
          fill="#FF6B00"
        />
        <path
          d="M11.0384 14.0521C11.0592 12.2356 11.593 11.681 12.5082 11.1125C13.0906 10.7589 13.5343 10.2458 13.5343 9.51782C13.5343 8.65117 12.8687 8.10344 12.0367 8.10344C11.288 8.10344 10.56 8.56104 10.4976 9.58715H9C9.05547 7.81918 10.3866 6.85547 12.0367 6.85547C13.8394 6.85547 15.0457 7.93705 15.0457 9.51782C15.0457 10.6341 14.5119 11.3759 13.6314 11.9167C12.7994 12.4228 12.4735 12.922 12.4527 14.0521V14.1353H11.0384V14.0521ZM10.8165 16.146C10.8096 15.6052 11.2602 15.1614 11.801 15.1614C12.3349 15.1614 12.7786 15.6052 12.7855 16.146C12.7786 16.6867 12.3349 17.1235 11.801 17.1305C11.2602 17.1235 10.8096 16.6867 10.8165 16.146Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="condition_tooltip_img">
      <img
        src="https://s3.marpple.co/files/u_3928205/2024/7/original/38001bef832cea80a0e41c2a8513a2895f1846c91.png"
        alt="툴팁"
      />
    </div>
  </div> `;
};

export const clickEvent = () =>
  $.on('click', '.condition_tooltip_text', async (e) => {
    const type = NewMakerCheckConditionF.is_full_fill_or_out_bounds();
    if (!type) return;

    return NewMakerConditionPopupF.open(type);
  });
