import { makeProductDetailMakerCanvasSize } from '../../../../../modules/Maker/F/product_detail_maker.js';
import { isWorkerInMaker } from '../../../../../modules/Maker/F/util.js';
import { isNeedFullCover } from '../../../../../modules/Maker/F/categorize.js';
import {
  getBaseProductInMaker,
  getCanvasScreenRatio,
  getCurrentBpId,
} from '../../../../../modules/Maker/F/getSth.js';
import { NewMakerWeS } from '../../../../../modules/NewMaker/We/S/Function/module/NewMakerWeS.js';
import { $qs } from 'fxdom/es';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

!(function () {
  const duration = isWorkerInMaker() ? 0 : 400;
  const duration2 = isWorkerInMaker() ? 0 : 700;
  const easing = 'easeOutQuart';
  // var easing2 = 'easeOutQuart';
  const easing2 = 'easeOutQuart';

  G.mp.maker.zoom = function (zoom) {
    const canvas = G.mp.maker.editing_canvas();
    if (!canvas) return;
    G.mp.maker.unselect_all();
    const canvas_container = $1('.canvas_container.selected');

    if (zoom) {
      $.add_class($('html'), 'maker_zoomed');
      pinch_zoom(canvas_container);
    } else {
      $.remove_class($('html'), 'maker_zoomed');
      set_zoom_attr($1('.canvas_container.selected .canvas_zoom_wrapper'));
    }
  };

  function set_zoom_attr(zoom_wrapper) {
    zoom_wrapper = zoom_wrapper || get_zoom_wrapper();
    _p.extend(G.mp.maker.zoom_attr, get_zoom_attr(zoom_wrapper));
  }
  G.mp.maker.zoom.set_zoom_attr = set_zoom_attr;

  function get_zoom_attr(zoom_wrapper) {
    return {
      translateX: anime.getValue(zoom_wrapper, 'translateX'),
      translateY: anime.getValue(zoom_wrapper, 'translateY'),
      transformOrigin: anime.getValue(zoom_wrapper, 'transformOrigin') + ' 0px',
      scale: parseFloat(anime.getValue(zoom_wrapper, 'scale')),
    };
  }

  function adjust_scale(target_canvas, scale, center, translateXY) {
    const scale_value = !UtilF.isLegacyMobile() ? 4 : 2.5;
    if (scale < 1) scale = 1;
    if (scale > scale_value) scale = scale_value;
    $.attr($1('html'), {
      zoomed: scale !== G.mp.maker.editing_canvas().default_ratio,
    });
    $.css(target_canvas, {
      transform: `translateX(${translateXY.x}px) translateY(${translateXY.y}px) scale(${scale})`,
      transformOrigin: `${center.x}px ${center.y}px 0`,
    });
  }
  G.mp.maker.zoom.adjust_scale = adjust_scale;
  function adjust_translate(target_canvas, x, y) {
    const scale = anime.getValue(target_canvas, 'scale');
    const translateX = parseInt(anime.getValue(target_canvas, 'translateX'));
    const translateY = parseInt(anime.getValue(target_canvas, 'translateY'));
    x = translateX + x;
    y = translateY + y;
    $.css(target_canvas, {
      transform: `translateX(${x}px) translateY(${y}px) scale(${scale})`,
    });
  }

  G.mp.maker.zoom.adjust_translate = adjust_translate;
  function make_center(target_canvas, center) {
    const scale = parseFloat(anime.getValue(target_canvas, 'scale'));
    const offset = $.offset(target_canvas);
    let x = center.x;
    let y = center.y;
    x += G.mp.maker.zoom_attr.offset.left;
    y += G.mp.maker.zoom_attr.offset.top;
    x -= offset.left;
    y -= offset.top;
    x *= 1 / scale;
    y *= 1 / scale;
    return { x, y };
  }
  G.mp.maker.zoom.maker_center = make_center;
  function make_translateXY(target_canvas, center) {
    const scale = parseFloat(anime.getValue(target_canvas, 'scale'));

    const transformOrigin = anime.getValue(target_canvas, 'transformOrigin').split(' ');
    const prev_center = {
      x: parseFloat(transformOrigin[0]),
      y: parseFloat(transformOrigin[1]),
    };
    const prev_xy = {
      x: prev_center.x * scale - prev_center.x,
      y: prev_center.y * scale - prev_center.y,
    };
    const current_xy = {
      x: center.x * scale - center.x,
      y: center.y * scale - center.y,
    };
    const translateXY = {
      x: current_xy.x - prev_xy.x,
      y: current_xy.y - prev_xy.y,
    };
    translateXY.x += parseFloat(anime.getValue(target_canvas, 'translateX'));
    translateXY.y += parseFloat(anime.getValue(target_canvas, 'translateY'));
    return translateXY;
  }

  G.mp.maker.zoom.make_translateXY = make_translateXY;
  G.mp.maker.zoom.pinch_zoom = pinch_zoom;
  function pinch_zoom(el) {
    $.remove($1('.zoom_info'));
    $.append(
      $1('.canvass'),
      pug`
      .zoom_info
        .pinch_zoom
        input[type="range" max="4" min="1" step="0.1" value="${G.mp.maker.zoom_attr.scale}"]
    `,
    );
    const zoom_info = $1('.zoom_info');

    const canvas_zoom_wrapper = $.find1(el, '.canvas_zoom_wrapper');
    let center = {};
    let translateXY = {};

    if (!UtilF.isLegacyMobile()) {
      center = {
        x: $.width(canvas_zoom_wrapper) / 2,
        y: $.height(canvas_zoom_wrapper) / 2,
      };
      translateXY = make_translateXY(canvas_zoom_wrapper, center);
      _go(
        zoom_info,
        $.on('input', 'input', function (e) {
          const scale = $.val(e.currentTarget);
          // adjust_scale(canvas_zoom_wrapper, scale, center, translateXY);
          adjust_scale($1('.canvas_container.selected .canvas_zoom_wrapper'), scale, center, translateXY);
        }),
        $.on('mousedown', '.pinch_zoom', function (e) {
          $.attr(e.currentTarget, {
            mousedowned: true,
            offsetX: e.offsetX,
            offsetY: e.offsetY,
          });
        }),
        $.on('mousemove', '.pinch_zoom', function (e) {
          const ct = e.currentTarget;
          if (!$.attr(ct, 'mousedowned')) return;
          const offsetX = $.attr(ct, 'offsetX');
          const offsetY = $.attr(ct, 'offsetY');
          const x = e.offsetX - offsetX;
          const y = e.offsetY - offsetY;
          $.attr(e.currentTarget, {
            mousedowned: true,
            offsetX: e.offsetX,
            offsetY: e.offsetY,
          });
          const target = $1('.canvas_container.selected .canvas_zoom_wrapper');
          G.mp.maker.zoom.adjust_translate(target, x, y);
        }),
        $.on('mouseup', '.pinch_zoom', function (e) {
          $.attr(e.currentTarget, { mousedowned: false });
          center = {
            x: $.width(canvas_zoom_wrapper) / 2,
            y: $.height(canvas_zoom_wrapper) / 2,
          };
          center = make_center(canvas_zoom_wrapper, center);
          translateXY = make_translateXY(canvas_zoom_wrapper, center);
        }),
      );
    }
  }

  function maker_modeA(option, extra_height, ratio2) {
    return _p.go(default_other_canvas_container(get_zoom_wrapper()), () =>
      maker_mode_baseA(option, extra_height, ratio2),
    );
  }

  G.mp.maker.zoom.maker_modeA = maker_modeA;

  G.mp.maker.zoom.zoomIsStarted = function zoomIsStarted() {
    $.attr($1('html'), { zoomed: true });
  };
  G.mp.maker.zoom.zoomIsEnded = function zoomIsEnded() {
    $.attr($1('html'), { zoomed: false });
  };

  function maker_mode_baseA(option, extra_height, ratio2) {
    const f_canvas = G.mp.maker.editing_canvas();
    if (!f_canvas) return;
    const canvas_container_selected = $1('.canvas_container.selected');
    const canvas_container = $.find1(canvas_container_selected, '.canvas-container');
    const inner_canvas_container_selected = $.find1(canvas_container_selected, '.canvas_zoom_wrapper');
    const canvas_width = $.width(canvas_container);
    const extra_height2 = extra_height || 88 + $.height($1('.maker_menu'));
    const canvas_height = $.height(canvas_container) - extra_height2;
    let { width, height, y_diff, x_diff } = f_canvas.size_info;

    const cv_print_area = G.mp.maker.cv_print_area(f_canvas);
    const screen_ratio = getCanvasScreenRatio(f_canvas) || 1;
    const bp = box.sel('maker->product_color->_->base_product');
    if (isNeedFullCover() || (bp?.maker_type === 'DEFAULT' && width < cv_print_area?.width * screen_ratio)) {
      const cv_img_line_ratio = canvas_width / G.mp.maker.CANVAS_WIDTH;
      width = cv_print_area.width * cv_img_line_ratio;
      height = cv_print_area.height * cv_img_line_ratio;
    }

    let ratio = _go(
      _p.min([canvas_width / width, canvas_height / height]),
      (r) => r.toPrecision(2),
      parseFloat,
      (r) => (ratio2 ? r - ratio2 : r - 0.1),
    );

    let diff = y_diff * ratio;
    if (extra_height) {
      diff -= $.height(window) / 2 - ($.height(window) - extra_height) / 2;
    } else {
      diff -= 16;
    }
    let translateY = diff;
    const translateX = x_diff * ratio;
    if (UtilF.isLegacyMobile() && isNeedFullCover()) translateY += 30;
    if (!UtilF.isLegacyMobile()) translateY = 0;
    if (_p.v(option, 'scale') === undefined && extra_height === undefined) {
      f_canvas.default_ratio = ratio;
      G.mp.maker.zoom.zoomIsEnded();
    }

    if (NewMakerWeS.isWeBaseProduct(getCurrentBpId())) {
      const { thumbnail_ratio } = getBaseProductInMaker();
      const thumb_ratio = parseFloat(thumbnail_ratio);
      if (thumb_ratio) ratio *= thumb_ratio;
    }
    const maker_frame_el = $qs('#maker_frame');
    const mobile_maker_size_ratio = parseFloat(maker_frame_el.dataset.feature__mobile_maker_size_ratio);
    if (mobile_maker_size_ratio) ratio *= mobile_maker_size_ratio;
    return _p.go(
      anime(
        _p.extend(
          {
            targets: [inner_canvas_container_selected],
            translateX: translateX,
            translateY: translateY,
            scale: !UtilF.isLegacyMobile() ? 1 : ratio,
            duration: duration,
            easing: easing,
          },
          option,
        ),
      ).finished,
      function () {
        G.mp.maker.zoom.set_zoom_attr();
      },
    );
  }

  function default_other_canvas_container(inner_canvas_container_selected) {
    const inner_canvas_contianer = _p.reject(
      $('.canvas_zoom_wrapper'),
      (v) => v === inner_canvas_container_selected,
    );
    return _p.mr(
      anime({
        targets: $('.canvas_container'),
        translateX: 0,
        translateY: 0,
        scale: 1,
        duration: 0,
      }).finished,
      anime({
        targets: inner_canvas_contianer,
        translateX: 0,
        translateY: 0,
        scale: 1,
        // easing: easing,
        duration: 0,
      }).finished,
    );
  }

  function get_zoom_wrapper() {
    return $1('.canvas_container.selected .canvas_zoom_wrapper');
  }

  function get_zoom_wrapper_center() {
    const canvas_zoom_wrapper = get_zoom_wrapper();
    return {
      x: $.width(canvas_zoom_wrapper) / 2,
      y: $.height(canvas_zoom_wrapper) / 2,
    };
    // return {x: 430 * G.mp.maker.zoom_attr.scale, y: 430 * G.mp.maker.zoom_attr.scale};
  }

  G.mp.maker.zoom.get_zoom_wrapper = get_zoom_wrapper;
  G.mp.maker.zoom.get_zoom_wrapper_center = get_zoom_wrapper_center;
  function is_same(zoom_wrapper) {
    return _p.every(get_zoom_attr(zoom_wrapper), function (v, key) {
      return v === G.mp.maker.zoom_attr[key];
    });
  }

  function recover() {
    const zoom_wrapper = get_zoom_wrapper();
    if (is_same(zoom_wrapper)) return;
    return _p.go(___, _p.wait(100), () => {
      return anime(
        _p.defaults(
          {
            targets: [zoom_wrapper],
            easing: easing2,
            duration: duration2,
          },
          _p.omit(G.mp.maker.zoom_attr, 'offset'),
        ),
      ).finished;
    });
  }

  G.mp.maker.zoom.recover = recover;

  async function by_active2(active, extra_height, option) {
    const zoom_wrapper = $1('.canvas_container.selected .canvas_zoom_wrapper');
    set_zoom_attr(zoom_wrapper);
    const center = active.getCenterPoint();
    center.x *= $.width(window) / G.mp.maker.CANVAS_WIDTH;
    center.y *= $.height(window) / G.mp.maker.CANVAS_HEIGHT;
    const view_center = { x: $.width(window) / 2, y: $.height(window) / 2 };
    const view_center_height = ($.height(window) - extra_height) / 2;

    return anime(
      _p.extend(
        _p.defaults(
          {
            targets: [zoom_wrapper],
            translateX: view_center.x - center.x,
            translateY: view_center_height - center.y,
            transformOrigin: `${center.x}px ${center.y}px 0px`,
            // scale: scale,
            easing: easing2,
            duration: duration2,
          },
          _p.omit(G.mp.maker.zoom_attr, 'offset'),
        ),
        option,
      ),
    ).finished;
  }

  async function by_active3(active, extra_height, option) {
    const zoom_wrapper = $1('.canvas_container.selected .canvas_zoom_wrapper');
    set_zoom_attr(zoom_wrapper);
    const center = active.getCenterPoint();
    center.x *= $.width(window) / G.mp.maker.CANVAS_WIDTH;
    center.y *= $.height(window) / G.mp.maker.CANVAS_HEIGHT;

    return anime(
      _p.extend(
        _p.defaults(
          {
            targets: [zoom_wrapper],
            filter: 'blur(10px)',
            // scale: scale,
            easing: easing2,
            duration: duration2,
          },
          _p.omit(G.mp.maker.zoom_attr, 'offset'),
        ),
        option,
      ),
    ).finished;
  }

  function get_transformOrigin() {
    const v = anime.getValue(get_zoom_wrapper(), 'transformOrigin').split(' ');
    return {
      x: parseFloat(v[0]),
      y: parseFloat(v[1]),
    };
  }

  function by_active1(active, extra_height, option) {
    active = active || G.mp.maker.cv_print_area();
    const view_center_height = ($.height(window) - extra_height) / 2;
    const zoom_wrapper = $1('.canvas_container.selected .canvas_zoom_wrapper');
    set_zoom_attr(zoom_wrapper);
    let translateY = active.getCenterPoint().y;
    translateY *= $.height(window) / G.mp.maker.CANVAS_HEIGHT;
    const center = active.getCenterPoint();
    center.x *= $.width(window) / G.mp.maker.CANVAS_WIDTH;
    center.y *= $.height(window) / G.mp.maker.CANVAS_HEIGHT;

    return anime(
      _p.extend(
        _p.defaults(
          {
            targets: [zoom_wrapper],
            translateY: view_center_height - translateY,
            easing: easing2,
            transformOrigin: `${get_transformOrigin().x}px ${center.y}px 0px`,
            duration: duration2,
          },
          _p.omit(G.mp.maker.zoom_attr, 'offset'),
        ),
        option,
      ),
    ).finished;
  }

  G.mp.maker.zoom.by_active1 = by_active1;
  G.mp.maker.zoom.by_active2 = by_active2;
  G.mp.maker.zoom.by_active3 = by_active3;
  function canvas_container_display_mode(is_no_duration) {
    const canvas_container_selected = $1('.canvas_container.selected');
    const inner_canvas_container = $.find1(canvas_container_selected, '.canvas_zoom_wrapper');
    const face_length = G.mp.maker.get_bpcfs_length();
    const half_width = $.width(inner_canvas_container) / 2;
    const half_height = $.height(inner_canvas_container) / 2;

    return _p.go(
      _p.range(face_length),
      _p.map(function (v) {
        return $('.canvas_zoom_wrapper')[v];
      }),
      _p.cmap(function (canvas_zoom_wrapper, i) {
        const { translateX, translateY, scale } = makeProductDetailMakerCanvasSize(
          box.sel('maker->canvass')[i].size_info,
        );
        if (canvas_zoom_wrapper === inner_canvas_container) {
          return anime({
            targets: [inner_canvas_container],
            translateY: translateY,
            translateX: translateX,
            scale: scale,
            transformOrigin: `${half_width}px ${half_height}px 0`,
            duration: is_no_duration ? 0 : duration,
            easing: easing,
          }).finished;
        } else {
          return anime({
            targets: [canvas_zoom_wrapper],
            translateX: translateX,
            translateY: translateY,
            transformOrigin: `${half_width}px ${half_height}px 0`,
            scale: scale,
            duration: 0,
          }).finished;
        }
      }),
      function () {
        G.mp.maker.zoom.zoomIsEnded();
      },
    );
  }

  G.mp.maker.zoom.canvas_container_display_mode = canvas_container_display_mode;

  G.mp.maker.zoom.toggle = function () {
    const canvas = G.mp.maker.editing_canvas();
    G.mp.maker.zoom(!canvas.zoomed);
  };
})({});
