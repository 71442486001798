import dataStr from 'fxdom/es/dataStr.js';
import { ippL, sortBy, filter, go, html, map, sel, strMap, join } from 'fxjs/es';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { UserProductS } from '../../../../../UserProduct/S/Function/module/UserProductS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCartTmplS } from '../../../../Cart/S/Tmpl/module/OMPCartTmplS.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPMyPageOrderS } from '../../../Order/S/Function/module/OMPMyPageOrderS.js';

const wishUserProductColor = (up_c, is_last) => {
  const thumbnail = {
    product_face: sel('_.product_color.product_faces2.value.0', up_c),
    // thumbnail_url: go(sel('_.product_color.thumbnails.value', up_c), find(sel('is_thumb')), sel('url')),
  };
  const { is_bps_hidden } = up_c._.product_color._.base_product;

  const name = `${up_c._.product_color._.base_product['name' + G._en]} `;
  const select_option_name = up_c._.product_color._.selected_option_group['title' + G._en];
  const sizes = map(
    (up_c_s) => ({
      _is_not_stock: up_c_s._is_not_stock,
      _is_discontinued: up_c_s._is_discontinued,
      size: select_option_name || up_c_s['_name' + G._en],
      quantity: UtilS.commify(up_c_s.quantity),
    }),
    filter((up_c_s) => up_c_s.quantity, up_c._.up_c_ss),
  );

  const product_color = up_c._.product_color;
  const base_product_color = product_color._.base_product_color;
  const base_product = product_color._.base_product;
  const product_faces2 = product_color.product_faces2;

  const selected_option_group = product_color._.selected_option_group;
  const all_option_names = go(
    UserProductS.naming.makeOptionNames1(
      {
        selected_option_group,
        base_product,
        base_product_color,
        product_faces2,
      },
      '',
    ),
    sortBy((option) => option.option_group_name === '사이즈'),
  );

  const option_names = go(
    all_option_names,
    ippL,
    filter(([idx]) => {
      const is_last = idx + 1 === all_option_names.length;
      return UtilS.isEmpty(selected_option_group?.option_names) ? true : !is_last;
    }),
    map(([, option]) => option),
  );

  const [last_option_name] = [...all_option_names].reverse();
  const [first_size] = sizes;
  const last_option_el =
    !UtilS.isEmpty(selected_option_group?.option_names) && last_option_name
      ? html`<span class="key">${last_option_name.option_group_name}</span>
          <div class="omp-cart__product-size-set-wrapper">
            ${OMPCartTmplS.cartSizeOptionTmpl({ name: last_option_name.option_name, size: first_size })}
          </div>`
      : '';

  const product_detail_url = OMPMyPageOrderS.makeUpcUrl(up_c);

  const { pdf_printing_file } = up_c;

  const has_pdf_printing_file = !UtilS.isEmpty(pdf_printing_file);
  const pdf_printing_file_el = has_pdf_printing_file
    ? OMPCartTmplS.cartPrintingFileTmpl({ pdf_printing_file, label: true })
    : '';
  const pdf_file_expiration = has_pdf_printing_file
    ? OMPCoreUtilS.isExpired({ expired_at: pdf_printing_file?.expired_at })
    : false;

  return html`
    <div
      class="omp-cart__product omp-my-wish__buy-later__product ${is_last ? 'is_last' : ''}"
      ${OMPCoreUtilS.createDataSet(up_c?.table_data)}
    >
      <div class="omp-cart__product-id">${OMPCartTmplS.cartProductNumberColumnTmpl(up_c)}</div>
      <div class="omp-cart__product-info-wrapper">
        <a
          href="${product_detail_url}"
          class="omp-cart__product-thumbnail"
          data-fx-json="${!thumbnail.thumbnail_url && thumbnail.product_face
            ? dataStr(thumbnail.product_face)
            : ''}"
        >
          ${pdf_file_expiration
            ? `<div class="expiration">${html`${TT('pdf::expired::1')}`}</div>`
            : thumbnail.thumbnail_url
            ? html` <img src="${thumbnail.thumbnail_url}" alt="" /> `
            : html`${UserProductS.thumbnail.smallCanvasHtml()}`}
        </a>
        <div class="omp-cart__product-info">
          <div class="omp-cart__product-name">
            <a href="${product_detail_url}">${name}</a>
            <button type="button" class="omp-my-wish__product-delete">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M2.5 2.5L17.5 17.5M2.5 17.5L17.5 2.5"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
          <div class="omp-cart__product-options">
            ${go(
              option_names,
              map(({ option_group_name: key, option_name: name }) =>
                OMPCartTmplS.cartOptionTmpl({ key, name }),
              ),
              join(''),
            )}
            ${last_option_el}
            ${UtilS.isEmpty(selected_option_group?.option_names) && !is_bps_hidden
              ? html`<span class="key">${TT('my_page::order::detail::detail_06')}</span>
                  <div class="omp-cart__product-size-set-wrapper">
                    ${strMap(
                      ({ size, quantity, _is_not_stock, _is_discontinued }) =>
                        OMPCartTmplS.cartSizeOptionTmpl({
                          name: size,
                          size: { quantity, _is_not_stock, _is_discontinued },
                        }),
                      sizes,
                    )}
                  </div>`
              : ''}
            ${pdf_printing_file_el}
          </div>
        </div>
      </div>
      <div class="omp-cart__product-price">${OMPCartTmplS.cartProductPriceColumnTmpl(up_c)}</div>
      <div class="omp-cart__product-edit-wrapper">
        <div class="omp-cart__product-edit-set">
          <button class="omp-my-wish__add-to-cart">${TT('my_page::wish::wish_11')}</button>
        </div>
      </div>
    </div>
  `;
};

export const wishUserProduct = (up_cs) => {
  return html`
    <div class="omp-cart__product-container">
      ${strMap(
        ([index, up_c]) => wishUserProductColor(up_c, index + 1 === up_cs.length),
        zipWithIndex(up_cs),
      )}
    </div>
  `;
};
