import { format } from 'date-fns';
import dataStr from 'fxdom/es/dataStr.js';
import { filter, go, html, ippL, join, map, sel, sortBy, strMap } from 'fxjs/es';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { isNeedPreview } from '../../../../Maker/S/categorize.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { NewMakerWeS } from '../../../../NewMaker/We/S/Function/module/NewMakerWeS.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { UserProductS } from '../../../../UserProduct/S/Function/module/UserProductS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPMyPageOrderS } from '../../../MyPage/Order/S/Function/module/OMPMyPageOrderS.js';
import { OMPCartS } from '../Function/module/OMPCartS.js';
import { OMPCartTmplS } from './module/OMPCartTmplS.js';

export const cartUserProductColor = (up_c, is_last, up_cs) => {
  const thumbnail = {
    product_face: sel('_.product_color.product_faces2.value.0', up_c),
    // thumbnail_url: go(sel('_.product_color.thumbnails.value', up_c), find(sel('is_thumb')), sel('url')),
  };
  const name = `${up_c._.product_color._.base_product['name' + G._en]} `;

  const sizes = map(
    (up_c_s) => ({
      _is_not_stock: up_c_s._is_not_stock,
      _is_discontinued: up_c_s._is_discontinued,
      size: up_c_s['_name' + G._en],
      quantity: UtilS.commify(up_c_s.quantity),
    }),
    filter((up_c_s) => up_c_s.quantity, up_c._.up_c_ss),
  );

  const { is_bps_hidden } = up_c._.product_color._.base_product;

  const product_color = up_c._.product_color;
  const base_product_color = product_color._.base_product_color;
  const base_product = product_color._.base_product;
  const product_faces2 = product_color.product_faces2;
  const selected_option_group = product_color._.selected_option_group;
  const all_option_names = go(
    UserProductS.naming.makeOptionNames1(
      {
        selected_option_group,
        base_product,
        base_product_color,
        product_faces2,
      },
      '',
    ),
    sortBy((option) => option.option_group_name === '사이즈'),
  );

  const option_names = go(
    all_option_names,
    ippL,
    filter(([idx]) => {
      const is_last = idx + 1 === all_option_names.length;
      return UtilS.isEmpty(selected_option_group?.option_names) ? true : !is_last;
    }),
    map(([, option]) => option),
  );

  const [last_option_name] = [...all_option_names].reverse();
  const [first_size] = sizes;
  const last_option_el =
    !UtilS.isEmpty(selected_option_group?.option_names) && last_option_name
      ? html` <span class="key">${last_option_name.option_group_name}</span>
          <div class="omp-cart__product-size-set-wrapper">
            ${OMPCartTmplS.cartSizeOptionTmpl({ name: last_option_name.option_name, size: first_size })}
          </div>`
      : '';

  const { price, discounted_price } = OMPMyPageOrderS.makeUpcProductPrice(up_c);

  const product_detail_url = OMPMyPageOrderS.makeUpcUrl(up_c);

  const { pdf_printing_file } = up_c;
  const has_pdf_printing_file = !UtilS.isEmpty(pdf_printing_file);
  const pdf_printing_file_el = has_pdf_printing_file
    ? OMPCartTmplS.cartPrintingFileTmpl({ pdf_printing_file, label: true })
    : '';
  const pdf_file_expiration = has_pdf_printing_file
    ? OMPCoreUtilS.isExpired({ expired_at: pdf_printing_file?.expired_at })
    : false;

  const base_product_colors = up_c._.product_color._.base_product._.base_product_colors;

  const disabled =
    NewMakerWeS.isWeBaseProduct(up_cs[0].base_product_id) ||
    up_cs[0]._.product_color?._?.base_product_color?.name === '' ||
    isNeedPreview(up_cs[0]._.product_color._.base_product) ||
    base_product_colors.length === 1 ||
    !!up_cs[0]._.product_color._.base_product?.maker_features?.[
      NewMakerPropertyBaseProductConstantS.NOT_SHOW_ADD_COLOR
    ];

  return html`
    <div
      class="omp-cart__product ${is_last ? 'is_last' : ''}"
      ${OMPCoreUtilS.createDataSet(up_c?.table_data)}
    >
      <div class="omp-cart__product-header">
        <a href="${product_detail_url}" class="omp-cart__product-id">
          <span class="omp-cart__product-number">${format(new Date(up_c.created_at), 'yyyy-MM-dd')}</span>
          <span class="omp-cart__product-number">${TT('cart::cart_09', { no: up_c.id })}</span>
        </a>
        <button type="button" class="omp-cart__product-delete">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M1 1L13 13M1 13L13 1" stroke="#858585" stroke-width="1.2" stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <div class="omp-cart__product-info-wrapper">
        <a
          href="${product_detail_url}"
          class="omp-cart__product-thumbnail"
          data-fx-json="${!thumbnail.thumbnail_url && thumbnail.product_face
            ? dataStr(thumbnail.product_face)
            : ''}"
        >
          ${pdf_file_expiration
            ? `<div class="expiration">${html`${TT('pdf::expired::1')}`}</div>`
            : thumbnail.thumbnail_url
            ? html` <img src="${thumbnail.thumbnail_url}" alt="" /> `
            : html`${UserProductS.thumbnail.smallCanvasHtml()}`}
        </a>
        <div class="omp-cart__product-info">
          <div class="omp-cart__product-name">
            <a href="${product_detail_url}">${name}</a>
          </div>
          <div class="omp-cart__product-options">
            ${go(
              option_names,
              map(({ option_group_name: key, option_name: name }) => {
                return OMPCartTmplS.cartOptionTmpl({
                  key,
                  name,
                });
              }),
              join(''),
            )}
            ${last_option_el}
            ${UtilS.isEmpty(selected_option_group?.option_names) && !is_bps_hidden
              ? html` <span class="key">${TT('my_page::order::detail::detail_06')}</span>
                  <div class="omp-cart__product-size-set-wrapper">
                    ${strMap(
                      ({ size, quantity, _is_not_stock, _is_discontinued }) =>
                        OMPCartTmplS.cartSizeOptionTmpl({
                          name: size,
                          size: { _is_not_stock, quantity, _is_discontinued },
                        }),
                      sizes,
                    )}
                  </div>`
              : ''}
            ${pdf_printing_file_el}
          </div>
        </div>
      </div>
      <div class="omp-cart__product-price">
        ${price === discounted_price
          ? html` <div>${TT('common::currency', { price })}</div> `
          : html`
              <div class="discounted">${TT('common::currency', { price })}</div>
              <div>${TT('common::currency', { price: discounted_price })}</div>
            `}
      </div>
      <div class="omp-cart__product-edit-wrapper">
        <div class="omp-cart__product-edit-set">
          <button type="button" class="omp-cart__product-edit">${TT('cart::cart_20')}</button>
          <button type="button" class="omp-cart__product-save">${TT('cart::cart_21')}</button>
        </div>
      </div>
      <div class="omp-cart__footer omp-cart__add-color-wrapper" data-up_id="${up_cs[0].up_id}">
        <div class="omp-cart__add-color" data-disabled="${disabled}">
          <div class="omp-cart__add-color-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
              <path
                d="M1.5 6H6.5M6.5 6H11.5M6.5 6V11M6.5 6V1"
                stroke="#FF6B00"
                stroke-width="1.25"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div>${TT('cart::cart_11')}</div>
        </div>
      </div>
    </div>
  `;
};

const cartUserProduct = (up_cs) =>
  html`
    <div class="omp-cart__product-container">
      ${strMap(
        ([index, up_c]) => cartUserProductColor(up_c, index + 1 === up_cs.length, up_cs),
        zipWithIndex(up_cs),
      )}
    </div>
  `;

export const cartTmplMo = ({ user_products, price_info, shipping_price }) => {
  const up_cs_count = TT('cart::cart_12');
  const total_product_price = TT('cart::cart_13');
  const total_shipping = TT('cart::cart_14');
  const total_price = TT('cart::cart_15');
  const empty_notice = TT('cart::cart_18');
  const empty_desc = TT('cart::cart_19');
  const delivery_fee_notice = TT('cart::cart_29');

  const up_cs = OMPCartS.processUserProductsForCartTable(user_products);
  const is_oversea = TT.lang !== 'kr';

  const is_free_shipping_price_kr = 100000 - price_info.product_price;

  return html`
    <div class="omp-cart">
      ${!user_products.length
        ? html`
            <div class="omp-cart__empty">
              <div class="omp-cart__empty-notice">${empty_notice}</div>
              <div class="omp-cart__empty-desc">${empty_desc}</div>
            </div>
          `
        : html`
            <div class="omp-cart__product-wrapper">${strMap(cartUserProduct, up_cs)}</div>
            <div class="omp-cart__summary">
              <div class="omp-cart__summary-body">
                <div class="omp-cart__summary-value">
                  <span class="omp-cart__summary-value-title">${up_cs_count}</span>
                  <span class="omp-cart__summary-body-value">
                    ${TT('common::quantity', { quantity: UtilS.commify(price_info.up_cs_count) })}
                  </span>
                </div>
                <div class="omp-cart__summary-value">
                  <span class="omp-cart__summary-value-title">${total_product_price}</span>
                  <span class="omp-cart__summary-body-value">
                    ${TT('common::currency', { price: PriceS.pricify(price_info.product_price) })}
                  </span>
                </div>
                <div class="omp-cart__summary-value total-shipping">
                  <span class="omp-cart__summary-value-title">${total_shipping}</span>
                  <span class="omp-cart__summary-body-value">
                    ${TT('common::currency', { price: PriceS.pricify(shipping_price) })}
                  </span>
                </div>
                <div class="omp-cart__summary-value total">
                  <span class="omp-cart__summary-value-title">${total_price}</span>
                  <span class="omp-cart__summary-body-value" data-style_total="true">
                    ${TT('common::currency', {
                      price: PriceS.pricify(PriceS.add(price_info.product_price, shipping_price)),
                    })}
                    ${is_oversea ? T('mshop_cart_and_checkout::배송비추가') : ``}
                  </span>
                </div>
                <div class="omp-cart__summary-value delivery-fee-info">
                  <span class="omp-cart__summary-value-info">${delivery_fee_notice}</span>
                </div>
                ${is_oversea
                  ? ``
                  : html` <div class="omp-cart__shipping-price-notice-wrapper">
                      <div class="omp-cart__shipping-price-notice">
                        <span class="omp-cart__shipping-price-notice-desc">
                          ${is_free_shipping_price_kr <= 0
                            ? '무료로 배송해 드려요!'
                            : html` ${UtilS.commify(is_free_shipping_price_kr)}원 더 담으면
                                <span class="og">배송비 무료</span>`}
                        </span>
                        ${is_free_shipping_price_kr <= 0
                          ? ''
                          : html` <a
                              href="/${TT.lang}/best-products"
                              class="omp-cart__shipping-price-notice-more-link"
                            >
                              <div class="omp-cart__shipping-price-notice-more">
                                ${OMPCoreAtomTmplS.plusIcon()}
                              </div>
                              <span> 더 담으러 가기 </span>
                            </a>`}
                      </div>
                    </div>`}
              </div>
              <div class="omp-cart__button">
                ${OMPCoreAtomTmplS.actionButton({
                  klass: 'omp-cart__checkout',
                  text: TT('cart::cart_16'),
                })}
              </div>
            </div>
          `}
    </div>
  `;
};
