import { filter, find, go, html, sel, strMap, pluck, last } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { checkedBox, questionMark, unCheckedBox } from '../../../OMP/Core/Atom/S/Tmpl/icon.js';
import { BpOptionUploadTypeS } from '../../UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { BpOptionConstantS } from '../Constant/module/BpOptionConstantS.js';
import { BpOptionS } from '../Function/module/BpOptionS.js';

const makeQuestionMark = (bp_option_group) => html`<span
  class="question_mark"
  data-additional_information_type="${bp_option_group.additional_information.type}"
  ,
  data-fx-json="${$dataStr(bp_option_group.additional_information)}"
  >${questionMark()}</span
>`;
export const additionalInfoHtml = (is_mobile, bp_option_group) => {
  return html`<span> ${bp_option_group['name' + G._en]} </span>
    <div class="additional-information">${makeQuestionMark(bp_option_group)}</div>`;
};

const makeCheckBoxTypeHtml = (bp_option_group, bp_options, selected_bp_option_id) => {
  const is_checked = go(
    bp_options,
    find(({ id }) => id === selected_bp_option_id),
    (option) => option.no === 1,
  );
  return html`
    <label class="bp_checkbox_option" data-is_checked=${is_checked}>
      ${is_checked ? checkedBox() : unCheckedBox()} ${bp_option_group['name' + G._en]}
    </label>
    ${bp_option_group.additional_information !== null ? makeQuestionMark(bp_option_group) : ''}
  `;
};
const bpOptionGroupsHtmlArr = ({
  selected_bp_option_id,
  base_products_bp_option_group,
  bp_option_group,
  bp_options,
  is_designed,
  is_editor_not_ready,
  is_mobile,
}) => {
  const { can_change, default_bp_option_id } = base_products_bp_option_group;
  const { is_visibility, is_name, is_lockable, is_virtual, name, name_en, name_jp, dev_name, id } =
    bp_option_group;
  let is_disabled;

  return html`
    <div
      class="bp_option_group"
      data-id="${id}"
      data-_default_bp_option_id="${default_bp_option_id}"
      data-is_lockable="${is_lockable}"
      data-dev_name="${dev_name}"
      data-is_virtual="${is_virtual}"
      data-is_name="${is_name}"
      data-can_change="${can_change}"
      data-is_visibility="${is_visibility}"
      data-name="${name}"
      data-name_en="${name_en}"
      data-name_jp="${name_jp}"
      data-ui_type_pc="${bp_option_group.ui_type_pc}"
      data-ui_type_mobile="${bp_option_group.ui_type_mobile}"
      data-additonal_information="${$dataStr(bp_option_group?.additional_information)}"
      data-is_full_width="${bp_option_group.is_full_width}"
    >
      <div class="title">
        ${bp_option_group.ui_type_pc === BpOptionConstantS.UI_TYPE.CHECKBOX
          ? makeCheckBoxTypeHtml(bp_option_group, bp_options, selected_bp_option_id)
          : bp_option_group.additional_information !== null
          ? additionalInfoHtml(is_mobile, bp_option_group)
          : bp_option_group['name' + G._en]}
      </div>
      ${bp_option_group.dev_name === BpOptionUploadTypeS.UPLOAD_TYPE.dev_name
        ? BpOptionUploadTypeS.UPLOAD_TYPE.customSelectBoxHtml(selected_bp_option_id, is_editor_not_ready)
        : bp_option_group.ui_type_pc === BpOptionConstantS.UI_TYPE.CHECKBOX
        ? ''
        : html`<div class="select_box">
            ${strMap(
              (bp_option) =>
                html`
                  <div
                    data-is_public="${bp_option.is_public}"
                    class="option ${selected_bp_option_id === bp_option.id ? 'selected' : ''}"
                    data-id="${bp_option.id}"
                  >
                    ${bp_option['name' + G._en]}
                  </div>
                `,
            )(bp_options)}
          </div>`}

      <select
        name="${bp_option_group.id}"
        data-fx-json="${$dataStr(bp_option_group)}"
        data-id="${bp_option_group.id}"
        data-name="${bp_option_group.name}"
        data-name_en="${bp_option_group.name_en}"
        data-name_jp="${bp_option_group.name_jp}"
        ${is_disabled || (is_designed && is_lockable) ? 'disabled' : ''}
      >
        ${strMap(
          (bp_option) =>
            html`
              <option
                data-id="${bp_option.id}"
                data-fx-json="${$dataStr(bp_option)}"
                data-dev_name="${bp_option.dev_name}"
                data-is_public="${bp_option.is_public}"
                data-name="${bp_option.name}"
                data-name_en="${bp_option.name_en}"
                data-name_jp="${bp_option.name_jp}"
                data-no="${bp_option.no}"
                value="${bp_option.id}"
                ${selected_bp_option_id === bp_option.id ? 'selected' : ''}
              >
                ${bp_option['name' + G._en]}
              </option>
            `,
        )(bp_options)}
      </select>
    </div>
  `;
};

export const getNeedFullWidthOptionGroup = (base_products_bp_option_groups) => {
  return go(
    base_products_bp_option_groups,
    pluck('_'),
    pluck('bp_option_group'),
    filter(
      (bp_option_group) =>
        bp_option_group.is_visibility &&
        bp_option_group.ui_type_mobile !== BpOptionConstantS.UI_TYPE.BUTTON &&
        bp_option_group.ui_type_mobile !== BpOptionConstantS.UI_TYPE.CHECKBOX &&
        bp_option_group.ui_type_mobile !== BpOptionConstantS.UI_TYPE.CUSTOM,
    ),
    (bp_option_group) => {
      if (bp_option_group.length % 2 !== 0) {
        return last(bp_option_group);
      }
    },
  );
};
export const bpAllOptions = ({ product, selected_option_group, is_mobile }) => {
  const is_designed = BpOptionS.isDesigned(product);
  selected_option_group = selected_option_group || product?._?.selected_option_group;
  const base_products_bp_option_groups = product._.base_product._.base_products_bp_option_groups;
  const is_editor_not_ready = go(
    BpOptionUploadTypeS.UPLOAD_TYPE.hasUploadType(base_products_bp_option_groups) &&
      BpOptionUploadTypeS.UPLOAD_TYPE_DIRECT.getFileType(product._.base_product),
    (upload_file_type) => upload_file_type?.is_editor_not_ready,
  );

  const full_width_option_group = getNeedFullWidthOptionGroup(base_products_bp_option_groups);

  return html`
    <div class="bp_option_groups" data-is_designed="${is_designed}">
      ${go(
        base_products_bp_option_groups,
        strMap((base_products_bp_option_group) => {
          const bp_option_group = base_products_bp_option_group._.bp_option_group;
          bp_option_group.is_full_width = bp_option_group.id === full_width_option_group?.id;

          const bp_options = bp_option_group._.bp_options;
          const selected_bp_option_id =
            go(
              bp_option_group._.bp_options,
              find(
                ({ id }) =>
                  selected_option_group?.bp_option_ids?.length &&
                  selected_option_group.bp_option_ids.includes(id),
              ),
              sel('id'),
            ) || base_products_bp_option_group.default_bp_option_id;

          return bpOptionGroupsHtmlArr({
            selected_bp_option_id,
            base_products_bp_option_group,
            bp_option_group,
            bp_options,
            is_designed,
            is_editor_not_ready,
            is_mobile,
          });
        }),
      )}
    </div>
  `;
};
