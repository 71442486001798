import { delay, each, find, go, map, sel } from 'fxjs/es';
import { $addClass, $closest, $find, $findAll, $qs, $removeClass } from 'fxdom/es';
import { BpOptionS } from '../../S/Function/module/BpOptionS.js';
import { BpOptionF } from './module/BpOptionF.js';
import {
  getBaseProductFacesInMaker,
  getBaseProductInMaker,
  getProductColorInMaker,
} from '../../../Maker/F/getSth.js';
import { BpOptionConstantS } from '../../S/Constant/module/BpOptionConstantS.js';
import { NewMakerCvObjActionF } from '../../../NewMaker/CvObjAction/F/Function/module/NewMakerCvObjActionF.js';
import { NewMakerMaskingTapeF } from '../../../NewMaker/MaskingTape/F/Function/module/NewMakerMaskingTapeF.js';
import { makeBpcfAvgSize } from '../../../Maker/F/canvas_size.js';
import { makeFakeMpMakerSize } from '../../../Maker/F/fake_mp_maker.js';
import { VectorEditorConstantS } from '../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { BpOptionKeyRingConstantS } from '../../KeyRing/S/Constant/module/BpOptionKeyRingConstantS.js';
import { stickerChangePreview } from '../../../SVGEditor/ProductDetail/F/Function/fs.js';
import { changeBpByBpidBasic } from '../../../Maker/F/change_bp.js';
import { NewMakerBpOptionF } from '../../../NewMaker/BpOption/F/Function/module/NewMakerBpOptionF.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { BpOptionUploadTypeS } from '../../UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { BpDiscountSetConstantS } from '../../../BpDiscountSet/S/Constant/module/BpDiscountSetConstantS.js';
import { BpDiscountSetWowBpDiscountSetS } from '../../../BpDiscountSet/WowBpDiscountSet/S/Function/module/BpDiscountSetWowBpDiscountSetS.js';
import { marpplizerProxy } from '../../../Maker/F/Marpplizer/marpplizer.js';
import axios from 'axios';
import { renderMpMakerProductPrice } from '../../../Maker/F/Product/product_tmpl.js';
import { NewMakerPropertyBaseProductF } from '../../../NewMaker/Property/BaseProduct/F/Function/module/NewMakerPropertyBaseProductF.js';
import { setIScroll } from '../../../Maker/F/Marpplizer/fs.js';
import { BpDiscountSetS } from '../../../BpDiscountSet/S/Function/module/BpDiscountSetS.js';
import { renderFacesByCurrentBpcfsInMakerA } from '../../../Maker/F/mp_maker.js';
import { NewMakerPropertyBpfF } from '../../../NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';
import { MakerDebugF } from '../../../MakerDebug/F/Function/module/MakerDebugF.js';
import { MakerDebugConstantS } from '../../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import { NewMakerUtilF } from '../../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';

export const selectBpOption = ({ bp_option_group_el, selected_bp_option_id }) => {
  go(
    bp_option_group_el,
    $findAll('.select_box .option'),
    each($removeClass('selected')),
    find((el) => {
      return el.dataset.id === selected_bp_option_id;
    }),
    $addClass('selected'),
  );
  go(bp_option_group_el, $find('select'), (select_el) => {
    select_el.value = selected_bp_option_id;
  });
};
function resetBizQuantity(product_color, bp_discount_ranges) {
  if (!bp_discount_ranges?.length) return;
  const biz_quantity = product_color.product_faces2?.biz?.quantity;
  if (biz_quantity) {
    const bp_discount_range = BpDiscountSetS.getBpDiscountRangeByQuantity(biz_quantity, bp_discount_ranges);
    if (!bp_discount_range) {
      product_color.product_faces2.biz.quantity = bp_discount_ranges[0]?.start;
    }
    if (product_color.product_faces2.biz.moq !== bp_discount_ranges[0]?.start) {
      product_color.product_faces2.biz.moq = bp_discount_ranges[0]?.start;
    }
  }
}

export const processAfterSelect = async (select_el, is_error) => {
  const bp_option_groups_el = $closest('.bp_option_groups')(select_el);

  const selected_bp_option_id = select_el.value;
  const product_color = getProductColorInMaker();

  const product = getProductColorInMaker();
  const selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);
  if (product_color._.base_product._?.bp_discount_set?.type === BpDiscountSetConstantS.TYPE.MP_WOW) {
    try {
      await BpDiscountSetWowBpDiscountSetS.setVirtualBpDiscountSet({
        product_color,
        selected_bp_option_ids: selected_option_group.bp_option_ids,
        getSizePrices: ({ bp_id, bp_option_ids }) => {
          return go(
            axios.get(`/${T.lang}/@api/wow_press/quantities_prices`, {
              params: { bp_id, bp_option_ids },
            }),
            sel('data'),
          );
        },
      });
      resetBizQuantity(product_color, product_color._.base_product._.bp_discount_set._.bp_discount_ranges);
    } catch (e) {
      if (is_error) {
        MakerDebugF.api.MakerDebugPost(
          getProductColorInMaker(),
          e,
          MakerDebugConstantS.Category.BpOption,
          'bp_option_reset_biz_quantity',
        );
        await NewMakerUtilF.alert({ content: T('error_alert::reload'), msg_width: 300 });
        location.reload();
      }
      await delay(2000)();
      await processAfterSelect(select_el, true);
    }
  }
  product._.selected_option_group = selected_option_group;
  if (getBaseProductInMaker().maker_type == BpOptionConstantS.MASKING_TAPE_EDITOR) {
    await NewMakerCvObjActionF.render({ bp_option_ids: [parseInt(selected_bp_option_id)] });
    await NewMakerMaskingTapeF.resetCloneHorizontalCvObj();
    G.mp.maker.bpcf_size = makeBpcfAvgSize();
    makeFakeMpMakerSize();
    G.mp.maker.zoom.canvas_container_display_mode(true);
  } else if (getBaseProductInMaker().maker_type == VectorEditorConstantS.KEYRING_EDITOR) {
    await NewMakerCvObjActionF.render({ bp_option_ids: [parseInt(selected_bp_option_id)] });
    G.mp.maker.bpcf_size = makeBpcfAvgSize();
    if (
      UtilF.isLegacyMobile() &&
      product._.selected_option_group.bp_option_ids.includes(
        BpOptionKeyRingConstantS.KEYRING_NO_GORI_BP_OPTION_ID,
      )
    ) {
      G.mp.maker.bpcf_size = {
        top: 207.12209302325581,
        left: 132.99418604651163,
        height: 239.38953488372093,
        bottom: 220.63953488372093,
        width: 110.75581395348837,
      };
    }
    makeFakeMpMakerSize();
    G.mp.maker.zoom.canvas_container_display_mode(true);
  } else if (getBaseProductInMaker().maker_type == VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR) {
    const preview_images = BpOptionS.selectedOptionGroup.getPreviewImagesInMaker(
      box().maker.product_color._.base_product._.base_products_bp_option_groups,
      selected_option_group,
    );
    if (bp_option_groups_el.dataset.is_designed !== 'true') {
      if (preview_images?.length) {
        await NewMakerBpOptionF.addPreviewImage(preview_images);
      }
    }
  } else {
    if (bp_option_groups_el.dataset.is_designed !== 'true') {
      await stickerChangePreview(bp_option_groups_el);
      /* 스티커만 해당 */
    }
    await NewMakerCvObjActionF.render({ bp_option_ids: [parseInt(selected_bp_option_id)] });
    /* 레거시 */
  }
  const base_products_bp_option_groups =
    box().maker.product_color._.base_product._.base_products_bp_option_groups;
  await BpOptionF.makeSelectedOptionPriceC2323({
    base_products_bp_option_groups,
    bp_option_groups_el,
    selected_option_group,
  });

  const need_render_all_faces = NewMakerPropertyBpfF.bpOptionLayers.hasBpOptionLayers(
    getBaseProductFacesInMaker(),
  );

  if (need_render_all_faces) await renderFacesByCurrentBpcfsInMakerA();

  G.mp.maker.reset_box_data();
  if (BpOptionUploadTypeS.UPLOAD_TYPE.hasUploadType(base_products_bp_option_groups)) {
    const mp_maker_el = $qs('#maker_frame');
    mp_maker_el.dataset.maker_upload_type = BpOptionUploadTypeS.UPLOAD_TYPE.getUploadType(
      base_products_bp_option_groups,
      selected_option_group?.bp_option_ids,
    );
    await marpplizerProxy();
  }
  G.mp.maker.reset_box_data();
  renderMpMakerProductPrice();
  NewMakerPropertyBaseProductF.bpOptionPreviews.renderElement(product);
  const activity_el = $closest('.activity', bp_option_groups_el);
  if (activity_el) {
    if (!UtilF.isLegacyMobile()) setIScroll(activity_el);
  }
};

export const changeVirtualSelect = async (bp_option_groups_el) => {
  await go(
    bp_option_groups_el,
    $findAll('.bp_option_group[data-is_virtual="true"] select'),
    map((select_el) => select_el.value),
    (bp_virtual_option_ids) =>
      $.post(`/@api/svg_editor/redirect_bp_id`, {
        bp_virtual_option_ids,
      }),
    async (data) => {
      if (!data?.base_product_id) throw new Error('network_error');
      if ($qs('#dream_factory') || $qs('.don_frame[frame_name="maker.design_collection"]')) {
        $.don_loader_start();
        await changeBpByBpidBasic(data.base_product_id);
        $.don_loader_end();
      } else {
        if (window.location.search?.includes('lang_col')) {
          const params = new URLSearchParams(location.search);
          params.set('bp_id', data.base_product_id);
          params.set('lang_col', T.lang);
          window.location.search = `?${params.toString()}`;
        } else {
          const params = new URLSearchParams(location.search);
          params.set('bp_id', data.base_product_id);
          params.delete('pc_id');
          window.location.search = `?${params.toString()}`;
        }
      }
    },
  );
};
