import { NewMakerPropertyBpOptionConstantS } from '../Constant/module/NewMakerPropertyBpOptionConstantS.js';
import { filter, find, go, map, sortBy } from 'fxjs/es';
import { BpOptionS } from '../../../../../BpOption/S/Function/module/BpOptionS.js';

export const dosuColors = {
  isUnlimited: ({ base_product, bpf_id }) => {
    const bp_option_group = dosuColors.getBpOptionGroup({ bpf_id, base_product });
    if (!bp_option_group) return;
    return [232, 251, 254, 256].includes(bp_option_group.id);
  },
  getBpOptionGroup: ({ base_product, bpf_id }) => {
    return BpOptionS.service.getBpfOptionGroupByDevName({
      bpf_id,
      base_product,
      dev_name: NewMakerPropertyBpOptionConstantS.DOSU_COLORS,
    });
  },
  getBpOptions: ({ bpf_id, base_product }) => {
    return go(dosuColors.getBpOptionGroup({ bpf_id, base_product }), BpOptionS.service.getBpOptions);
  },
  findBpOption: ({ bpf_id, base_product, color_code }) => {
    const color_options = dosuColors.getBpOptions({ bpf_id, base_product });
    const _color_code = color_code.toUpperCase();
    return go(
      color_options,
      find(({ dev_name }) => dev_name.toUpperCase() === _color_code),
    );
  },
  getHexCodes: ({ bpf_id, base_product }) => {
    const color_options = dosuColors.getBpOptions({ bpf_id, base_product });
    return go(
      color_options,
      sortBy((option) => option.no),
      map((co) => co.dev_name),
      filter((hex_code) => hex_code && hex_code.startsWith('#')),
      map((hex) => hex.split('**')[0]),
      map((hex) => hex.toUpperCase()), // 별색인 경우 / 뒤에 별색 이름이 올 수 있음
    );
  },
};
