import { go, strMap } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { isWorkerInMaker } from '../util.js';

export const makeLayerHtml = (designs) => legacyHtml`
  <div class="design_list iscroll_ul_wrapper design_list_ev">
    ${go(
      designs,
      strMap(
        (design) => legacyHtml`
          <div
            class="design i_item ${design.cv_object.active ? 'selected' : ''}"
            cid ="${design.cv_object.cid}"
            file_id="${design.cv_object._data.file_id ?? ''}"
            visible="${design.cv_object._data._visible || design.cv_object.visible}"
            is_overflow="${design.cv_object.is_overflow}"
            is_locked="${!design.cv_object.evented}"
          >
            <div class="img">
              <img src="${design.src || ''}" alt="" />
            </div>
            ${isWorkerInMaker() ? option_for_worker(design) : ''}
            <div class="options">
              <button class="hide"></button>
              <button class="locking"></button>
            </div>
          </div>
        `,
      ),
    )}
  </div>
`;

const option_for_worker = (design) => legacyHtml`
  <div class="info">
    <div class="rotation_info">
      <div class="rotate">${design.cv_object._data.rotate || 0}</div>
      <div class="width">${design.cv_object._data.width_cm + ' x ' + design.cv_object._data.height_cm}</div>
    </div>
    <div class="from_start_info">
      ${G.mp.maker.from_start_info(design.cv_object)} ${design.cv_object._data.is_embro ? '<b>패치</b>' : ''}
    </div>
  </div>
`;
