import { discountedPrice } from '../../../ProductColorPrice/S/fs.js';
import { UtilNumberS } from '../../../Util/Number/S/Function/module/UtilNumberS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const calcCrewMargins = (product, quantity, discount_rate) => {
  const discounted_price = discountedPrice(product, quantity, undefined, undefined, true).discounted_one;
  return Math.floor(discount_rate ? (discounted_price * discount_rate * quantity) / 100 : 0);
};

// 일단 USD 기준으로만, 추후에 환율 별로 소수점 몇 자리까지 바꿔야 하는지 체크 필요
export const exchangeCurrency = (adjustment) => {
  return UtilS.floorTo(
    -2,
    UtilNumberS.divDecimal(adjustment.profit + adjustment.bonus_profit, adjustment.exchange_rate),
  );
};
