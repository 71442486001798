import { html } from 'fxjs/es';

export const thumbnail = {
  smallCanvasHtml: () => {
    return html`<div
      style="width: 100% !important; height: 100% !important; position: relative"
      class="thumbnail_wrapper"
    >
      <canvas width="300" height="300"></canvas>
      <div class="don_loader_wrap2">
        <div class="don_loader_img2"></div>
      </div>
    </div>`;
  },
};
