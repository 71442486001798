import { each, go, ippL, mapC } from 'fxjs/es';
import { errorHandlingEventWrapper } from '../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { legacyHtml } from '../../Util/S/Function/util.js';
import {
  getBpcfsLength,
  getCanvasContainerElSize,
  getItsBpf,
  getOtherScreenCanvass,
  getRealCanvasZoomWrapper,
  getRealFcanvass,
  getSelectedCanvasContainer,
  getSelectedCanvasZoomWrapperEl,
} from './getSth.js';
import {
  isWorkerInMaker,
  makeFcanvasSelectionFalse,
  makeFcanvasSelectionTrue,
  makeLeftForBeingInCenter,
  offBlockingCanvas,
  onBlockingCanvas,
} from './util.js';
import { getCurrentFcanvas, getCvObj } from './Fcanvas/cv_object.js';
import { addCvPreview } from './CvPreview/cv_object.js';
import { changeEditingCanvasByClone, changeOtherCanvassByClone } from './canvas_size.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import { setMakerCurrentFaceLocOptimizationData } from './LocOptimization.js';
import { $setAttr, $addClass, $removeClass, $qsa, $qs, $attr, $on, $setCss } from 'fxdom/es';
import { isShadeStyle } from './CvPreview/Shade/render.js';
import { getFontColors } from './CvTextImage/fs.js';
import { BpOptionF } from '../../BpOption/F/Function/module/BpOptionF.js';
import { OMPCoreUtilF } from '../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import tippy from 'tippy.js';
import { NewMakerCallConditionPopupF } from '../../NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { is_selected_shading, isCylinderStyle, isNeedCvPrintAreaMask, isNeedLine } from './categorize.js';

G.mp.maker = G.mp.maker || {};
G.mp.maker.opening_select_face = false;

async function open_select_face() {
  G.mp.maker.unselect_all();
  if ($.has_class($1('html'), 'all_faces_mode')) return;
  $.selectable2($1('.select_face .other_faces'));
  const idx = G.mp.maker.editing_canvas_idx();
  $.selectable2($('.front_blank.for_select_faces .square')[idx]);
  await openAllFacesModeA();
  G.mp.maker.zoom.zoomIsEnded();
}

export const close_select_faceA = function () {
  return closeAllFacesModeA();
};

export const closeAllFacesModeA = function closeAllFacesModeA() {
  $.selectable2($1('.select_face .selected_face'));
  offBlockMenuMarpplizerPc();
  makeFcanvasSelectionTrue();
  $.remove_class($1('html'), 'all_faces_mode');
  // $.css($('.front_blank'), {
  //   zIndex: -1
  // });
  // $.hide($('.front_blank'));

  $.remove_class($1('html'), 'front_blank_show');
  $.remove_class($1('html'), 'front_blank_zIndex');
  $.hide(getOtherScreenCanvass());
  return selectFaceInitA();
};

function offBlockMenuMarpplizerPc() {
  if (!UtilF.isLegacyMobile()) {
    $.remove($('.pc_size_select_face_block'));
  }
}

function onBlockMenuMarpplizerPc() {
  if (!UtilF.isLegacyMobile()) {
    $.append($1('.maker_menu'), legacyHtml` <div class="pc_size_select_face_block"></div> `);
    $.append($1('#marpplizer'), legacyHtml` <div class="pc_size_select_face_block"></div> `);
    $.css($('.pc_size_select_face_block'), {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
      opacity: 0.7,
    });
  }
}

export const openAllFacesModeA = function (extra_bottom_height) {
  onBlockMenuMarpplizerPc();
  makeFcanvasSelectionFalse();
  $.add_class($1('html'), 'all_faces_mode');

  const selected_zoom_wrapper_el = getSelectedCanvasZoomWrapperEl();
  const canvas_container_size = getCanvasContainerElSize();

  let { width, height } = canvas_container_size;
  const margin_top = extra_bottom_height ? 0 : 0.02;
  extra_bottom_height = extra_bottom_height / 2 || 0;
  let scale = 0.3;
  const translateX_ratio_3 = 0.33;
  const translateX_ratio_2 = 0.17;
  const translateY_ratio_2_1 = -0.15 - margin_top;
  let translateY_ratio_2_2 = 0.15 - margin_top;
  const duration = isWorkerInMaker() ? 0 : 700;
  const text_bottom_margin = UtilF.isLegacyMobile() ? 5 : 10;
  const text_height = 18;

  if (extra_bottom_height) {
    height -= extra_bottom_height;
  }
  if (height - extra_bottom_height < 400) {
    scale = 0.25;
    translateY_ratio_2_2 = 0.1;
  }

  // $.show($1('.front_blank'));

  function makeTrans(i) {
    const faces_length = getBpcfsLength();
    let trans;
    if (faces_length === 6) {
      trans =
        i === 0
          ? {
              translateX: -width * translateX_ratio_3,
              translateY: translateY_ratio_2_1 * height,
            }
          : i === 1
          ? { translateX: 0, translateY: height * translateY_ratio_2_1 }
          : i === 2
          ? {
              translateX: width * translateX_ratio_3,
              translateY: translateY_ratio_2_1 * height,
            }
          : i === 3
          ? {
              translateX: -width * translateX_ratio_3,
              translateY: height * translateY_ratio_2_2,
            }
          : i === 4
          ? { translateX: 0, translateY: height * translateY_ratio_2_2 }
          : {
              translateX: width * translateX_ratio_3,
              translateY: height * translateY_ratio_2_2,
            };
    } else if (faces_length === 5) {
      trans =
        i === 0
          ? {
              translateX: -width * translateX_ratio_3,
              translateY: translateY_ratio_2_1 * height,
            }
          : i === 1
          ? { translateX: 0, translateY: height * translateY_ratio_2_1 }
          : i === 2
          ? {
              translateX: width * translateX_ratio_3,
              translateY: translateY_ratio_2_1 * height,
            }
          : i === 3
          ? {
              translateX: -width * translateX_ratio_2,
              translateY: height * translateY_ratio_2_2,
            }
          : {
              translateX: width * translateX_ratio_2,
              translateY: height * translateY_ratio_2_2,
            };
    } else if (faces_length === 4) {
      trans =
        i === 0
          ? {
              translateX: -width * translateX_ratio_2,
              translateY: translateY_ratio_2_1 * height,
            }
          : i === 1
          ? {
              translateX: width * translateX_ratio_2,
              translateY: translateY_ratio_2_1 * height,
            }
          : i === 2
          ? {
              translateX: -width * translateX_ratio_2,
              translateY: translateY_ratio_2_2 * height,
            }
          : {
              translateX: width * translateX_ratio_2,
              translateY: translateY_ratio_2_2 * height,
            };
    } else if (faces_length === 3) {
      trans =
        i === 0
          ? { translateX: -width * translateX_ratio_3, translateY: 0 }
          : i === 1
          ? { translateX: 0, translateY: 0 }
          : { translateX: width * translateX_ratio_3, translateY: 0 };
    } else if (faces_length === 2) {
      trans =
        i === 0
          ? { translateX: -width * translateX_ratio_2, translateY: 0 }
          : { translateX: width * translateX_ratio_2, translateY: 0 };
    } else if (faces_length === 1) {
      scale = 0.5;
      trans = { translateX: 0, translateY: 0 };
    }

    trans.translateY -= extra_bottom_height;
    trans.scale = scale;
    return trans;
  }

  const realCanvasZoomWrappers = getRealCanvasZoomWrapper();

  $.add_class($1('html'), 'front_blank_show');
  go(
    $('.front_blank .square'),
    ippL,
    each(([i, square]) => {
      const trans = makeTrans(i);
      const { top, left } = virtualOffset(trans, getCanvasContainerElSize());
      $.css(square, {
        width: G.mp.maker.bpcf_size.width * scale,
        height:
          ($.height(selected_zoom_wrapper_el) - G.mp.maker.bpcf_size.top - G.mp.maker.bpcf_size.bottom) *
            scale +
          text_height +
          text_bottom_margin,
        top: top + G.mp.maker.bpcf_size.top * scale,
        left:
          left +
          makeLeftForBeingInCenter(G.mp.maker.bpcf_size.width * scale, canvas_container_size.width * scale),
      });
    }),
  );
  return go(
    realCanvasZoomWrappers,
    ippL,
    mapC(([i, canvas_zoom_wrapper_el]) => {
      $.show($.closest(canvas_zoom_wrapper_el, '.canvas_container'));
      const trans = makeTrans(i);
      if (!extra_bottom_height && selected_zoom_wrapper_el === canvas_zoom_wrapper_el)
        trans.duration = duration;
      const f_canvas = getRealFcanvass()[i];
      const bottom = f_canvas.size_info.bottom - G.mp.maker.bpcf_size.bottom;
      trans.translateY = trans.translateY + bottom * scale;
      return anime(
        _p.defaults(
          {
            transformOrigin: `${canvas_container_size.width / 2}px ${canvas_container_size.height / 2}px 0`,
          },
          trans,
          {
            targets: [canvas_zoom_wrapper_el],
            duration: 0,
            easing: 'easeOutExpo',
          },
        ),
      ).finished;
    }),
    function () {
      $.add_class($1('html'), 'front_blank_zIndex');
    },
  );
};

function virtualOffset({ translateX, translateY, scale }, { width, height }) {
  const left = go(getSelectedCanvasContainer(), $.css('margin-left'), parseInt);
  return {
    left: makeLeftForBeingInCenter(width * scale, width) + translateX + left,
    top: makeLeftForBeingInCenter(height * scale, height) + translateY,
  };
}

export const makeSelectFacesEvent = function (maker_el) {
  go(
    maker_el,
    $.on2(
      'click',
      '.select_face .selected_face:not(.selected) .wrapper',
      errorHandlingEventWrapper(function () {
        return _p.retry(
          function () {
            return !G.mp.maker.opening_select_face;
          },
          close_select_faceA,
          _p.noop,
          300,
        );
      }),
    ),
    $.on2(
      'click',
      '.select_face .other_faces:not(.selected) .wrapper',
      errorHandlingEventWrapper(function () {
        G.mp.maker.opening_select_face = true;
        return _p.go(open_select_face(), function () {
          G.mp.maker.opening_select_face = false;
          destroyTippy();
        });
      }),
    ),
    $.on('click', '.front_blank.for_select_faces .text', function (e) {
      e.stopPropagation();
    }),
    $.on2(
      'click',
      '.front_blank.for_select_faces .square',
      errorHandlingEventWrapper(function (e) {
        if ($.has_class($1('html'), 'prev_product_matcher')) return;
        const target = $.closest(e.currentTarget, '.square');
        $.selectable2(target);
        return _p.retry(
          function () {
            return !G.mp.maker.opening_select_face;
          },
          function () {
            G.mp.maker.unselect_all();
            return _p.go(target, $.attr('idx'), G.mp.maker.editing_canvas, close_select_faceA);
          },
          _p.noop,
          300,
        );
      }),
    ),
  );
};

export function renderVisibleWhenSelected(fcanvas) {
  if (!fcanvas) return;
  if (fcanvas.is_editing_cylinder) return;
  if (G.mp.maker.cv_mask2(fcanvas)._element) {
    G.mp.maker.cv_mask1(fcanvas).set('visible', false);
    G.mp.maker.cv_mask2(fcanvas).set('visible', true);
  }
  if (isNeedLine()) {
    const cv_safe_area = G.mp.maker.cv_safe_area(fcanvas);
    if (cv_safe_area._element) {
      cv_safe_area.set('visible', true);
    } else {
      G.mp.maker.cv_print_area(fcanvas).set('visible', true);
      const cv_safety_area = getCvObj(fcanvas._objects, 'cv_safety_area');
      if (cv_safety_area) cv_safety_area.set('visible', true);
    }
  }
  if (is_selected_shading()) {
    G.mp.maker.cv_shading(fcanvas).set('visible', false);
  }
  if (isNeedCvPrintAreaMask()) G.mp.maker.cv_print_area_mask(fcanvas).set('visible', false);
  fcanvas.renderAll();
}

export function renderVisibleWhenCleared(fcanvas) {
  if (fcanvas.is_editing_cylinder) {
    return;
  }
  if (isNeedCvPrintAreaMask()) G.mp.maker.cv_print_area_mask(fcanvas).set('visible', true);
  if (!isCylinderStyle(fcanvas)) {
    G.mp.maker.cv_mask1(fcanvas).set('visible', true);
    G.mp.maker.cv_mask2(fcanvas).set('visible', false);
  }
  G.mp.maker.cv_print_area(fcanvas).set('visible', false);
  const cv_safe_area = G.mp.maker.cv_safe_area(fcanvas);
  if (cv_safe_area) {
    cv_safe_area.set('visible', false);
  }
  const cv_safety_area = getCvObj(fcanvas._objects, 'cv_safety_area');
  if (cv_safety_area) {
    cv_safety_area.set('visible', false);
  }
  if (is_selected_shading()) {
    G.mp.maker.cv_shading(fcanvas).set('visible', true);
  }
}

export async function selectFaceInitA(product_change_mode, size_change_mode) {
  const face_length = G.mp.maker.get_bpcfs_length();
  if (face_length - 1 < G.mp.maker.editing_canvas_idx()) {
    G.mp.maker.editing_canvas(0);
  }
  $.selectable2($('#maker_frame .canvas_container')[G.mp.maker.editing_canvas_idx()]);
  G.mp.maker.center_mode.init();

  G.mp.maker.reset_layer();
  getFontColors();

  const is_mobile = OMPCoreUtilF.isMobile();

  if (is_mobile) {
    // 모바일일 때 이미지 에디터에서 도수 색상 옵션이 있는 경우 -> 이미지에 색상 편집 팔레트 버튼 활성화
    const has_dosu_color_option = BpOptionF.biz.get.currentFace.hasDosuColorOptions();
    go(
      $qsa('.dosu_image_color.circle_icon'),
      each((dosu_image_color_edit_btn_el) => {
        if (has_dosu_color_option) {
          $removeClass('hidden', dosu_image_color_edit_btn_el);
        } else {
          $addClass('hidden', dosu_image_color_edit_btn_el);
        }
      }),
    );
  }

  return _p.go(
    onBlockingCanvas(),
    function () {
      if (size_change_mode) return;
      return product_change_mode
        ? G.mp.maker.zoom.maker_modeA(
            {
              scale: 1,
              duration: 0,
              transformOrigin: `${$.width($1('.canvas_container.selected .canvas_zoom_wrapper')) / 2}px ${
                $.height($1('.canvas_container.selected .canvas_zoom_wrapper')) / 2
              }px 0`,
            },
            212,
          )
        : G.mp.maker.zoom.maker_modeA();
    },
    function () {
      if ($1('#dream_factory')) return;
      const mp_maker = $1('#maker_frame');
      const is_hard_computing = mp_maker?.dataset?.is_bad_computing_power === 'true';
      changeOtherCanvassByClone();
      changeEditingCanvasByClone(is_hard_computing ? 1 : 2);
    },
    offBlockingCanvas,
    () => go(getCurrentFcanvas(), (f_canvas) => f_canvas.preview && addCvPreview(f_canvas)),
    () => {
      const mp_maker = $1('#maker_frame');
      const bpf = getItsBpf(getCurrentFcanvas());
      if (mp_maker) mp_maker.dataset.has_background = String(!!bpf?.has_background);

      go(mp_maker, $setAttr({ is_shade: isShadeStyle(G.mp.maker.editing_canvas()) }));
      mp_maker.dataset.is_dosu_color_app = BpOptionF.biz.get.currentFace.hasDosuColorOptions();

      destroyTippy();
      if ($attr('faces_length', mp_maker) !== '1') {
        activeTippy();
      }
      if (NewMakerCallConditionPopupF.is_mapping_confirm_popup()) {
        renderVisibleWhenSelected(getCurrentFcanvas());
      }
    },
    setMakerCurrentFaceLocOptimizationData,
  );
}

function destroyTippy() {
  if (G.collabo_type !== '') return;
  const select_face_el = $qs('.select_face');
  if (!select_face_el) return;
  select_face_el.__tippy?.destroy();
}
let current_bp_id = null;
function activeTippy() {
  if (G.collabo_type !== '') return;
  if ($qs('html#dream_factory')) return;
  const select_face_el = $qs('.select_face');
  if (!select_face_el) return;
  const bp_id = box().maker.product_color.base_product_id;
  if (current_bp_id === bp_id) {
    return;
  } else {
    current_bp_id = bp_id;
  }
  select_face_el.__tippy = tippy(select_face_el, {
    content: `<div style="display: flex;align-items: center;"><span>${T(
      'maker::select_face::tooltip',
    )}</span><img src="//s3.marpple.co/files/u_193535/2024/2/original/6e76d55351484915787811d054dd869df3348b4c1.png" alt="" style="width:8px;height:8px;margin-left:8px;"></img></div>`,
    allowHTML: true,
    theme: UtilF.isLegacyMobile() ? 'maker-select-face-mobile' : 'maker-select-face-pc',
    showOnCreate: true,
    placement: UtilF.isLegacyMobile() ? 'top' : 'bottom-end',
    appendTo: select_face_el,
    maxWidth: 'none',
    trigger: 'click',
    onShown(instance) {
      go(
        instance.popper,
        $on('click', () => {
          instance.destroy();
        }),
        $setCss({ 'pointer-events': 'initial' }),
      );
      // ...
    },
    hideOnClick: false,
  });
}

G.mp.maker.select_face_initA = selectFaceInitA;
