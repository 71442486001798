export const messages = {
  title: `studio::title`,
  menus: {
    make_products: `studio::menus::make_products`,
    products: `studio::menus::products`,
    orders: `studio::menus::orders`,
    digital_orders: `studio::menus::digital_orders`,
    seller_product: `studio::menus::seller_product`,
    real_pb_products: `studio::menus::real_pb_products`,
    inventory_management: `studio::menus::inventory_management`,
    pb_orders: `studio::menus::pb_orders`,
    pb_item_orders: `studio::menus::pb_item_orders`,
    pb_refund_orders: `studio::menus::pb_refund_orders`,
    digital_product: `studio::menus::digital_product`,
    make_digital_products: `studio::menus::make_digital_products`,
    question_list: `studio::menus::question_list`,
    shop_management: `studio::menus::shop_management`,
    styles: `studio::menus::styles`,
    arrangement: `studio::menus::arrangement`,
    shop_info: `studio::menus::shop_info`,
    ecommerce_info: `studio::menus::ecommerce_info`,
    open_api: `studio::menus::open_api`,
    external_services: `studio::menus::external_services`,
    adjustments: `studio::menus::adjustments`,
    design_adjustments: `studio::menus::design_adjustments`,
    seller_adjustments: `studio::menus::seller_adjustments`,
    tpl_adjustments: `studio::menus::tpl_adjustments`,
    dtg_adjustments: `studio::menus::dtg_adjustments`,
    withdraws: `studio::menus::withdraws`,
    bank_account: `studio::menus::bank_account`,
    analytics: `studio::menus::analytics`,
    analytics_by_prod: `studio::menus::analytics_by_prod`,
    my_shop: `studio::menus::my_shop`,
    youtube: `studio::menus::youtube`,
    seller_guide: `studio::menus::seller_guide`,
    seller_tip: `studio::menus::seller_tip`,
    faq: `studio::menus::faq`,
    solution: `studio::menus::solution`,
    invite: `studio::menus::invite`,
    creator_community_club: `studio::menus::creator_community_club`,
    update_note: `studio::menus::update_note`,
    invite_info: `studio::menus::invite_info`,
    account: `studio::menus::account`,
    marketing: `studio::menus::marketing`,
    help: `studio::menus::help`,
    contact_marppleshop: `studio::menus::contact_marppleshop`,
    mp_shop: `studio::menus::mp_shop`,
    logout: `studio::menus::logout`,
    adobe_illustrator: `studio::menus::adobe_illustrator`,
  },
  common: {
    kr: `common::lang::kr`,
    en: `common::lang::en`,
    jp: `common::lang::jp`,
    krw: `common::unit::krw`,
    usd: `common::unit::usd`,
    jpy: `common::unit::jpy`,
    reveal_type: `common::reveal_type`,
    private: `common::private`,
    public: `common::public`,
    all_cate: `common::all_cate`,
    seller: `common::seller`,
  },
  form: {
    title1: `studio::form::title1`,
    title2: `studio::form::title2`,
    save: `common::action::save`,
    cancel: `common::action::cancel`,
  },
  footer: {
    privacy: `studio::footer::privacy`,
    terms: `studio::footer::terms`,
    p: `studio::footer::p`,
  },
  shop_info: {
    p1: `studio::shop_info::p1`,
  },
  ecommerce_info: {
    p1: `studio::ecommerce_info::p1`,
  },
  styles: {
    p1: `studio::styles::p1`,
    btn1: `studio::styles::btn1`,
  },
  products: {
    p1: `studio::products::p1`,
    p2: `studio::products::p2`,
  },
  real_pb_products: {
    p1: `studio::real_pb_products::p1`,
    p2: `studio::real_pb_products::p2`,
  },
  digital_products: {
    p1: `studio::digital_products::p1`,
    p2: `studio::digital_products::p2`,
    p3: `studio::digital_products::p3`,
    p4: `studio::digital_products::p4`,
    cate_wall_paper: `studio::digital_products::cate_wall_paper`,
    cate_voice_pack: `studio::digital_products::cate_voice_pack`,
    cate_good_note: `studio::digital_products::cate_good_note`,
    cate_digital_asset: `studio::digital_products::cate_digital_asset`,
    cate_e_book: `studio::digital_products::cate_e_book`,
    header: `studio::digital_products::header`,
    header_modify: `studio::digital_products::header_modify`,
    required: 'studio::digital_products::required',
    no_image_info_title: 'studio::digital_products::no_image_info_title',
    no_image_info_contents: `studio::digital_products::no_image_info_contents`,
    image_upload_btn: 'studio::digital_products::image_upload_btn',
    set_first: 'studio::digital_products::set_first',
    recommend_size: 'studio::digital_products::recommend_size',
    delete_image: 'studio::digital_products::delete_image',
    btn_register: 'studio::digital_products::btn_register',
  },
  make: {
    p1: `studio::make::p1`,
    p2: `studio::make::p2`,
    p3: `studio::make::p3`,
    p4: `studio::make::p4`,
  },
  invite: {
    p1: `studio::invite::p1`,
    p2: `studio::invite::p2`,
    p3: `studio::invite::p3`,
    p4: `studio::invite::p4`,
    p5: `studio::invite::p5`,
    p6: `studio::invite::p6`,
    t1: `studio::invite::t1`,
    l1: `studio::invite::l1`,
    t2: `studio::invite::t2`,
    l2: `studio::invite::l2`,
    l3: `studio::invite::l3`,
    b1: `studio::invite::b1`,
  },
  invite_info: {
    p1: `studio::invite_info::p1`,
    p2: `studio::invite_info::p2`,
    p3: `studio::invite_info::p3`,
    p4: `studio::invite_info::p4`,
    t1: `studio::invite_info::t1`,
    t2: `studio::invite_info::t2`,
    t3: `studio::invite_info::t3`,
    l1: `studio::invite_info::l1`,
    l2: `studio::invite_info::l2`,
    l3: `studio::invite_info::l3`,
    l4: `studio::invite_info::l4`,
    submit: `common::action::regist`,
  },
  marketing: {
    p1: `studio::marketing::p1`,
    agree: `studio::marketing::agree`,
    not_agree: `studio::marketing::not_agree`,
    modify: `studio::marketing::modify`,
  },
  account: {
    p1: `studio::account::p1`,
    email: `studio::account::email`,
    save_changes: `studio::account::save_changes`,
    pw: `studio::account::pw`,
    reset_pw: `studio::account::reset_pw`,
    name: `studio::account::name`,
    brand_name: `studio::account::brand_name`,
    phone: `studio::account::phone`,
    store_url: `studio::account::store_url`,
    modify: `studio::account::modify`,
    my_point: `studio::account::my_point`,
    e1: `studio::account::e1`,
  },
  orders: {
    p1: `studio::orders::p1`,
    p2: `studio::orders::p2`,
    t1: `studio::orders::t1`,
    empty: `studio::orders::empty`,
  },
  table: {
    th1: `studio::table::th1`,
    th2: `studio::table::th2`,
    th3: `studio::table::th3`,
    th4: `studio::table::th4`,
    th5: `studio::table::th5`,
    th6: `studio::table::th6`,
    th7: `studio::table::th7`,
    th8: `studio::table::th8`,
    th9: `studio::table::th9`,
    th10: `studio::table::th10`,
    th11: `studio::table::th11`,
    th12: `studio::table::th12`,
    th13: `studio::table::th13`,
    th14: `studio::table::th14`,
    th15: `studio::table::th15`,
    th16: `studio::table::th16`,
    th16_1: `studio::table::th16_1`,
    th16_2: `studio::table::th16_2`,
    th16_3: `studio::table::th16_3`,
    th16_4: `studio::table::th16_4`,
    th17: `studio::table::th17`,
    th18: `studio::table::th18`,
    th19: `studio::table::th19`,
    th20: `studio::table::th20`,
    th21: `studio::table::th21`,
    th22: `studio::table::th22`,
    th23: `studio::table::th23`,
    th24: `studio::table::th24`,
    th25: `studio::table::th25`,
    th26: `studio::table::th26`,
    th27: `studio::table::th27`,
    th28: `studio::table::th28`,
    th29: `studio::table::th29`,
    th30: `studio::table::th30`,
    th31: `studio::table::th31`,
    th32: `studio::table::th32`,
    th33: `studio::table::th33`,
    th34: `studio::table::th34`,
    th35: `studio::table::th35`,
    th36: `studio::table::th36`,
    th37: `studio::table::th37`,
    th38: `studio::table::th38`,
    th39: `studio::table::th39`,
    th40: `studio::table::th40`,
    th41: `studio::table::th41`,
    th42: `studio::table::th42`,
    th43: `studio::table::th43`,
    th44: `studio::table::th44`,
  },
  analytics: {
    p1: `studio::analytics::p1`,
  },
  adjustments: {
    p1: `studio::adjustments::p1`,
    p2: `studio::adjustments::p2`,
    btn1: `studio::adjustments::btn1`,
    btn2: `studio::adjustments::btn2`,
    btn3: `studio::adjustments::btn3`,
    btn4: `studio::adjustments::btn4`,
    release: `studio::adjustments::release`,
    release_date: `studio::adjustments::release_date`,
    confirmed: `studio::adjustments::confirmed`,
    change: `studio::adjustments::change`,
    shipping: `studio::adjustments::shipping`,
    ym_locale: `format::date::ym_locale`,
    ym_digit: `format::date::ym_digit`,
  },
  withdraws: {
    p1: `studio::withdraws::p1`,
    p1_1: `studio::withdraws::p1_1`,
    p2: `studio::withdraws::p2`,
    p3: `studio::withdraws::p3`,
    p4: `studio::withdraws::p4`,
    t1: `studio::withdraws::t1`,
    btn1: `studio::withdraws::btn1`,
    btn2: `studio::withdraws::btn2`,
    btn3: `studio::withdraws::btn3`,
    btn4: `studio::withdraws::btn4`,
    btn5: `studio::withdraws::btn5`,
    btn6: `studio::withdraws::btn6`,
    finish: `studio::withdraws::finish`,
    cancel: `studio::withdraws::cancel`,
    approve: `studio::withdraws::approve`,
    pending: `studio::withdraws::pending`,
    tt_title: `studio::withdraws::tt_title`,
    lb1: `studio::withdraws::lb1`,
    lb2: `studio::withdraws::lb2`,
    opt1: `studio::withdraws::opt1`,
    opt2: `studio::withdraws::opt2`,
    opt3: `studio::withdraws::opt3`,
    opt4: `studio::withdraws::opt4`,
  },
  tooltip: {
    h2: `studio::tooltip::h2`,
    t1: `studio::tooltip::t1`,
    t2: `studio::tooltip::t2`,
    t3: `studio::tooltip::t3`,
    t3_1: `studio::tooltip::t3_1`,
    t3_2: `studio::tooltip::t3_2`,
    t4: `studio::tooltip::t4`,
    t5: `studio::tooltip::t5`,
    t6: `studio::tooltip::t6`,
  },
  arrangement: {
    only_pc: `studio::arrangement::only_pc`,
    desc: `studio::arrangement::desc`,
    desc2: `studio::arrangement::desc2`,
    save: `common::save`,
  },
  marpple_seller: `studio::marpple_seller`,
  type_mobile: `studio::type_mobile`,
  need_allow: `studio::need_allow`,
  possible_device_change: `studio::possible_device_change`,
  select_category: `studio::select_category`,
  ba: {
    warning: 'bank_account::ba91',
    header: 'bank_account::ba1',
    desc: 'bank_account::ba2',
    title_1: 'bank_account::ba3',
    title_2: 'bank_account::ba23',
    title_3: 'bank_account::ba42',
    title_4: 'bank_account::ba58',
    title_5: 'bank_account::ba8',
    title_6: 'bank_account::ba33',
    title_7: 'bank_account::ba73',
    opt_child: 'bank_account::ba4',
    opt_personal: 'bank_account::ba5',
    opt_company: 'bank_account::ba6',
    opt_foreigner: 'bank_account::ba7',
    country: 'bank_account::ba28',
    sel_country: 'bank_account::ba29',
    pic_id: 'bank_account::ba32',
    mo_cert: 'bank_account::ba26',
    info_1: 'bank_account::ba24',
    info_2: 'bank_account::ba25',
    btn_parent_agree: 'bank_account::ba51',
    parent_name: 'bank_account::ba50',
    parent_birthday: 'bank_account::ba49',
    parent_mobile: 'bank_account::ba48',
    parent_mo_cert: 'bank_account::ba46',
    dl_parent_agree: 'bank_account::ba47',
    name: 'plus_admin::pa162',
    birthday: 'my_info::BirthDay',
    mobile: 'bank_account::ba92',
    save: 'common::save',
    agree_1: 'bank_account::ba43',
    agree_2: 'bank_account::ba44',
    agree_3: 'bank_account::ba45',
    bn_name: 'bank_account::ba59',
    bn_name_ph: 'bank_account::ba60',
    bn_ceo: 'bank_account::ba61',
    bn_ceo_ph: 'bank_account::ba62',
    bn_no: 'bank_account::ba63',
    bn_mng_email: 'bank_account::ba64',
    bn_mng_email_ph: 'bank_account::ba65',
    bn_addr: 'bank_account::ba66',
    bn_addr_ph: 'bank_account::ba66',
    bn_type: 'bank_account::ba68',
    bn_type_ph: 'bank_account::ba69',
    bn_type2: 'bank_account::ba70',
    bn_type2_ph: 'bank_account::ba71',
    bn_license: 'bank_account::ba72',
    id_no: 'bank_account::ba30',
    id_no_front: 'bank_account::ba31',
    id_no_back: 'bank_account::ba93',
    edit: 'plus_admin::pa30',
    seller_name_ph: 'bank_account::ba27',
    bank: 'bank_account::ba9',
    bank_ph: 'bank_account::ba10',
    bank_owner: 'bank_account::ba11',
    bank_owner_ph: 'bank_account::ba12',
    paypal_info_1: 'bank_account::ba20',
    paypal_info_2: 'bank_account::ba21',
    paypal: 'bank_account::ba17',
    paypal_ph: 'bank_account::ba18',
    paypal_img: 'bank_account::ba19',
    view_file: 'bank_account::ba22',
    account_no: 'bank_account::ba13',
    account_no_ph: 'bank_account::ba14',
    account_pic: 'bank_account::ba15',
    term_1: 'bank_account::ba35',
    term_2: 'bank_account::ba36',
    term_3: 'bank_account::ba88',
    term_4: 'bank_account::ba89',
    rule_1: 'studio::footer::privacy',
    rule_2: 'bank_account::ba94',
    agree_privacy: 'bank_account::ba34',
    agree_adjust: 'bank_account::ba95',
    agree_lb: 'bank_account::ba37',
    foreigner_cond: 'bank_account::ba74',
    foreigner_cond_guide1: 'bank_account::ba75',
    foreigner_cond_guide2: 'bank_account::ba76',
    foreigner_cond_agree: 'bank_account::ba77',
    foreigner_ex: 'bank_account::ba78',
    foreigner_ex_rule1: 'bank_account::ba79',
    foreigner_ex_rule2: 'bank_account::ba80',
    foreigner_ex_agree: 'bank_account::ba81',
    confirm: 'translation::Confirm',
    invoice_hd: 'bank_account::ba52',
    invoice_desc: 'bank_account::ba53',
    invoice_guide1: 'bank_account::ba54',
    invoice_guide2: 'bank_account::ba55',
    invoice_able: 'bank_account::ba56',
    invoice_not_able: 'bank_account::ba57',
    not_invoice_hd: 'bank_account::ba96',
    not_invoice_desc: 'bank_account::ba97',
    not_invoice_q: 'bank_account::ba98',
    not_invoice_btn_bn: 'bank_account::ba99',
    not_invoice_btn_pn: 'bank_account::ba100',
    file_empty: 'bank_account::ba16',
    file_upload_btn: 'plus_admin::pa410',
    file_delete: 'bank_account::ba101',
    file_confirm: 'bank_account::ba102',
    not_parent: 'plus_admin::pa391',
    not_adult: 'plus_admin::pa392',
    error: 'plus_admin::pa393',
    no_paypal_pic: 'bank_account::ba103',
    only_pc: 'bank_account::ba104',
  },
};
