import { html, type Html, View } from 'rune-ts';
import klass from '../ProductCard.module.scss';
import { htmlIf } from '../../../../../shared/util';
import { HeartFillIcon } from '../../../atoms/Icon';
import { ProductCardData } from '../../../../../features/ProductList/type';

type LikeButtonType = Pick<ProductCardData, 'is_liked' | 'is_creator_ranking'>;

export class ProductLikeButton extends View<LikeButtonType> {
  protected override template(data: LikeButtonType): Html {
    return html`
      <button
        class="${klass.like} ${htmlIf(klass.active, !!data.is_liked)} ${htmlIf(
          klass.is_creator_ranking,
          !!data.is_creator_ranking,
        )}"
      >
        ${HeartFillIcon}
      </button>
    `;
  }

  override redraw(): this {
    this.element().classList.toggle(klass.active);
    return this;
  }
}
