import { each, go, find, sel, pluck, reject } from 'fxjs/es';

import { NewMakerPropertyBpfS } from '../../NewMaker/Property/Bpf/S/Function/module/NewMakerPropertyBpfS.js';
import { NewMakerPropertyBpfConstantS } from '../../NewMaker/Property/Bpf/S/Constant/module/NewMakerPropertyBpfConstantS.js';

export const excludingDiscontinued = (bpss, discountinued_size_ids) => {
  return go(
    bpss,
    reject((bp) => discountinued_size_ids.includes(bp.id)),
  );
};

export const discontinuedSizeIds = (product_color, base_product_colors) =>
  go(
    base_product_colors,
    find((bpc) => bpc.id === product_color.base_product_color_id),
    sel('_.discontinued_sizes'),
    pluck('id'),
  );

export const soldOutSizeIds = (product_color, base_product_colors) =>
  go(
    base_product_colors,
    find((bpc) => bpc.id === product_color.base_product_color_id),
    sel('_.sold_out_sizes'),
    pluck('id'),
  );

function resetFaces(faces, bpcfs, base_product_color) {
  each(function (face) {
    const bpcf = find((bpcf) => bpcf.base_product_face_id == face.bpf_id, bpcfs);
    if (!bpcf) return;
    face.cv_bpcf.src = bpcf.url || '';
    face.cv_mask1.src = bpcf.mask_url || '';
    face.cv_mask2.src = bpcf.mask2_url || '';
    face.cv_shading.src = bpcf._.base_product_face.shading_url || '';
    if (face.cv_preview?._data?.shade_material) {
      const shade_material = face.cv_preview?._data?.shade_material;
      if (shade_material)
        NewMakerPropertyBpfS.common.shadeMaterial.changeColorData({
          shade_material,
          base_product_color,
        });
      if (
        !shade_material.name ||
        shade_material.name === NewMakerPropertyBpfConstantS.BPC_COLOR_CODE2_RENDER
      ) {
        NewMakerPropertyBpfS.bpcColorCode2Render.changeColorData(face, base_product_color);
      }
    }
  }, faces);
}

export function changeBpc2(product_color, base_product_color) {
  product_color.base_product_color_id = base_product_color.id;
  const bpcfs = base_product_color._.base_product_color_faces;
  resetFaces(sel('product_faces2.value', product_color), bpcfs, base_product_color);
  if (sel('product_faces.value', product_color))
    resetFaces(sel('product_faces.value', product_color), bpcfs, base_product_color);
}
