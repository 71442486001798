import { each, go } from 'fxjs/es';
import { makeBpcfAvgSize } from './canvas_size.js';
import { close_select_faceA } from './select_faces.js';
import { setLayerTopInMaker } from './Layer/fs.js';
import { $attr, $find, $height, $qs, $setCss, $width } from 'fxdom/es';
import { marpplizerProxy } from './Marpplizer/marpplizer.js';
import { NewMakerUtilF } from '../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import { NewMakerPropertyBaseProductS } from '../../NewMaker/Property/BaseProduct/S/Function/module/NewMakerPropertyBaseProductS.js';
import { getBaseProductInMaker } from './getSth.js';

function frame_screen_size(el) {
  const don_frame = $.closest(el, '.don_frame');
  return _p.go(don_frame, $.attr('is_modal'), function (is_modal) {
    const is_short = go(
      $qs('html'),
      $attr('marpplizer_mode'),
      (marpplizer_mode) => marpplizer_mode === 'short',
    );
    const header_height = NewMakerUtilF.getPcHeaderHeight();
    const body_width = NewMakerUtilF.getPcBodyWidth();
    const marpplizer_width = go($qs('#marpplizer'), $width);
    const panel_width = is_short ? 320 : G.collabo_type === '' ? marpplizer_width : 460;

    if ($qs('html.ciety-product-maker')) {
      return {
        width: body_width - panel_width,
        height: $.height(window) - (header_height - 56),
      };
    }
    if (is_modal) {
      return _p.go(don_frame, $.find1('.don_wrapper'), function (don_wrapper) {
        const header_el = $find('.header')(don_wrapper);
        return {
          width: $.width(don_wrapper) - panel_width,
          height: $.innerHeight(don_wrapper) - ((header_el && $height(header_el)) || 0),
        };
      });
    } else {
      return {
        width: body_width - panel_width,
        height: $.height(window) - header_height,
      };
    }
  });
}

export function marpplizerMode(mode) {
  $.attr($1('html'), { marpplizer_mode: mode });
  // $.attr($1('html'), { marpplizer_mode: 'short' });
}

export const setCanvasContainerSizeAndMpMakerHeight = function () {
  if (!UtilF.isLegacyMobile()) {
    if (G.collabo_type === '') {
      marpplizerMode(document.documentElement.clientWidth > 1397 ? 'basic' : 'short');
    }
    const el_mp_maker = $1('.mp_maker');
    const { width, height } = frame_screen_size(el_mp_maker);

    const is_vector = el_mp_maker?.dataset.is_vector === 'true';
    const maker_menu_height =
      is_vector || NewMakerPropertyBaseProductS.bpOptionPreviews.getObject(getBaseProductInMaker())
        ? 0
        : $.height($1('#maker_frame .maker_menu'));
    $.css(el_mp_maker, { height });
    const canvas_width = Math.min(height - maker_menu_height, width);
    go(
      el_mp_maker,
      $find('.board'),
      $setCss({
        top: maker_menu_height + (height - maker_menu_height - canvas_width) / 2,
        height: canvas_width,
      }),
    );
    go(
      $('.canvas_container').concat(),
      each(
        $.css({
          height: canvas_width,
          width: canvas_width,
        }),
      ),
    );
    setLayerTopInMaker();
  } else {
    _p.go(
      $('.canvas_container'),
      $.css({
        width: $.width(window),
        height: G.mp.maker.window_height,
      }),
    );
  }
};

export const whenResizeInMaker = async function () {
  if ($qs('.don_loader_wrap')) return;
  if ($1('html.mp_maker_not_ready')) return;
  if ($1('html.maker_page.all_faces_mode')) await close_select_faceA();
  G.mp.maker.bpcf_size = makeBpcfAvgSize();
  setCanvasContainerSizeAndMpMakerHeight();
  _p.go(G.mp.maker.zoom.maker_modeA({ duration: 0 }), function () {
    G.mp.maker.zoom_attr.offset = $.offset($1('.canvas_container.selected .canvas_zoom_wrapper'));
  });
  G.mp.maker.center_mode.init();
  await marpplizerProxy(G.mp.maker.active());
};
