import { html, strMap } from 'fxjs/es';
import { DISCLAIMER } from '../Constant/mobilepdfuploader.js';
import format from 'date-fns/format/index.js';
import { SVG_ICONS } from '../Constant/svg_icons.js';
import dataStr from 'fxdom/es/dataStr.js';
import { hasPdfFile } from '../Function/mobilepdfuploader.js';
import { PrintTemplateS } from '../../../../../PrintTemplate/S/Function/module/PrintTemplateS.js';

/**
 * @param {number} bp_id
 * @param {PdfPrintingFileRow | undefined} pdf_printing_file
 * @param {PrintingTemplate | undefined} template
 * @return {Promise<string>} PDF 파일 업로드 프레임 전체 html 문자열
 *                  - header 는 frame 에 정의되어 있음
 *                  - body
 *                  - footer
 * */
export function mobilePdfUploaderTmpl({ bp_id, pdf_printing_file, template } = {}) {
  return html`<div class="pdf_uploader__body flex-column g-20" bp_id="${bp_id}">
      <div class="pdf_uploader__body__disclaimer flex-column g-20">${pdfPrintingFileDisclaimerHtml()}</div>
      <div class="pdf_uploader__body__upload flex-column flex-center g-20">
        ${pdfPrintingFileUploadHtml({ pdf_printing_file, template })}
      </div>
    </div>
    <div class="pdf_uploader__footer flex-column flex-center">
      <button class="flex flex-center g-8 fs-14 fw-500 lh-100" name="template_down" bp_id="${bp_id}">
        ${SVG_ICONS.DOWN} ${TT('pdf::uploader::12')}
      </button>
    </div>`;
}

/**
 * @return {string} PDF 업로드 주의사항 html 문자열
 * */
function pdfPrintingFileDisclaimerHtml() {
  return html`
    <div class="title fs-16 fw-500 lh-150">${TT('pdf::uploader::2')}</div>
    <div class="description flex-column g-8">
      ${strMap((text) => {
        return html` <div class="item fs-12 fw-400 lh-140">${DISCLAIMER.BULLET}<span>${text()}</span></div>`;
      }, DISCLAIMER.TEXTS)}
    </div>
  `;
}

/**
 * @param {PdfPrintingFileRow | undefined} pdf_printing_file
 * @param {PrintingTemplate | undefined} template
 * @return {string} PDF 파일 업로드 섹션 html 문자열
 * */
export function pdfPrintingFileUploadHtml({ pdf_printing_file, template }) {
  const has_pdf_file = hasPdfFile({ pdf_printing_file });

  return html`
    <div
      class="section file flex-column flex-center g-20"
      has_pdf_file="${has_pdf_file}"
      data-fx-json="${dataStr(pdf_printing_file ?? {})}"
    >
      <div class="thumbnail_bg flex-column flex-center">
        ${pdfThumbnailImgHtml({ has_pdf_file, thumbnail_url: pdf_printing_file?.thumbnail_urls?.[0] })}
        ${fileRemovalButton({ has_pdf_file })}
      </div>
      <span class="filename fs-14 fw-500 lh-100"
        >${has_pdf_file ? pdf_printing_file.file_name : TT('pdf::uploader::1')}</span
      >
    </div>
    <div class="horizontal_line"></div>
    <div class="section description flex-column flex-center g-12">
      ${has_pdf_file ? pdfFileDesc({ pdf_printing_file, template }) : pdfEmptyFileDesc()}
    </div>
  `;
}

/**
 * @param {boolean} has_pdf_file
 * @return {string} PDF 파일 제거 버튼 html 문자열
 * */
function fileRemovalButton({ has_pdf_file }) {
  return has_pdf_file
    ? html`<button class="flex-column flex-center" name="remove">${SVG_ICONS.X}</button>`
    : ``;
}

/**
 * @param {boolean} has_pdf_file
 * @param {string} thumbnail_url
 * @return {string} PDF 썸네일 이미지 html 문자열
 * */
function pdfThumbnailImgHtml({ has_pdf_file, thumbnail_url }) {
  return has_pdf_file
    ? html`<img src="${thumbnail_url ?? ''}" alt="pdf upload thumbnail" />`
    : SVG_ICONS.PLUS;
}

/**
 * @return {string} PDF 파일 없을 때 업로드 안내문구 html 문자열
 * */
export function pdfEmptyFileDesc() {
  return html` <span class="notice_empty fs-12 fw-500 lh-140">${TT('pdf::uploader::8')}</span> `;
}

/**
 * @param {PdfPrintingFileRow} pdf_printing_file
 * @param {PrintingTemplate | null} template
 * @return {string} PDF 파일 있을 때 파일 상세 설명 문구 html 문자열
 * */
export function pdfFileDesc({ pdf_printing_file, template }) {
  const { doc_name, doc_version, created_at } = pdf_printing_file;
  const { is_OK: is_valid_pdf_file } = PrintTemplateS.validatePdfPrintingFile({
    template,
    pdf_printing_file,
  });

  return html`
    ${(() => {
      if (!is_valid_pdf_file) {
        return html`${SVG_ICONS.WARN}
          <span class="notice_detail fs-12 fw-500 lh-140">${TT('pdf::uploader::15')}</span> `;
      } else {
        const doc_info_str = doc_name && doc_version ? `${doc_name} (v.${doc_version})` : '';
        return html` <span class="notice_detail fs-12 fw-500 lh-140"
          >${doc_info_str} ${TT('pdf::uploader::11')}</span
        >`;
      }
    })()}
    <span class="notice_period fs-12 fw-400 lh-100"
      >${format(new Date(created_at), 'yyyy-MM-dd HH:mm')} (${TT('pdf::uploader::10')} :
      6${TT('pdf::uploader::14')})</span
    >
  `;
}
