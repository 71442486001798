import { BpOptionConstantS } from '../../../../../modules/BpOption/S/Constant/module/BpOptionConstantS.js';
import { removeAllTempLocalStorage, setTempLocalStorage } from '../../../../../modules/Maker/F/mp_maker.js';
import { onbeforeunload_event_on, orderFaceBackgroundInMaker } from '../../../../../modules/Maker/F/util.js';
import { chunk, delay, each, go, ippL, map, mapC } from 'fxjs/es';
import {
  getCurrentFcanvas,
  getCvDesigns,
  makeCvObjToJSON,
} from '../../../../../modules/Maker/F/Fcanvas/cv_object.js';
import { addCvPreview, removeCvPreview } from '../../../../../modules/Maker/F/CvPreview/cv_object.js';
import { NewMakerMaskingTapeF } from '../../../../../modules/NewMaker/MaskingTape/F/Function/module/NewMakerMaskingTapeF.js';
import { BpOptionF } from '../../../../../modules/BpOption/F/Function/module/BpOptionF.js';
import { NewMakerSharedProductF } from '../../../../../modules/NewMaker/SharedProduct/F/Function/module/NewMakerSharedProductF.js';
import { OMPDosuF } from '../../../../../modules/OMP/Dosu/F/Function/module/OMPDosuF.js';
import {
  getBaseProductInMaker,
  getCurrentBpcfInMaker,
  getProductColorInMaker,
} from '../../../../../modules/Maker/F/getSth.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../../../modules/NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { VectorEditorConstantS } from '../../../../../modules/VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { NewMakerUtilS } from '../../../../../modules/NewMaker/Util/S/Function/module/NewMakerUtilS.js';
import { NewMakerCallConditionPopupF } from '../../../../../modules/NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';

!(function (LF) {
  let stack, state_index, start_state;
  function render_view() {
    const maker_frame_el = $1('#maker_frame');
    const redo_is_able = state_index < stack.length - 1;
    const undo_is_able = state_index > 0;
    if (maker_frame_el)
      $.attr(maker_frame_el, {
        redo_is_able,
        undo_is_able,
      });
    G.mp.maker.reset_box_data();
    BpOptionF.makeSelectDisabled();

    const has_history = state_index > 0;
    const first_load = state_index === 0;
    const params = new URLSearchParams(location.search);
    const product = getProductColorInMaker();
    if (G.collabo_type === '') {
      const shared_product = box.sel('shared_product');

      if (!shared_product && params.get('pc_id') && has_history && NewMakerUtilS.hasDesignsInFaces(product)) {
        params.delete('pc_id');
        $.frame.extend_state(null, null, `${location.pathname}?${params.toString()}`, 'replace');
      }

      if (!params.get('pc_id') && first_load && NewMakerUtilS.hasDesignsInFaces(product)) {
        setTempLocalStorage();
      }
    } else {
      if (first_load && NewMakerUtilS.hasDesignsInFaces(product)) {
        setTempLocalStorage();
      }
    }

    if (has_history) {
      if (NewMakerUtilS.hasDesignsInFaces(product)) {
        setTempLocalStorage();
      } else {
        removeAllTempLocalStorage();
      }
    }
    if (
      !VectorEditorConstantS.EDITOR_TYPES.includes(
        box.sel('maker->product_color->_->base_product->maker_type'),
      )
    ) {
      onbeforeunload_event_on(state_index);
    }
  }

  async function undo_redo_body(index) {
    $.don_loader_start();
    await delay(10, () => {});
    G.mp.maker.cleared_pass = true;
    const prev_idx = G.mp.maker.editing_canvas_idx();
    const current_idx = current().canvas_idx;
    G.mp.maker.editing_canvas(current_idx, true);
    await go(current().cv_objects, reset_canvas, async function () {
      G.mp.maker.cleared_pass = false;
      await G.mp.maker.cleared();
      if (prev_idx !== current_idx) await G.mp.maker.select_face_initA();
      G.mp.maker.design_collection_type_setting();
      if (index !== state_index) return undo_redo_body(state_index);
    });
    $.don_loader_end();
  }
  let on = false;
  G.mp.maker.state = {
    current: current,

    start: start,

    reset: async function () {
      if (state_index === 0) return;
      $.don_loader_start();
      await delay(100, () => {});
      G.mp.maker.cleared_pass = true;
      state_index = 0;
      render_view();
      G.mp.maker.editing_canvas(start_state.canvas_idx, true);
      const shared_product = box.sel('shared_product');
      if (shared_product) {
        NewMakerSharedProductF.renderSharedProductCanSaveButton(
          shared_product._original_state_index !== state_index,
        );
      }
      return go(
        start_state.objectss,
        ippL,
        each(([i, objects]) => reset_canvas(objects, box.sel('maker->canvass->' + i))),
        async function () {
          G.mp.maker.cleared_pass = false;
          await G.mp.maker.cleared();
          await G.mp.maker.select_face_initA();
          G.mp.maker.lock.render_state();
          OMPDosuF.validation.update.faceValidationText();
          $.don_loader_end();
          NewMakerCallConditionPopupF.reset();
          // go(G.mp.maker.designs(getCurrentFcanvas()), (cv_objs) => {
          //   if (cv_objs?.length) {
          //     each((cv_obj) => NewMakerCallConditionPopupF.setAndRender(cv_obj));
          //   } else {
          //     NewMakerCallConditionPopupF.uiRender();
          //   }
          // });
        },
      );
    },

    undo: async function () {
      if (!state_index) return;
      const base_product = getBaseProductInMaker();
      if (base_product.maker_features?.[NewMakerPropertyBaseProductConstantS.UNABLE_UNDO_REDO]) return;
      await _undo_redo(--state_index);
      NewMakerCallConditionPopupF.reset();
    },

    redo: async function () {
      if (state_index >= stack.length - 1) return;
      const base_product = getBaseProductInMaker();
      if (base_product.maker_features?.[NewMakerPropertyBaseProductConstantS.UNABLE_UNDO_REDO]) return;
      await _undo_redo(++state_index);
      NewMakerCallConditionPopupF.reset();
    },

    push: push,
    getCurrentStateIndex: () => {
      return state_index;
    },
  };

  function current() {
    return stack[state_index];
  }

  function start() {
    stack = [];
    state_index = -1;
    render_view();
    return go(
      box.sel('maker->canvass'),
      map((canvas) => map(makeCvObjToJSON, G.mp.maker.designs(canvas))),
      function (objectss) {
        start_state = {
          canvas_idx: box.sel('maker->editing_canvas_idx'),
          objectss: objectss,
        };
        OMPDosuF.validation.update.faceValidationText();
      },
    );
  }

  async function _undo_redo(state_index) {
    const shared_product = box.sel('shared_product');
    if (shared_product) {
      const current_stack = stack[stack.length - 1]?.canvas_idx;
      state_index = current_stack !== 0 ? state_index - 1 : state_index;

      NewMakerSharedProductF.renderSharedProductCanSaveButton(
        shared_product._original_state_index !== state_index,
      );
    }
    render_view();
    if (on) return;
    on = true;
    await undo_redo_body(state_index);
    OMPDosuF.validation.update.faceValidationText();
    G.mp.maker.lock.render_state();

    on = false;
  }

  function push(state) {
    // if (state) {
    //   stack.splice(++state_index);
    //   stack[state_index] = state;
    //   return;
    // }

    // return new Promise(function(resolve) {
    if (!stack) return;
    const fcanvas = box.sel('maker->editing_canvas');
    stack.splice(++state_index);
    const active_group = fcanvas.getActiveGroup();
    if (active_group) {
      const is_overflow = active_group.is_overflow;
      const is_oversized = active_group.is_oversized;
      const last_cleared_pass = G.mp.maker.cleared_pass;
      const last_selected_pass = G.mp.maker.selected_pass;
      G.mp.maker.cleared_pass = true;
      G.mp.maker.selected_pass = true;
      const _objects = active_group._objects;
      G.mp.maker.unselect_all(fcanvas);
      if (fcanvas.fcanvas_data?.maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR) {
        G.mp.maker.multiple_select(_objects, fcanvas);
        NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(G.mp.maker.active());
        G.mp.maker.unselect_all(fcanvas);
      }
      _push(fcanvas);
      G.mp.maker.multiple_select(_objects, fcanvas);
      fcanvas.getActiveGroup().is_overflow = is_overflow;
      fcanvas.getActiveGroup().is_oversized = is_oversized;
      G.mp.maker.cleared_pass = last_cleared_pass;
      G.mp.maker.selected_pass = last_selected_pass;
    } else {
      return _push(fcanvas);
    }
  }

  function _push(fcanvas) {
    return go(
      getCvDesigns(fcanvas._objects),
      map((cv_obj) => {
        const _cv_obj = makeCvObjToJSON(cv_obj);
        _cv_obj.is_warning = cv_obj.is_warning;
        return _cv_obj;
      }),
      function (cloned_cv_objects) {
        const prev_canvas_idx = stack[stack.length - 1]?.canvas_idx;
        stack[state_index] = {
          cv_objects: cloned_cv_objects,
          canvas_idx: box.sel('maker->editing_canvas_idx'),
        };
        const shared_product = box.sel('shared_product');
        if (shared_product) {
          if (state_index > 0 && state_index <= shared_product._original_state_index) {
            shared_product._original_state_index = null;
          }
        }
        if (prev_canvas_idx === box.sel('maker->editing_canvas_idx')) {
          NewMakerSharedProductF.renderSharedProductCanSaveButton(true);
        }
        render_view();
        OMPDosuF.validation.update.faceValidationText();
      },
    );
  }

  function reset_canvas(state_cv_objects, canvas) {
    return new Promise(function (resolve) {
      canvas = canvas || box.sel('maker->editing_canvas');
      if (canvas.getActiveGroup()) G.mp.maker.remove_temp_group(canvas, canvas.getActiveGroup());
      const cv_objects = getCvDesigns(canvas._objects);
      if (canvas.preview?.on) removeCvPreview(canvas, true, true);
      canvas._is_bulk_added = true;
      G.mp.maker.removed_pass = true;
      G.mp.maker.added_pass = true;
      go(
        state_cv_objects,
        mapC(G.mp.maker.from_cv_attrs_for_maker),
        chunk(10),
        ippL,
        each(async ([i, cv_objs]) => {
          if (i > 0) {
            await delay(50, () => {});
          }
          await each(async (cv_obj) => {
            canvas.add(cv_obj);
            orderFaceBackgroundInMaker(canvas);
          }, cv_objs);
        }),
        () => each((cv_object) => canvas.remove(cv_object), cv_objects),
        async function () {
          G.mp.maker.removed_pass = false;
          G.mp.maker.added_pass = false;
          if (canvas.preview) {
            await addCvPreview(canvas, true);
            canvas.renderAll();
          }
          G.mp.maker.pinch_mode.render();
          await G.mp.maker.reset_layer();
          G.mp.maker.design_collection_type_setting();
          canvas.renderAll();
          canvas._is_bulk_added = false;
          // await NewMakerCallConditionPopupF.reset();
        },
        resolve,
      );
    });
  }
})({});
