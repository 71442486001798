import { CustomEventWithDetail, html, on, View } from 'rune-ts';
import { makePopupScreenNavigate, makeUtilScreenNavigate } from '../../../../shared/app/navigate';
import { Locals } from '../../../../shared/type/locals';
import { getResizedImage100WebpUrl } from '../../../../shared/util/getResizedImageUrl';
import { QuickMenuData, QuickMenuListView } from '../QuickMenu/QuickMenu';
import klass from './ShopProductCategory.module.scss';
import limited_klass from './LimitedEditionBanner.module.scss';
import { staticTypo, typo } from '../../../../shared/typography/typo';
import { ButtonText } from '../../atoms/ButtonText/ButtonText';
import { CloseIcon } from '../../atoms/Icon';
import { generateProductListUrl } from '../../../../features/ProductList/util';
import { AutoCompleteSearchInput } from '../SearchModule/view/AutoCompleteSearchInput';

type CateListViewData = {
  id?: number;
  label: string;
};

type CateItemViewData = {
  id?: number;
  cate_list_id?: number;
  label: string;
};

export type ShopProductCategoryData = {
  cate_lists: CateListViewData[];
  cate_items: CateItemViewData[];
  placeholder?: string;
};

export class ShopProductCategoryCloseClicked extends CustomEvent<{}> {}

export class ShopProductCategoryChangeEvent extends CustomEventWithDetail<{
  cate_list_id?: number;
  cate_item_id?: number;
}> {}

class CateListView extends ButtonText<CateListViewData> {
  constructor(data: CateListViewData, option: { is_active?: boolean } = {}) {
    super(data, {
      ...option,
      container_klass: `${klass.cate_view} ${klass.cate_list}`,
      active_klass: klass.cate_list_active,
      prevent_default_click: true,
    });
  }
}

class CateItemView extends ButtonText<CateItemViewData> {
  constructor(data: CateItemViewData, option: { is_active?: boolean } = {}) {
    super(data, {
      ...option,
      container_klass: `${klass.cate_view} ${klass.cate_item}`,
      data_id: data.cate_list_id,
      prevent_default_click: true,
      is_active: !!data.cate_list_id && !data.id,
    });
  }
}

class LimitedEditionView extends View {
  override template() {
    return html`<a class="${limited_klass.container}" href="${generateProductListUrl({ is_limited: true })}">
      <div class="${limited_klass.text_container}">
        <span class="${limited_klass.text} ${staticTypo('bebas_20_bold')}">LIMITED</span>
        <span class="${limited_klass.text} ${staticTypo('bebas_20_bold')}">EDITION</span>
      </div>
      <div class="${limited_klass.image_container}">
        <img
          class=${limited_klass.background_image}
          src="//s3.marpple.co/files/u_2232571/2024/5/original/3b6b77967541bf62f26ef2a5d3ca0e048b8ac5701.png"
        />
        <img
          class="${limited_klass.clover_image}"
          src="//s3.marpple.co/files/u_2232571/2024/5/original/f7c746797a65d8ad4aba6afd232ce8836f4dcb931.png"
        />
      </div>
    </a> `;
  }
}

export class ShopProductCategory extends View<ShopProductCategoryData> {
  constructor(
    data: ShopProductCategoryData,
    public option: {
      webviewapp?: Locals['webviewapp'];
      has_bottom_tab_bar?: boolean;

      // 활성화 할 대카테고리
      // cate_list_id?: number;

      // 활성화 할 중카테고리
      // cate_item_id?: number;
    } = {},
  ) {
    super(data, option);
  }

  static quick_menus = (): QuickMenuData[] => [
    {
      title_pc: ET('mps2::main::quick_menus::curation'),
      title_mo: ET('mps2::main::quick_menus::curation'),
      image: getResizedImage100WebpUrl(
        '//s3.marpple.co/files/u_2283830/2024/6/original/2b061eb19d4d9ba206a1cd270b23bdd071656cd11.png',
        300,
      ),
      href: `/${T.lang}/@/curations`,
      target: '_self',
    },
    {
      title_pc: ET('mps2::main::quick_menus::best_ranking'),
      title_mo: ET('mps2::main::quick_menus::best_ranking_mo'),
      image: getResizedImage100WebpUrl(
        '//s3.marpple.co/files/u_2283830/2024/6/original/64b7957be5a69617480165b7591f4454bdc41cfe2.png',
        300,
      ),
      navigate: makeUtilScreenNavigate(`/${ET.lang}/@/weekly_product`),
      href: `/${ET.lang}/@/weekly_product`,
      target: '_self',
    },
    {
      title_pc: ET('mps2::main::quick_menus::popup_store'),
      title_mo: ET('mps2::main::quick_menus::popup_store_mo'),
      image: getResizedImage100WebpUrl(
        '//s3.marpple.co/files/u_2283830/2024/6/original/ac46fdf1a0bd5d44facef1a3ef6d75f27be4e5522.png',
        300,
      ),
      navigate: makePopupScreenNavigate(),
      href: `/${T.lang}/@/popup-store`,
      target: '_self',
    },
    {
      title_pc: ET('mps2::main::quick_menus::photo_review'),
      title_mo: ET('mps2::main::quick_menus::photo_review_mo'),
      image: getResizedImage100WebpUrl(
        '//s3.marpple.co/files/u_2283830/2024/4/original/65beba8a37818d81772e946cc1beffac6de67e9a2.png',
        300,
      ),
      navigate: makeUtilScreenNavigate(`/${T.lang}/@/communities`),
      href: `/${T.lang}/@/communities`,
      target: '_self',
    },
  ];

  override template() {
    const [first_cate_item, ...rest_cate_items] = this.data.cate_items;
    const webviewapp = this.option.webviewapp;
    const has_bottom_tab_bar = this.option.has_bottom_tab_bar;

    return html`<div
      ${webviewapp?.is_tab_screen ? html`style="padding-bottom: ${62 + webviewapp.safe_area_bottom}px"` : ''}
      data-tabbar="${!!has_bottom_tab_bar}"
      class="${klass.shop_product_category} ${typo('14_medium')}"
    >
      <div class="${klass.search}">
        ${new AutoCompleteSearchInput({
          placeholder: this.data.placeholder,
          container_klass: klass.search_input,
          debounce_ms: 200,
          placeholder_gray50: true,
        })}
        <span class="${klass.close}">${CloseIcon()}</span>
      </div>
      ${new QuickMenuListView(ShopProductCategory.quick_menus(), { is_fit: true, is_mobile: true })}
      <div class="${klass.cate_slider}">
        <div class="${klass.cate_container} ${klass.cate_list_container}">
          ${this.data.cate_lists.map((cate_list) => new CateListView(cate_list))}
        </div>
        <div class="${klass.cate_container} ${klass.cate_item_container}">
          ${new CateItemView(first_cate_item)} ${new LimitedEditionView({})}
          ${rest_cate_items.map((cate_item) => new CateItemView(cate_item))}
        </div>
      </div>
    </div>`;
  }

  @on('click', `.${klass.close}`)
  onClickClose() {
    this.dispatchEvent(ShopProductCategoryCloseClicked, {
      bubbles: true,
    });
  }

  override onRender() {
    let activeCateListView: CateListView | undefined;
    const cate_list_map = new Map<string, CateListView>();

    let activeCateItemView: CateItemView;
    const cate_item_map = new Map<number | undefined, CateItemView>();

    const cate_slider_el = this.element().querySelector(`.${klass.cate_slider}`) as HTMLElement;

    // 활성화 된 cate_item 변경
    const onCateListChange = (view: CateListView) => {
      if (activeCateListView) {
        activeCateListView.deactivate();
      }
      view.activate();
      activeCateListView = view;

      const cate_list_el = view.element();
      const cate_list_rect = cate_list_el.getBoundingClientRect();
      const slider_rect = cate_slider_el.getBoundingClientRect();

      // 활성화할 cate_list 가 윗부분에서 가려질 때
      if (cate_list_rect.top < slider_rect.top) {
        cate_list_el.scrollIntoView({ block: 'start' });
        // 활성화할 cate_list 가 아래 부분에서 가려질 때
      } else if (cate_list_rect.bottom > slider_rect.bottom) {
        cate_list_el.scrollIntoView({ block: 'end' });
      }
    };

    // 현재 보여지는 cate_item 컨테이너에서 가장 상위의 cate_item 이 변경될 때 실행하는 함수
    const onFirstCateItemChanged = (el: HTMLElement) => {
      const cate_list_id = el.dataset.id ?? 'undefined';
      const cateListViewToFocus = cate_list_map.get(cate_list_id);
      if (!cateListViewToFocus) {
        throw new Error();
      }
      onCateListChange(cateListViewToFocus);
    };

    // cate item이 스크롤될 때 가장 상단에 있는 cate item을 감시
    // cate item이 속한 cate list의 활성화 상태를 변경해준다.
    const cateItemObserver = createCateItemObserver({
      container_el: cate_slider_el,
      onFirstCateItemChanged: (entry) => onFirstCateItemChanged(entry.target as HTMLElement),
    });

    // create CateItemView map
    this.subViews(CateItemView).forEach((view) => {
      const { cate_list_id } = view.data;
      if (!cate_item_map.has(cate_list_id)) {
        cate_item_map.set(cate_list_id, view);
      }
      cateItemObserver.observe(view.element());
    });

    // create CateListView map
    this.subViews(CateListView).forEach((view) => {
      const { id } = view.data;
      cate_list_map.set((id ?? 'undefined').toString(), view);
    });

    // 초기 활성화 카테고리 설정
    // this.subViews(CateItemView).forEach((cate_item_view) => {
    //   const { cate_list_id, id } = cate_item_view.data;
    //
    //   if (cate_list_id === this.option.cate_list_id && id === this.option.cate_item_id) {
    //     const cate_list_view = cate_list_map.get((cate_list_id ?? 'undefined').toString());
    //     if (!cate_list_view) {
    //       return;
    //     }
    //     activeCateListView = cate_list_view;
    //     activeCateListView.activate();
    //     activeCateListView.element().scrollIntoView({ block: 'start' });
    //
    //     activeCateItemView = cate_item_view;
    //     cate_item_view.activate();
    //     activeCateItemView.element().scrollIntoView({ block: 'start' });
    //   }
    // });

    this.delegate('click', CateListView, function onCateListClick(e, view) {
      const cate_item_view = cate_item_map.get(view.data.id);
      if (!cate_item_view) {
        throw new Error();
      }
      const cate_item_el = cate_item_view.element();
      cate_item_el.scrollIntoView({
        behavior: 'smooth',
      });
    });

    this.delegate('click', CateItemView, function onCateItemClick(e, view) {
      // if (activeCateItemView) {
      //   activeCateItemView.deactivate();
      // }
      // view.activate();
      // activeCateItemView = view;
      //
      this.dispatchEvent(ShopProductCategoryChangeEvent, {
        bubbles: true,
        detail: {
          cate_list_id: view.data.cate_list_id,
          cate_item_id: view.data.id,
        },
      });
    });
  }
}

const createCateItemObserver = ({
  container_el,
  onFirstCateItemChanged,
}: {
  container_el: HTMLElement;
  onFirstCateItemChanged: (entry: IntersectionObserverEntry) => void;
}): IntersectionObserver => {
  return new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        const boundingRect = entry.boundingClientRect;
        const intersectionRect = entry.intersectionRect;
        if (boundingRect.top < intersectionRect.top) {
          onFirstCateItemChanged(entry);
        }
      });
    },
    {
      threshold: [0.1, 0.9],
      root: container_el,
      rootMargin: '-10px',
    },
  );
};
