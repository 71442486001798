export const DOCUMENT_EVENT = {
  header_pinned: 'header_pinned',
  header_unpinned: 'header_unpinned',
  header_top: 'header_top',
  header_nottop: 'header_nottop',
  header_bottom: 'header_bottom',
  header_notbottom: 'header_notbottom',
  sub_header_menu_change: 'sub_header_menu_change',
} as const;

export const sendDocumentEvent = (event_name: keyof typeof DOCUMENT_EVENT, detail?: any) => {
  document.body.dispatchEvent(new CustomEvent(event_name, { detail }));
};

export const listenDocumentEvent = (
  event_name: keyof typeof DOCUMENT_EVENT,
  listener: (e: Event) => void,
) => {
  document.body.addEventListener(event_name, listener);
};
