import { html, strMap, values } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreLayoutTmplS } from '../../../Core/Layout/S/Tmpl/module/OMPCoreLayoutTmplS.js';
import { OMPAuthConstantS } from '../Constant/module/OMPAuthConstantS.js';
import { OMPAuthTmplS } from './module/OMPAuthTmplS.js';

export const signupModalTmpl = () => {
  const signup = TT('auth::signup::signup_01');
  const fb_login = TT('auth::signup::signup_01_01');

  const sign_up = OMPAuthConstantS.SIGN_UP();

  return html`<div class="omp-auth-signup">
    <form class="omp-auth-signup__data" method="POST" action="/${TT.lang}/@api/sign_up">
      <div class="omp-auth-signup__inputs">
        ${strMap((input) => {
          return html` <span class="omp-auth-signup__input-wrapper"> ${input.template()} </span> `;
        }, values(sign_up.inputs))}
      </div>
      <div class="omp-auth-signup__term-wrapper">
        <div class="omp-auth-signup__checkbox-wrapper">
          ${OMPCoreAtomTmplS.emptyCheckbox({
            name: 'agree_all',
            title: TT('auth::signup::signup_11'),
            style: { size: 'small' },
          })}
        </div>
        <hr />
        ${strMap((terms) => terms.template(), values(sign_up.terms))}
      </div>
      <div class="omp-auth-signup__button-wrapper">
        ${OMPCoreAtomTmplS.actionButton({
          klass: 'omp-auth-signup__data-submit',
          solidity: 'thick',
          text: signup,
        })}
        <div class="omp-auth-signup__or">OR</div>
        ${OMPCoreAtomTmplS.actionButton({
          klass: 'omp-auth__facebook',
          text: fb_login,
          solidity: 'thick',
          type: 'button',
          data: { color: 'blue' },
        })}
      </div>
    </form>
  </div> `;
};

export const signupPageTmpl = (locals) => {
  return OMPCoreLayoutTmplS.bodyTmpl({
    locals,
    body: html`
      <div class="omp-auth-page">
        <div class="omp-auth-page__title">
          <a href="/${TT.lang}/signup" data-active="true"> ${TT('auth::signup::signup_01')} </a>
          <a href="/${TT.lang}/login" data-active="false"> ${TT('auth::login::login_01')} </a>
        </div>

        ${OMPAuthTmplS.signupModalTmpl()}
      </div>
    `,
  });
};
