import { $qs } from 'fxdom/es';
import { BpOptionS } from '../../../../BpOption/S/Function/module/BpOptionS.js';

export const setDataUploadTypeIsDesigned = () => {
  const _product = box.sel('maker->product_color');
  if (!_product) return;
  const is_designed = BpOptionS.isDesigned(_product);
  const mp_maker_el = $qs('#maker_frame');
  if (mp_maker_el) mp_maker_el.dataset.upload_type_is_designed = is_designed;
};
