import { go, html } from 'fxjs/es';
import { editOldCvTextFontStyle } from '../../../../Maker/F/text.js';
import { $attr, $delegate, $hasClass } from 'fxdom/es';
import { openMobileKeyboardA } from '../../../../Maker/F/CvTextImage/frame.js';
import { openMakerImageColorFrame } from '../../../../Maker/F/CvImage/frame.js';
import { openCvPatternFrame } from '../../../../Maker/F/CvPattern/frame.js';
import { changeLineSticker } from '../../../../Maker/F/image.js';
import { NewMakerCvObjectCvImageF } from '../../../CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { errorHandlingEventWrapper } from '../../../Error/F/Function/errorHandlingEventWrapper.js';
import { NewMakerFitPrintWidthF } from '../../../FitPrintWidth/F/Function/module/NewMakerFitPrintWidthF.js';
import { SimpleModalMuiF } from '../../../../SimpleModal/F/Mui/module/SimpleModalMuiF.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { NewMakerUtilF } from '../../../Util/F/Function/module/NewMakerUtilF.js';
import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import { NewMakerPropertyBpfF } from '../../../Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';
import { getCurrentFcanvas } from '../../../../Maker/F/Fcanvas/cv_object.js';

export const event = (mp_maker) =>
  go(
    mp_maker,
    $delegate(
      'click',
      '.btm_menu',
      errorHandlingEventWrapper(function (e) {
        if (e.target && $hasClass('btm_menu')(e.target)) G.mp.maker.unselect_all();
      }),
    ),
    $delegate(
      'click',
      '.btm_menu .icon',
      errorHandlingEventWrapper(function () {
        G.mp.maker.hide_mobile_tip();
      }),
    ),
    $delegate(
      'click',
      '.btm_menu.text_editor .font, .btm_menu.text_pattern_editor .font',
      errorHandlingEventWrapper(function () {
        if ($1('.mp_maker.cv_old_text')) {
          editOldCvTextFontStyle();
          return;
        }
        G.mp.maker.zoom.by_active1(G.mp.maker.active(), 280 + G.mp.frame.v_height);

        // el_class: 'after_bar __text_temp_transparent_bar',
        const mp_maker_el = $1('#maker_frame');
        if (NewMakerPropertyBpfF.bpcColorCode2Render.isTarget(getCurrentFcanvas())) {
          $.frame.open(
            {
              frame_name: 'maker.m_text',
              page_name: 'm_text_char_page4',
            },
            {
              page_name: 'm_text_char_page4',
              el_class: 'after_bar __text_temp_transparent_bar',
            },
          );
        } else if ($attr('is_shade')(mp_maker_el) === 'true') {
          if (mp_maker_el.dataset.is_carved_phonecase_product === 'true') {
            $.frame.open(
              {
                frame_name: 'maker.m_text',
                page_name: 'm_text_char_page2',
              },
              {
                page_name: 'm_text_char_page2',
                el_class: 'after_bar __text_temp_transparent_bar is_carved_phonecase_product',
              },
            );
          } else {
            $.frame.open(
              {
                frame_name: 'maker.m_text',
                page_name: 'm_text_char_page2',
              },
              {
                page_name: 'm_text_char_page2',
                el_class: 'after_bar __text_temp_transparent_bar',
              },
            );
          }
        } else if (mp_maker_el.dataset.is_embroidery_app === 'true') {
          $.frame.open({
            frame_name: 'maker.m_text',
            page_name: 'm_text_char_page3',
          });
        } else {
          $.frame.open({
            frame_name: 'maker.m_text',
            page_name: 'm_text_char_page',
          });
        }
      }),
    ),

    $delegate(
      'click',
      '.btm_menu.text_editor .keyboard',
      errorHandlingEventWrapper(function () {
        return openMobileKeyboardA();
      }),
    ),

    $delegate(
      'click',
      '.btm_menu.image_editor .color',
      errorHandlingEventWrapper(function () {
        openMakerImageColorFrame();
      }),
    ),

    $delegate(
      'click',
      '.btm_menu.image_editor .dosu_image_color',
      errorHandlingEventWrapper(function () {
        openCvPatternFrame({
          is_with_image_color_picker: BpOptionF.biz.get.currentFace.hasDosuColorOptions(),
          open_tab_name: 'm_image_dosu_color_picker',
        });
      }),
    ),

    $delegate(
      'click',
      '.btm_menu.image_editor .pattern',
      errorHandlingEventWrapper(function () {
        openCvPatternFrame({
          is_with_image_color_picker: BpOptionF.biz.get.currentFace.hasDosuColorOptions(),
          open_tab_name: 'm_image_pattern',
        });
      }),
    ),

    $delegate('click', '.btm_menu.image_editor .change_img', errorHandlingEventWrapper(changeLineSticker)),

    $delegate(
      'click',
      '.btm_menu.image_editor .request_bg_removal',
      errorHandlingEventWrapper(function (e) {
        // $.toggle_class(e.currentTarget, 'checked');
        // NewMakerCvObjectCvImageF.setRequestBgRemoval(
        //   G.mp.maker.active(),
        //   $.has_class(e.currentTarget, 'checked'),
        // );
        const checked = $.has_class(e.currentTarget, 'checked');
        if (checked) {
          $.toggle_class(e.currentTarget, 'checked');
          NewMakerCvObjectCvImageF.setRequestBgRemoval(
            G.mp.maker.active(),
            $.has_class(e.currentTarget, 'checked'),
          );
          return;
        }
        MuiF.openFrame(SimpleModalMuiF.frame, async (frame, page, [tab]) => {
          tab.appended = (tab_el) =>
            go(
              tab_el,
              $delegate('click', '.done', () => {
                $.toggle_class(e.currentTarget, 'checked');
                NewMakerCvObjectCvImageF.setRequestBgRemoval(
                  G.mp.maker.active(),
                  $.has_class(e.currentTarget, 'checked'),
                );
                MuiF.closeFrame();
              }),
              $delegate('click', '.cancel', () => {
                MuiF.closeFrame();
              }),
            );
          tab.makeData = () => {
            return {
              header: '배경 지움 안내',
              body: html`
                <div class="bg_removal_body">
                  <img
                    src="//s3.marpple.co/files/u_193535/2022/6/original/d8bb30ab78f6573373e5a2215fe951e4b8a263e82.jpg"
                    alt=""
                  />
                  <div class="content">실제 사진의 경우 배경 지움이 불가합니다.</div>
                </div>
              `,
              footer: html`
                <button type="button" class="cancel">${T('취소')}</button>
                <button type="button" class="done" style="${NewMakerUtilF.getCssStyle()}">
                  ${T('Background Remove Request')}
                </button>
              `,
            };
          };
        });
      }),
    ),
    $delegate(
      'click',
      '.btm_menu.image_editor .fit_to_print_width',
      errorHandlingEventWrapper(function (e) {
        $.toggle_class(e.currentTarget, 'checked');
        if ($.has_class(e.currentTarget, 'checked')) {
          const cv_obj = G.mp.maker.active();
          NewMakerFitPrintWidthF.activateFitToPrintWidth(cv_obj, cv_obj.canvas);
          cv_obj.canvas.renderAll();
        } else NewMakerFitPrintWidthF.cancelFitToPrintWidth(G.mp.maker.active());
      }),
    ),
  );
