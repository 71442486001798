import { filter, map, go, flatMap, reject } from 'fxjs/es';

export const getBpOptionGroupsOfBpfId = (bpf_id, base_product) => {
  return go(
    base_product._.base_products_bp_option_groups,
    filter((base_products_bp_option_group) => base_products_bp_option_group.base_product_face_id == bpf_id),
    map((base_products_bp_option_group) => base_products_bp_option_group._.bp_option_group),
  );
};

export function getAllBpOptions() {
  return go(
    box.sel('maker->product_color->_->base_product->_->base_products_bp_option_groups'),
    reject((base_products_bp_option_group) => base_products_bp_option_group.base_product_face_id),
    flatMap((base_products_bp_option_group) => base_products_bp_option_group._.bp_option_group._.bp_options),
  );
}

export function getDefaultBpOptions() {
  return go(
    box.sel('maker->product_color->_->base_product->_->base_products_bp_option_groups'),
    reject((base_products_bp_option_group) => base_products_bp_option_group.base_product_face_id),
    map((base_products_bp_option_group) => base_products_bp_option_group.default_bp_option_id),
  );
}
