/**
 * @define 사용자의 pdf 파일을 템플릿 파일과 검증하는 함수
 * @param {PdfPrintingFileRow} pdf_printing_file
 * @param {PrintingTemplate | null} template
 * @return {Promise<{is_OK: true, reason:null} | {is_OK: false, reason: string}>} validation result
 * */
import { UtilStringS } from '../../../Util/String/S/Function/module/UtilStringS.js';
import { PDF_PRINTING_FILE_CLOUD_FRONT_HOST } from '../Constant/printtemplate.js';

export function validatePdfPrintingFile({ pdf_printing_file, template }) {
  const response = {
    is_OK: false,
    reason: '',
  };

  const { doc_version, author: author_user } = pdf_printing_file;

  if (template == null) {
    return (response.reason = 'Not exist print template') && response;
  }

  const { author: author_template, v_major: v_major_template } = template;
  const v_major_user = parseInt(UtilStringS.extractNumbersFromString(doc_version?.split('.')?.[0]));
  if (v_major_template !== v_major_user) {
    return (response.reason = 'version mismatch') && response;
  }

  if (author_template !== author_user) {
    return (response.reason = 'author mismatch') && response;
  }

  return { is_OK: true, reason: null };
}

/**
 * @param {string} s3_url
 * @return {string} cloudfront url
 */
export function printingTemplateS3UrlToCloudFrontUrl(s3_url) {
  const url = new URL(s3_url);
  url.host = PDF_PRINTING_FILE_CLOUD_FRONT_HOST;

  return decodeURI(url.toString());
}
