import { html, compact, each, find, go, last, pipe, reject, tap } from 'fxjs/es';
import anime from 'animejs';
import { is_shared_product_design_changed } from '../shared_product.js';
import { marpplizer_home } from './home.js';
import { marpplizer_cv_text_image } from './cv_text_image.js';
import {
  $appendTo,
  $attr,
  $delegate,
  $el,
  $find,
  $hide,
  $on,
  $qs,
  $qsa,
  $remove,
  $setAttr,
  $setCss,
  $show,
} from 'fxdom/es';
import { marpplizerCvOldTextImage } from './cv_old_text.js';
import { marpplizerImage } from './cv_image.js';
import { makeOpacityFunctionEvent } from '../opacity.js';
import { setIScroll } from './fs.js';
import { isShadeStyle } from '../CvPreview/Shade/render.js';
import { getCurrentFcanvas } from '../Fcanvas/cv_object.js';
import { NewMakerRangeSliderF } from '../../../NewMaker/RangeSlider/F/Function/module/NewMakerRangeSliderF.js';
import { NewMakerCvObjectCvBackgroundF } from '../../../NewMaker/CvObject/CvBackground/F/Function/module/NewMakerCvObjectCvBackgroundF.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import tippy from 'tippy.js';
import { NewMakerCallConditionPopupF } from '../../../NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { NewMakerConditionAgreeTextF } from '../../../NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';

let is_first = true;

function hide(el, to_right) {
  return anime({
    targets: [el],
    translateX: to_right ? '20%' : '-20%',
    opacity: 0,
    duration: 200,
    easing: 'easeInOutQuart',
  }).finished;
}
function show(el) {
  return anime({
    targets: [el],
    translateX: 0,
    opacity: 1,
    duration: 200,
    easing: 'easeInOutQuart',
  }).finished;
}

let queue = [];

export function startMarpplizer() {
  is_first = true;
  queue = [];
}

function getRoute(cv_type) {
  if (cv_type === 'cv_image' || cv_type === 'cv_pattern') return 'image';
  else if (cv_type === 'cv_text') return 'text';
  else if (cv_type === 'cv_text_image' || cv_type === 'cv_text_image_pattern') return 'text';
  else if (cv_type === 'cv_old_text') return 'old_text';
  else if (cv_type === 'cv_background') return 'cv_background';
  else return 'home';
}

export const mpMakerLoaderStart = function () {
  mpMakerLoaderEnd();
  return go(html` <div class="don_loader_mp_maker_wrap"></div> `, $el, $appendTo($qs('body')));
};
export const mpMakerLoaderEnd = function () {
  go([$qs('.don_loader_mp_maker_wrap')], compact, each($remove));
};

export async function marpplizerProxy(cv_active) {
  if (box.sel('shared_product')) {
    // vector 옵션 바뀔때 변경사항 저장하기 활성화
    is_shared_product_design_changed();
  }
  if (UtilF.isLegacyMobile()) return;
  const cv_type =
    (isShadeStyle(getCurrentFcanvas()) && cv_active && cv_active._data.cv_type === 'cv_image') ||
    cv_active?._data?.is_embro
      ? 'home'
      : cv_active && cv_active._data?.cv_type;
  const route = getRoute(cv_type);
  try {
    mpMakerLoaderStart();
    await openMarpplizer(route, cv_active || { cid: _p.uniqueId('c') });
    queue.shift();
  } catch (e) {
    if (e.message === 'no_active' || e.message === 'wrong_data' || e.message === 'same_cid') {
      /*
       * TODO jip 언젠가......
       * */
    } else {
      console.error(e);
    }
    queue.shift();
  } finally {
    mpMakerLoaderEnd();
    $.don_loader_end();
  }
}

const event = $delegate('click', '.marpplizer_back_btn', async function () {
  G.mp.maker.unselect_all();
});

function destroyTippy() {
  const marpplizer_el = $qs('#marpplizer');
  if (!marpplizer_el) return;
  if (marpplizer_el.__tippies?.length) {
    go(
      marpplizer_el.__tippies,
      each((_tippy) => _tippy.destroy()),
    );
    marpplizer_el.__tippies = [];
  }
}
const removed_tooltips = new Set();
function activeTippy(marpplizer_contents_wrapper_el, tooltips) {
  // const is_shared_product = $qs('.mp_maker').dataset.is_shared_product === 'true';
  const marpplizer_el = $qs('#marpplizer');
  if (!marpplizer_el) return;
  marpplizer_el.__tippies = [];
  go(
    tooltips,
    reject((tooltip) => removed_tooltips.has(tooltip.target_unique_class)),
    each((tooltip) => {
      const target_el = go(marpplizer_contents_wrapper_el, $find(tooltip.target_unique_class));
      if (!target_el) return;
      // if (
      //   is_shared_product &&
      //   tooltip.target_unique_class !== '#to_save_changes_vector' &&
      //   tooltip.target_unique_class !== '#to_save_changes'
      // )
      //   return;
      marpplizer_el.__tippies.push(
        tippy(target_el, {
          content: tooltip.content,
          allowHTML: true,
          theme: 'maker-marpplizer',
          showOnCreate: true,
          placement: 'top',
          maxWidth: 'none',
          appendTo: marpplizer_contents_wrapper_el,
          trigger: 'manual',
          onShown(instance) {
            go(
              instance.popper,
              $on('click', () => {
                instance.destroy();
                removed_tooltips.add(tooltip.target_unique_class);
              }),
              $setCss({ 'pointer-events': 'initial' }),
            );
            // ...
          },
          hideOnClick: false,
        }),
      );
    }),
  );
}

const afterAppended = (activity_attr) =>
  pipe(tap(activity_attr.event), NewMakerRangeSliderF.start, setIScroll, event, makeOpacityFunctionEvent);

async function showHideAsync(activity_attr, last_queue) {
  const marpple_home = $qs('#marpplizer .marpplizer_wrapper >.home');
  const activitys = $qs('#marpplizer .marpplizer_wrapper >.activitys');
  if (activity_attr.app === 'base1') {
    if ($attr('app', $1('#marpplizer')) === 'base1') return;
    $setAttr({ app: last_queue.activity_attr.app }, $qs('#marpplizer'));
    await Promise.all([show(marpple_home), hide(activitys, true)]);
  } else {
    if ($attr('app', $1('#marpplizer')) === 'base2') return;
    $setAttr({ app: last_queue.activity_attr.app }, $qs('#marpplizer'));
    await Promise.all([show(activitys), hide(marpple_home)]);
  }
}

async function render(activity_attr) {
  destroyTippy();
  if (activity_attr.app === 'base1') {
    const marpple_home = $qs('#marpplizer .marpplizer_wrapper >.home');
    await go(
      undefined,
      () => {
        if (activity_attr.remove) activity_attr.remove(marpple_home);
        return go($el(activity_attr.html), $appendTo(marpple_home), afterAppended(activity_attr));
      },
      (el) => {
        if (activity_attr.tooltips?.length) {
          activeTippy(go(el, $find('.marpplizer_contents_wrapper')), activity_attr.tooltips);
        }
      },
    );
    return $find('.activity', marpple_home);
  } else {
    return go(
      $el(activity_attr.html),
      $appendTo($qs('#marpplizer .marpplizer_wrapper >.activitys')),
      afterAppended(activity_attr),
    );
  }
}

const openMarpplizer = function (route, cv_active) {
  const cid = cv_active.cid;
  if (find((v) => v.cid === cid, queue)) throw new Error('same_cid');
  queue.push({ route, cid: cv_active.cid });
  return go(
    undefined,
    () => {
      if (route === 'home') return marpplizer_home();
      if (route === 'text') return marpplizer_cv_text_image();
      if (route === 'old_text') return marpplizerCvOldTextImage();
      if (route === 'image') return marpplizerImage();
      if (route === 'cv_background') return NewMakerCvObjectCvBackgroundF.marpplizerCvBackground();
    },
    tap((activity_attr) => {
      find((v) => v.cid === cid, queue).activity_attr = activity_attr;
    }),
    render,
    tap(() => {
      // NOTE: 조건 팝업 매핑된 상품일 시 디자인할 요소가 비활성화 되었을 시에는 장바구니 담기 버튼 x
      const general_option_buttons = $qs('.general_option_buttons');
      if (route === 'home') {
        $show(general_option_buttons);
        const shared_product = box.sel('shared_product');
        if (shared_product) {
          const x_image = html`<img src="//s3.marpple.co/files/u_193535/2024/3/original/846518fd0bf13a97986b396befa3002aac127f771.png" style="width:8px;height:8px;margin-left:8px;" alt=""></img>`;
          activeTippy($qs('#marpplizer'), [
            {
              content: go(
                html`
                  <div style="text-align: center;">
                    <span>${TT('maker_upload_type::디자인을 저장하지 않으면 변경사항이 사라져요!')}</span
                    >${x_image}
                  </div>
                `,
                $el,
              ),
              target_unique_class: '#to_save_changes',
            },
            {
              content: go(
                html`
                  <div style="text-align: center;">
                    <span>${TT('maker_upload_type::디자인을 저장하지 않으면 변경사항이 사라져요!')}</span
                    >${x_image}
                  </div>
                `,
                $el,
              ),
              target_unique_class: '#to_save_changes_vector',
            },
          ]);
        }
      } else {
        $hide(general_option_buttons);
      }
    }),
    tap((el) => {
      find((v) => v.cid === cid, queue).el = el;
    }),
    async () => {
      const last_queue = last(queue);
      if (last_queue.cid !== cid) return;
      await showHideAsync(last_queue.activity_attr, last_queue);
    },
    function () {
      const last_queue = last(queue);
      if (last_queue.cid !== cid) return;
      const { activity_attr } = last_queue;
      const activity_els = $qsa('#marpplizer .marpplizer_wrapper >.activitys .activity');
      if (activity_attr.app === 'base1') {
        go(activity_els, each($remove));
      } else {
        if (activity_els.length > 1)
          go(
            activity_els,
            reject((el) => {
              return el === last_queue.el;
            }),
            each($remove),
          );
      }
    },
  );
};

export const resetMainConditionAgreeText = () => {
  const condition_agree_text_el = $qs('.general_option_buttons .condition_agree_text');
  if (!condition_agree_text_el) return;

  const html_warning = NewMakerConditionAgreeTextF.fn(null, true);
  condition_agree_text_el.innerHTML = html_warning;
};

export const makeMarpplizerHtml = () => {
  const btn_disabled =
    NewMakerCallConditionPopupF.is_mapping_confirm_popup() &&
    NewMakerConditionAgreeTextF.getAgreeTextData(null, true)?.length;

  const html_warning = NewMakerConditionAgreeTextF.fn(null, true);
  const shared_product = box.sel('shared_product');
  return legacyHtml`
    <div id="marpplizer">
      <div class="wrapper iscroll_wrapper">
        <div class="marpplizer_wrapper iscroll_ul_wrapper">
          <div class="home"></div>
          <div class="activitys" style="opacity:0; transform: translateX(20%);"></div>
        </div>
      </div>
      <div class="general_option_buttons">
        <div class="condition_agree_text" data-route="home">${html_warning}</div>
        ${
          shared_product
            ? html`<button
                id="to_save_changes"
                can_save="false"
                class="buying marpplizer_footer make_share_product_save"
              >
                변경사항 저장하기
              </button>`
            : ''
        }
        ${
          G.collabo_type === '' && $.attr($1('html'), 'mp_worker_policy') && !shared_product
            ? html`<button id="to_share" class="buying marpplizer_footer make_share_product">
                상품 링크 생성하기
              </button>`
            : ''
        }
        ${
          G.collabo_type === ''
            ? shared_product
              ? html`<div class="shared_product_editing_buttons">
                  <button id="to_vector_editor" type="button">${T('Start Creating')}</button
                  ><button
                    id="to_save_changes_vector"
                    can_save="false"
                    is_saved="false"
                    class="buying marpplizer_footer make_share_product_save"
                  ></button>
                </div>`
              : html`<button id="to_vector_editor" type="button">${T('Start Creating')}</button>`
            : html`
                <div id="to_vector_editor">
                  <div class="border_box">
                    <div class="text">${T('Start Creating')}</div>
                    <div class="border"></div>
                    <div class="one deco"></div>
                    <div class="two deco"></div>
                    <div class="three deco"></div>
                    <div class="four deco"></div>
                  </div>
                </div>
              `
        }
              
        <button id="to_option" class="buying marpplizer_footer maker_to_cart mp_maker_done ${
          btn_disabled ? 'btn_disabled' : ''
        }" type="button">${T('Add To Cart')}</button>
        <button id="to_next" class="buying marpplizer_footer maker_go_next mp_maker_done ${
          btn_disabled ? 'btn_disabled' : ''
        }" type="button">${T('Design Complete')}</button>
        <button id="to_direct_custom" class="buying marpplizer_footer" type="button" style="display: none;">${T(
          '바로구매',
        )}</button>
      </div>
    </div>
  `;
};
