import { makeQueryString } from '../../../../modules/Util/S/Function/util';

const RESIZE_IMAGE_URL = 'image1.marpple.co';

const is_gif = (url) => /.+\.gif$/.test(url);

const addProtocol = (url) => ([/^http/, /^https/].some((re) => re.test(url)) ? url : 'https:' + url);

export interface ResizedUrlOption {
  url: string;
  width?: number;
  quality?: number;
  height?: number;
  format?: string;
  bg?: string;
  r?: number;
}

/**
 * Constructs a URL with specified resizing options.
 *
 * @param option - The options for resizing the image.
 * @param option.url - The URL of the image to resize.
 * @param option.quality - The quality of the resized image (1-100).
 * @param option.height - The desired height of the image.
 * @param option.width - The desired width of the image.
 * @param option.format - The desired format of the output image (e.g., 'jpg', 'png').
 * @param option.bg - The background color, applicable if the image has transparency.
 * @param option.r - ???
 * @return The constructed URL that incorporates all resizing parameters.
 */
export const getResizedUrl = (option: ResizedUrlOption): string => {
  const { url, quality, height, width, format, bg, r } = option;

  if (!url) {
    throw new TypeError(`url is required.`);
  }
  if (is_gif(url)) {
    return url;
  }

  const url_obj = new URL(addProtocol(url));
  const params = { q: quality, h: height, w: width, f: format, bg, r };
  const qs = makeQueryString(params) || '';
  return `https://${RESIZE_IMAGE_URL}${url_obj.pathname}?${qs}`;
};
