import { isValid, isMatch, parse } from 'date-fns';

/**
 * string 형식의 날짜를 Date 타입으로 변환한다.
 * @param {string} date_str
 * @param {string} format date-fns parse 함수의 dateFormat
 * @return {Date|null}
 */
export const parseStringifyDate = (date_str, format) => {
  // check invalid params
  if (!date_str || !format) {
    return null;
  }

  // trim string
  date_str = date_str.trim();
  if (!isMatch(date_str, format)) {
    return null;
  }

  const date = parse(date_str, format, new Date());
  return isValid(date) ? date : null;
};

/**
 * yyyyMMdd 형식의 string을 Date 타입으로 변환한다.
 * @param {string} date_str
 * @return {Date|null}
 */
export const parseStringifyDate_yyyyMMdd = (date_str) => {
  return parseStringifyDate(date_str, 'yyyyMMdd');
};
