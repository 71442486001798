import { BpOptionS } from './module/BpOptionS.js';
import { NewMakerUtilS } from '../../../NewMaker/Util/S/Function/module/NewMakerUtilS.js';
import { BpOptionUploadTypeS } from '../../UploadType/S/Function/module/BpOptionUploadTypeS.js';

export const isDesigned = (product) => {
  return (
    (BpOptionS.hasBpOptionListWithoutVectorEditor(product._.base_product) &&
      NewMakerUtilS.hasDesignsInFaces(product)) ||
    !!product.svg_product_id ||
    !!product._.svg_product_materials?.length ||
    !!BpOptionUploadTypeS.UPLOAD_TYPE_DIRECT.getPdfPrintingFile(product)
  );
};
