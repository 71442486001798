import { $append, $attr, $delegate, $el, $find, $hide, $on, $setAttr, $show } from 'fxdom/es';
import { go } from 'fxjs/es';
import StickySidebar from 'sticky-sidebar';
import { MShopShareFramePopUpF } from '../../../MShop/ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopStudioPcInfoF } from '../../../MShop/Studio/PcInfo/F/Function/module/MShopStudioPcInfoF.js';
import { MShopUtilF } from '../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { STUDIO_UPDATE_NOTE_URL } from '../S/constant.js';

const handleChangeLanguage = (evt) => {
  const { value } = evt.detail;
  const { pathname, search } = location;
  const newPath = pathname.replace(/^\/(kr|en|jp)/, `/${value}`);
  location.href = newPath + search;
};

const handleCloseMenu = () => {
  $show($find('#creator_settings_body', document.body));
  const $menu = $find('#creator_settings_menu', document.body);
  $setAttr({ 'menu-open': false }, $menu);
};

const handleClickMenuBtn = () => {
  $hide($find('#creator_settings_body', document.body));
  const $menu = $find('#creator_settings_menu', document.body);
  const isOpen = $attr('menu-open', $menu) === 'true';
  $setAttr({ 'menu-open': !isOpen }, $menu);
};

const handleWebviewClose = () => {
  MShopUtilF.postMessage({ goBack: true });
};

const handleUpdateNode = async (e) => {
  e.preventDefault();
  const is_first_visit = !window.localStorage.getItem('studio-first-visit');

  if (is_first_visit) {
    window.localStorage.setItem('studio-first-visit', true);
    if (
      !(await MShopShareFramePopUpF.confirm({
        title: T('✨ 업데이트 노트 ✨'),
        body: `마플샵의 업데이트와 개선사항을 알려드려요.<br />앞으로 더욱 새로워질 마플샵을 만나보세요!`,
        cancel: T('mshop::Close'),
        ok: T('보러 가기'),
      }))
    ) {
      return;
    }
  }
  window.open(STUDIO_UPDATE_NOTE_URL, '_blank');
};

export const defnCommonEvents = (el) => {
  if (!MShopUtilF.isMobile()) {
    new StickySidebar('#creator_settings_menu', {
      containerSelector: '#creator_settings',
      innerWrapperSelector: '.inner_menu_wrapper',
      resizeSensor: true,
      bottomSpacing: 20,
    });
  }

  // MShopUtilF.isWheelNumberBlur(['.body']);

  $on('load', async () => {
    if (typeof box == 'function' && box.sel('question_count') > 0) {
      const menu_question_el = $find('.question-list-a', el);
      if (menu_question_el) {
        $append(
          $el(`<span class="setting-menu-count">${box.sel('question_count')}</span>`),
          menu_question_el,
        );
      }
    }
  })(window);

  return go(
    el,
    $delegate('change.language', '[is="dropdown"].lang', handleChangeLanguage),
    $delegate('click', '#toggle_menu_btn', handleClickMenuBtn),
    $delegate('click', '#creator_settings_menu .close-btn .btn', handleCloseMenu),
    $delegate('click', '.seller-studio-closer .btn', handleWebviewClose),
    $delegate('click', '.studio-pc-info__close', MShopStudioPcInfoF.closePopup),
    $delegate('click', '.lnb-update-note', handleUpdateNode),
  );
};
