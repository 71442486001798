export const BASE_PRODUCT_COLOR_BY_BPC_ID_API_URL = '/@api/bpc_bpcf_bpf';
export const NEW_PRODUCT_COLOR_BY_BP_ID_BPC_ID_API_URL = '/@api/new_pc_by_bp_id_bpc_id';

export const BPF_WANRINGS = [
  {
    type: 'fill_dotted_line_for_phone_case',
    description: '위·아래·옆면까지 전체 인쇄를 원하시면 점선까지 채워주세요.',
    text: () => T('maker::Message::Fill the image to the dotted line for full-sided printing.'),
  },
  {
    type: 'fill_solid_line',
    description: '위·아래·옆면까지 전체 인쇄를 원하시면 실선까지 채워주세요.',
    text: () => T('maker::Message::Fill the image to the solid line for full-sided printing.'),
  },
  {
    type: 'need_in_safe_area',
    description: '중요한 이미지는 점선 내부에 배치해 주세요.',
    text: () => T('maker::Message::Place important images inside the dotted line.'),
  },
  {
    type: 'fill_dot_line',
    description: '전면 인쇄를 원하시면 바깥쪽 점선까지 디자인을 채워주세요.',
    text: () =>
      T('maker::Message::Please fill in the design to the outer dotted line to full print the image.'),
  },
  {
    type: 'fill_dot_line_absolutely',
    description: '제작의 안정성을 위해 디자인을 점선까지 채워주세요.',
    text: () => T('maker::Message::Please fill in the design to the dotted line for production safety.'),
  },
  {
    type: 'cancel_fit_width',
    description: `이미지 크기 조절이 필요한 경우, '양옆 채우기' 옵션을 해제하세요.`,
    text: () => T('maker::Message::cancel_fit_width'),
  },
  {
    type: 'border_1mm',
    description: '제품 특성상 테두리 1mm는 인쇄가 되지 않습니다.',
    text: () => T('maker::Message::border_1mm'),
  },
  {
    type: 'border_2mm',
    description: '제품 특성상 테두리 2mm는 인쇄가 되지 않습니다.',
    text: () => T('maker::Message::border_2mm'),
  },
  {
    type: 'embro_product',
    description: '업로드한 이미지 크기에 따라 별도의 추가비용이 발생할 수 있습니다.',
    text: () => T('maker::Message::embro_product'),
  },
  {
    type: 'important_in_green',
    description: '디자인 내 중요 요소는 초록색 점선 내에 배치해 주세요.',
    text: () => T('maker::Message::important_in_green'),
  },
  {
    type: 'important_in_green2',
    description:
      '전면 인쇄를 원하시면 바깥쪽 점선까지 디자인을 채워주시고/중요 요소는 초록색 점선 내에 배치해 주세요.',
    text: () =>
      T(
        'maker::Message::전면 인쇄를 원하시면 바깥쪽 점선까지 디자인을 채워주시고/중요 요소는 초록색 점선 내에 배치해 주세요.',
      ),
  },
  {
    type: 'only_design_backside',
    description: '이 상품은 뒷면만 인쇄가 가능합니다.',
    text: () => T('maker::Message::이 상품은 뒷면만 인쇄가 가능합니다.'),
  },
  {
    type: 'front_and_back_color_is_same',
    description: '앞면과 뒷면 모두 동일한 색상으로 인쇄돼요.',
    text: () => T('maker::Message::앞면과 뒷면 모두 동일한 색상으로 인쇄돼요.'),
  },
];

export const LOC_OPT_ACTIVATE_ATTR_NAME = 'is_activate_loc_optimization';
export const LOC_OPT_ENABLE_ATTR_NAME = 'is_enable_loc_optimization';
export const LOC_OPT_SEEN_PROOF = 'is_proof_of_seen_loc_optimization';
export const LOC_OPT_REQUEST_CS = 'is_cs_requested_loc_optimization';
