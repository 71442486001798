import { defaults, each, extend, filter, go, sortBy } from 'fxjs/es';
import { getCurrentFcanvas, getCvDesigns } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { getBpfOfFcanvas, getItsSf } from '../../../../Maker/F/getSth.js';
import { deepFindCvObjects } from '../../../../Maker/F/util.js';
import { plusStrokeWidth } from '../../../../Maker/F/Fcanvas/stroke.js';
import { $css, $qs, $setCss } from 'fxdom/es';
import { NewMakerBaseProductsKeyRingF } from '../../../BaseProducts/KeyRing/F/Function/module/NewMakerBaseProductsKeyRingF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

function upsert({ fcanvas, cv_obj, prev_cv_obj }) {
  if (prev_cv_obj) {
    fcanvas.insertAt(cv_obj, fcanvas._objects.indexOf(prev_cv_obj));
    fcanvas.remove(prev_cv_obj);
    if (prev_cv_obj._data.price) {
      cv_obj._data.price = prev_cv_obj._data.price;
      cv_obj._data.price_en = prev_cv_obj._data.price_en;
      cv_obj._data.price_jp = prev_cv_obj._data.price_jp;
    }
  } else {
    fcanvas.add(cv_obj);
  }
}

export async function render({ bp_option_ids: selected_bp_option_ids }) {
  const fcanvas = getCurrentFcanvas();
  try {
    if (!$qs('.process_time_loader')) $.don_loader_start();

    const cv_obj_actions_to_apply = go(
      getBpfOfFcanvas(fcanvas),
      (bpf) => bpf._?.cv_obj_actions,
      filter((cv_obj_action) => cv_obj_action.cv_attr),
      filter((cv_obj_action) => selected_bp_option_ids.includes(cv_obj_action.bp_option_id)),
      sortBy((cv_obj_action) => cv_obj_action.no),
    );
    if (!cv_obj_actions_to_apply?.length) return;
    const objects = fcanvas._objects;
    fcanvas.renderOnAddRemove = false;
    if (cv_obj_actions_to_apply.length > 1) $setCss({ visibility: 'hidden' }, fcanvas.lowerCanvasEl);
    const sf = getItsSf(fcanvas);
    await go(
      cv_obj_actions_to_apply,
      each(async (cv_obj_action) => {
        const { target_cv_type: cv_type, target_cid: cid, cv_attr } = cv_obj_action;
        const new_cv_attr = JSON.parse(JSON.stringify(cv_attr));
        if (cid) {
          const prev_cv_obj = deepFindCvObjects(objects, (cv_obj) => cv_obj.cid == cid);
          if (new_cv_attr?._data) {
            const cv_obj = await go(G.mp.maker._from_cv_attrs_for_maker(new_cv_attr, 1), G.mp.maker.nscreen);
            upsert({ fcanvas, cv_obj, prev_cv_obj });
            cv_obj.cid = cid;
          }
        } else if (cv_type) {
          const prev_cv_obj = deepFindCvObjects(objects, (cv_obj) => cv_obj._data.cv_type == cv_type);
          defaults(new_cv_attr, G.mp.maker.to_attrs2(prev_cv_obj.toJSON()));
          if (
            ['cv_print_area', 'cv_safety_area'].includes(new_cv_attr?._data?.cv_type) &&
            new_cv_attr?._data?._size
          ) {
            extend(new_cv_attr, go(new_cv_attr._data._size, plusStrokeWidth));
            const size = new_cv_attr._data._size;
            if (cv_type === 'cv_print_area') {
              sf.print.px = {
                rx: 0,
                ry: 0,
                scaleX: 1,
                scaleY: 1,
                ...size,
              };
            }
            if (cv_type === 'cv_safety_area') {
              sf.safety = {
                px: {
                  rx: 0,
                  ry: 0,
                  scaleX: 1,
                  scaleY: 1,
                  ...size,
                },
              };
            }
          }
          const cv_obj = await go(G.mp.maker._from_cv_attrs_for_maker(new_cv_attr, 1), G.mp.maker.nscreen);
          if (cv_obj._data.cv_type === 'cv_print_area' && cv_obj._data.real_width_cm) {
            fcanvas._px_per_1cm = cv_obj.width / cv_obj._data.real_width_cm;
            fcanvas._print_ratio = (2.54 * 150) / cv_obj.width;
            sf.px_per_1cm = fcanvas._px_per_1cm;
          }
          upsert({ fcanvas, cv_obj, prev_cv_obj });
        }
      }),
    );

    /*키링만 해당*/
    const main_cv_img = deepFindCvObjects(objects, (cv_obj) => cv_obj.cid == 'c2323');
    const gori_cv_img = deepFindCvObjects(objects, (cv_obj) => cv_obj.cid == 'c9898');

    if (main_cv_img && gori_cv_img) {
      const { image_height_cm } = main_cv_img._data;
      const { image_height_cm: gori_height_cm } = gori_cv_img._data;
      const px_per_1cm = UtilF.isLegacyMobile() ? 65 : 50;
      const max_cm = 500 / px_per_1cm;
      const ratio = image_height_cm > max_cm ? max_cm / image_height_cm : 1;
      sf.px_per_1cm = Math.max(px_per_1cm * ratio);
      const minus = Math.min(0, (gori_height_cm * sf.px_per_1cm - image_height_cm * sf.px_per_1cm) / 2);
      sf.print.etc_meta.hole_position.y = G.mp.maker.CANVAS_WIDTH_ORIGIN / 2 + minus;
      fcanvas._px_per_1cm = sf.px_per_1cm;
    }
    go(getCvDesigns(fcanvas._objects), each(NewMakerBaseProductsKeyRingF.setSizeByHoleAndPxPer1cm));
    /*키링만*/

    fcanvas.renderAll();
    go(getCvDesigns(fcanvas._objects), each(G.mp.maker.reset_data_cm));
    fcanvas.renderOnAddRemove = true;
  } finally {
    if ($css('visibility')(fcanvas.lowerCanvasEl) === 'hidden') {
      $setCss({ visibility: 'visible' })(fcanvas.lowerCanvasEl);
    }
    $.don_loader_end();
  }
}
