import { each, go } from 'fxjs/es';
import { getBaseProductInMaker, getCurrentBpcInMaker, getRealFcanvass } from '../getSth.js';
import { changeProductColorByBpc, renderFacesByCurrentBpcfsInMakerA } from '../mp_maker.js';
import { isNeedPreview, isWatchBand } from '../../S/categorize.js';
import { renderMakerPageShade } from '../CvPreview/Shade/render.js';
import { getCurrentFcanvas, getCvDesigns } from '../Fcanvas/cv_object.js';
import { marpplizerProxy } from '../Marpplizer/marpplizer.js';
import { NewMakerCallConditionPopupF } from '../../../NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { renderVisibleWhenSelected } from '../select_faces.js';

function makeColorName(bpc) {
  return bpc ? ($1('#dream_factory') ? bpc['name2' + _en] || bpc['name' + _en] : bpc['name' + _en]) : '';
}

export function getBpcTitle() {
  const bp = getBaseProductInMaker();
  if (isWatchBand(bp)) return T('Buckle Color');
  if (isNeedPreview(bp)) return T('maker::Preview');
  return T('Color');
}

export async function changeProductBpcA(bpc) {
  changeProductColorByBpc(bpc);
  await renderFacesByCurrentBpcfsInMakerA();
  if (G.mp.maker.editing_canvas().preview) {
    await go(
      getRealFcanvass(),
      each((fcanvas) => renderMakerPageShade(fcanvas, getCvDesigns(fcanvas._objects))),
    );
  }
  await G.mp.maker.reset_layer();
  NewMakerCallConditionPopupF.uiRender();
}
export async function eventProductBpcA(ct) {
  $.selectable3(ct);
  const bpc = box.sel(ct);
  G.mp.maker.unselect_all();
  if ($1('.color.section .bp_color_name')) $.text($1('.color.section .bp_color_name'), makeColorName(bpc));
  if ($1('.color_picker .bp_color_name')) $.text($1('.color_picker .bp_color_name'), makeColorName(bpc));
  await changeProductBpcA(bpc);
  await marpplizerProxy();

  if (NewMakerCallConditionPopupF.is_mapping_confirm_popup()) {
    renderVisibleWhenSelected(getCurrentFcanvas());
  }
}

export function getCurrentBpcName() {
  return go(getCurrentBpcInMaker(), makeColorName);
}
