import axios from 'axios';
import { defaults, each, go, keys, tap } from 'fxjs/es';
import {
  isAirPodCase,
  isBuzzCase,
  isCarrier,
  isCateItemPhoneCase,
  isCylinderStyle,
  isTinCase,
} from '../categorize.js';
import { hasVideoUrl } from '../CvBpcfVideo/fs.js';
import { getCurrentFcanvas, getCvObj } from '../Fcanvas/cv_object.js';
import { MAKER_STROKE_WIDTH, plusStrokeWidth } from '../Fcanvas/stroke.js';
import { bpcIsBlack, getBaseProductInMaker, getBpfOfFcanvas, isCanOverflow } from '../getSth.js';

export function getCvPrintArea() {
  return getCvObj(getCurrentFcanvas()._objects, 'cv_print_area');
}
export function makePrintAreaAttr(sf) {
  const rx = sf.print.px.rx || 0;
  const ry = sf.print.px.ry || 0;
  const stroke = go(undefined, function () {
    if (hasVideoUrl()) return '#000000';
    if (isCateItemPhoneCase()) return '#929292';
    if (bpcIsBlack()) return '#eaeaea';
    return '#2f2f2f';
  });
  const is_cylinder = isCylinderStyle(sf);
  const px = sf.print.px;
  return defaults(
    {
      selectable: false,
      evented: false,
      visible: false,
      stroke,
      strokeWidth: MAKER_STROKE_WIDTH,
      rx,
      ry,
      strokeDashArray: isCanOverflow() ? [6, 3] : null,
      fill: is_cylinder ? '#ccc' : 'rgba(0,0,0,0)',
      opacity: is_cylinder ? 0.3 : 1,
      _data: {
        cv_type: 'cv_print_area',
        is_not_design: true,
        print_px: px,
      },
    },
    plusStrokeWidth(px),
  );
}

export const makeCvPrintAreaInMaker = function (fcanvas, sf) {
  const last_cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
  if (last_cv_print_area) fcanvas.remove(last_cv_print_area);
  const bp = getBaseProductInMaker();
  if (isCateItemPhoneCase(bp)) {
    sf.print.px.rx = sf.print.px.rx === undefined ? 40 : sf.print.px.rx;
    sf.print.px.ry = sf.print.px.ry === undefined ? 40 : sf.print.px.ry;
  } else if (isAirPodCase(bp)) {
    sf.print.px.rx = 40;
    sf.print.px.ry = 40;
  } else if (isBuzzCase(bp)) {
    sf.print.px.rx = 48;
    sf.print.px.ry = 48;
  } else if (isCarrier(bp)) {
    sf.print.px.rx = 20;
    sf.print.px.ry = 20;
  } else if (isTinCase(bp)) {
    sf.print.px.radius = 48;
  }
  sf.print.px.rx = sf.print.px.rx || 0;
  sf.print.px.ry = sf.print.px.ry || 0;
  return go(
    new fabric.Rect(makePrintAreaAttr(sf, fcanvas)),
    G.mp.maker.nscreen,
    tap((cv_obj) => fcanvas.add(cv_obj)),
  );
};

export async function updateBaseProductFacePreview(fcanvas, preview, to_db) {
  const base_product_face = getBpfOfFcanvas(fcanvas);
  // preview = / ?
  if (!preview || keys(preview).length === 0) preview = null;
  if (to_db)
    await axios.post('/@api/base_product_faces/update_preview', { id: base_product_face.id, preview });
  base_product_face.preview = preview;
  return preview;
}

export async function updateBaseProductFaceSizeFaces(fcanvas, func, to_db) {
  const base_product_face = getBpfOfFcanvas(fcanvas);
  const size_faces = JSON.parse(JSON.stringify(base_product_face.size_faces));
  go(
    size_faces,
    each((sf) => func(sf)),
  );
  const data = {
    id: base_product_face.id,
    size_faces,
  };
  // go(data, JSON.stringify, $.alert);
  if (to_db) await axios.post('/@api/base_product_faces/size_faces_update', data);
  base_product_face.size_faces = size_faces;
  return size_faces;
}
