import { html } from 'fxjs/es';
import { kr20230125 } from './kr20230125.js';

export const kr20230118 = () => html`
  <div class="terms_body">
    <div class="title">
      <h1>[마플, 마플샵] 이용자 이용약관 변경 안내</h1>
    </div>
    <div class="body">
      <p>
        안녕하세요. 마플코퍼레이션입니다. 마플샵 (이하 "몰")의 이용약관의 일부 내용이 변경되어 2023년 1월
        25일자로 적용될 예정입니다. 이에 이용자 이용약관 개정에 관한 내용을 사전에 알려드리니 회원
        여러분께서는 새로운 약관을 확인하시고 참고하여 주시기를 바랍니다.
      </p>
      <p><b>변경고지일 : 2023년 1월 18일 변경 내용</b></p>
      <p>변경 내용</p>
      <table class="terms_preview">
        <tr>
          <th>변경 전</th>
          <th>변경 후</th>
        </tr>
        <tr>
          <td>
            <h3>제1조(목적)</h3>
            <p>
              이 약관은 (주)마플코퍼레이션(전자상거래 사업자)이 운영하는 [마플, 마플샵 (이하 "몰"이라 한다)]에서 제공하는 개발 및 주문인쇄제작(PRINT ON DEMAND)을 통한 전자상거래 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.
            </p>
          </td>
          <td>
            <h3>제1조(목적)</h3>
            <p>
              이 약관은 (주)마플코퍼레이션(전자상거래 사업자)이 운영하는 [마플, 마플샵 (이하 "몰"이라 한다)]에서 제공하는 개발 및 주문인쇄제작(PRINT ON DEMAND)을 통한 전자상거래 관련 서비스 및 판매자와 구매자가 상품과 용역을 거래할 수 있는 “마켓플레이스” 기능(이하 "서비스"라 한다)을 모두 이용함에 있어 사이버 "몰"과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <h3>제9조(구매신청)</h3>
            <ol>
              <li>1.  "몰"이용자는 "몰"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "몰"은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
                <ol>
                  <li>1. 재화 등의 검색 및 선택</li>
                </ol>
              </li>
            </ol>
          </td>
          <td>
            <h3>제9조(구매신청)</h3>
            <ol>
              <li>1.  "몰"이용자는 "몰"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "몰"은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
                <ol>
                  <li>
                    1. 재화 등의 검색 및 선택
                    <ol>
                      <li>
                        1-1. 마플샵 판매 상품의 정의
                        <ol>
                          <li>
                            1. 마플샵 배송 상품: ‘회사’가 ‘판매자회원’으로부터 ‘아이디어, 콘텐츠, 디자인’의
                            이용을 허락받아 의류, 핸드폰케이스, 악세사리 등으로, ‘회사’는 ‘구매자회원’과
                            ‘상품’에 대한 매매계약을 직접 체결함으로써 이를 "몰"을 통해 판매되는 상품을
                            의미합니다.
                          </li>
                          <li>
                            2. 셀러 배송 상품: ‘판매자회원'이 직접 ‘회사'에 직접 등록해서 판매하고 있는 상품,
                            ‘마플 배송 상품'과는 다르게 판매자회원이 직접 마플샵 위탁 제조 방식을 이용하지 않고
                            판매자회원이 상품을 자체적으로 공급하여 직접 판매하는 상품을 의미합니다.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            <h3>제11조(지급방법)</h3>
            <p>
              "몰"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수
              있습니다. 단, "몰"은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여
              징수할 수 없습니다.
            </p>
            <ol>
              <li>1. 인터넷뱅킹 등의 각종 계좌이체</li>
              <li>2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
              <li>3. 온라인무통장입금</li>
              <li>4. 마일리지 등 "몰"이 지급한 포인트에 의한 결제</li>
              <li>5. "몰"과 계약을 맺었거나 "몰"이 인정한 상품권에 의한 결제</li>
              <li>6. 기타 전자적 지급 방법에 의한 대금 지급 등</li>
            </ol>
          </td>
          <td>
            <h3>제11조(대금결제 및 구매안전 서비스)</h3>
            <ol>
              <li>
                1. "몰"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할
                수 있습니다. 단, "몰"은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도
                추가하여 징수할 수 없습니다.
                <ol>
                  <li>1. 인터넷뱅킹 등의 각종 계좌이체</li>
                  <li>2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
                  <li>3. 온라인무통장입금</li>
                  <li>4. 마일리지 등 "몰"이 지급한 포인트에 의한 결제</li>
                  <li>5. "몰"과 계약을 맺었거나 "몰"이 인정한 상품권에 의한 결제</li>
                  <li>6. 기타 전자적 지급 방법에 의한 대금 지급 등</li>
                </ol>
              </li>
              <li>
                2. "몰"은 이용자가 현금, 카드 기타의 방법으로 대금을 결제할 수 있는 방법을 제공하며, 이용자가
                대금 결제 시 사용한 결제수단에 대해 정당한 사용권한을 가지고 있는 지의 여부를 확인할 수
                있습니다.
              </li>
              <li>
                3. "몰"은 이용자가 상품을 주문한 후 일정 기간 내에 대금을 결제하지 않은 경우 당해 주문을
                이용자의 동의 없이 취소할 수 있으며, 본 조 제 3항의 확인이 완료될 때까지 거래진행을 중지하거나,
                확인이 불가능한 경우 해당 거래를 취소할 수 있습니다.
              </li>
              <li>
                4. "몰"은 서비스내에서 이루어지는 선불식 통신판매에 있어서 고객이 지급하는 대금을 예치하고
                배송이 완료된 후 재화 또는 용역의 대금을 판매자에게 지급함으로써 구매과정의 안전을 도모합니다.
              </li>
              <li>
                5. "몰"은 이용자와 판매자가 서비스를 이용함에 있어서 편리하게 금전 거래를 할 수 있도록
                결제대금예치 서비스(에스크로) 및 지급대행서비스를 제공합니다.
              </li>
              <li>
                6. "몰"은 이용자가 상품을 받은 날로부터 7영업일이 지나도록 배송완료 사실을 통보하지 아니한
                때에는 이용자가 상품을 받은 것으로 간주하여 판매자에게 매매대금을 지급할 수 있습니다.
              </li>
              <li>
                7. 분쟁 및 그 밖의 사유 발생시 "몰"은 합리적인 판단에 따라 이용자와 판매자에게 이용 제한 등의
                필요한 조치를 취할 수 있으며, 진행중인 거래의 서비스 대금 지급이나 서비스 대금을 환불 혹은
                보류할 수 있습니다.
              </li>
              <li>
                8. "몰"이 제공하는 구매안전 서비스를 이용하지 않고 회원간 직접 거래하는 행위(직거래)는 거래의
                안전을 위하여 금지되고, 직거래를 통하여 발생한 제반 문제에 대한 책임은 거래 당사자에게 있으며
                회사는 이에 대해 어떠한 책임도 지지 않습니다.
              </li>
              <li>
                9. 해당 결제대금예치서비스(에스크로)와 지급 대행 서비스는 금융위원회에 등록된 전자금융업 허가 및
                등록을 한 결제대행사를 통해 판매자회원을 대행하여 이용자가 판매자에게 지급하여야 할 자금의
                내역을 전자적인 방법으로 지급인에게 고지, 수수, 정산합니다.
              </li>
              <li>
                10. "몰"에서 결제한 모든 거래는 (주)KG이니시스의 결제대금예치 서비스 및 지급대행서비스가
                적용됩니다.
              </li>
              <li>
                11. 회사는 이용자와 판매자의 거래의 안정성과 편의성 향상을 목적으로 해당 서비스를 제공하고
                있으며 회사의 고의 또는 중대한 과실이 없는 한 해당 결제 서비스를 이용하는 당사자 간의 거래에
                어떠한 책임을 지지 않습니다.
              </li>
              <li>
                12. 회사는 자사의 결제 서비스를 사용하는 이용자 혹은 판매자의 어느 일방을 대리, 대행하거나 그
                이행을 보조하는 위치에 있지 않습니다. 회사는 거래 당사자 간 자금의 흐름에 직접 관여하거나
                개입하지 않으며, 회사와 계약을 체결한 결제대급예치업자가 이용자가 지불한 금액을 판매자에게
                전달합니다.
              </li>
              <li>
                13. 해당 결제서비스를 이용하는데 있어 잘못된 정보를 입력하여 잘못된 대금지급이 이루어졌을 경우,
                회사는 이에 책임을 지지 않습니다.
              </li>
              <li>
                14. 회원, 결제대행업체, 금융기관 등과의 사이에서 발생한 분쟁은 당사자 간의 해결을 원칙으로 하며,
                회사는 이와 관련한 어떠한 책임도 지지 않으며, 해당 사안의 결제 대행업체 또는 금융기관의 약관이
                우선됩니다.
              </li>
              <li>
                15. "몰"의 대금 결제 및 구매안전 서비스에 대해서는
                <a href="https://www.inicis.com/terms" target="_blank">(주)KG이니시스의 전자금융거래약관</a>이
                적용됩니다.
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            <h3>제13조(재화 등의 공급)</h3>
            <ol>
              <li>
                ① "몰"은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, '몰'이 재화 공급 가능한
                빠른 시일 내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만,
                "몰"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 지체없이 조치를 취합니다. 이때
                "몰"은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
              </li>
              <li>
                ② "몰"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을
                명시합니다. 이때 재화의 생산 중단 및 "몰"의 기타 사정에 의한 재화의 공급이 어려운 경우 재화는
                고지 없이 제공이 중단될 수 있으며 이에 대하여 회사는 책임을 부담하지 않습니다.
              </li>
            </ol>
          </td>
          <td>
            <h3>제13조(재화 등의 공급)</h3>
            <ol>
              <li>
                ① "몰"은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, '몰'이 재화 공급 가능한
                빠른 시일 내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만,
                "몰"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 지체없이 조치를 취합니다. 이때
                "몰"은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
              </li>
              <li>
                ② "몰"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을
                명시합니다. 이때 재화의 생산 중단 및 "몰"의 기타 사정에 의한 재화의 공급이 어려운 경우 재화는
                고지 없이 제공이 중단될 수 있으며 이에 대하여 회사는 책임을 부담하지 않습니다.
              </li>
              <li>
                ③ 본 약관 9조 1항을 참고, 마플샵이 취급하고 있는 상품의 종류에 따라서 배송일자, 배송방법이 다를
                수 있음을 고객에게 명시하고 있으며, 해당 "몰"은 고객이 상품을 구매하기전에 배송과 관련
                주의사항을 인지할 수 있는 곳에 안내해드리고 있습니다. 이용자는 구매전 해당 약관에 대한 동의
                없이는 특정 상품에 대한 구매가 불가능합니다.
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            <h3>제15조(청약철회 등)</h3>
            <p>(중략)</p>
            <ol>
              <li>
                ③ 제2항 제2호 내지 제4호의 경우에 "몰"이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알
                수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이
                제한되지 않습니다.
              </li>
              <li>
                ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나
                계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알
                수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
              </li>
            </ol>
          </td>
          <td>
            <h3>제15조(청약철회 등)</h3>
            <p>(중략)</p>
            <ol>
              <li>
                ③ 마플샵 배송 상품이 아닌 판매자가 직접 판매하는 “셀러 배송 상품"의 청약철회가 필요할 경우에는
                "몰" 이용자가 판매자와 직접 소통을 통하여 진행해야 합니다.
              </li>
              <li>
                ④ "셀러 배송 상품"의 상품 및 서비스적 결함 그리고 하자가 발생할 경우 "몰"은 통신판매중개자로
                거래 당사자가 아니므로, 본 상품에 대한 청약철회의 책임 "몰"에게 없으며 해당 판매자에게 있습니다.
              </li>
              <li>⑤ 청약철회의 귀책사유가 이용자에 있을 경우에는 청약철회가 어려울 수 있습니다.</li>
              <li>
                ⑥ 제2항 제2호 내지 제4호의 경우에 "몰"이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알
                수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이
                제한되지 않습니다.
              </li>
              <li>
                ⑦ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나
                계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알
                수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            <h3>제16조(청약철회 등의 효과)</h3>
            <p>(중략)</p>
            <ol>
              <li>
                ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 "몰"은 청약철회 시 그 비용을 누가
                부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.
              </li>
            </ol>
          </td>
          <td>
            <h3>제16조(청약철회 등의 효과)</h3>
            <p>(중략)</p>
            <ol>
              <li>
                ④ 다만, 이용자의 구매 상품이 "셀러 배송 상품"이며, 교환/환불의 귀책사유가 판매자에게 있는 경우
                공급받은 재화 등의 반환에 필요한 비용은 셀러가 직접 부담을 하고 있습니다.
              </li>
              <li>
                ⑤ "셀러 배송 상품"의 교환/환불의 귀책사유가 불분명한 경우는 이용자가 판매자와 직접 소통을 통해서
                해결하는 것을 원칙으로 삼고 있습니다.
              </li>
              <li>
                ⑥ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 "몰"은 청약철회 시 그 비용을 누가
                부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            <h3>제23조(분쟁해결)</h3>
            <ol>
              <li>
                ① "몰"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여
                피해보상처리기구를 설치․운영합니다.
              </li>
              <li>
                ② "몰"은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한
                처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
              </li>
              <li>
                ③ "몰"과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는
                공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
              </li>
            </ol>
          </td>
          <td>
            <h3>제23조(분쟁해결)</h3>
            <p>(중략)</p>
            <ol>
              <li>
                ④ 이용자는 판매자가 직접 등록한 "셀러 배송 상품" 관련 분쟁해결을 위해 홈페이지에서 판매자에게
                직접 문의를 할 수 있으며, 판매자는 접수된 이용자의 문의에 성실하게 답하고 사건을 처리할 책임을
                가지고 있습니다.
              </li>
              <li>
                ⑤ "셀러 배송 상품"의 하자와 결함의 원인이 판매자에게 있을 경우, 결제 대금 및 교환 그리고 환불에
                대한 책임은 판매자에게 있으며 판매자는 이용자가 느끼는 불편함에 대해서 해결하며 사건을 종결시킬
                책임을 가지고 있습니다.
              </li>
              <li>
                ⑥ "셀러 배송 상품"의 환불시 발생할 수 있는 추가 배송비의 부담의 주체는 이용자와 판매자의 소통을
                통하여 정해야하며, 기존 환불금에 포함되어 있는 배송비와는 별도로 계산해야합니다. (초기배송비 및
                반품배송비, 제주 및 도서산간비용 모두 별도 처리가 필요)
              </li>
              <li>
                ⑦ "마플샵 배송 상품"의 하자 및 결함이 발견되었을 경우, 이용자는 반드시 구매확정을 하기 전에 해당
                결함을 배송이 완료 된 날로부터 7일이내 해당 “몰"에 보고함으로서 교환 및 환불에 대한 클레임을 걸
                수 있습니다. 구매확정 이후 발견되는 결함에 대해서는 해당 “몰"은 책임을 지지 않습니다.
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            <h3>제 26조(면책)</h3>
            <ol>
              <li>
                ① "몰"은 천재지변, 불가항력 기타 "몰"의 합리적인 통제범위를 벗어난 사유로 인하여 서비스를 제공할
                수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.
              </li>
              <li>
                ② "몰"은 이용자의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지
                않습니다.
              </li>
              <li>
                ③ "몰"은 이용자가 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해 얻은 자료를
                이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다.
              </li>
              <li>
                ④ 이용자가 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 또는 정확성에 대하여는 해당
                이용자가 책임을 부담하며, "몰"은 내용의 부적정성으로 인해 이용자 또는 제3자에게 발생한 손해에
                대하여는 아무런 책임을 부담하지 않습니다.
              </li>
              <li>
                ⑤ "몰"은 서비스 이용과 관련하여 이용자의 고의 또는 과실로 인하여 이용자 또는 제3자에게 발생한
                손해에 대하여는 아무런 책임을 부담하지 않습니다.
              </li>
            </ol>
          </td>
          <td>
            <h3>제 26조(면책)</h3>
            <p>(중략)</p>
            <ol>
              <li>
                ① "몰"은 천재지변, 불가항력 기타 "몰"의 합리적인 통제범위를 벗어난 사유로 인하여 서비스를 제공할
                수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.
              </li>
              <li>
                ② "몰"은 이용자의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지
                않습니다.
              </li>
              <li>
                ③ "몰"은 이용자가 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해 얻은 자료를
                이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다.
              </li>
              <li>
                ④ 이용자가 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 또는 정확성에 대하여는 해당
                이용자가 책임을 부담하며, "몰"은 내용의 부적정성으로 인해 이용자 또는 제3자에게 발생한 손해에
                대하여는 아무런 책임을 부담하지 않습니다.
              </li>
              <li>
                ⑤ "몰"은 서비스 이용과 관련하여 이용자의 고의 또는 과실로 인하여 이용자 또는 제3자에게 발생한
                손해에 대하여는 아무런 책임을 부담하지 않습니다.
              </li>
              <li>
                ⑥ "몰"은 "셀러 배송 상품"에 한하여 이용자와 판매자간의 상품 공급과 이용을 위한 중개 서비스만을 제공할 뿐, 판매자가 마켓을
                통해 취급하는 상품에 대하여 어떠한 보증을 제공하지 않습니다.
              </li>
              <li>
                ⑦ 이용자가 서비스를 이용할 때 잘못된 정보를 입력하여 대금지급이 이루어졌을 경우 회사는 이에
                대하여 책임을 지지 않습니다.
              </li>
              <li>
                ⑧ "셀러 배송 상품"에 한해서는 이용자가 주문을 확정하면 거래가 종료됩니다. 따라서 주문 확정을 통하여 대금의 지급이 완료된
                이후 발생한 분쟁 또는 불만 사항은 원칙적으로 거래 당사자 간 협의를 통해 해결해야 합니다.
              </li>
              <li>
                ⑨ "몰"은 개별 판매자가 상품을 판매할 수 있는 “마켓플레이스"를 제공하고 있으며 회사는
                통신판매중개자로 거래 당사자가 아니므로, 판매자가 등록한 상품정보 및 거래 등에 대해 일체 책임을
                지지 않습니다.
              </li>
              <li>⑩ 단, 회사가 판매자로 등록 판매한 상품의 경우는 판매자로서 책임을 직접 부담합니다.</li>
            </ol>
          </td>
        </tr>
      </table>

      <p>
        변경된 서비스 이용약관은 2023년 1월25일부터 적용됩니다. 변경된 이용약관에 동의하시지 않는 경우, 마플
        홈페이지에서 탈퇴 혹은 마플샵 고객센터를 통해 탈퇴를 요청하시거나 이의 제기를 하실 수 있습니다.
      </p>
      <p>
        변경된 약관의 사전 변경 고지 개시 후 7일 이내에 별도의 이의 제기를 하지 않는 경우에는 본 약관 변경에
        동의한 것으로 간주하여 변경 된 이용약관이 적용됩니다.
      </p>
    </div>
    <hr style="margin-top: 50px;" />
  </div>

  ${kr20230125()}
  </div>
`;
