import { createCanvasElement } from '../../../../Canvas/S/util.js';
import { each, every, find, go, map } from 'fxjs/es';
import { makeImage } from '../../../../Maker/F/canvas_trim.js';
import { NewMakerCheckerS } from '../../S/Function/module/NewMakerCheckerS.js';

async function makeAmbiguousAreaCanvas({ print_area, safety_area, print_area_img_url, safety_area_img_url }) {
  if (!(print_area && safety_area)) throw Error('require print_area, safety_area');
  const canvas = createCanvasElement({
    width: G.mp.maker.CANVAS_WIDTH_ORIGIN,
    height: G.mp.maker.CANVAS_WIDTH_ORIGIN,
  });
  const ctx = canvas.getContext('2d');
  if (print_area_img_url && safety_area_img_url) {
    const img = await makeImage(G.to_600(print_area_img_url));
    ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      print_area.left,
      print_area.top,
      print_area.width,
      print_area.height,
    );
    ctx.globalCompositeOperation = 'destination-out';
    const img2 = await makeImage(G.to_600(safety_area_img_url));
    ctx.drawImage(
      img2,
      0,
      0,
      img2.width,
      img2.height,
      safety_area.left,
      safety_area.top,
      safety_area.width,
      safety_area.height,
    );
  } else {
    if (print_area.width > safety_area.width || print_area.height > safety_area.height) {
      ctx.fillStyle = '#ff0000';
      ctx.fillRect(print_area.left, print_area.top, print_area.width, print_area.height);
      ctx.globalCompositeOperation = 'destination-out';
      ctx.fillRect(safety_area.left, safety_area.top, safety_area.width, safety_area.height);
    } else {
      ctx.fillStyle = '#ff0000';
      ctx.fillRect(safety_area.left, safety_area.top, safety_area.width, safety_area.height);
      ctx.globalCompositeOperation = 'destination-out';
      ctx.fillRect(print_area.left, print_area.top, print_area.width, print_area.height);
    }
  }
  return canvas;
}

const _isAmbigousAreaCheckPass = async ({
  designs,
  print_area,
  safety_area,
  print_area_img_url,
  safety_area_img_url,
}) => {
  const canvas = createCanvasElement({
    width: G.mp.maker.CANVAS_WIDTH_ORIGIN,
    height: G.mp.maker.CANVAS_WIDTH_ORIGIN,
  });
  const ctx = canvas.getContext('2d');
  const fcanvas = new window.fabric.Canvas(canvas, {
    enableRetinaScaling: false,
  });
  await go(
    designs,
    map(G.mp.maker.from_cv_attrs_for_maker),
    each((cv_obj) => fcanvas.add(cv_obj)),
  );
  const ambiguous_area_canvas = await makeAmbiguousAreaCanvas({
    print_area,
    safety_area,
    print_area_img_url,
    safety_area_img_url,
  });
  ctx.globalCompositeOperation = 'destination-in';
  ctx.drawImage(ambiguous_area_canvas, 0, 0);
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let pixel = 0;
  for (let y = 0; y < canvas.height; y++)
    for (let x = 0; x < canvas.width; x++) {
      const idx = (x + canvas.width * y) * 4;
      if (imageData.data[idx + 3] !== 0) pixel++;
    }
  const ambiguous_area_pixel_count = Math.abs(
    print_area.width * print_area.height - safety_area.width * safety_area.height,
  );
  return pixel === 0 || pixel === ambiguous_area_pixel_count;
};

/*
 * 현재 WE 제품만 쓰고 있음.
 * */
export const isAmbiguousAreaCheckPass = async ({
  product_faces2,
  base_product_size_id,
  base_product_faces,
}) => {
  if (!every([product_faces2, base_product_size_id, base_product_faces]))
    throw Error('require product_faces2, base_product_size_id, base_product_faces');
  return go(
    product_faces2.value,
    every((pf) => {
      const { bpf_id, designs } = pf;
      const { print, safety } = NewMakerCheckerS.getSizeFace({
        base_product_faces,
        base_product_size_id,
        base_product_face_id: bpf_id,
      });

      console.log('print', print);

      if (!safety) return true;
      const print_area = print.px;
      const safety_area = safety.px;
      if (print_area.width === safety_area.width) return true;
      return _isAmbigousAreaCheckPass({ designs, print_area, safety_area });
    }),
  );
};

export const findSthInAmbiguousArea = async ({
  product_faces2,
  base_product_size_id,
  base_product_faces,
}) => {
  if (!every([product_faces2, base_product_size_id, base_product_faces]))
    throw Error('require product_faces2, base_product_size_id, base_product_faces');
  return go(
    product_faces2.value,
    find(async (pf) => {
      const { bpf_id, designs } = pf;
      const { print, safety } = NewMakerCheckerS.getSizeFace({
        base_product_faces,
        base_product_size_id,
        base_product_face_id: bpf_id,
      });
      if (!safety) return;
      const print_area = print.px;
      const print_area_img_url = print.img_url;
      const safety_area = safety.px;
      const safety_area_img_url = safety.img_url;
      if (
        Math.floor(print_area.width) === Math.floor(safety_area.width) &&
        Math.floor(print_area.height) === Math.floor(safety_area.height)
      )
        return;
      return !(await _isAmbigousAreaCheckPass({
        designs,
        print_area,
        safety_area,
        print_area_img_url,
        safety_area_img_url,
      }));
    }),
  );
};

export const findSthInOuterPrintArea = async ({
  product_faces2,
  base_product_size_id,
  base_product_faces,
}) => {
  if (!product_faces2) throw new Error('no product_faces2');
  if (!base_product_size_id) throw new Error('no base_product_size_id');
  if (!base_product_faces) throw new Error('no base_product_faces');
  return go(
    product_faces2.value,
    find(async (pf) => {
      const { bpf_id, designs } = pf;
      const { print } = NewMakerCheckerS.getSizeFace({
        base_product_faces,
        base_product_size_id,
        base_product_face_id: bpf_id,
      });
      const overflow_of_horizontal_print_area_checker =
        print?.etc_meta?.overflow_of_horizontal_print_area_checker;
      const overflow_of_vertical_print_area_checker =
        print?.etc_meta?.overflow_of_vertical_print_area_checker;
      if (!(overflow_of_horizontal_print_area_checker || overflow_of_vertical_print_area_checker)) return;
      const print_area = print.px;
      const margin = 20;
      return await go(undefined, async () => {
        const safety_area = {
          left: print_area.left,
          width: print_area.width,
          top: print_area.top,
          height: print_area.height,
        };
        if (overflow_of_horizontal_print_area_checker) {
          safety_area.left -= margin;
          safety_area.width += margin * 2;
        }
        if (overflow_of_vertical_print_area_checker) {
          safety_area.top -= margin;
          safety_area.height += margin * 2;
        }
        const canvas = createCanvasElement({
          width: G.mp.maker.CANVAS_WIDTH_ORIGIN,
          height: G.mp.maker.CANVAS_WIDTH_ORIGIN,
        });
        const ctx = canvas.getContext('2d');
        const fcanvas = new window.fabric.Canvas(canvas, {
          enableRetinaScaling: false,
        });
        await go(
          designs,
          map(G.mp.maker.from_cv_attrs_for_maker),
          each((cv_obj) => fcanvas.add(cv_obj)),
        );
        const ambiguous_area_canvas = await makeAmbiguousAreaCanvas({
          print_area,
          safety_area,
        });
        ctx.globalCompositeOperation = 'destination-in';
        ctx.drawImage(ambiguous_area_canvas, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let pixel = 0;
        for (let y = 0; y < canvas.height; y++)
          for (let x = 0; x < canvas.width; x++) {
            const idx = (x + canvas.width * y) * 4;
            if (imageData.data[idx + 3] !== 0) pixel++;
          }
        return pixel > 0;
      });
    }),
  );
};

export const findSthInInnerPrintArea = async ({
  product_faces2,
  base_product_size_id,
  base_product_faces,
}) => {
  if (!product_faces2) throw new Error('no product_faces2');
  if (!base_product_size_id) throw new Error('no base_product_size_id');
  if (!base_product_faces) throw new Error('no base_product_faces');
  return go(
    product_faces2.value,
    find(async (pf) => {
      const { bpf_id, designs } = pf;
      const { print } = NewMakerCheckerS.getSizeFace({
        base_product_faces,
        base_product_size_id,
        base_product_face_id: bpf_id,
      });
      const possible_of_horizontal_fitted_print_area_checker =
        print?.etc_meta?.possible_of_horizontal_fitted_print_area_checker;
      const possible_of_vertical_fitted_print_area_checker =
        print?.etc_meta?.possible_of_vertical_fitted_print_area_checker;
      if (
        !(possible_of_horizontal_fitted_print_area_checker || possible_of_vertical_fitted_print_area_checker)
      )
        return;
      const print_area = print.px;
      const margin = 50;
      return await go(undefined, async () => {
        const safety_area = {
          left: print_area.left,
          top: print_area.top,
          width: print_area.width,
          height: print_area.height,
        };

        if (possible_of_horizontal_fitted_print_area_checker) {
          safety_area.left += margin;
          safety_area.width -= margin * 2;
        }
        if (possible_of_vertical_fitted_print_area_checker) {
          safety_area.top += margin;
          safety_area.height -= margin * 2;
        }
        const pixel = await _findPixel({ designs, print_area, safety_area });
        if (pixel > 0) {
          const margin = 1;
          const edge_pixel1 = await go(undefined, () => {
            const _print_area = {
              left: print_area.left,
              top: print_area.top,
              width: print_area.width,
              height: print_area.height,
            };
            const safety_area = {
              left: _print_area.left,
              top: _print_area.top,
              width: _print_area.width,
              height: _print_area.height,
            };
            if (possible_of_horizontal_fitted_print_area_checker) {
              safety_area.left += margin;
              safety_area.width = Math.floor(safety_area.width / 2);
              safety_area.width -= margin;
              _print_area.width = Math.floor(_print_area.width / 2);
            }
            if (possible_of_vertical_fitted_print_area_checker) {
              safety_area.top += margin;
              safety_area.height = Math.floor(safety_area.height / 2);
              safety_area.height -= margin;
              _print_area.height = Math.floor(_print_area.height / 2);
            }
            return _findPixel({ designs, print_area: _print_area, safety_area });
          });
          const edge_pixel2 = await go(undefined, () => {
            const _print_area = {
              left: print_area.left,
              top: print_area.top,
              width: print_area.width,
              height: print_area.height,
            };
            const safety_area = {
              left: _print_area.left,
              top: _print_area.top,
              width: _print_area.width,
              height: _print_area.height,
            };
            if (possible_of_horizontal_fitted_print_area_checker) {
              const is_float = safety_area.width % 2 !== 0;
              safety_area.width = Math.floor(safety_area.width / 2);
              safety_area.left += safety_area.width + (is_float ? 1 : 0);
              safety_area.width -= margin;
              _print_area.width = Math.floor(_print_area.width / 2);
              _print_area.left += _print_area.width + (is_float ? 1 : 0);
            }
            if (possible_of_vertical_fitted_print_area_checker) {
              const is_float = safety_area.height % 2 !== 0;
              safety_area.height = Math.floor(safety_area.height / 2);
              safety_area.top += safety_area.height + (is_float ? 1 : 0);
              safety_area.height -= margin;
              _print_area.height = Math.floor(_print_area.height / 2);
              _print_area.top += _print_area.top + +(is_float ? 1 : 0);
            }
            return _findPixel({ designs, print_area: _print_area, safety_area });
          });
          return edge_pixel1 === 0 || edge_pixel2 === 0;
        }
      });
    }),
  );
};

async function _findPixel({ designs, print_area, safety_area }) {
  const canvas = createCanvasElement({
    width: G.mp.maker.CANVAS_WIDTH_ORIGIN,
    height: G.mp.maker.CANVAS_WIDTH_ORIGIN,
  });
  const ctx = canvas.getContext('2d');
  const fcanvas = new window.fabric.Canvas(canvas, {
    enableRetinaScaling: false,
  });
  await go(
    designs,
    map(G.mp.maker.from_cv_attrs_for_maker),
    each((cv_obj) => fcanvas.add(cv_obj)),
  );
  const ambiguous_area_canvas = await makeAmbiguousAreaCanvas({
    print_area,
    safety_area,
  });
  ctx.globalCompositeOperation = 'destination-in';
  ctx.drawImage(ambiguous_area_canvas, 0, 0);
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let pixel = 0;
  for (let y = 0; y < canvas.height; y++)
    for (let x = 0; x < canvas.width; x++) {
      const idx = (x + canvas.width * y) * 4;
      if (imageData.data[idx + 3] !== 0) pixel++;
    }
  return pixel;
}
