import axios from 'axios';
import { flat, go, pluck, reject, sel, tap, uniqBy } from 'fxjs/es';
import { isAmbiguousAreaCheckPass } from './isAmbiguousAreaCheckPass.js';
import { findSmallerCvImageThanRequirement } from './isImageQualityPass.js';
import { isParallelCheckPassOnlyWeForServer } from './isParallelCheckPassOnlyWe.js';

function getProducts() {
  return axios('/@api/test_search/seller_products_by_id');
}

// const bp_세로_엽서 = await go(getProducts(4577),
//   filter(isAmbiguousAreaCheckPass()),
//   filter(isImageQualityPass()),
//   filter(isParallelCheckPassOnlyWeForServer())
// );
// const bp_정사각_엽서 = await go(getProducts(4578),
//   filter(isAmbiguousAreaCheckPass()),
//   filter(isImageQualityPass()),
//   filter(isParallelCheckPassOnlyWeForServer())
// );
// const bp_A5_노트 = await go(getProducts(4602),
//   filter(isAmbiguousAreaCheckPass()),
//   filter(isImageQualityPass()),
//   filter(isParallelCheckPassOnlyWeForServer())
// );
// const bp_B5_노트 = await go(getProducts(4580),
//   filter(isAmbiguousAreaCheckPass()),
//   filter(isImageQualityPass()),
//   filter(isParallelCheckPassOnlyWeForServer())
// );
export const updateWeProductsForWrongProduct = async () => {
  await go(
    getProducts(),
    sel('data'),
    tap((products) => console.log(`총: ${products.length}`)),
    async (products) =>
      Promise.all([
        reject(
          ({ product_faces2, base_product_size_id, _ }) =>
            isAmbiguousAreaCheckPass({
              product_faces2,
              base_product_size_id,
              base_product_faces: _.base_product._.base_product_faces,
            }),
          products,
        ),
        reject(
          ({ product_faces2, base_product_size_id, _ }) =>
            !findSmallerCvImageThanRequirement({
              product_faces2,
              base_product_size_id,
              base_product_faces: _.base_product._.base_product_faces,
            }),
          products,
        ),
        reject(
          ({ product_faces2, base_product_size_id, _ }) =>
            isParallelCheckPassOnlyWeForServer({
              product_faces2,
              base_product_size_id,
              base_product_faces: _.base_product._.base_product_faces,
            }),
          products,
        ),
      ]),
    flat,
    uniqBy((product) => product.id),
    pluck('id'),
    async (product_ids) => {
      window.product_ids = product_ids;
      const bool = await $.confirm(`진행합니까?`);
      if (bool) await axios.post('/@api/test_search/products', { product_ids });
    },
  );
};
