import { format } from 'date-fns';
import dataStr from 'fxdom/es/dataStr.js';
import { html, filter, find, go, map, sel, strMap, sumBy } from 'fxjs/es';
import { selectedOptionGroup } from '../../../../../BpOption/S/Function/selectedOptionGroup.js';
import { UserProductS } from '../../../../../UserProduct/S/Function/module/UserProductS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPMyPageConstantS } from '../../../S/Constant/module/OMPMyPageConstantS.js';
import { ORDER_STATUS } from '../Constant/order.js';
import { OMPMyPageOrderS } from '../Function/module/OMPMyPageOrderS.js';
import { informationIcon } from './orderInformationIcon.js';

export const orderTableOrderInfoColumTmpl = (up_c) => {
  return html`
    <span>${format(new Date(up_c.table_data.created_at), 'yyyy-MM-dd')}</span>
    <span class="underline">${up_c.table_data.prj_id}</span>
  `;
};

export const orderTableDetailColumTmpl = (up_c, show_model_thumb_url = false) => {
  const { is_cancel_req, is_canceled } = up_c.table_data;
  const product_face = sel('_.product_color.product_faces2.value.0', up_c);
  const thumbnail_url = go(sel('_.product_color.thumbnails.value', up_c), find(sel('is_thumb')), sel('url'));

  const select_option_name = up_c._.product_color._.selected_option_group[OMPCoreUtilS.col('title')];
  const option_names = selectedOptionGroup.getOnlyOptionNames(up_c._.product_color._.selected_option_group);

  const infos = go(
    up_c._.up_c_ss,
    filter((up_c_s) => !up_c_s.is_hidden),
    map((up_c_s) => {
      return {
        color: up_c._.product_color._.base_product_color[OMPCoreUtilS.col('name')],
        size: up_c_s._.base_product_size[OMPCoreUtilS.col('name')],
        select_option_name,
        option_names,
        quantity: UtilS.commify(up_c_s.quantity),
        selected_option_group: up_c._.product_color._.selected_option_group,
        base_product_size: up_c_s._.base_product_size,
        base_product_color: up_c._.product_color._.base_product_color,
        base_product: up_c._.product_color._.base_product,
        product_faces2: up_c._.product_color.product_faces2,
      };
    }),
  );
  const name = up_c._.product_color._.base_product[OMPCoreUtilS.col('name')];

  const bp_thumbnail = up_c._.product_color._.base_product[OMPCoreUtilS.col('model_thumb_url')];
  const { pdf_printing_file, product_pdf_printing_file_histories } = up_c._.product_color._;
  const [pdf_printing_file_history] = product_pdf_printing_file_histories || [];
  const { issue_customer } = pdf_printing_file_history || {};

  const has_issue_customer = !UtilS.isEmpty(issue_customer) && !(is_cancel_req || is_canceled);
  const has_pdf_printing_file = !UtilS.isEmpty(pdf_printing_file);
  const pdf_file_expiration = has_pdf_printing_file
    ? OMPCoreUtilS.isExpired({ expired_at: pdf_printing_file?.expired_at })
    : false;

  return html`
    <div class="omp-order-product__thumbnail" data-fx-json="${dataStr(product_face)}">
      ${has_pdf_printing_file && show_model_thumb_url
        ? html`<img src="${bp_thumbnail}" alt="" />`
        : html`${has_issue_customer ? informationIcon() : ''}
          ${pdf_file_expiration
            ? `<div class="expiration">${html`${TT('pdf::expired::3')}`}</div>`
            : UserProductS.thumbnail.smallCanvasHtml()}`}
    </div>
    <div class="omp-order-product__info" data-file-error="${has_issue_customer}">
      <div class="omp-order-product__name">
        <span>${name}</span>
      </div>
      ${strMap(
        ({
          quantity,
          selected_option_group,
          base_product_size,
          base_product_color,
          base_product,
          product_faces2,
        }) => {
          const option_names = UserProductS.naming.makeOptionNames1(
            {
              selected_option_group,
              base_product_size,
              base_product_color,
              base_product,
              product_faces2,
            },
            undefined,
            undefined,
            true,
          );

          return html` <div class="omp-order-product__detail">
            ${!UtilS.isEmpty(option_names) ? `${option_names} /` : ''}
            ${quantity > 0
              ? html`<span>${TT('common::quantity', { quantity })}</span>`
              : TT('my_page::landing::landing_21')}
          </div>`;
        },
        infos,
      )}
    </div>
  `;
};

export const orderTablePriceColumTmpl = (up_c) => {
  const { currency_en } = up_c.table_data;
  const { price, discounted_price } = OMPMyPageOrderS.makeUpcProductPrice(up_c, currency_en);

  return html`
    ${price === discounted_price
      ? html` <div>${OMPMyPageOrderS.getCurrency(price, currency_en)}</div> `
      : html`
          <div class="discounted">${OMPMyPageOrderS.getCurrency(price, currency_en)}</div>
          <div>${OMPMyPageOrderS.getCurrency(discounted_price, currency_en)}</div>
        `}
  `;
};

export const orderTableStatusColumTmpl = (up_c) => {
  const order_status_title = OMPMyPageConstantS.OrderStatusTitle();
  const { table_data } = up_c;
  const { is_cancel_req, is_canceled, status, shipping } = table_data;

  const is_canceled_status = is_cancel_req || is_canceled;

  let status_key = is_cancel_req && is_canceled ? 'canceled' : is_cancel_req ? 'cancel_request' : status;

  const total_upcs_qty = sumBy((upcs) => upcs.quantity, up_c._.up_c_ss);

  status_key = is_canceled_status
    ? status_key
    : total_upcs_qty === 0
    ? ORDER_STATUS.INDIVIDUAL_CANCEL
    : status_key;

  const delivery_company_name = shipping._.shipping_company.name;
  const shipping_tracking_number = shipping.waybill_number;
  const shipping_tracking_url = OMPMyPageOrderS.makeShippingCheckLink(shipping);

  const { product_pdf_printing_file_histories } = up_c._.product_color._;
  const [pdf_printing_file_history] = product_pdf_printing_file_histories || [];
  const { issue_customer } = pdf_printing_file_history || {};

  return html`
    <span
      class="omp-order-product-status__name ${issue_customer &&
      !['canceled', 'cancel_request'].includes(status_key)
        ? 'omp-order-product-status__file-error'
        : ''}"
      >${issue_customer && !['canceled', 'cancel_request'].includes(status_key)
        ? `${TT('pdf::resubmit::5')}`
        : order_status_title[status_key]}</span
    >
    ${!issue_customer && delivery_company_name && shipping_tracking_number && shipping_tracking_url
      ? html`<span class="omp-order-product-status__info"
          >${delivery_company_name}
          <a href="${shipping_tracking_url}" target="_blank" class="underline"
            >${shipping_tracking_number}</a
          ></span
        >`
      : ''}
  `;
};

export const orderTableEtcColumTmpl = (up_c) => {
  const { product_pdf_printing_file_histories } = up_c._.product_color._;
  const [pdf_printing_file_history] = product_pdf_printing_file_histories || [];
  const { issue_customer } = pdf_printing_file_history || {};

  const { is_cancel_req, is_canceled } = up_c.table_data;

  const can_check_modifications = !UtilS.isEmpty(issue_customer) && !(is_cancel_req || is_canceled);

  const is_designed_by_worker = up_c.product_color_id === up_c.printable_product_id;

  const can_purchase_url =
    up_c.base_product_id && !can_check_modifications ? OMPMyPageOrderS.makeUpcUrl(up_c) : false;

  const order_review_condition =
    ['confirmed', 'before_confirm', 'delivering'].includes(up_c.table_data.status) &&
    !up_c.table_data.is_cancel_req &&
    !up_c.table_data.is_canceled;

  const can_review = order_review_condition && !up_c.review_id;

  return html`
    ${can_review
      ? html`<span class="table-button__review" data-up_c_id="${up_c.id}" data-prj_id="${up_c.projection_id}">
          ${TT('my_page::order::order_32')}
        </span>`
      : ''}
    ${can_check_modifications
      ? html`<button class="table-button__check_modifications" type="button" data-fx-json="${dataStr(up_c)}">
            ${TT('pdf::resubmit::3')}</button
          ><button class="table-button__resubmit" type="button" data-fx-json="${dataStr(up_c)}">
            ${TT('pdf::resubmit::2')}
          </button>`
      : ''}
    ${can_purchase_url && !can_check_modifications
      ? `<a href="${
          can_purchase_url || ''
        }" class="table-button__purchase" is_designed_by_worker="${is_designed_by_worker}">
      ${can_purchase_url ? TT('my_page::order::order_31') : ''}
    </a>`
      : ''}
  `;
};

export const orderTablePrintingFileColumTmpl = (pdf_printing_file) => {
  if (UtilS.isEmpty(pdf_printing_file)) {
    return ``;
  }

  const { pdf_url, file_name, created_at, expired_at } = pdf_printing_file;
  const [file_title] = (file_name || '').split('.pdf');
  const is_expired = OMPCoreUtilS.isExpired({ expired_at });

  return html`
    <div class="omp-order-product__printing-file">
      <div class="omp-order-product__printing-file-name ${is_expired ? 'disabled' : ''}">
        ${is_expired
          ? `<div class="omp-order-product__printing-file-title">${file_name}</div>`
          : `<div class="omp-order-product__printing-file-title" data-file-url="${pdf_url}" data-file-name="${file_name}">${file_title}</div>`}
        <span class="omp-order-product__printing-file-mimetype">.pdf</span>
      </div>
      <div class="omp-order-product__printing-file-date">
        ${is_expired ? `${TT('pdf::expired::2')}` : moment(created_at).format('YYYY-MM-DD HH:mm')}
      </div>
    </div>
  `;
};

export const orderTableProductSizesColumnTmpl = ({ product_info }) => {
  const size_option = selectedOptionGroup.getSizeValue(product_info.selected_option_group);

  const sizes = map(
    (up_c_s) => {
      return {
        _is_not_stock: up_c_s._is_not_stock,
        _is_discontinued: up_c_s._is_discontinued,
        size: up_c_s.size,
        quantity: UtilS.commify(up_c_s.quantity),
      };
    },
    filter((up_c_s) => !up_c_s.is_hidden, product_info.infos),
  );

  if (!UtilS.isEmpty(size_option)) {
    const [size] = sizes;
    const { _is_not_stock, _is_discontinued, quantity } = size;
    return html`
      <div
        class="omp-order-product__size-set"
        data-is_not_stock="${_is_not_stock}"
        data-is_discontinued="${_is_discontinued}"
      >
        <span class="omp-order-product__size">${size_option}</span>
        <span class="omp-order-product__quantity"
          >${UtilS.safeStrToNumber(quantity) > 0 ? quantity : TT('my_page::landing::landing_21')}</span
        >
      </div>
    `;
  }

  return strMap(
    ({ size, quantity, _is_not_stock, _is_discontinued }) => html`
      <div
        class="omp-order-product__size-set"
        data-is_not_stock="${_is_not_stock}"
        data-is_discontinued="${_is_discontinued}"
      >
        <span class="omp-order-product__size">${size}</span>
        <span class="omp-order-product__quantity"
          >${UtilS.safeStrToNumber(quantity) > 0 ? quantity : TT('my_page::landing::landing_21')}</span
        >
      </div>
    `,
    sizes,
  );
};
